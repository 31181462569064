import { appConstants } from "../helpers/actionType";
export const target10Reducer = (state = { cartItems: [], currentDraw: {} }, action) => {
  switch (action.type) {
    case appConstants.ADD_CARTITEM:
      return {
        ...state,
        cartItems: [...state.cartItems, action.cItem]
      };
      case appConstants.UPDATE_CARTITEM:
      return {
        ...state,
        cartItems: [...state.cartItems.filter(a => a.id != action.Items.id),{...action.Items}].sort((a, b) => (a.bMax > b.bMax) ? 1 : -1)
      };
    case appConstants.REMOVE_CARTITEM:
      return {
        ...state,
        cartItems: [...state.cartItems.filter(a => a.id != action.cid)]
      };
    case appConstants.REMOVE_CLOSEDDRAW_CARTITEM:
      return {
        ...state,
        cartItems: [...state.cartItems.filter(a => a.drawID != action.did)]
      };
    case appConstants.SET_DRAW:
      return {
        ...state,
        currentDraw: action.cDraw
      };
    case appConstants.UPDATE_STAKE_ALL:
      return {
        ...state,
        stakeAll: action.stake
      };
    case appConstants.CLEAR_CARTITEM:
      return {
        ...state,
        cartItems: []
      };    
    case appConstants.GET_CURRENT_RESULT:
      return {
        ...state,
        currentResult: action.cResult
      };
    default:
      return { ...state }
  }

}