import React, { Component } from 'react'
import { connect } from 'react-redux';
import QuickWinLeft from "./quickWinLeft";
import QuickWinRight from "./quickWinRight";
import { gameActions } from '../../../action/gamesAction';
import { quickWinActions } from '../../../action/quickWinAction';
import Countdown, { zeroPad } from 'react-countdown';
import WebGLApp from "./webGL";
import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';

class QuickWin extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cartItems: [],
			currentRunningDraw: {},
			currentFinishingDraw: {},
			coundownStart: 0,
			coundTime: 18900000,
			currentDraw: {},
			currentAvailableDraws: [],
			currentCartItem: { id: 0, ballNo: "", stakeValue: 100, oddsValue: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, drawNo: 0, gameID: 0, gameGroupID: 0 },

			ballStartNumber: 0,
			ballEndNumber: 0,
			QPBallCount: 0,
			random: [],
			showSingleMsg: false,
			_serverTimeDiff: 0,
			allGameClosed: 0,
			isViewAnimation: false,
			webglInit: false,
			timerGetResult: null,
			timerGetLatestResult: null,
			timerGetResultCount: 0,
		};
		this.my_componentWillUnmount();
		if (props.currentGamesFamily.id !== undefined) {
			props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id, this.props.i18n.language);
			props.GetLatestResult(props.currentGamesFamily.id, this.props.i18n.language);
			// let ss = Date.now;
			// let ssss = ss();
			// console.log("Date.now : " + ss);
		}
	}

	static getDerivedStateFromProps(props, state) {
		return {
			currentAvailableDraws: props.currentAvailableDraws,
			cartItems: props.cartItems,
			currentDraw: props.currentDraw,
			currentGamesFamily: props.currentGamesFamily,
			latestResult: props.latestResult,
			currentDraw: props.currentDraw,
			currentResult: props.currentResult
		};
	};

	createBall = () => {
		let ballLst = [];
		if (this.state.currentGamesFamily != undefined && this.state.currentDraw != null && Object.keys(this.state.currentDraw).length > 0) {
			for (let i = this.state.ballStartNumber; i < this.state.currentGamesFamily.ball + this.state.ballStartNumber; i++) {
				let b = ("0" + i).slice(-2);
				let b_i = this.state.currentDraw.GameId + "_" + b;
				ballLst.push(<div id={`ball_${b_i}`} onClick={() => { this.selectBall(b_i) }} className={`lottoBalls ${this.state.cartItems.filter(x => x.id == b_i).length > 0 ? "lottoBallsActive" : ""}`}>{b}</div>);
			}
			this.state.ballEndNumber = this.state.currentGamesFamily.ball + (-1 + this.state.ballStartNumber);
		}
		else {
			return <div style={{ color: "#000" }}>Draw Closed</div>;
		}
		return ballLst;
	};

	showSingleMsg = false;
	selectBall = (b_id, qpMax) => {
		if (this.state.currentDraw == null || Object.keys(this.state.currentDraw).length == 0) { this.state.showSingleMsg = true; alert("Please select the draw"); return; }
		let obj = this.state.cartItems.filter(x => x.id == b_id);
		let objLst = { id: 0, ballNo: "", stakeValue: 100, oddsValue: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, drawNo: 0, gameID: 0, gameGroupID: 0, minStake: 0, maxStake: 0 };

		let default_Min_val = this.state.currentGamesFamily.minSelect;
		let default_max_val = this.state.currentGamesFamily.maxSelect;

		if (obj.length > 0) {
			// this.state.cartItems = this.state.cartItems.filter(x => x.id !== b_id);
			// this.setState({
			// 	cartItems: this.state.cartItems.filter(x => x.id !== b_id)
			// });
			this.props.RemoveFromCart(b_id);
		}
		else if (default_max_val <= this.state.cartItems.length) {
			this.state.showSingleMsg = true;
			alert(this.props.t('lblMaximumselection : ') + default_max_val);
			return;
		}
		else {
			//no of ball~min select~max select~min stake~max stake~
			let _noOfBalls = this.state.currentDraw.NoofBalls.split('~');
			if (_noOfBalls.length > 4) {
				objLst.minStake = parseInt(_noOfBalls[3]);
				objLst.maxStake = parseInt(_noOfBalls[4]);
			}
			else {
				objLst.minStake = 100;
				objLst.maxStake = 2000;
			}
			objLst.stakeValue = objLst.minStake;
			objLst.gameName = this.state.currentDraw.LotName;
			objLst.drawID = this.state.currentDraw.DrawID;
			objLst.drawNo = this.state.currentDraw.DrawNo;
			objLst.gameID = this.state.currentDraw.GameId;
			objLst.drawDateTime = this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime;
			objLst.ballNo = b_id.split("_")[1];
			objLst.id = b_id;
			objLst.oddsValue = this.state.currentDraw.RateInPs;
			objLst.total = objLst.stakeValue * objLst.oddsValue;
			if (qpMax) {
				objLst.bMax = qpMax;
			}
			else {
				let newid = 1;
				if (this.state.cartItems.length > 0) {
					newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.bMax), this.state.cartItems[0].bMax);
				}
				objLst.bMax = newid + 1;
			}
			this.props.AddToCart(objLst);
		}
	};

	selectDraw = (_draw) => {
		this.props.SetCurrentDraw(_draw);

	};

	selectRunningDraw = () => {
		if (this.state.coundownStart != undefined && this.state.coundownStart == 0) {
			//if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			if (this.state.currentFinishingDraw != undefined) {
				//if (this.state.currentFinishingDraw.DrawID == undefined || this.state.currentRunningDraw.DrawID == undefined || this.state.currentFinishingDraw.DrawID !=this.state.currentRunningDraw.DrawID) {
				let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
				if (lst.length > 0 || this.state.currentAvailableDraws.length <= 0) {
					let _draw = this.state.currentAvailableDraws[0];
					//let _draw = lst[0];
					this.setState({
						currentRunningDraw: _draw,
					})
					this.state.currentRunningDraw = _draw;
					this.selectDraw(_draw);
				}
				else {
					return;
				}
			}

			// if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			// 	let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
			// 	if (lst.length > 0) {
			// 		return;
			// 	}
			// }

			let _draw = this.state.currentAvailableDraws[0];
			if (_draw == undefined) { this.state.coundownStart = 1; return; }
			try {
				let sTime = undefined; //new Date(Date.now() - this.state.coundTime);
				if (_draw != undefined) {
					var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
					let ss = new Date(_draw.DrawDateTime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
					sTime = new Date(ss.setSeconds(ss.getSeconds() - 60));
				}
				let dStr = ("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + sTime.getHours() + ":" + sTime.getMinutes() + ":" + sTime.getSeconds();
				window.timer = null;
				this.state.coundownStart = window.startDraw(dStr, this.RefreshAfterGameClose);
			}
			catch (ex) {
				alert(ex);
			}
		}
	};

	componentDidUpdate(previousProps, previousState) {
		if ((previousState.currentResult != this.state.currentResult) || (this.state.currentResult && this.state.currentResult.indexOf("1~") == 0)) {
			console.log("start startAnimationWebGL in componentDidUpdate with : " + this.state.currentResult);
			this.startAnimationWebGL();
		}
		if (this.state.currentAvailableDraws.length > 0 && ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length <= 0) || this.state.currentAvailableDraws[0].DrawNo != this.state.currentRunningDraw.DrawNo))
		//if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0))
		{
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				this.state.allGameClosed = 1
			}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
			if (this.state.webglInit == false && this.state.currentRunningDraw) {
				this.setWelcomScreenWebGL();
				this.state.webglInit = true;
			}

		}

	};


	componentDidUpdate_old(previousProps, previousState) {
		//if ((this.state.currentRunningDraw ==null || Object.keys(this.state.currentRunningDraw).length == 0) && this.state.currentAvailableDraws.length>0) {
		//if ((this.state.currentDraw ==null || Object.keys(this.state.currentDraw).length == 0) && (previousState.currentDraw ==null && (this.state.currentDraw==null || this.state.currentAvailableDraws.length>0))) {
		if (this.state.currentDraw != null && Object.keys(this.state.currentDraw).length != 0) {
			this.state.allGameClosed = 0;
		}
		if (previousState.currentResult != this.state.currentResult) {
			this.startAnimationWebGL();
		}
		if (this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0 && this.state.currentAvailableDraws.length > 0 && this.state.currentAvailableDraws.length == previousState.currentAvailableDraws.length) {
			return;
		}

		if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0)) {
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				this.state.allGameClosed = 1
			}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
			if (this.state.webglInit == false && this.state.currentRunningDraw) {
				this.setWelcomScreenWebGL();
				this.state.webglInit = true;
			}

		}

		//for stake all text box hide
		// if(previousProps.stakeAll !=this.props.stakeAll)
		// {
		// 	if(this.state.cartItems !=null )
		// 	{
		// 			for (let i = 0; i < this.state.cartItems.length; i++) {
		// 				this.state.cartItems[i].stakeValue=this.props.stakeAll;
		// 				this.state.cartItems[i].total = this.props.stakeAll * a.oddsValue;
		// 			}
		// 	}
		// }
	};

	componentDidMount() {
		//setTimeout(	this.RefreshAfterGameClose,10000);
		//setInterval(() => {		this.RefreshAfterGameClose()	 }, 60*1000); 
		//window.documentReadyjQuery();
		window.addEventListener('resize', window.documentReadyResize);
		//window.loadWebGL();
		//window.WelcomeAnimationScreen();
		//this.setWelcomScreenWebGL();
	};
	my_componentWillUnmount = () => {
		this.props.ClearCart();
		this.setState({
			currentRunningDraw: null,
		})
		this.props.ClearAvailableGames();
	};

	RefreshAfterGameClose = () => {
		//alert("coundown finish");
		this.state.coundownStart = 0;
		this.state._serverTimeDiff = 0;
		this.state.currentFinishingDraw = this.state.currentRunningDraw;

		this.props.RemoveClosedDrawbetFromCart(this.state.currentFinishingDraw.DrawID);
		setTimeout(() => { this.props.GameAvailableGames(this.props.user.mobileNo, this.props.currentGamesFamily.id, this.props.i18n.language) }, 2000)
		this.state.currentRunningDraw = {};
		//setTimeout(this.props.GetLatestResult(this.props.currentGamesFamily.id),21000);
		console.log("RefreshAfterGameClose drawID : " + this.state.currentFinishingDraw.GameId)
		this.state.timerGetResultCount = 0;
		this.state.timerGetResult = setTimeout(() => { this.props.GetCurrentResult(this.state.currentFinishingDraw.GameId) }, 5000);
		//setTimeout(this.props.GetCurrentResult(145), 2000);
		//setTimeout(this.startAnimationWebGL, 5000);
	};

	setWelcomScreenWebGL = () => {
		if (this.state.currentRunningDraw && this.state.currentRunningDraw.DrawDateTime) {
			var s = this.state.currentRunningDraw.DrawDateTime.split(' ');
			var strkey = "ThOtArPuElLaThA~" + this.state.currentRunningDraw.LotName + "~" + this.state.currentRunningDraw.DrawNo + "~" + s[0] + "~" + s[1] + "";
			//let strkey = "ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2";
			console.log("start setWelcomScreenWebGL");
			this.fnWelcome(strkey);
		}
	}
	startAnimationWebGL = () => {
		//alert("call animate");
		//this.state.currentResult="0~1~142~05/09/2021~10:00 AM~Quick Win~9";
		if (this.state.currentResult) {
			let arr = this.state.currentResult.split("~");
			if (arr[0] == "0") {
				//var s = this.state.currentRunningDraw.Drawdatetime.split(' ');
				//var strkey = "ThOtArPuElLaThA~" + this.state.currentRunningDraw.GameName + "~" + this.state.currentRunningDraw.GameID + "~" + s[0] + "~" + s[1] + "";
				let strkey = "ThOtArPuElLaThA~" + this.state.currentResult.substring(2, this.state.currentResult.length);
				var s = this.state.currentDraw ? this.state.currentDraw.DrawTime : "No More";
				if (s != null) {
					strkey = strkey + "~" + s;
				}
				else {
					strkey = strkey + "~00:00";
				}
				console.log("start startAnimationWebGL with : " + strkey);
				this.viewAnimationResult(1);
				this.fnStartAnimation(strkey);
				clearTimeout(this.state.timerGetResult);
				this.state.timerGetResult = null;
				this.state.timerGetResultCount = 0;
				this.state.timerGetLatestResult = setTimeout(() => { this.props.GetLatestResult(this.props.currentGamesFamily.id, this.props.i18n.language) }, 30000);
			}
			else {
				if (this.state.timerGetResult)
					clearTimeout(this.state.timerGetResult);
				if (this.state.timerGetResultCount < 5) {
					console.log("GetCurrentResult drawID : " + this.state.currentFinishingDraw.GameId)
					this.state.timerGetResult = setTimeout(() => { this.props.GetCurrentResult(this.state.currentFinishingDraw.GameId, this.props.i18n.language) }, 2000);
				}
				this.state.timerGetResultCount++;
				//	setTimeout(this.startAnimationWebGL(), 3000);
			}
		}
		else {
			//	this.props.GetCurrentResult(this.state.currentDraw.DrawNo)
			//	setTimeout(this.startAnimationWebGL(), 3000);
		}
	}

	fnWelcome = () => {
	}
	fnStartAnimation = () => {
	}
	viewAnimationResult = (flog) => {
		if (flog == 1 && this.state.isViewAnimation == true) {
			return;
		}
		this.setState({ isViewAnimation: !this.state.isViewAnimation });
	}
	clearAfterAddToCart = () => {
		let obj = this.state.cartItems.filter(x => x.gameID == this.state.currentDraw.GameId);
		for (let i = 0; i < obj.length; i++) {
			this.props.RemoveFromCart(obj[i].id);
		}
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};

	clearCart = () => {
		this.props.ClearCart();
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};

	quickPick = () => {
		if (this.state.cartItems.length == this.state.currentGamesFamily.maxSelect) {
			//this.props.ClearCart();
			//this.clearAfterAddToCart();
			alert(this.props.t('lblMaximumselection') + " : " + this.state.currentGamesFamily.maxSelect);
			return;
		}
		this.state.random = this.state.cartItems.filter(a => a.gameID == this.state.currentDraw.GameId).map(a => a.ballNo);
		this.state.QPBallCount = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		if (this.state.QPBallCount <= 0) {
			this.state.QPBallCount = this.state.currentGamesFamily.maxSelect;
		}
		var index = -1;

		let newid = 0;
		if (this.state.cartItems.length > 0) {
			newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.bMax), this.state.cartItems[0].bMax);
		}

		//for (var k = this.state.ballStartNumber; k <= this.state.QPBallCount; k++) {
		index = this.generateRandomInteger();
		let _bal = this.state.currentDraw.GameId + "_" + index;
		newid++;
		this.selectBall(_bal, newid);
		if (this.state.showSingleMsg == true) {
			this.state.showSingleMsg = false;
			return;
		}
		//}
	};

	generateRandomInteger = () => {
		var min = this.state.ballStartNumber;
		var max = this.state.ballEndNumber;
		if (this.state.random.length >= this.state.currentGamesFamily.maxSelect) {
			this.state.random = [];
		}
		let valFound = false
		while (valFound == false) {
			for (var i = min; i <= max; i++) {
				var temp = Math.floor(max - Math.random() * (max - min));
				temp = ("0" + temp).slice(-2);
				if (temp && this.state.random.indexOf(temp) == -1) {
					this.state.random.push(temp);
					valFound = true;
					return temp;
				}
			}
		}
		// 	console.log("temp123Test123 : ");
		// return	this.generateRandomInteger();
	};

	removeFromCart = (cid) => {
		this.props.RemoveFromCart(cid);
	};

	countdownCompleted = () => {
		this.RefreshAfterGameClose();
	};

	getGameTime = () => {
		let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
		if (_date != undefined && _date != "") {
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
			console.log("getGameTime : " + sTime);
			console.log("DateNow : " + Date.now());
			return sTime;
		}
		else {
			return 0;
		}
	};

	// Renderer callback with condition
	renderer = ({ total, days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state			
			return <span>Draw Closed!</span>;
		} else {
			// Render a countdown
			return <span>{zeroPad(total)}:{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
		}
	};

	getServerTime = () => {
		if (this.state._serverTimeDiff != 0 && this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0) {
			//return this.state._serverTimeDiff+Date.now()+1000;
			console.log("getServerTime : " + this.state._serverTimeDiff);
			return this.state._serverTimeDiff = (this.state._serverTimeDiff + 1000);
		}
		else {
			let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
			if (_date != undefined && _date != "") {
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
				var sTime = (new Date(ss.setSeconds(ss.getSeconds() - 10)).getTime());
				//this.state._serverTimeDiff=Date.now()-sTime;
				this.state._serverTimeDiff = sTime;
				console.log("getServerTime : " + sTime);
				console.log("DateNow : " + Date.now());
				return sTime;

			}
			else {
				return 0;
			}
		}
	};

	reCount = (rundraw) => {
		//return <span class="DropCnt" data-pid={this.state.currentDraw ? this.state.currentDraw.DrawID : ""} data-countdown={this.state.currentDraw ? this.state.currentDraw.DrawDateTime : ""}></span>;
		//return <span class="DropCnt" data-pid={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawID : ""} data-countdown={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : ""}></span>;		
		//return <span><Countdown now={Date.now} date={Date.now() + 100000} onTick={this.rendererTick()} /> </span>;

		if (this.state.currentDraw == null || rundraw == null || Object.keys(rundraw).length == 0) {

			return <span></span>;
		}
		else {
			this.state.coundownStart = 1;
			return <span key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime()} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{this.props.t('lblnextdraw')} {zeroPad((days * 24) + hours)}hh:{zeroPad(minutes)}mm:{zeroPad(seconds)}ss</div>) }} /> </span>;
		}
	};

	BuyLotteryBet = () => {
		if (this.props.user == undefined || this.props.user.mobileNo == "0") {
			alert(this.props.t('lblLoginValidation'));
			return;
		}
		if (this.state.cartItems == undefined || this.state.cartItems.length <= 0) {
			alert(this.props.t('lblplcurbet'));
			return;
		}
		let totStake = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
		let minTotStake = 500;
		let maxTotStake = 10000;
		if (this.state.currentGamesFamily != undefined) {
			minTotStake = this.state.currentGamesFamily.minStakePerTrans;
			maxTotStake = this.state.currentGamesFamily.maxStakePerTrans;
		}

		if (totStake < minTotStake)//300
		{
			alert(this.props.t('lblMinimumplayAmt') + " : " + minTotStake);
			return;
		}
		else if (totStake > maxTotStake)//10000
		{
			alert(this.props.t('lblMaximumplayAmt') + " : " + maxTotStake);
			return;
		}

		var sTime = new Date(this.props.serverTime);
		//let cartList = this.state.cartItems.sort((a, b) => (a.drawID > b.drawID) ? 1 : -1);		
		let cartList = this.state.cartItems.sort((a, b) => a.drawID - b.drawID);
		let transactionString = "";
		let gamestring = "";
		let subgameId = 0;
		cartList.forEach(item => {
			if (subgameId != 0 && subgameId != item.drawID) {
				gamestring += "*";
			}
			if (subgameId != item.drawID) {
				gamestring += item.drawID + "-" + item.gameID;
			}
			else {
				gamestring += item.gameID;
			}
			gamestring += "^0";
			gamestring += "^" + item.ballNo;
			gamestring += "^" + parseInt(item.stakeValue);
			gamestring += "^" + parseInt(item.total);
			gamestring += "^" + item.oddsValue;
			gamestring += "^0^|";
			subgameId = item.drawID;
		})
		gamestring += "*";
		let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);

		let betoption = "2";

		transactionString += `'${this.props.user.mobileNo}',`;
		transactionString += `'${TransID}',`;
		transactionString += `'${gamestring}',`;
		transactionString += `'-',`;
		transactionString += `${betoption},`;
		transactionString += `'${this.props.user.loginKey}','0','0','${this.props.i18n.language}'`;

		this.props.Buybet(transactionString);
		this.clearCart();
	};

	render() {
		const { t } = this.props;
		return (
			<div className="mainContainer">
				<Header />
				<div className="Gamesection">
					<div class="container">

						<QuickWinLeft selectDraw={this.selectDraw} currentAvailableDraws={this.state.currentAvailableDraws} />
						<div className="col-md-9 col-sm-12 smMbl">

							<div className="drawTabs">
								<a className="" id="drawTabs1">{t('lblAllDraws')}</a>
								<a className="" id="drawTabs2">{t('lbllatresult')}</a>

								<a class="" id="virtualbetSlipBtn">{t('lblgameslip')} <span class="betCount">{this.state.cartItems.length}</span></a>
							</div>
							<div class="drawTabs1024">
								<a class="" id="drawTabs3">{t('lblAllDraws')}</a>
							</div>
							{/* <div class="BetSLiparea">
								<div class="BetSLipBtn1024">
									<a id="virtualbetSlipBtn" href="#">Game Slip</a><span class="betCount"> {this.state.cartItems.length}</span>
								</div>
							</div> */}

							<div class="lottoTimesec">
								<span><img src="./assets/img/icons/quickwin.png" /></span>

								<div class="TimeSec">
									<small style={{ borderRight: "1px solid #fff" }}>{this.reCount(this.state.currentRunningDraw)}</small>
									<small>{this.state.currentDraw ? this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime : '-'}</small>
								</div>
							</div>


							<div class="lottoGame">
								<div class="col-md-12">
									<span id="eyeIcon" class={`eyeIcon eyeOpen ${this.state.isViewAnimation ? 'eyeClose' : ''}`} onClick={() => { this.viewAnimationResult() }} >{this.state.isViewAnimation ? t('lblHide') : t('lblShow')}</span>
									<div class="animateSec" style={{ display: this.state.isViewAnimation ? "block" : "none" }}>
										<WebGLApp fnWelcome={(dd) => { this.fnWelcome = dd; }} fnStartAnimation={(dd) => { this.fnStartAnimation = dd; }} />
									</div>
								</div>
								<div class="col-md-4">
									<div class="lottoInnr">
										<div class="lottoInnrTime">
											<span>{t('lbloddsval')} : <small>{this.state.currentDraw ? this.state.currentDraw.RateInPs : 0}</small></span>
										</div>
										<div className="lottoBalCont">
											{this.createBall()}
										</div>
										<div className="lottoBtns">
											<button className="redBg" onClick={() => { this.clearAfterAddToCart() }}>{t('lblclear')}</button>
											<button className="YellowBg" onClick={() => { this.quickPick() }}>QP</button>
										</div>
									</div>
								</div>
								<QuickWinRight buyLotteryBet={this.BuyLotteryBet} clearCart={this.clearCart} removeFromCart={this.removeFromCart} latestResult={this.state.latestResult} />
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	};
}

function mapStateToProps(state) {
	const { currentGamesFamily, currentAvailableDraws, myBet, latestResult } = state.gameReducer;
	const { serverTime } = state.commonReducer;
	const { user } = state.loginReducer;
	const { cartItems, currentDraw, stakeAll, currentResult } = state.quickWinReducer;
	return { currentGamesFamily, user, currentAvailableDraws, cartItems, currentDraw, serverTime, myBet, stakeAll, latestResult, currentResult };
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	AddToCart: quickWinActions.AddToCart,
	RemoveFromCart: quickWinActions.RemoveFromCart,
	RemoveClosedDrawbetFromCart: quickWinActions.RemoveClosedDrawbetFromCart,
	SetCurrentDraw: quickWinActions.SetCurrentDraw,
	ClearCart: quickWinActions.ClearCart,
	Buybet: quickWinActions.Buybet,
	GetLatestResult: gameActions.GetLatestResult,
	GetCurrentResult: quickWinActions.GetCurrentResult,
	ClearAvailableGames: gameActions.ClearAvailableGames
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(QuickWin))