import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import { loginActions } from '../../action/loginAction';
import { withTranslation } from 'react-i18next';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            mobileNo: '',
            email: '',
            message: '',
            submitted: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitCustomerQuery = this.submitCustomerQuery.bind(this);
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name.toLowerCase().includes("mobile") && value && (/^[0-9\b]+$/.test(value)) == false) {
            return;
        }
        this.setState({
            [name]: value
        });
    };

    submitCustomerQuery(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        let { firstName, lastName, mobileNo, email, message } = this.state;
        if (mobileNo && firstName && message) {
            mobileNo = "250" + mobileNo.substring(1, mobileNo.length);
            email = email.replace(/'/g, "''");
            message = message.replace(/'/g, "''");
            firstName = firstName.replace(/'/g, "''");
            lastName = lastName.replace(/'/g, "''");
            this.props.saveCustomerComplaint(firstName + lastName, this.props.appvariables.countryCode + mobileNo, email, message,this.props.i18n.language);
            this.setState({
                firstName: '',
                lastName: '',
                mobileNo: '',
                email: '',
                message: '',
                submitted: false,
            })
        }
    };

    componentDidMount() {
        window.scrollTo(0,0);
    };

    render() {
        const { t } = this.props;
        const { firstName, lastName, mobileNo, email, message, submitted } = this.state;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    <div class="col-md-4">
                        <h1 style={{ color: "#000" }}>{t('lblYoutalk')}?</h1>
                        <div class="contactBtm">
                            <h2>{t('lblGetIntouch')}</h2>
                            <p>Carousel LTD.<br />CHIC MALL,2nd floor,Room F019A,<br />KN2 Avenue,KIGALI,RWANDA,<br />{t('lblmobno')} : 0791402424 </p>
                        </div>
                        <div class="contactBtm2">
                            <p>{t('lblSalesenquriy')} / {t('lblBecanagt')}</p>
                            <p>{t('lblmobno')} : 0791402424 </p>
                            <p>{t('lblEmail')} : sales@inzozilotto.rw </p>
                        </div>
                        <div class="contactBtm2">
                            <p>{t('lblcustomerservice')}</p>
                            <p>{t('lblmobno')} : 0791402424 </p>
                            <p>{t('lblEmail')} : customercare@inzozilotto.rw </p>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="mapArea">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3987.440056493591!2d30.045377215273433!3d-1.9783934373319794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sCHIC%20MALL%20%2C%202nd%20floor%20%2C%20Room%20F019A%20%2C%20KN2%20Avenue%20%2C%20KIGALI%20%2C%20RWANDA.!5e0!3m2!1sen!2sin!4v1631600860220!5m2!1sen!2sin" width="600" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <form class="contactForm" name="contactForm">
                                <div class="form-group row">
                                    <div class="col-md-6 field">
                                        <label>{t('lblFirstName')} *</label>
                                        <input maxLength="20" type="text" name="firstName" class="form-control" value={this.state.firstName} onChange={this.handleInputChange} />
                                        {submitted && !firstName && <small>{t('lblRequired')}</small>}
                                    </div>

                                    <div class="col-md-6 field">
                                        <label>{t('lblLastName')}</label>
                                        <input type="text" maxLength="20" name="lastName" class="form-control" value={this.state.lastName} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 field">
                                        <label>{t('lblmobno')} *</label>
                                        <input maxLength="10" type="text" name="mobileNo" class="form-control" value={this.state.mobileNo} onChange={this.handleInputChange} />
                                        {submitted && !mobileNo && <small>{t('lblRequired')}</small>}
                                        {submitted && mobileNo && (!(/\d{10}$/.test(mobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
                                    </div>
                                    <div class="col-md-6 field">
                                        <label>{t('lblEmail')}</label>
                                        <input maxLength="50" type="email" name="email" class="form-control" value={this.state.email} onChange={this.handleInputChange} />
                                        {submitted && email && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) && <small>{t('lblInvalidEmailID')}</small>}
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12 field">
                                        <label>{t('lblDescription')} *</label>
                                        <textarea maxLength="140" name="message" cols="10" rows="5" class="form-control" value={this.state.message} onChange={this.handleInputChange}></textarea>
                                        {submitted && !message && <small>{t('lblRequired')}</small>}
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12 field">
                                        <button class="sendMsgBtn" name="send" type="button" onClick={this.submitCustomerQuery}>{t('lblSubmit')}</button>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { appvariables } = state.loginReducer;
    return { appvariables }
}
const mapActionToProps = {

    saveCustomerComplaint: loginActions.saveCustomerComplaint,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Contact))