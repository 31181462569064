import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//by thamil,no need loger
//import { createLogger } from 'redux-logger';
import rootReducer from '../reducer/rootReducer';

//const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
       // loggerMiddleware
    )
);