import {appConstants} from '../helpers/actionType';
import { gameService } from '../services/gameService';
import { commonClass } from "..//helpers/commonClass";
import { alertActions } from "../action/alertAction";
import { history } from '../helpers/history';
export const lottoActions={
    AddToCart,
    SetCurrentDraw,
    RemoveFromCart,
    ClearCart,
    RemoveClosedDrawbetFromCart,
    Buybet,   
}

function AddToCart(cItem){ 
    return dispatch =>{        
    dispatch({type:appConstants.ADD_CARTITEM,cItem});        
   }
};

function RemoveFromCart(cid){ 
    return dispatch =>{        
    dispatch({type:appConstants.REMOVE_CARTITEM,cid});        
   }
};

function RemoveClosedDrawbetFromCart(did){ 
    return dispatch =>{        
    dispatch({type:appConstants.REMOVE_CLOSEDDRAW_CARTITEM,did});        
   }
};

function ClearCart(){ 
    return dispatch =>{        
    dispatch({type:appConstants.CLEAR_CARTITEM});        
   }
};

function SetCurrentDraw(cDraw){ 
    return dispatch =>{        
    dispatch({type:appConstants.SET_DRAW,cDraw});        
   }
};


function Buybet(buyStr) {
    try{
    return dispatch => {        
        dispatch({ type: appConstants.LOADING });
        let data = buyStr;
        gameService.LotteryOnlineSale(data)
            .then(
                buyInfo => {
                    let myBet = generateBetSlip(buyInfo);                                   
                    dispatch({ type: appConstants.LOADED });

                    if (myBet.status == 1) {
                        dispatch(alertActions.error(myBet.msg));
                    }
                    else if (myBet.status == 3) {
                        dispatch(alertActions.error(myBet.msg));
                        setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                    }
                    else if (myBet.status == 4) {
                        dispatch(alertActions.error(myBet.msg));
                        setTimeout(() => { history.push('/login'); }, 2000)
                    }
                    else {
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                    }
                    if (myBet.balance) {
                        let balance = myBet.balance;
                        let winbalance =myBet.winbalance;    
                    dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });  
                    }
                       
                },
                error => {
                    //dispatch({ type: appConstants.BUY_ERROR, error });
                    let myBet = { status: 2, msg: "Something Wrong!" };
                    dispatch({ type: appConstants.LOADED });
                    dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                }
            );
    }
}
catch (ex) {		
    commonClass.writeLog(ex);
}
};

function generateBetSlip(mybet) {
    try{
    let betArr = mybet.split('~');
    if (betArr[0] != 0) {
        return { status: betArr[0], msg: betArr[1] };
    }
    let tempStr = betArr[9].split('|');
    //let tempStr1 = tempStr[0].split('@');
    //let tempStr2 = tempStr[1].split('|');
    let tempStr1 = [];
    tempStr.forEach(el => {
        let tempStr2 = el.split('@');
        if (tempStr2.length > 1) {
            tempStr1.push({ no: tempStr2[0], amt: tempStr2[1] });
        }
    });
    let _bMsg="";
    if( betArr.length>16)
    {
        _bMsg=betArr[16];
    }
    let betObj = {
        Id: betArr[2],
        TransDate: betArr[3],
        TransTime: betArr[4],
        DrawNo: betArr[5],
        DrawDate: betArr[6],
        DrawTime: betArr[7],
        Ball: tempStr1,
        TotalStakeAmount: betArr[10],
        GameName: betArr[13],
        status: betArr[0],
        msg: "success",
        balance : betArr[11],
        winbalance : betArr[12],
        familyID:4,
        buttomMsg:_bMsg
    }    
    return betObj;
}
catch (ex) {		
    commonClass.writeLog(ex);
}
};

