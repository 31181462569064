import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const LangSelector = () => {

    const getCookie = (name) => {
        try {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            let lang_val = process.env.REACT_APP_DEFAULT_LANGUAGE;
            if (parts.length === 2)
                lang_val = parts.pop().split(';').shift();
            console.log("selected lang : " + lang_val);
            return lang_val;
        }
        catch (ex) {
            return process.env.REACT_APP_DEFAULT_LANGUAGE;
        }
    };

    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(getCookie("lang"));


    const changeLanguage = (event) => {
        setSelectedLang(event);
        i18n.changeLanguage(event);
        document.cookie = "lang=" + event;
    }
    useEffect(() => {
        window.documentReadyjQueryLang();       
    }, [])
    
    return (

        // <div onChange={changeLanguage}>
        //     <label className="mr10"><input type="radio" value="en" name="language" checked={selectedLang === 'en'} /> English</label>
        //     <label><input type="radio" value="rw" name="language" checked={selectedLang === 'rw'} /> Kiya Rwanda</label>
        // </div>

        <div class="dropdownLang">
            <div class="dropdownlg"  id="lstLang">
                <button class="dropbtnlg">
                <img src={`./assets/img/icons/Flags-${selectedLang}.webp`}/>{selectedLang === 'en'?"EN ":"RWA "}<i class="fa fa-angle-down" aria-hidden="true"></i>
                </button>
                <div class="dropdown-contentlg">
                    <a onClick={()=>changeLanguage('en')}><img src="./assets/img/icons/Flags-en.webp"/>EN</a>
                    <a onClick={()=>changeLanguage('rw')} ><img src="./assets/img/icons/Flags-rw.webp"/>RWA</a>
                </div>
            </div>
        </div>
    )
}

export default LangSelector;