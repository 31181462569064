import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { alertReducer } from './alertReducer';
import { gameReducer } from './gameReducer';
import { lottoReducer } from './lottoReducer';
import { accountReducer } from './accountReducer';
import { quickWinReducer } from './quickWinReducer';
import {target10Reducer } from './target10Reducer';
import {raffleReducer } from './raffleReducer';
import {rouletteReducer} from './RouletteReducer';
import { commonReducer } from './commonReducer';
const rootReducer = combineReducers({
    loginReducer,
    alertReducer,
    gameReducer,
    lottoReducer,
    accountReducer,
    quickWinReducer,
    target10Reducer,
    raffleReducer,
    commonReducer,   
    rouletteReducer
});

export default rootReducer;