import { appConstants } from '../helpers/actionType';
import { gameService } from '../services/gameService';
import { history } from '../helpers/history';
import { commonClass } from "..//helpers/commonClass";
export const gameActions = {
    GameFamily,
    SetGameFamily,
    GameAvailableGames,
    ClearAvailableGames,
    GetServerTime,
    SetServerTime,
    GetLatestResult,
    closeBetSlip,
    GetCasinoGames,
}

function GameFamily(mobileNo, langID) {
    try {
        return dispatch => {
            // dispatch({ type: appConstants.LOADING });
            localStorage.removeItem('cFamily');
            let data = `'${mobileNo}','${langID}'`;
            gameService.GetGameFamily(data)
                .then(
                    games => {                        
                        localStorage.setItem('allFamily', JSON.stringify(games));
                        dispatch({ type: appConstants.GET_GAMEFAMILY_SUCCESS, games });
                        // dispatch({ type: appConstants.LOADED });
                    },
                    error => {
                        error = error.message;
                        dispatch({ type: appConstants.GET_GAMEFAMILY_ERROR, error });
                        // dispatch({ type: appConstants.LOADED });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
//if new games ,add here and gameReducer file
function SetGameFamily(gFamily, langID) {
    try {
        return dispatch => {
            localStorage.setItem('cFamily', JSON.stringify(gFamily));
            dispatch({ type: appConstants.SET_GAMEFAMILY, gFamily });
            if (gFamily.id == 4)
                history.push('/lotto');
            if (gFamily.id == 5)
                history.push('/quickwin');
            if (gFamily.id == 3)
                history.push('/target');
            if (gFamily.id == 1)
                history.push('/igitego');
            if (gFamily.id == 7)
                history.push('/Pick3');
            if (gFamily.id == 8)
                history.push('/Roulette');
            if (gFamily.id == 9)
                history.push('/moto');
            if (gFamily.id == 10)
                history.push('/iphone');

        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function ClearAvailableGames() {
    return dispatch => {
        let error = [];
        dispatch({ type: appConstants.GET_AVAILDRAWS_ERROR, error });
    }
};
function GameAvailableGames(mobileNo, familyId, langID) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            //by thamil for fix the session share diff browser tabs with diff games on 24/02/2023
            //localStorage.removeItem('cDraw');
            let data = `'${mobileNo}','${familyId}','${langID}'`;
            gameService.GetAvailableGames(data)
                .then(
                    currentAvailableDraws => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_AVAILDRAWS_SUCCESS, currentAvailableDraws });
                        if (currentAvailableDraws.length > 0) {
                            let cDraw = currentAvailableDraws[0];
                            dispatch({ type: appConstants.SET_DRAW, cDraw });
                        }
                        else {
                            dispatch({ type: appConstants.SET_DRAW });
                        }
                    },
                    error => {
                        error = error.message;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_AVAILDRAWS_ERROR, error });
                        dispatch({ type: appConstants.SET_DRAW });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetServerTime() {
    try {
        return dispatch => {
            gameService.GetServerTime()
                .then(
                    _date => {
                        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                        let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                        //let serverTime = (new Date(ss.setSeconds(ss.getSeconds())).getTime());
                        //let serverTime = (ss.getTime()+((110*60*6000)+ 73*60000));
                        let serverTime = (ss.getTime());
                        console.log("ServerTime form db : " + serverTime);
                        dispatch({ type: appConstants.GET_SERVERTIME_SUCCESS, serverTime });
                    },
                    error => {
                        error = error.message;
                        dispatch({ type: appConstants.GET_SERVERTIME_ERROR, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function SetServerTime(serverTime) {
    return dispatch => { dispatch({ type: appConstants.GET_SERVERTIME_SUCCESS, serverTime }); }
};


function GetLatestResult(gameID, langID) {
    try {
        if (gameID == undefined) { return; }
        return dispatch => {
            //no need ,bcoz it will distrub while buy next draw.
            //dispatch({ type: appConstants.LOADING });
            let data = `'1','${gameID}','${langID}'`;
            gameService.GetLatestResult(data)
                .then(
                    lResult => {
                        // dispatch({ type: appConstants.LOADED});
                        dispatch({ type: appConstants.GET_LATEST_RESULT_SUCCESS, lResult });
                    },
                    error => {
                        // dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch({ type: appConstants.GET_LATEST_RESULT_ERROR, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};


function closeBetSlip() {
    return dispatch => { dispatch({ type: appConstants.BUY_ERROR }); }
};


function GetCasinoGames(userID, gameID, langID) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = `'${userID}','${gameID}','${langID}'`;
            gameService.GetCasinoGames(data)
                .then(
                    casinoGame => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_CASINO_GAMES, casinoGame });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = [];
                        dispatch({ type: appConstants.GET_CASINO_GAMES, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};