import React, { Component } from 'react'
import { connect } from 'react-redux';

import { gameActions } from '../../../action/gamesAction';
//import { loginActions } from '../../../action/loginAction';
//import UpcomingDraws from './UpcomingDraws';
import LatestResult from './LatestResult';

import Header from "../../Header/Header";
import { Link } from 'react-router-dom';
//import Footer from "../../Footer/Footer";
//import bannerList from "../../../banner.json";
import { withTranslation } from 'react-i18next';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			games: [],
			bannerList: []
		};
		//props.GameFamily(this.props.user.mobileMo);		
		props.GameFamily('1', this.props.i18n.language);
	}

	componentDidMount() {
		//	window.documentReadyjQuery();
		this.setjsondata();
	};
	componentDidUpdate() {
		window.documentReadyjQueryFlex();
	};

	setjsondata = (myJson) => {
		//fetch('assets/img/banner/banner.json')		
		fetch(process.env.REACT_APP_BANNER_URL + 'HomeBanner.json?v1')
			.then(response => response.json())
			.then(data =>
				this.setState({ bannerList: data.data })
			);
	};

	handleInputChange = (e, inx) => {
		var items = this.state.games
		var item = this.state.games[inx];
		let name = e.target.name;
		let value = e.target.value;
		item[name] = value;
		this.setState({
			games: items
		});
	};

	selectGameFamily = (inx) => {
		var item = this.state.games[inx];
		// item.ball = parseInt(item.ball);
		// item.minSelect = parseInt(item.minSelect);
		// item.maxSelect = parseInt(item.maxSelect);
		this.props.SelectGameFamily(item);
		//this.props.GameAvailableGames(this.props.user.mobileNo, item.id);
	};

	static getDerivedStateFromProps(props, state) {
		return {
			games: props.games
		};
	};

	render() {
		const { t } = this.props;
		return (
			<div className="mainContainer">
				<Header />
				<div class="banner bounceIn delay-500 fast go">
					<div class="flexslider hmBan">
						<ul class="slides">
							{/* <li><img src="./assets/img/banner/banner8.jpg" /></li>
							<li><img src="./assets/img/banner/banner6.jpeg" /></li>
							<li><img src="./assets/img/banner/banner7.jpg" /></li> */}
							{this.state.bannerList && this.state.bannerList.map((x, indx) =>
								// <li key={`banimg_${indx}`}>{x.linkUrl !=undefined && x.linkUrl != "" ? <Link to={x.linkUrl} ><img src={x.imagPath} /></Link>:<img src={x.imagPath} />}</li>							
								<li key={`banimg_${indx}`}><img src={x.imagPath} /></li>
							)}
						</ul>
					</div>
				</div>

				<div class="GameSection forpadd">
					<div class="container bounceIn delay-500 fast go">
						<div class="GameSectionCent">
							{this.state.games && this.state.games.map((x, indx) =>
								<div class="col-md-3 col-sm-5 col-xs-12 palySec" key={`inxgame_${indx}`}>
									<div class="palySecInnr">
										<h3 style={{}}>{x.name}</h3>
										{/* <img src={`./assets/img/game${x.id}.webp`} /> */}
										<a onClick={(e) => { this.selectGameFamily(indx) }}>{t('lblPlaynow')}</a>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>

				<div class="GameAccountSec">
					<div class="weServe">
						<ul>
							<li>
								<div>
									<Link to={{ pathname: "/account", state: { tabIDT: 1 } }}><img src="./assets/img/Fund Your Wallet.webp" /></Link>
								</div>
								<span>{t('lblFndyouwal')}</span>
							</li>
							<li>
								<div>
									<Link to="/lotto">	<img src="./assets/img/Play Games.webp" /></Link>
								</div>
								<span>{t('lblPlaygame')}</span>
							</li>
							<li>
								<div>
									<Link to={{ pathname: "/account", state: { tabIDT: 2 } }}><img src="./assets/img/Cash Out Winning.webp" /></Link>
								</div>
								<span>{t('lblCashoutwin')}</span>
							</li>
						</ul>
					</div>
				</div>

				<div class="WorkArea">
					<div class="container">
						<div class="col-md-4 teleCommute">
							<img src="./assets/img/support.webp" />
							<div class="OverPlay">
								<div class="helpSec">
									<span><img src="./assets/img/icons/Icon phone-call.webp" /></span>
									<div>
										<small>{t('lblStucksomewhere')}</small>
										<p>{t('lblCall')} 2424</p> {/*+250 789 231 231*/}
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 howitwork">
							<h2>{t('lblHowwork')}</h2>
							<div class='scrollbar' style={{ maxHeight: '350px', overflowY: 'auto', float: 'left' }}>
								<p style={{ fontSize: "14px" }}><h4>IGITEGO MILLION</h4>{t('lblHowToplayRaffle')}.</p>
								<p style={{ fontSize: "14px" }}><h4>QUICK 10</h4>{t('lblHowToplayQuick10')}.</p>
								<p style={{ fontSize: "14px" }}><h4>IMPAMO JACKPOT</h4>{t('lblHowToplayjackpot')}.</p>
								<p style={{ fontSize: "14px" }}><h4>QUICK LOTTO</h4>{t('lblHowToplayQucik')}.</p>
								<p style={{ fontSize: "14px" }}><h4>WATATU</h4>{t('lblHowToplayPick3')}.</p>
								<p style={{ fontSize: "14px" }}><h4>IGITEGO-MOTO</h4>{t('lblHowToplayMotto')}.</p>
								<p style={{ fontSize: "14px" }}><h4>IGITEGO-IPHONE</h4>{t('lblHowToplayPhone')}.</p>
							</div>
						</div>
						<div class="col-md-4 vedioSec" style={{ height: "280px", paddingBottom: "5px" }}>
							<iframe src="https://www.youtube.com/embed/htyW4W53FPk?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</div>

				{/* <UpcomingDraws /> */}
				<LatestResult />

				<div class="container">
					<div class="Partnersec forpadd">
						<h2>{t('lblInzozioffpart')}</h2>
						<div class="col-md-12 col-xs-12"><a href="https://www.minisports.gov.rw" target="_blank"><img src="./assets/img/partners/Partners -1.webp" /></a></div>
						{/* <div class="col-md-3 col-xs-6"><a href="http://www.ferwafa.rw" target="_blank"><img src="./assets/img/partners/Partners -2.png" /></a></div>
						<div class="col-md-3 col-xs-6"><a href="http://www.ferwacy.rw" target="_blank"><img src="./assets/img/partners/Partners -3.png" /></a></div>
						<div class="col-md-3 col-xs-6"><a href="http://ferwaba.web.geniussports.com" target="_blank"><img src="./assets/img/partners/Partners -4.png" /></a></div> */}
					</div>
				</div>
			</div>
		)
	};
}

function mapStateToProps(state) {
	const { games } = state.gameReducer;
	const { user } = state.loginReducer;
	return { games, user };
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	SelectGameFamily: gameActions.SetGameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))