import React, { Component } from 'react'
import { connect } from 'react-redux';
import { rouletteActions } from '../../../action/RouletteAction';
import { withTranslation } from 'react-i18next';
class RouletteRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stake: 0,
            nos:[2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35]
        }
    }

    setUpdateStake = (stake) => {
        this.setState({
            stake: stake
        })
        this.props.UpdateStakeAll(stake);
    };

    updatesStakeValue = (bid, flog) => {
        if (this.state.cartItems != null) {
            let lst = this.state.cartItems.filter(a => a.id == bid);
            if (lst != null && lst != undefined && lst.length > 0) {
                lst = lst[0];
                if (flog == "+") {
                    if (lst.stakeValue + 1 > lst.maxStake) {
                        alert(this.props.t('lblMaximumstake') + " : " + lst.maxStake);
                        return;
                    }
                    else
                        lst.stakeValue = lst.stakeValue + 50;
                }
                else if (flog == "-") {
                    if (lst.stakeValue - 1 <= lst.minStake) {
                        alert(this.props.t('lblMinimumstake') + " : " + lst.minStake);
                        return
                    }
                    else
                        lst.stakeValue = lst.stakeValue - 50;
                }
                lst.total = lst.stakeValue * lst.oddsValue;
                this.props.UpdateCartAll(lst);
            }
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        };
    }
    render() {
        const { t } = this.props;       
        return (
            <div class="col-md-4">
                {/* Commended by narmatha no need of bet slip  */}
                {/* <div class="betslip">
                    <h2>Betslip</h2>
                    {this.state.cartItems && this.state.cartItems.length<=0 && <div className="betError2">
                    <h2 className="warningMsg bounceIn animated infinite">{t('lblplcurbet')}</h2>
                </div>}
                    <div class="betSlip3 scrollbar">
                        <ul class="betSlipCont">
                        {this.state.cartItems && this.state.cartItems.map((x, indx) =>
                        <li class="toggle" key={`bet_${indx}`} id={x.Id}>
                        <div class="rouletteBetslipLi">
                            <div class="rouletteCont">
                                <span class="drawNumbrSers">Draw No : #{x.DrawNo}</span>
                                <a class="rltbetCloseBtn" onClick={() => { this.props.removeFromCart(x.Id) }}><i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                            <div class="rouletteCont2">
                                <div class="rouletteDrwDte"><span>{x.DispName}</span></div>
                                <div class="rouletteDrwCont"><span>{x.StakeValue}</span></div>
                                <div class="rouletteDrwCont">
                                    <div class="rltInrVlueCont rltVlue1"><span>{x.SubTotal}</span></div>
                                    <div class="rltInrVlueCont rltVlue2"><span>{x.OddsValue}<small>X</small></span></div>
                                </div>                                
                            </div>                            
                        </div>								
                    </li>
                        )}  
                        </ul>
                    </div>
                    <div class="countArea">
                        <div class="betSlipBtomContNew">
                            <span class="lftTxt">Total Bet :{this.state.cartItems.length}</span>
                        </div>
                        <div class="betSlipBtomContNew">
                            <span class="lftTxt">Total stack : {this.state.cartItems.reduce((a, b) => a = a + b.StakeValue, 0)}</span>
                        </div>
                    </div>
                    <div class="lottoBtns">
                        <button class="redBg" href="#" onClick={() => { this.props.clearCart() }}>Clear</button>
                        <button class="YellowBg" id="" onClick={() => { this.props.buyLotteryBet() }}>Buy</button>
                    </div>

                </div> */}
                <div class="latestRslt" style={{ marginTop: "4px" }}>
                    <h2>{t('lbllatresult')}</h2>
                    <ul class="roultrecentRsltCont scrollbar">
                        {this.props.latestResult ? this.props.latestResult.map((x, indx) =>
                            <li key={`lstrsl_${indx}`}>
                                <div class="drawNoTime">
                                    <div class="drawNoRtl"><span>#{x.DrawNo}</span></div>
                                    <div class="drawTimeRtl"><span>{x.Drawdatetime}</span></div> 
                                    <div class="drawCountRtl"><span class="RtlDrwaBg">{x.WinningNo}</span></div>
                                      
                                       {/* {this.state.nos.filter(winno=>(winno==x.WinningNo)).length>0?
                                         <div class="drawCount"><span class="circle2 bgrte2">{x.WinningNo}</span></div> :
                                         (x.WinningNo==0)?<div class="drawCount"><span class="circle2">{x.WinningNo}</span></div>:
                                         <div class="drawCount"><span class="circle2 bgrte1">{x.WinningNo}</span></div>
                                        } */}
                                    
                                </div>
                            </li>) : <div></div>}
                    </ul>
                    <div class="rolutRsltLink"><a href="#">View All Results</a></div>
                </div>

            </div>
        );
    }
}


function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { cartItems } = state.rouletteReducer;
    return { user, cartItems };
}

const mapActionToProps = {
    UpdateStakeAll: rouletteActions.UpdateStakeAll,
    UpdateCartAll: rouletteActions.UpdateCartAll,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(RouletteRight))