import React, { Component } from 'react'
import { connect } from 'react-redux';
import RouletteLeft from "./RouletteLeft";
import RouletteRight from "./RouletteRight";
import { gameActions } from '../../../action/gamesAction';
import { rouletteActions } from '../../../action/RouletteAction';
import Countdown, { zeroPad } from 'react-countdown';
import WebGLApp from "./webGL";
import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

class Roulette extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cartItems: [],
			currentRunningDraw: {},
			currentFinishingDraw: {},
			coundownStart: 0,
			coundTime: 18900000,
			currentDraw: {},
			currentAvailableDraws: [],
			//currentCartItem: { id: 0, ballNo: "", stakeValue: 100, oddsValue: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, drawNo: 0, gameID: 0, gameGroupID: 0 },

			ballStartNumber: 0,
			ballEndNumber: 0,
			QPBallCount: 0,
			random: [],
			showSingleMsg: false,
			_serverTimeDiff: 0,
			allGameClosed: 0,
			isViewAnimation: true,
			webglInit: false,
			timerGetResult: null,
			timerGetLatestResult: null,
			timerGetResultCount: 0,
			CurrentBallAction: 0,
			objSelectedDraw: {},
			CurrentCoinValue: 1,
			outerMaxpoint: 5000,
			outerMinpoint: 10,
			innerMaxpoint: 300,
			CurrentOddsValue: 36,
			lstSelectLottoBalls: [],
			IsAutoApply: 0,
			AutoApplyCount: "",
			CurrentCoinValue: 1,
			CurrentOddsValue: 36,
			tooltipSpan: null,
			TiersLst: [{ "Id": 1, "data": "obj_05_08" }, { "Id": 2, "data": "obj_10_11" }, { "Id": 3, "data": "obj_13_16" }, { "Id": 4, "data": "obj_23_24" }, { "Id": 5, "data": "obj_27_30" }, { "Id": 6, "data": "obj_33_36" }],
			OrphelinsLst: [{ "Id": 1, "data": "obj_01" }, { "Id": 2, "data": "obj_06_09" }, { "Id": 3, "data": "obj_14_17" }, { "Id": 4, "data": "obj_17_20" }, { "Id": 5, "data": "obj_31_34" }],
			VoisinsLst: [{ "Id": 1, "data": "obj_00_02_03" }, { "Id": 2, "data": "obj_04_07" }, { "Id": 3, "data": "obj_12_15" }, { "Id": 4, "data": "obj_18_21" }, { "Id": 5, "data": "obj_19_22" }, { "Id": 6, "data": "obj_25_26_28_29" }, { "Id": 7, "data": "obj_32_35" }],
			ZeroLst: [{ "Id": 1, "data": "obj_00_03" }, { "Id": 2, "data": "obj_12_15" }, { "Id": 3, "data": "obj_26" }, { "Id": 4, "data": "obj_32_35" }],
			RedLst: [{ "Id": 1, "data": "obj_09_12" }, { "Id": 2, "data": "obj_16_19" }, { "Id": 3, "data": "obj_18_21" }, { "Id": 4, "data": "obj_27_30" }],
			BlockLst: [{ "Id": 1, "data": "obj_08_11" }, { "Id": 2, "data": "obj_10_13" }, { "Id": 3, "data": "obj_17_20" }, { "Id": 4, "data": "obj_26_29" }, { "Id": 5, "data": "obj_28_31" }],
			LstRouletteBetType:window.getRouletteBetType()
		};
		this.my_componentWillUnmount();
		if (props.currentGamesFamily.id !== undefined) {
			props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id, this.props.i18n.language);
			props.GetLatestResult(props.currentGamesFamily.id, this.props.i18n.language);
		}
		// let ss = Date.now;
		// let ssss = ss();
		// console.log("Date.now : " + ss);
	}

	static getDerivedStateFromProps(props, state) {
		return {
			currentAvailableDraws: props.currentAvailableDraws,
			cartItems: props.cartItems,
			currentDraw: props.currentDraw,
			currentGamesFamily: props.currentGamesFamily,
			latestResult: props.latestResult,
			currentDraw: props.currentDraw,
			currentResult: props.currentResult
		};
	};

	selectDraw = (_draw) => {
		this.props.SetCurrentDraw(_draw);

	};

	selectRunningDraw = () => {
		if (this.state.coundownStart != undefined && this.state.coundownStart == 0) {
			//if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			if (this.state.currentFinishingDraw != undefined) {
				//if (this.state.currentFinishingDraw.DrawID == undefined || this.state.currentRunningDraw.DrawID == undefined || this.state.currentFinishingDraw.DrawID !=this.state.currentRunningDraw.DrawID) {
				let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
				if (lst.length > 0 || this.state.currentAvailableDraws.length <= 0) {
					let _draw = this.state.currentAvailableDraws[0];
					//let _draw = lst[0];
					this.setState({
						currentRunningDraw: _draw,
					})
					this.state.currentRunningDraw = _draw;
					this.selectDraw(_draw);
				}
				else {
					return;
				}
			}

			// if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			// 	let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
			// 	if (lst.length > 0) {
			// 		return;
			// 	}
			// }

			let _draw = this.state.currentAvailableDraws[0];
			if (_draw == undefined) { this.state.coundownStart = 1; return; }
			try {
				let sTime = undefined; //new Date(Date.now() - this.state.coundTime);
				if (_draw != undefined) {
					var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
					let ss = new Date(_draw.DrawDateTime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
					sTime = new Date(ss.setSeconds(ss.getSeconds() - 60));
				}
				let dStr = ("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + sTime.getHours() + ":" + sTime.getMinutes() + ":" + sTime.getSeconds();
				window.timer = null;
				this.state.coundownStart = window.startDraw(dStr, this.RefreshAfterGameClose);
			}
			catch (ex) {
				alert(ex);
			}
		}
	};

	componentDidUpdate(previousProps, previousState) {
		if ((previousState.currentResult != this.state.currentResult) || (this.state.currentResult && this.state.currentResult.indexOf("1~") == 0)) {
			console.log("start startAnimationWebGL in componentDidUpdate with : " + this.state.currentResult);
			this.startAnimationWebGL();
		}
		if (this.state.currentAvailableDraws.length > 0 && ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length <= 0) || this.state.currentAvailableDraws[0].DrawNo != this.state.currentRunningDraw.DrawNo))
		//if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0))
		{
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				this.state.allGameClosed = 1
			}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
			if (this.state.webglInit == false && this.state.currentRunningDraw) {
				this.setWelcomScreenWebGL();
				this.state.webglInit = true;
			}

		}

	};


	componentDidUpdate_old(previousProps, previousState) {
		//if ((this.state.currentRunningDraw ==null || Object.keys(this.state.currentRunningDraw).length == 0) && this.state.currentAvailableDraws.length>0) {
		//if ((this.state.currentDraw ==null || Object.keys(this.state.currentDraw).length == 0) && (previousState.currentDraw ==null && (this.state.currentDraw==null || this.state.currentAvailableDraws.length>0))) {
		if (this.state.currentDraw != null && Object.keys(this.state.currentDraw).length != 0) {
			this.state.allGameClosed = 0;
		}
		if (previousState.currentResult != this.state.currentResult) {
			this.startAnimationWebGL();
		}
		if (this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0 && this.state.currentAvailableDraws.length > 0 && this.state.currentAvailableDraws.length == previousState.currentAvailableDraws.length) {
			return;
		}

		if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0)) {
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				this.state.allGameClosed = 1
			}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
			if (this.state.webglInit == false && this.state.currentRunningDraw) {
				this.setWelcomScreenWebGL();
				this.state.webglInit = true;
			}

		}

		//for stake all text box hide
		// if(previousProps.stakeAll !=this.props.stakeAll)
		// {
		// 	if(this.state.cartItems !=null )
		// 	{
		// 			for (let i = 0; i < this.state.cartItems.length; i++) {
		// 				this.state.cartItems[i].stakeValue=this.props.stakeAll;
		// 				this.state.cartItems[i].total = this.props.stakeAll * a.oddsValue;
		// 			}
		// 	}
		// }
	};

	componentDidMount() {
		//setTimeout(	this.RefreshAfterGameClose,10000);
		//setInterval(() => {		this.RefreshAfterGameClose()	 }, 60*1000); 
		//window.documentReadyjQuery();
		window.addEventListener('resize', window.documentReadyResize);
		//window.loadWebGL();
		//window.WelcomeAnimationScreen();
		//this.setWelcomScreenWebGL();    
		//window.getRouletteBetType();
		let _self = this;
		$("span, canvas").mouseover(function (e) {
			_self.mouseEnterEvt(e.target);
		});

		$("span, canvas").mouseleave(function (e) {
			_self.mouseLeaveEvt(e.target);
		});


		$(".coins ul li div").click(function (e) {
			_self.showchooseCoin(e.currentTarget);
		});

		_self.state.tooltipSpan = document.getElementById('tooltipBallVal');
		$(".betUndo").click(function () {
			if (_self.state.CurrentBallAction == 0) {
				_self.state.tooltipSpan.style.display = "block";
				_self.state.CurrentBallAction = 1;
			}
			else {
				_self.state.tooltipSpan.style.display = "none";
				_self.state.CurrentBallAction = 0;
			}
		});

		$(".rltteGameCont").mouseleave(function () {
			_self.state.tooltipSpan.style.display = "none";
		});

		$(".rltteGameCont").mouseenter(function () {
			if (_self.state.CurrentBallAction == 1) {
				_self.state.tooltipSpan.style.display = "block";
			}
			else {
				_self.state.tooltipSpan.style.display = "none";
			}
		});

		$("span, canvas, .ball").mousemove(function (e) {
			if (_self.state.tooltipSpan == null) { return; }
			var x = e.clientX,
				y = e.clientY;
			_self.state.tooltipSpan.style.top = (y - 15) + 'px';
			_self.state.tooltipSpan.style.left = (x + 5) + 'px';
		});

		//$("span").click(function (e) {
			//try {
				//if ($(e.currentTarget.className) == undefined) { return; }
				//_self.PutCoin(e);
				/* var ssa = $(e).attr('class').toLowerCase().indexOf("rteball");
				var ss = $(e).prop('tagName').toLowerCase().indexOf("canvas");
				var ss1 = $(e).attr('class').toLowerCase().indexOf("group");
				if (ssa > -1 || ss > -1 || ss1 > -1) { _self.PutCoin(e); } */

			//} catch (e) { }
		//});


	};
	my_componentWillUnmount = () => {
		this.props.ClearCart();
		this.setState({
			currentRunningDraw: null,
		})
		this.props.ClearAvailableGames();
	};

	RefreshAfterGameClose = () => {
		//alert("coundown finish");
		this.state.coundownStart = 0;
		this.state._serverTimeDiff = 0;
		this.state.currentFinishingDraw = this.state.currentRunningDraw;

		this.props.RemoveClosedDrawbetFromCart(this.state.currentFinishingDraw.DrawID);
		setTimeout(() => { this.props.GameAvailableGames(this.props.user.mobileNo, this.props.currentGamesFamily.id, this.props.i18n.language) }, 2000)
		this.state.currentRunningDraw = {};
		//setTimeout(this.props.GetLatestResult(this.props.currentGamesFamily.id),21000);
		console.log("RefreshAfterGameClose drawID : " + this.state.currentFinishingDraw.GameId)
		this.state.timerGetResultCount = 0;
		this.state.timerGetResult = setTimeout(() => { this.props.GetCurrentResult(this.state.currentFinishingDraw.GameId) }, 5000);
		//setTimeout(this.props.GetCurrentResult(145), 2000);
		//setTimeout(this.startAnimationWebGL, 5000);
	};

	setWelcomScreenWebGL = () => {
		if (this.state.currentRunningDraw && this.state.currentRunningDraw.DrawDateTime) {
			var s = this.state.currentRunningDraw.DrawDateTime.split(' ');
			var strkey = "ThOtArPuElLaThA~" + this.state.currentRunningDraw.LotName + "~" + this.state.currentRunningDraw.DrawNo + "~" + s[0] + "~" + s[1] + "";
			//let strkey = "ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2";
			console.log("start setWelcomScreenWebGL");
			this.fnWelcome(strkey);
		}
	}
	startAnimationWebGL = () => {
		//alert("call animate");
		//update by thamil
		//this.state.currentResult="0~142~05/09/2021~10:00 AM~Quick Win~9";
		if (this.state.currentResult) {
			let arr = this.state.currentResult.split("~");
			if (arr[0] == "0") {
				//var s = this.state.currentRunningDraw.Drawdatetime.split(' ');
				//var strkey = "ThOtArPuElLaThA~" + this.state.currentRunningDraw.GameName + "~" + this.state.currentRunningDraw.GameID + "~" + s[0] + "~" + s[1] + "";
				let strkey = "ThOtArPuElLaThA~" + this.state.currentResult.substring(2, this.state.currentResult.length);
				var s = this.state.currentDraw ? this.state.currentDraw.DrawTime : "No More";
				if (s != null) {
					strkey = strkey + "~" + s;
				}
				else {
					strkey = strkey + "~00:00";
				}
				console.log("start startAnimationWebGL with : " + strkey);
				this.viewAnimationResult(1);
				this.fnStartAnimation(strkey);
				clearTimeout(this.state.timerGetResult);
				this.state.timerGetResult = null;
				this.state.timerGetResultCount = 0;
				this.state.timerGetLatestResult = setTimeout(() => { this.props.GetLatestResult(this.props.currentGamesFamily.id, this.props.i18n.language) }, 30000);
			}
			else {
				if (this.state.timerGetResult)
					clearTimeout(this.state.timerGetResult);
				if (this.state.timerGetResultCount < 5) {
					console.log("GetCurrentResult drawID : " + this.state.currentFinishingDraw.GameId)
					this.state.timerGetResult = setTimeout(() => { this.props.GetCurrentResult(this.state.currentFinishingDraw.GameId, this.props.i18n.language) }, 2000);
				}
				this.state.timerGetResultCount++;
				//	setTimeout(this.startAnimationWebGL(), 3000);
			}
		}
		else {
			//	this.props.GetCurrentResult(this.state.currentDraw.DrawNo)
			//	setTimeout(this.startAnimationWebGL(), 3000);
		}
	}

	fnWelcome = () => {
	}
	fnStartAnimation = () => {
	}
	viewAnimationResult = (flog) => {
		if (flog == 1 && this.state.isViewAnimation == true) {
			return;
		}
		this.setState({ isViewAnimation: !this.state.isViewAnimation });
	}
	clearAfterAddToCart = () => {
		let obj = this.state.cartItems.filter(x => x.GameId == this.state.currentDraw.GameId);
		for (let i = 0; i < obj.length; i++) {
			this.props.RemoveFromCart(obj[i].id);
		}
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};

	clearCart = () => {
		this.props.ClearCart();
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};

	quickPick = () => {
		if (this.state.cartItems.length == this.state.currentGamesFamily.maxSelect) {
			//this.props.ClearCart();
			//this.clearAfterAddToCart();
			alert(this.props.t('lblMaximumselection') + " : " + this.state.currentGamesFamily.maxSelect);
			return;
		}
		this.state.random = this.state.cartItems.filter(a => a.GameId == this.state.currentDraw.GameId).map(a => a.ballNo);
		this.state.QPBallCount = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		if (this.state.QPBallCount <= 0) {
			this.state.QPBallCount = this.state.currentGamesFamily.maxSelect;
		}
		var index = -1;

		let newid = 0;
		if (this.state.cartItems.length > 0) {
			newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.bMax), this.state.cartItems[0].bMax);
		}

		//for (var k = this.state.ballStartNumber; k <= this.state.QPBallCount; k++) {
		index = this.generateRandomInteger();
		let _bal = this.state.currentDraw.GameId + "_" + index;
		newid++;
		this.selectBall(_bal, newid);
		if (this.state.showSingleMsg == true) {
			this.state.showSingleMsg = false;
			return;
		}
		//}
	};

	generateRandomInteger = () => {
		var min = this.state.ballStartNumber;
		var max = this.state.ballEndNumber;
		if (this.state.random.length >= this.state.currentGamesFamily.maxSelect) {
			this.state.random = [];
		}
		let valFound = false
		while (valFound == false) {
			for (var i = min; i <= max; i++) {
				var temp = Math.floor(max - Math.random() * (max - min));
				temp = ("0" + temp).slice(-2);
				if (temp && this.state.random.indexOf(temp) == -1) {
					this.state.random.push(temp);
					valFound = true;
					return temp;
				}
			}
		}
		// 	console.log("temp123Test123 : ");
		// return	this.generateRandomInteger();
	};

	removeFromCart = (cid) => {
		this.props.RemoveFromCart(cid);
	};

	countdownCompleted = () => {
		this.RefreshAfterGameClose();
	};

	getGameTime = () => {
		let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
		if (_date != undefined && _date != "") {
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
			console.log("getGameTime : " + sTime);
			console.log("DateNow : " + Date.now());
			return sTime;
		}
		else {
			return 0;
		}
	};

	// Renderer callback with condition
	renderer = ({ total, days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state			
			return <span>Draw Closed!</span>;
		} else {
			// Render a countdown
			return <span>{zeroPad(total)}:{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
		}
	};

	getServerTime = () => {
		if (this.state._serverTimeDiff != 0 && this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0) {
			//return this.state._serverTimeDiff+Date.now()+1000;
			console.log("getServerTime : " + this.state._serverTimeDiff);
			return this.state._serverTimeDiff = (this.state._serverTimeDiff + 1000);
		}
		else {
			let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
			if (_date != undefined && _date != "") {
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
				var sTime = (new Date(ss.setSeconds(ss.getSeconds() - 10)).getTime());
				//this.state._serverTimeDiff=Date.now()-sTime;
				this.state._serverTimeDiff = sTime;
				console.log("getServerTime : " + sTime);
				console.log("DateNow : " + Date.now());
				return sTime;

			}
			else {
				return 0;
			}
		}
	};

	reCount = (rundraw) => {
		//return <span class="DropCnt" data-pid={this.state.currentDraw ? this.state.currentDraw.DrawID : ""} data-countdown={this.state.currentDraw ? this.state.currentDraw.DrawDateTime : ""}></span>;
		//return <span class="DropCnt" data-pid={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawID : ""} data-countdown={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : ""}></span>;		
		//return <span><Countdown now={Date.now} date={Date.now() + 100000} onTick={this.rendererTick()} /> </span>;

		if (this.state.currentDraw == null || rundraw == null || Object.keys(rundraw).length == 0) {

			return <span></span>;
		}
		else {
			this.state.coundownStart = 1;
			return <span key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime()} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{this.props.t('lblnextdraw')} {zeroPad((days * 24) + hours)}hh:{zeroPad(minutes)}mm:{zeroPad(seconds)}ss</div>) }} /> </span>;
		}
	};

	BuyLotteryBet = () => {
		if (this.props.user == undefined || this.props.user.mobileNo == "0") {
			alert(this.props.t('lblLoginValidation'));
			return;
		}
		if (this.state.cartItems == undefined || this.state.cartItems.length <= 0) {
			alert(this.props.t('lblplcurbet'));
			return;
		}
		let totStake = this.state.cartItems.reduce((a, b) => a = a + b.StakeValue, 0);
		let minTotStake = 500;
		let maxTotStake = 10000;
		if (this.state.currentGamesFamily != undefined) {
			minTotStake = this.state.currentGamesFamily.minStakePerTrans;
			maxTotStake = this.state.currentGamesFamily.maxStakePerTrans;
		}

		if (totStake < minTotStake)//300
		{
			alert(this.props.t('lblMinimumplayAmt') + " : " + minTotStake);
			return;
		}
		else if (totStake > maxTotStake)//10000
		{
			alert(this.props.t('lblMaximumplayAmt') + " : " + maxTotStake);
			return;
		}

		var sTime = new Date(this.props.serverTime);
		let gameGroupid=this.state.cartItems[0].GameGroupID;
		//let cartList = this.state.cartItems.sort((a, b) => (a.drawID > b.drawID) ? 1 : -1);		
		let cartList = this.state.cartItems.sort((a, b) => a.DrawNo - b.DrawNo);
		let transactionString = "";
		let gamestring = "";
		let subgameId = 0;
		cartList.forEach(item => {
			item.BallNo = item.BallNo.replaceAll("_", ",");
			var betcomb = this.state.LstRouletteBetType.filter(a => a.Number == item.BallNo);
			if (betcomb != null)
			{
				item.BallNo = betcomb[0].ID;
			}
			else
			{
				//continue;			
			}
			if (subgameId != 0 && subgameId != item.DrawNo) {
				gamestring += "*";
			}
			if (subgameId != item.DrawNo) {
				gamestring += item.GameID + "-";
			}				
			gamestring += item.BallNo;
			gamestring += "," + parseInt(item.StakeValue);			
			gamestring += "," + parseInt(item.OddsValue);
			gamestring += "|";
			subgameId = item.DrawNo;
		})
		gamestring += "*";		
		let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);

		//let betoption = "2";

		transactionString += `'${this.props.user.mobileNo}',`;
		transactionString += `'${this.props.user.mobileNo}',`;		
		transactionString += `'${gameGroupid}',`;
		transactionString += `'${gamestring}',`;		
		//transactionString += `'${this.props.user.loginKey}','${TransID}','0','0','${cartList.length}','${totStake}','${this.props.i18n.language}'`;
		transactionString += `'${this.props.user.loginKey}','${TransID}','0','0','${cartList.length}','${totStake}','1','0'`; // 1- Credential Id and 0- Dummy

		this.props.Buybet(transactionString);
		this.clearCart();
	};
	PutCoin = (objEle, isFromOvelForChecking, cTabid) => {

		if (this.state.CurrentBallAction == 1) {
			return false;
		}
		if (this.state.currentDraw == null) {
			alert("Veuillez choisir le tirage");
			return false;
		}

		var coinParient = null;
		var coinVal = this.state.CurrentCoinValue;
		if (cTabid == 3) { coinVal = this.state.CurrentCoinValue * 2; }
		var newID = "coin";
		var newIDonly = "";
		var refIDCount = 0;
		var disName = "";
		var _refID = objEle.target.dataset.refid;
		if (_refID == undefined) { return true; }
		var ss = _refID.split("~");
		var cn = "";
		var dcn = "";
		$.each(ss, function (key, value) {
			refIDCount++;
			var ids = value.split("-");
			newIDonly = newIDonly + cn + ids[1];
			cn = "_";
			disName = disName + dcn + ids[1];
			dcn = ",";
		});

		newID = newID + "_" + newIDonly;
		if (refIDCount == 6) {
			var ids1 = disName.split(",");
			disName = ids1[0] + "-" + ids1[5]
		}
		var _dname = objEle.target.dataset.dname;
		if (_dname != undefined && _dname != null) {
			disName = _dname;
		}
		var _flog = false;
		var noVal = newIDonly;
		if (noVal != undefined) {
			//noVal = noVal.replaceAll("_", ",");
			noVal = noVal.split("_").join(',');
			var fLst1 = this.state.LstRouletteBetType.filter(function (a) { return a.Number == noVal });
			if (fLst1 != undefined && fLst1 != null && fLst1.length > 0) {
				var fLst = window.maxValueLst.filter(function (a) { return a.Id == fLst1[0].CateID });
				this.state.innerMaxpoint = fLst[0].MaxValue;
				this.state.outerMaxpoint = fLst[0].MaxValue;
			}
			else {
				_flog = true;
			}
		}
		else {
			_flog = true;
		}
		if (_flog) {
			this.state.outerMaxpoint = 5000;
			this.state.innerMaxpoint = 500;
		}
		if ($("#" + newID).length) {
			var _oldVal = $("#" + newID).data('coinval');
			var _ballcnt = $("#" + newID).data('ballcount');
			//update by thamil for coin value chnages.
			//var newVal = (parseFloat(_oldVal) + coinVal).toFixed(2);
			var newVal = parseFloat(_oldVal + coinVal);
			if (_ballcnt <= 6 && newVal > this.state.innerMaxpoint) {
				alert("sélectionner Max:" + this.state.innerMaxpoint);
				return false;
			}
			if (_ballcnt <= 6 && !this.ChecktheinnerMaxPoint(newIDonly, 0)) {
				alert("sélectionner Max:" + this.state.innerMaxpoint);
				return false;
			}
			else if (_ballcnt > 6 && newVal > this.state.outerMaxpoint) {
				alert("sélectionner Max:" + this.state.outerMaxpoint);
				return false;
			}
			else if (_ballcnt > 6 && newVal < this.state.outerMinpoint) {
				//alert("Minimum Point limit:" + outerMinpoint);
				//return false;
				coinVal = this.state.outerMinpoint;
			}
			else {
				if (isFromOvelForChecking != 1) {
					$("#" + newID).data('coinval', newVal);
					$("#" + newID).html(newVal);
					setTimeout(function () { this.addtoCart($("#" + newID), "1"); }, 10);
					//UpdateToolTip(refIDCount, newVal, 1);

				}
			}
			return true;
		}
		else {
			if (refIDCount <= 6 && !this.ChecktheinnerMaxPoint(newIDonly, 0)) {
				alert("sélectionner Max:" + this.state.innerMaxpoint);
				return false;
			}
			else if (refIDCount <= 6 && coinVal > this.state.innerMaxpoint) {
				alert("sélectionner Max:" + this.state.innerMaxpoint);
				return false;
			}
			else if (refIDCount > 6 && coinVal < this.state.outerMinpoint) {
				//alert("Minimum Point limit:" + outerMinpoint);
				//return false;
				coinVal = this.state.outerMinpoint;
			}
		}
		//added by thamil for check the all numbers max limit under in selected Ovel .
		if (isFromOvelForChecking == 1) { return true; }

		var OtherGames = null;
		if (this.state.oldBetSlipList != null) {
			//var expired = oldBetSlipList.filter(function (a) { return a.GameID == CurrentoldBetSlip.GameID });
			//if (expired != undefined && expired != null && expired.length > 0) {
			if (this.state.objSelectedDraw.GameID == this.state.CurrentoldBetSlip.GameID) {
				var str = <div onclick='ballClick(this)' onmouseleave='mouseLeaveEvt(this)' onmouseover='mouseEnterEvt(this)' class='ball' id='" + newID + "' data-coinval='" + coinVal + "' data-ballno='" + newIDonly + "' data-ballcount='" + refIDCount + "' data-dname='" + disName + "'>" + coinVal + "</div>;
				$(objEle).parent().append(str);
			}
			else {
				//setTimeout(function () { addtoCartOnReload(CurrentoldBetSlip, "1"); }, 200);
				OtherGames = "1"
			}
		}
		else {
			//commend by narmatha ball position is changing 
			//var str = "<div onclick='ballClick(this)' onmouseleave='mouseLeaveEvt(this)' onmouseover='mouseEnterEvt(this)' class='ball' id='" + newID + "' data-coinval='" + coinVal + "' data-ballno='" + newIDonly + "' data-ballcount='" + refIDCount + "' data-dname='" + disName + "'>" + coinVal + "</div>";
			/* var str=React.createElement("div",{'onclick':'ballClick(this)','onmouseleave':'mouseLeaveEvt(this)','onmouseover':'mouseEnterEvt(this)','id':newID,'className':'ball','data-coinval':coinVal,'data-ballno':newIDonly,'data-ballcount':refIDCount,'data-dname':disName},coinVal);
			let consoleLine = document.getElementById(objEle.target.id).parentElement; 
			consoleLine.appendChild(str);  */
			//$("#"+objEle.target.id).parent()[0].appendChild(str);

		}

		//this one placed instant of buttom
		if (OtherGames == "1") {
			this.addtoCartOnReload(this.state.CurrentoldBetSlip);
		}
		else {
			//window.setTimeout(this.addtoCart($("#" + newID), "0"), 100);
			window.setTimeout(this.addtoCart(newIDonly, coinVal, refIDCount, disName, "0"), 100);
		}

		//Commend by narmatha Changes in Coin position
		/* if (refIDCount == 1) {
			coinParient = $("#" + _refID).children('span');
		}
		else {
			coinParient = $(objEle);
		}
		var pos1 = $(coinParient).position();
		var newObjXval = 0;
		var newObjYval = 0;
		var objClassName = $(coinParient).attr('class');
		var objHeight = $(coinParient).height();
		var objWidth = $(coinParient).width();

		var objHeight_New = $("#" + newID).height() / 9;
		var objWidth_New = $("#" + newID).width() / 2;

		if (_refID == "rouletteBall-00") {
			//pos1.top = pos1.top + 50;
		}
		if (objClassName.indexOf("Top") != -1) //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 15);
		}
		else if (objClassName.indexOf("Btom") != -1)   //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 15);
		}
		else if (objClassName.indexOf("rteBallVal") != -1)  //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 10);
		}
		else if (objClassName.indexOf("Rht") != -1)  //ok
		{
			newObjXval = pos1.left - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 13);
		}
		else if (objClassName.indexOf("Lft") != -1)  //ok
		{
			newObjXval = pos1.left - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 13);
		}

		else if (objClassName.indexOf("center") != -1)   //ok
		{
			newObjXval = pos1.left - objWidth_New + 5;
			newObjYval = pos1.top - (objHeight_New + 8);
		}
		else if (objClassName.indexOf("group") != -1)   //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 8);
		}

		$("#" + newID).css({ top: parseInt(newObjYval) + "px", left: parseInt(newObjXval) + "px" }); */
		//this one placed on top
		//if (OtherGames == "1") {
		//    addtoCartOnReload(CurrentoldBetSlip);
		//}
		//else {
		//    setTimeout(function () { addtoCart($("#" + newID), "0"); }, 10);
		//}

		//UpdateToolTip(refIDCount, coinVal, 1);
		return true;
	};

	ballClick = (obj) => {
		if (this.state.CurrentBallAction == 1 && this.state.objSelectedDraw != null && this.state.objSelectedDraw != undefined) {
			this.mouseLeaveEvt(obj);
			var href = $(obj).data("ballno");
			var cid = this.state.objSelectedDraw.GameID + "_" + href;
			this.DeleteBetSlip(cid);
			return;
		}

		//var obj1 = $(obj).data('parientid');
		//var ObjEle = null;
		//if (obj1 != null && obj1 != undefined) {
		//    ObjEle = $("#" + obj1);
		//}
		//else {
		//    ObjEle = $(obj).parent().children('span');
		//}

		var obj1 = $(obj).attr('id');
		var ObjEle = null;
		if (obj1 != null && obj1 != undefined) {
			var newid = obj1.replace("coin", "obj");
			ObjEle = $("#" + newid);
		}
		else {
			ObjEle = $(obj).parent().children('span');
		}
		//var ObjEle = $(obj).parent().children('span');
		this.PutCoin(ObjEle);
	};

	mouseLeaveEvt = (ele) => {
		var obj = null;
		if ($(ele).attr("class") != "ball") { obj = $(ele); }
		else {
			var newid = $(ele).attr("id").replace("coin", "obj");
			obj = $("#" + newid);
		}
		var href = obj[0].dataset.refid;
		if (href == undefined) { return false; }
		var ss = href.split("~");
		//by thamil ,no orphns bet type for this roulette
		/* var isdoubleselect = 0;
		if ($(ele).attr('class').toLowerCase().indexOf("ovel") != -1 || $(ele).attr('class').toLowerCase().indexOf("orphns") != -1 || $(ele).attr('class').toLowerCase().indexOf("splist") != -1) {
			isdoubleselect = 1;
		} */
		$.each(ss, function (key, value) {
			$("#" + value).children("span").toggleClass("bgHover");
			/* if (isdoubleselect == 1)
			{ $("#" + value.replace("rteOvelTbl", "roulette")).children("span").toggleClass("bgHover"); } */
		});
	};

	mouseEnterEvt = (ele) => {
		var obj = null;
		if ($(ele).attr("class") != "ball") { obj = $(ele); }
		else {
			var newid = $(ele).attr("id").replace("coin", "obj");
			obj = $("#" + newid);
		}
		var href = obj[0].dataset.refid;
		if (href == undefined) { return false; }
		var ss = href.split("~");
		var newID = "coin";
		var _toolTipHeader = "";
		var ballCount = 0;
		//by thamil ,no orphns bet type for this roulette
		//var isdoubleselect = 0;
		//if ($(ele).attr('class').toLowerCase().indexOf("ovel") != -1 || $(ele).attr('class').toLowerCase().indexOf("orphns") != -1 || $(ele).attr('class').toLowerCase().indexOf("splist") != -1) {
		//	isdoubleselect = 1;
		//}
		$.each(ss, function (key, value) {
			if ($("#" + value).children("span").hasClass("bgHover")) { $("#" + value).children("span").toggleClass("bgHover"); }

			$("#" + value).children("span").toggleClass("bgHover");
			// if (isdoubleselect == 1) {
			// 	if ($("#" + value).children("span").hasClass("bgHover"))
			// 	{ $("#" + value.replace("rteOvelTbl", "roulette")).children("span").removeClass("bgHover"); }
			// 	$("#" + value.replace("rteOvelTbl", "roulette")).children("span").toggleClass("bgHover");
			// }
			var ids = value.split("-");
			newID = newID + "_" + ids[1];
			ballCount++;
		});
	}
	addtoCart = (ballno, coinValue, ballCnt, displayName, IsUpdate) => {
		//var noVal = obj.data("ballno");
		var noVal = ballno;
		if (noVal == null || noVal == undefined) { return; }
		var no = noVal.toString();
		//var _ballcount = obj.data("ballcount");
		var _ballcount = ballCnt;
		//var stakeval = $("#txtCommonStakeAmount").val();
		//var stakeval = obj.data("coinval");
		//var _dispName = obj.data('dname');
		var stakeval = coinValue;
		var _dispName = displayName;
		if (stakeval == null || stakeval == "") {
			stakeval = 0;
		}
		var key = this.state.currentDraw.GameId + "_" + no;
		//add by thamil for implement the multiple buy.
		var isExist = $("#" + key);
		if (isExist != undefined && isExist != null && isExist.length > 0) { IsUpdate = "1"; }
		if (IsUpdate == "1") {
			this.UpdateAmountInControl(key, stakeval)
			this.CalculateBetSlipTotal();
			return;
		}
		var _oddsValue = parseFloat(this.state.CurrentOddsValue / parseInt(_ballcount)).toFixed(2);
		//var _oddsValue = objSelectedDraw.OddsValue / parseInt(_ballcount);

		var _bet = { "Id": key, "BallNo": no, "DispName": _dispName, "BallCount": _ballcount, "GameGroupID": this.state.currentDraw.GameGroupId, "SubGameID": this.state.currentDraw.SubGameID, "DrawNo": this.state.currentDraw.DrawNo, "GameID": this.state.currentDraw.GameId, "GameName": this.state.currentDraw.GameName, "GameTime": this.state.currentDraw.Drawdatetime, "OddsValue": _oddsValue, "StakeValue": stakeval, "SubTotal": (stakeval * _oddsValue).toFixed(2) };
		this.props.AddToCart(_bet);
		//this.appendBetSlip(_bet);
		//var divToToggle = $(obj.parent().attr('att'));
		//divToToggle.toggle(1000);

		this.CalculateBetSlipTotal();
	};


	ChecktheinnerMaxPoint = (obj2, repeat) => {
		var totamt = 0;
		var c_id = null;
		sessionStorage.setItem("Rouletteslip", JSON.stringify(this.state.lstSelectLottoBalls));
		var lstSelectLottoBallsTemp = JSON.parse(sessionStorage.getItem("Rouletteslip"));
		var validreturn = true;
		if (repeat == 0) {
			totamt = 0;
			//var obj2 = $("#" + objID).data("ballno");
			if (obj2 != undefined && obj2 != null) {
				c_id = obj2.toString().split("_");
			}
		}

		$.each(c_id, function (key1, value1) {
			var value12 = ("0" + value1).slice(-2);
			var lstSelectLottoBallsTemp1 = lstSelectLottoBallsTemp.filter(function (a) { return a.BallNo.indexOf(value12) != -1 });
			lstSelectLottoBallsTemp = lstSelectLottoBallsTemp.filter(function (a) { return a.BallNo.indexOf(value12) == -1 });
			$.each(lstSelectLottoBallsTemp1, function (i, item) {
				var obj = $("#coin_" + item.BallNo);
				if (obj == undefined || obj == null) { return; }
				var _val = obj.data("coinval");
				totamt = totamt + parseFloat(_val);
				if ((totamt + this.state.CurrentCoinValue) > this.state.innerMaxpoint) { validreturn = false; return false; }

				return validreturn;
			});
			return validreturn;
		});
		return validreturn;
	};

	UpdateAmountInControl = (id, amt) => {
		var idgame = "txtStakeVal_" + id;
		var $qty = $('#' + idgame);
		var currentVal = parseFloat(amt);
		if (isNaN(currentVal)) {
			currentVal = 0;
			$qty.html(currentVal);
		}
		if (!isNaN(currentVal)) {
			$qty.html(currentVal);
		}
		this.UpdateTotalInListOnly(id, amt)
	};

	CalculateBetSlipTotal = () => {
		$("#betscount").html("" + this.state.lstSelectLottoBalls.length);
		$("#popcount").html("" + this.state.lstSelectLottoBalls.length);
		var Total = 0.00;
		var betAmt = 0.00;
		$.each(this.state.lstSelectLottoBalls, function (k, v) {
			Total = Total + parseFloat(v.SubTotal);
			betAmt = betAmt + parseFloat(v.StakeValue);
		});
		$("#totStakeAmount").html("" + parseFloat(betAmt).toFixed(2));
		//$("#betAmt").html("" + parseFloat(Total));
		sessionStorage.setItem("Rouletteslip", JSON.stringify(this.state.lstSelectLottoBalls));
		if (this.state.lstSelectLottoBalls != null && this.state.lstSelectLottoBalls.length == 0) {
			this.ShowEmptyLottery(1)
		}
		else {
			this.ShowEmptyLottery(0)
		}
		if ($("#betslipErrordiv").is(":visible")) {
			$("#betslipErrordiv").hide();
			$('#betslipError').text("");
		}

		if (this.state.IsAutoApply == 1 && this.state.AutoApplyCount != "") {
			$('#lblAutoApplyInfo').text("Auto Apply Draw Stake :" + this.state.AutoApplyCount + " X " + parseFloat(betAmt) + " = " + parseFloat(this.state.AutoApplyCount) * parseFloat(betAmt));
		}
		else {
			$('#lblAutoApplyInfo').text("");
		}
	};
	appendBetSlip = (_bet) => {
		//var clk = "onkeydown='update_ValData(this)' onClick = \"this.select()\"; onblur='make_selecttext(this)'";
		var topdrawsection = "<div class='rouletteBetslipLi'><div class='rouletteCont'><span class='rtedrawNumber'>Draw #" + _bet.DrawNo + "</span><a class='rteclose' onclick=\"DeleteBetSlip('" + _bet.Id + "','1');\"><i class='fa fa-times' aria-hidden='true'></i></a></div>";
		var line1 = "<div class='rouletteCont2'><div class='rouletteDrwCont'><span>" + _bet.DispName + "</span></div>";
		var line2 = "<div class='rouletteDrwCont'><span class='rtebetValue' id='txtStakeVal_" + _bet.Id + "' >" + _bet.StakeValue + "</span></div>";
		var line3 = "<div class='rouletteDrwCont'><span class='rtebetValueEntr2Lft' id='txtSubtot_" + _bet.Id + "'>" + _bet.SubTotal + "</span><span class='rtebetValueEntr2Rht'>" + _bet.OddsValue + "X</span></div></div>";
		var footerbetsilpsection = "<div class='rtebetSlipLi'>" + line1 + line2 + line3 + "</div></div>";
		var fullbetslip = "<li class='toggle' id=" + _bet.Id + ">" + topdrawsection + "" + footerbetsilpsection + "</li>";

		//var line1 = "<div class='betValuedrawnme'><div class='betValueEntrCont1 roulette-betValueInput'><span>" + _bet.DispName + "</span></div></div>";
		//var line2 = "<div class='betValuedrawnme2'><div class='betValueEntrCont1 roulette-betValueInput'><span id='txtStakeVal_" + _bet.Id + "' >" + _bet.StakeValue + "</span></div></div>";
		//var line3 = "<div class='betValueEntr'><div class='betValueEntrCont'><span class='betValueEntr2Lft' id='txtSubtot_" + _bet.Id + "'>" + _bet.SubTotal + "</span><span class='betValueEntr2Rht'>" + _bet.OddsValue + "X</span></div></div>";
		//var line4 = "<div class='betClose'><a class='betCloseBtn' onclick=\"DeleteBetSlip('" + _bet.Id + "');\"><i class='fa fa-times' aria-hidden='true'></i></a></div>";
		//var footerbetsilpsection = "<div class='betSlipLi'>" + line1 + "<div class='drawNumber'><span>#" + _bet.GameID + "</span></div>" + line2 + line3 + line4 + "</div>";
		//var fullbetslip = "<li class='toggle border-color-bottom background-color' id=" + _bet.Id + ">" + footerbetsilpsection + "</li>";

		$("#betslipmaster").prepend(fullbetslip);
		$("#betslipmaster").animate({ scrollTop: 0 }, 300);
		this.state.lstSelectLottoBalls.push(_bet);
	};

	ShowEmptyLottery = (isadd) => {

		if (isadd == 1) {
			$('#betslipEmpty').text("Veuillez placer votre pari");
			$("#betslipEmptydiv").show();
		}
		else {
			$("#betslipEmptydiv").hide();
			$('#betslipEmpty').text("")
		}
		this.ShowAdds();
	};

	UpdateTotalInListOnly = (Id, amount) => {
		var search = null;
		for (var i = 0; i < this.state.lstSelectLottoBalls.length; i++) {
			if (Id == this.state.lstSelectLottoBalls[i].Id) {
				search = this.state.lstSelectLottoBalls[i];
				break;
			}
		}
		if (search == null) { return; }
		search.StakeValue = amount;
		var subTotal = search.StakeValue * search.OddsValue;
		search.SubTotal = subTotal.toString().replace(",", ".");
		//sessionStorage.setItem("Rouletteslip", JSON.stringify(AllLotteryNumber));     
		$("#txtSubtot_" + search.Id).html(subTotal.toFixed(2));
		this.CalculateBetSlipTotal();

		$("#betslipErrordiv").hide();
		$('#betslipError').text("");
	};

	ShowAdds = () => {
		if (this.state.lstSelectLottoBalls != null && this.state.lstSelectLottoBalls.length > 2) {
			$("#lstLatestResult").css("display", "none");
		}
		else {
			$("#lstLatestResult").css("display", "block");
		}
		return;
		if ($(window).width() < 1024) {
			$("#RightAddImg1").css("display", "none");
			return;
		}
		if (this.state.lstSelectLottoBalls != null && this.state.lstSelectLottoBalls.length > 1) {
			$("#RightAddImg1").css("display", "none")
		}
		else {
			$("#RightAddImg1").css("display", "block")
		}
	};

	addtoCartOnReload = (_CurrentoldBetSlip, IsUpdate) => {
		var noVal = _CurrentoldBetSlip.BallNo;
		if (noVal == null || noVal == undefined) { return; }
		var no = noVal.toString();
		var _ballcount = _CurrentoldBetSlip.BallCount;
		var stakeval = _CurrentoldBetSlip.StakeValue;
		var _dispName = _CurrentoldBetSlip.DispName;
		if (stakeval == null || stakeval == "") {
			stakeval = 0;
		}
		var key = _CurrentoldBetSlip.GameID + "_" + no;
		//add by thamil for implement the multiple buy.
		var isExist = $("#" + key);
		if (isExist != undefined && isExist != null && isExist.length > 0) { IsUpdate = "1"; }
		if (IsUpdate == "1") {
			this.UpdateAmountInControl(key, stakeval)
			this.CalculateBetSlipTotal();
			return;
		}
		var _oddsValue = parseFloat(this.state.CurrentOddsValue / parseInt(_ballcount)).toFixed(2);
		var _bet = _CurrentoldBetSlip;
		this.UpdateTotalInListOnlappendBetSlip(_bet);
		//var divToToggle = $(obj.parent().attr('att'));
		//divToToggle.toggle(1000);
		this.CalculateBetSlipTotal();
	};
	UpdateAmountInControl = (id, amt) => {

		var idgame = "txtStakeVal_" + id;
		var $qty = $('#' + idgame);
		var currentVal = parseFloat(amt);
		if (isNaN(currentVal)) {
			currentVal = 0;
			$qty.html(currentVal);
		}
		if (!isNaN(currentVal)) {
			$qty.html(currentVal);
		}
		this.UpdateTotalInListOnly(id, amt)
	};

	UpdateTotalInListOnly = (Id, amount) => {
		var search = null;
		for (var i = 0; i < this.state.lstSelectLottoBalls.length; i++) {
			if (Id == this.state.lstSelectLottoBalls[i].Id) {
				search = this.state.lstSelectLottoBalls[i];
				break;
			}
		}
		if (search == null) { return; }
		search.StakeValue = amount;
		var subTotal = search.StakeValue * search.OddsValue;
		search.SubTotal = subTotal.toString().replace(",", ".");
		//sessionStorage.setItem("Rouletteslip", JSON.stringify(AllLotteryNumber));     
		$("#txtSubtot_" + search.Id).html(subTotal.toFixed(2));
		this.CalculateBetSlipTotal();

		$("#betslipErrordiv").hide();
		$('#betslipError').text("");
	};

	DeleteBetSlip = (gameid, fromHtml) => {
		for (var i = 0; i < this.state.cartItems.length; i++) {
			var search = this.state.cartItems[i];
			if (search.Id != gameid) {
				continue;
			}
			$("#" + gameid).remove();
			this.state.cartItems = this.state.cartItems.filter(function (a) { return a.gameID !== gameid })
			$.each($(".ball"), function (p, item3) {
				if ($(this).data("ballno") == search.BallNo) {
					$(this).remove();
					return false;
				}
			});
			if (fromHtml == "1") {
				if (this.state.cartItems == null || this.state.cartItems.length <= 0) {
					$("#virtualbetSlipBtn").click();
				}
			}
			//sessionStorage.setItem("Rouletteslip", JSON.stringify(AllLotteryNumber));
			this.CalculateBetSlipTotal();
			break;
		}
	};

	DeleteAllBetSlip=(isQP)=> {		
		for (var i = 0; i < this.state.cartItems.length; i++) {
			var search = this.state.cartItems[i];
			var gameid = search.Id
			$("#" + gameid).remove();
			if (isQP != "1") {
				$.each($(".ball"), function (p, item3) {
					if ($(this).data("ballno") == search.BallNo) {
						$(this).remove();
						return false;
					}
				});
			}
		}
		this.state.cartItems = [];
		this.CalculateBetSlipTotal();
		$("#txtCommonStakeAmount").val("");
		//$("#txtCommonStakeAmountUp").val("");
		if (isQP != "1") {
			//ClearQuickPick();
		}
		if ($("#chkApplySelection").is(":checked")) {
			$("#chkApplySelection").click();
		}
		/* IsAutoApply = 0;
		AutoApplyCount = "";
		IsReBet = "0"; */
		//by thamil for in mobile view check box click event issue.
		//if ($(".BetSLipBtn1024").css("display") == "block" || $(".betCloseMob").css("display") == "block") {
		//    $(".BetSLipBtn1024 a").click();
		//}
	};

	showchooseCoin = (e) => {
		if (!($(e).parent().hasClass("coinSelImgActive"))) {

			$(".coins ul li").removeClass("coinSelImgActive");
			$(e.parentElement).addClass("coinSelImgActive");
		}
		this.state.CurrentCoinValue = parseFloat(e.innerText);
		this.state.CurrentBallAction = 0;
	}
	/* Clearplaybtn = (e) => {
		var $ = jQuery.noConflict();
		if ($(window).width() <= 1023) {
			$(".BetSLipBtn1024 a").click();
		}
	}; */

	
	ballClickNew = (obj) => {
		if (this.state.CurrentBallAction == 1 && this.state.objSelectedDraw != null && this.state.objSelectedDraw != undefined) {
			this.mouseLeaveEvt(obj);
			var href = $(obj).data("ballno");
			var cid = this.state.objSelectedDraw.GameID + "_" + href;
			this.DeleteBetSlip(cid);
			return;
		}
		var obj1 = $(obj).attr('id');
		var ObjEle = null;
		if (obj1 != null && obj1 != undefined) {
			var newid = obj1.replace("coin", "obj");
			ObjEle = $("#" + newid);
		}
		else {
			ObjEle = $(obj).parent().children('span');
		}
		this.PutCoin(ObjEle);
	};

	PutCoin = (objEle, isFromOvelForChecking, cTabid) => {
		if (objEle==undefined || objEle==null ||objEle.length==0) {
			return false;
		}
		if (this.state.CurrentBallAction == 1) {
			return false;
		}
		if (this.state.currentDraw == null||this.state.currentDraw.length==undefined) {
			alert("Veuillez choisir le tirage");
			return false;
		}

		var _objIDArr = null;
		if (objEle.target.dataset.id) {
			_objIDArr = objEle.target.dataset.id.replaceAll("obj_", "");
		}
		else {
			_objIDArr = objEle.target.dataset.refid.replaceAll("rouletteBall-", "");
		}

		var _objID = _objIDArr.split("~").join(',');
		var fLst1 = this.state.LstRouletteBetType.filter(function (a) { return a.Number == _objID });
		var coinVal = this.state.CurrentCoinValue;
		var disName = "";
		var refIDCount = 0;
		var newID = "coin_" + _objIDArr;
		if (fLst1.length > 0) {
			fLst1 = fLst1[0];
			if (fLst1.Stake && fLst1.Stake > 0) {
				fLst1.Stake = fLst1.Stake + coinVal;
			}
			else {
				fLst1.Stake = coinVal;
			}
			disName = fLst1.DispName ? fLst1.DispName : fLst1.Number;
			refIDCount = fLst1.Number.split(",").length;
			fLst1.CoinID = newID;
		}
		window.setTimeout(this.addtoCart(_objIDArr, coinVal, refIDCount, disName, "0"), 100);
		//set Coin Position
		var coinParient=null;
		let _refID = objEle.target.dataset.refid;
		if (refIDCount == 1) {			
			coinParient = $("#" + _refID).children('span');
		}
		else {
			coinParient = $(objEle);
		}
		var pos1 = $(coinParient).position();
		var newObjXval = 0;
		var newObjYval = 0;
		var objClassName = $(coinParient).attr('class');
		var objHeight = $(coinParient).height();
		var objWidth = $(coinParient).width();

		var objHeight_New = $("#" + newID).height() / 9;
		var objWidth_New = $("#" + newID).width() / 2;

		if (_refID == "rouletteBall-00") {
			//pos1.top = pos1.top + 50;
		}
		if (objClassName.indexOf("Top") != -1) //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 15);
		}
		else if (objClassName.indexOf("Btom") != -1)   //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 15);
		}
		else if (objClassName.indexOf("rteBallVal") != -1)  //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 10);
		}
		else if (objClassName.indexOf("Rht") != -1)  //ok
		{
			newObjXval = pos1.left - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 13);
		}
		else if (objClassName.indexOf("Lft") != -1)  //ok
		{
			newObjXval = pos1.left - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 13);
		}
		else if (objClassName.indexOf("center") != -1)   //ok
		{
			newObjXval = pos1.left - objWidth_New + 5;
			newObjYval = pos1.top - (objHeight_New + 8);
		}
		else if (objClassName.indexOf("group") != -1)   //ok
		{
			newObjXval = pos1.left + (objWidth / 2) - objWidth_New;
			newObjYval = pos1.top + (objHeight / 2) - (objHeight_New + 8);
		}
		//set Position
		//{top:"46px",left:"7px"}
		fLst1.Position = { top: parseInt(newObjYval) + "px", left: parseInt(newObjXval) + "px" };
	
}

	render() {
		const { t } = this.props;

		return (
			<div className="mainContainer">
				<Header />
				<div className="Gamesection">
					<div class="container">

						<RouletteLeft selectDraw={this.selectDraw} currentAvailableDraws={this.state.currentAvailableDraws} />
						<div className="col-md-9 col-sm-12 smMbl">

							<div className="drawTabs">
								<a className="" id="drawTabs1">{t('lblAllDraws')}</a>
								<a className="" id="drawTabs2">{t('lbllatresult')}</a>

								{/* <a class="" id="virtualbetSlipBtn">{t('lblgameslip')} <span class="betCount">{this.state.cartItems.length}</span></a> */}
							</div>
							<div class="drawTabs1024">
								<a class="" id="drawTabs3">{t('lblAllDraws')}</a>
							</div>
							{/* <div class="BetSLiparea">
								<div class="BetSLipBtn1024">
									<a id="virtualbetSlipBtn" href="#">Game Slip</a><span class="betCount"> {this.state.cartItems.length}</span>
								</div>
							</div> */}

							<div class="lottoTimesec">
								<span><img src="./assets/img/icons/Karaga_B.png" /></span>

								<div class="TimeSec">
									<small style={{ borderRight: "1px solid #fff" }}>{this.reCount(this.state.currentRunningDraw)}</small>
									<small>{this.state.currentDraw ? this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime : '-'}</small>
								</div>
							</div>


							<div class="lottoGame">
								<div class="col-md-8">
									{/* <span id="eyeIcon" class={`eyeIcon eyeOpen ${this.state.isViewAnimation ? 'eyeClose' : ''}`} onClick={() => { this.viewAnimationResult() }} >{this.state.isViewAnimation ? t('lblHide') : t('lblShow')}</span> */}
									<div class="animateSec" style={{ display: this.state.isViewAnimation ? "block" : "none" }}>
										<WebGLApp fnWelcome={(dd) => { this.fnWelcome = dd; }} fnStartAnimation={(dd) => { this.fnStartAnimation = dd; }} />
									</div>
								</div>
								<RouletteRight buyLotteryBet={this.BuyLotteryBet} clearCart={this.clearCart} removeFromCart={this.removeFromCart} latestResult={this.state.latestResult} />
							</div>
						</div>
					</div>
				</div>
			</div >

		)
	};
}

function mapStateToProps(state) {
	const { currentGamesFamily, currentAvailableDraws, myBet, latestResult } = state.gameReducer;
	const { serverTime } = state.commonReducer;
	const { user } = state.loginReducer;
	const { cartItems, currentDraw, stakeAll, currentResult } = state.rouletteReducer;
	return { currentGamesFamily, user, currentAvailableDraws, cartItems, currentDraw, serverTime, myBet, stakeAll, latestResult, currentResult };
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	AddToCart: rouletteActions.AddToCart,
	RemoveFromCart: rouletteActions.RemoveFromCart,
	RemoveClosedDrawbetFromCart: rouletteActions.RemoveClosedDrawbetFromCart,
	SetCurrentDraw: rouletteActions.SetCurrentDraw,
	ClearCart: rouletteActions.ClearCart,
	Buybet: rouletteActions.Buybet,
	GetLatestResult: gameActions.GetLatestResult,
	GetCurrentResult: rouletteActions.GetCurrentResult,
	ClearAvailableGames: gameActions.ClearAvailableGames
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Roulette));