import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';
//import { loginActions } from '../../action/loginAction';

class SocialResponsibility extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    };

    render() {
        const { i18n } = this.props;
        const mystyle = { textAlign: "initial" };
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    {i18n.language == "en" ?
                        <div class="privacyCont">
                            <h1>Responsible Gaming</h1>
                            <p>Underage: inzozi Lotto only allows at least eighteen (18) years of age or above and play games.
                                Fair Gaming: All the inzozi Lotto gaming product are tested by an independent international organisation iTech labs and GLI to ensure they are fair and random and that they are adhere to the rules of that game.
                            </p>
                            <p>Customer Satisfaction: We are open for comments and complaints and user can reach us on <a href="mailto:customercare@inzozilotto.rw">customercare@inzozilotto.rw</a> or escalate to the Ministry of Industry and Trade in charge of the gaming activities if you feel unsatisfied.</p>
                            <p>Instantaneous customer winnings payment: We believe in “Tsinda-Dutsinde” and make sure the customer winning paid on the spot.</p>
                            <p>Money Laundering: Inzozi Lotto are adhered with the land of the law for anti-money laundering policies and procedure, we promote the digitals transaction and transparency and we implement Know Your Customer (KYC) at the time of registration on our Web / USSD platform.</p>
                            <p>
                                Time Limits: We have limited operation time for the day but you can play at anytime.<br/>
                                Deposits Limit: We enforce the deposit limit in our platform.<br/>
                                Temporary / Permanent account closure: We provide the facility to the user to suspend the account temporary or can close it permanent and withdraw the balance from the account.
                            </p>
                        </div>
                        :
                        <div class="privacyCont">
                            <h1>Gukina mu rugero</h1>
                            <p style={mystyle}>Imyakay’ubukure: UmuntuwemerewegukinaInzozi lotto niuwujujeimayaka 18 kujyanahejuru.<br />
                                Gukinantaburiganya :ImikinoyoseyaInzozi Lotto yagenzuweniikigompuzamahangakigengacyaiTech labs and GLI, cyemeza ko ntaburiganyabushoborakuba mu gukinakandi ko amategekoagengaimikinoakurikizwa.
                            </p>
                            <p style={mystyle}>Kunyurwanaserivisidutanga: Twishimirakwakiraibitekerezoby’abakiliyabacuniyompamvutwashyizehoukomwatwandikira :<a href="mailto:customercare@inzozilotto.rw">customercare@inzozilotto.rw</a>. Uwabaatishimiyeserivisen’ibisobanurotwamuhaye, yemerewekandikuganaMinisiteriy’ubucuruzin’ingandank’urwegorukurikiranaibigobcuruzaimikinoy’amahirwe. </p>
                            <p style={mystyle}>Guhabwaibihembokuburyobwihuse: Interoyacuni “Tsinda – Dutsinde” niyompamvutwabigizeihamegutangaibihembobyatsindiweakokanya.</p>
                            <p style={mystyle}>Ihererekanyary’amafaranga mu buryobutemewe: Inzozi lotto ikurikizaamategekon’amabwirizabyshyiriwekwirindaihererekanyary’amafaranga mu buryobutemewe, niyompamvudushyiraimbereikoreshwary’ikoranabuhanga no Gufataamakuruy’abakiliyaigihebiyandikishak’urubugankoranyambagarwacucyangwa mu buryobw’akanyenyeri (ussd platform).</p>
                        </div>
                    }
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(SocialResponsibility))