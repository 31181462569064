import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
class AcccountInfo extends Component {

    constructor(props) {
        super(props);
    }
    render() {        
		const { t } = this.props;
        return (
            <section class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblWalletDetails')}</h2>
                </div>
                <br />
                <ul class="balanceEnq">
                    <li><span>{t('lblCreditbal')}</span><span class="amntBg"><img src="./assets/img/icons/money-bill2.png" />{this.props.appvariables.currency} {this.props.user.balance}</span></li>
                    <li><span>{t('lblWinningbal')}</span><span class="amntBg"><img src="./assets/img/icons/money-bill2.png" />{this.props.appvariables.currency} {this.props.user.winbalance}</span></li>
                    <li><span>{t('lblTotalbal')}</span><span class="amntBg ActiveamntBg"><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {(parseFloat(this.props.user.balance) + parseFloat(this.props.user.winbalance)).toFixed(2)}</span></li>
                </ul>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    return { user, appvariables }
}

const mapActionToProps = {
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AcccountInfo))