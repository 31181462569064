import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import {lottoActions} from '../../../action/lottoAction';
class Left extends Component {
	constructor(props) {
		super(props);
	}
	static getDerivedStateFromProps(props, state) {
		return {
			gameFamily: props.games.filter(a => a.id == 4)
		};
	};
	render() {
		const { t } = this.props;
		return (
			<div class="col-md-3">
				<div class="Nxtdrawsec scrollbar">
					<ul>
						{this.props.currentAvailableDraws && this.props.currentAvailableDraws.length > 0 ? this.props.currentAvailableDraws.map((x, indx) =>
							<li onClick={() => { this.props.selectDraw(x) }} key={`draw_${x.DrawID}`} className={(this.props.currentDraw != undefined && x.DrawID == this.props.currentDraw.DrawID) ? "Active" : ""}>

								<div class="lotteryimg">
									<span>
										<img src="./assets/img/icons/lottoY.png?v1" />
									</span>
								</div>
								<div class="lotterytime">
									<small style={{ borderBottom: "1px solid #ccc" }}>{x.DrawTime}</small>
									<small>{x.DrawDate}</small>
								</div>
							</li>
						) : <li><a><div class="lotterytime"><small>{t('lblNoMoreDraws')}</small></div></a></li>}
					</ul>
					{/* <img style={{width:"100%"}} src="./assets/img/Jackpot prize.jpg?ver2.0"/> */}
					<div class="JackpotImg">
						<img src={process.env.REACT_APP_BANNER_URL+'JackpotPrize.webp?v1'} />
						 <div class="JackpotImgPos">
							{this.state.gameFamily && this.state.gameFamily.length > 0 && this.state.gameFamily[0].prizeInfo != "" && this.state.gameFamily[0].prizeInfo.split('^').map((x, indx) =>
								<span>{x}</span>
							)}
						</div> 
					</div>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	const { user } = state.loginReducer;
	const { currentDraw } = state.lottoReducer;
	const { games } = state.gameReducer;
	return { user, currentDraw, games };
}

const mapActionToProps = {
	//SetCurrentDraw:lottoActions.SetCurrentDraw,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Left))