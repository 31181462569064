import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class AcccountSummary extends Component {

    constructor(props) {
        super(props);
    }

    getRecord = (flg) => {
        if (flg != 1) {
            this.state.startDate = document.getElementById("datepicker2").value;
            this.state.endDate = document.getElementById("datepicker3").value;
        }
        let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate)
        if(_flog)
        this.props.GetAccountSummary(this.props.user.mobileNo, this.state.startDate, this.state.endDate,this.props.i18n.language);
    };
    reSetFilter() {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            AccountSummary: props.AccountSummary,
        }
    };
    componentDidMount() {
        window.documentReadyDatePicker();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    render() {
        const { t } = this.props;
        return (
            <section id="cTab4" class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblAccountSummary')}</h2>
                    <div class="selRslt">
                        <ul>
                            <li class="dateCont">
                                <div class="selRsltCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate white" type="text" value={this.state.startDate} id="datepicker2" name="startDate" placeholder="2017-06-10" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="selRsltCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="endDate white" type="text" value={this.state.endDate} id="datepicker3" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="resetFtrBtn" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                                <a href="#" class="relaodBtn" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>

                    {this.state.AccountSummary && this.state.AccountSummary.openBalance && <div class="CAshColectionInner">
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblOpeningbal')}</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.AccountSummary.openBalance}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblTotalPlayedAmount')}</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.AccountSummary.totSale}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblTotalWonPrizes')}</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.AccountSummary.totClaim}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblTotalCashIn')}</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.AccountSummary.totCashIn}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblTotalCashOut')}</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.AccountSummary.totCashOut}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblNetAccountBal')}</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.AccountSummary.netTotal}</h3>
                            </div>
                        </div>
                    </div>}
                    {this.state.AccountSummary==undefined ||this.state.AccountSummary.openBalance==undefined && <div class="CAshColectionInner" style={{textAlign:"center"}}>
                    {t('lblnorecordfound')}
                      </div>}
            </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { AccountSummary } = state.accountReducer;
    return { user, appvariables, AccountSummary }
}

const mapActionToProps = {
    GetAccountSummary: accountActions.GetAccountSummary
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AcccountSummary))