import React, { Component } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { withTranslation  } from 'react-i18next';
class webGLApp extends Component {
  constructor(props) {
    super(props);    
    this.state={progression:0};
  }

  unityContext = new UnityContext({
    loaderUrl: "assets/WebGL/W9/W9.loader.js",
    dataUrl: "assets/WebGL/W9/W9.data",
    frameworkUrl: "assets/WebGL/W9/W9.framework.js",
    codeUrl: "assets/WebGL/W9/W9.wasm",
  });


  fnWelcome = (strkey) => {
    let tmr = setInterval(() => {
      let _flog = document.getElementsByClassName("mywebgl");
      if (_flog != undefined && _flog != null && _flog.length > 0 && _flog[0].style.cursor == "default") {
        //let ssd=_flog.style.cursor;
        clearInterval(tmr);
        //strkey = "ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2";
        this.unityContext.send("Inputcontroller", "WelcomeData", strkey)

        // setTimeout(() => {
        //   this.fnStartAnimation("ThOtArPuElLaThA~12~11/11/11~11:00 AM~Test~2~3:00:00");
        // },5000)

        // setTimeout(() => {
        //   this.fnStartAnimation("ThOtArPuElLaThA~12~11/11/11~11:00 AM~Test~5~3:00:00");
        // },90000)

      }
    }, 1000);

  }
  fnStartAnimation = (strkey) => {
    let tmr = setInterval(() => {
      let _flog = document.getElementsByClassName("mywebgl");//document.getElementsByClassName("mywebgl");
      if (_flog != undefined && _flog != null && _flog.length > 0 && _flog[0].style.cursor == "default") {
        clearInterval(tmr);
        //strkey = "ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2~3:00:00";
        this.unityContext.send("Inputcontroller", "ResultData", strkey);
      }
    }, 1000);
  }
  componentDidMount() {
    this.setState({ proCompleted: 0 });
    this.props.fnWelcome(this.fnWelcome);
    this.props.fnStartAnimation(this.fnStartAnimation);
    let sss=this;
    this.unityContext.on("progress", function (_prog) {
      sss.setState({progression:_prog});
    });
    // this.fnUnityLoaded();

  }
  // fnUnityLoaded = () => {
  //   this.unityContent.on("loaded", function () {
  //     alert("loaded")
  //   });
  //    this.unityContext.on("progress", function (progression) {
  //      console.log("progression : "+progression);
  //     // this.setState({proCompleted:progression});
  //    });
  // }
  render() {
		const { t } = this.props;
    return (<div>
      {/* <button onClick={() => { this.fnWelcome() }}>Welcome</button>
      <button onClick={() => { this.fnStartAnimation() }}>StartAnimate</button> */}
      {/* <Unity width='500px' height='350px' className="mywebgl" unityContext={this.unityContext} /> */}
      {this.state.progression!=1 && <p style={{color:"#f1b71c",fontSize:"22px",textAlign:"center"}}>{t('lblLoading')} {parseInt(this.state.progression * 100)} %...</p> }
       <Unity className="mywebgl" unityContext={this.unityContext} />
    </div>
    )
  }

}
export default withTranslation()(webGLApp)

