import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const getCookie = (name) => {
  try {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    let lang_val = process.env.REACT_APP_DEFAULT_LANGUAGE;
    if (parts.length === 2)
      lang_val = parts.pop().split(';').shift();
    console.log("selected lang : " + lang_val);
    return lang_val;
  }
  catch (ex) {
    return process.env.REACT_APP_DEFAULT_LANGUAGE;
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getCookie("lang"),
    backend: {
      /* translation file path */
      loadPath: './assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  });


export default i18n;