import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import Header from "../Header/Header";
//import { loginActions } from '../../action/loginAction';
import { withTranslation } from 'react-i18next';

class TermsAndCondition extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    };

    render() {
        const { i18n } = this.props;
        return (
            <div className="mainContainer termsbg">
                <div class="container">
                    {/* <Header/> */}
                    <div class="logo2">
                        <img src="./assets/img/logo.png" alt="inzozilotto Logo" />
                    </div>
                    {i18n.language == "en" ?
                        <div class="privacyCont">
                            <h1>Terms and Condition</h1>
                            <p>Welcome to <a href="http://carousel.rw/" target="_blank"> http://carousel.rw/</a> (“Company,” “we” or “us”) through its product inzozilotto is the sole company authorized to operate the national lottery, licensed and regulated by the Ministry of Industry and Trade in charge of the gaming activities <a href="https://www.minicom.gov.rw/.">https://www.minicom.gov.rw/.</a></p>
                            <p>The following Terms and Conditions of Use, together with any documents expressly incorporate by reference (collectively, these “Terms and Conditions of Use”), govern you and any entity you represent (collectively, “you” or “your”), with respect to access and use of <a href="https://inzozilotto.rw/">https://inzozilotto.rw/</a> including any Content (as defined in this terms of service agreement), technology, processes, materials, functionality and services designed, offered, or marketed by the Company on or through <a href="https://inzozilotto.rw/">https://inzozilotto.rw/</a> and at such other domain names used to identify websites on which the Company may offer Services (collectively, the “Service”), whether as a guest or a registered user.</p>
                            <p>IMPORTANT LEGAL NOTICE: PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS. INZOZILOTTO.RW IS OPERATED UNDER A PUBLIC PRIVATE PARNERSHIP AGREEMENT BETWEEN CAROUSEL LTD AND THE GOVERNMENT OF RWANDA. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OF USE, PLEASE DO NOT ACCESS OR USE THE SERVICE.</p>
                            <h2>TABLE OF CONTENT</h2>
                            <h2>1.	Acceptance of the Terms and Conditions of Use</h2>
                            <p>By playing games and acquiring lottery ticket via our Website, social media pages, mobile apps, online partner marketplaces accounts, point of sales, sales events, physical stores or any other form of sales and marketing channels, offline and online which are operated by us, creating an account via the Service (“Account”), downloading the inzozilotto.rw App or clicking to accept or agree to these Terms and Conditions of Use when this option is made available to you, you hereby (i) acknowledge that you have read and agree to be bound to and abide by these Terms and Conditions of Use and (ii) represent and warrant that you are eligible to access the Service and are authorized and able to accept these Terms and Conditions of Use. If you do not wish to be bound by these Terms and Conditions of Use, you must not access or use the Service. By declining to accept these Terms and Conditions of Use, you will be unable to create an Account or access or use the Service.</p>
                            <h2>2.	General Rules, Policies & Procedures</h2>
                            <h3>2.1.	Eligibility </h3>
                            <p>To be eligible to create an Account and become a registered user of the Service, you must, at the time of registration (i) be at least eighteen (18) years of age or above, (ii) not be a person barred from participating in the Service under the laws of the Republic of Rwanda, and (iii) at all times abide by these Terms and Conditions of Use.</p>
                            <p>If you reside in a Jurisdiction that requires you to be older than eighteen (18) years of age to participate in Lottery activities (as defined in Section 4.1), you must adhere to that territory’s laws in order to use the Service. If you do not meet all of these requirements, you must not access or use the Service. The Company reserves the right to verify your age, identity and eligibility at any time. Any failure to cooperate with the Company in this respect may result in the suspension and/or termination of your Account.</p>
                            <p>Employees, officers, directors, members, managers, investors, agents, and representatives of the Company and any of its or their parent companies, subsidiaries, affiliates as well as direct technology vendors, content providers, component suppliers (both hardware and software) directly related to the Service, and each of their respective immediate family (defined as parents, spouse, partner, and children) and any person residing in the same household are not eligible to use the Service. Such persons may, however, access the Service, and will from time to time do so for the purpose of testing the Service, including, without limitation, evaluating user experience, and other reasonable and fair uses at the sole discretion of the Company.</p>
                            <p>By accessing or using the Service, you represent and warrant that you have the right, authority and capacity to enter into this agreement, to abide by all of these Terms and Conditions of Use, and that you are not prohibited from accessing or using the Service. The Company makes no representations or warranties, implicit or explicit, as to your legal right to access or use the Service and no person affiliated, or claiming affiliation, with the Company shall have authority to make any such representations or warranties. We do not intend for the Service to be used by persons present in Jurisdictions in which participation may be prohibited or restricted. You agree that the availability of the Service does not constitute an offer, solicitation or invitation by the Company for the use of the Service in any Jurisdiction in which such activities are prohibited or restricted. The Company reserves the right to deny access to the Service to anyone at its sole discretion.</p>
                            <h3>2.2.	Games Rules and Schemes</h3>
                            <p>Please refer to our “How to Play” and “Winning Schemes” of the various products offered by Inzozilotto.</p>
                            <h3>2.3.	Compliance</h3>
                            <p>You will comply with all of the terms and conditions of this Agreement and all applicable laws, regulations and rules when you use the Service. A User must be 18 years or older to participate in the Service. These rules & policies are valid only where legal. You are responsible for ensuring that it is legal for you to use the Service in your local jurisdiction. The Company reserves the right to cancel a user Account if behavior is deemed unacceptable by Carousel Ltd. The Company reserves the right to suspend a user Account if the user’s profile information is incorrect and / or in violation of any of the present Terms and Conditions of Use and / or is in breach of any regulatory and / or statutory regulations. In the event of any winnings generated from an account which is not in strict compliance of the present Terms and Conditions of Use, the rights to said winnings may be compromised.</p>
                            <p>At all times you are responsible to ensure that your user profile information is up to date and accurate.</p>
                            <h3>2.4.	Notice of Modifications or Changes</h3>
                            <p>The Company may modify the Service at any time with or without notice to you. The Company and its content including, without limitation, the information, graphics, products, features, functionality, services, and links (collectively the “Content”) may be changed, deleted or updated without notice. Carousel Ltd. may discontinue, suspend or modify the Service at any time without notice, and the Company may block, terminate or suspend your access to the Service at any time for any reason in its sole discretion, even if access continues to be allowed to others.</p>
                            <h3>2.5.	Tickets</h3>
                            <p>Tickets for a Lottery drawing are purchased by any Natural Customer of at least 18 years of age or older and who is legally able to enter into a valid contract. If you are under 18, you may use the Website or the Marketplace only with involvement of a parent or guardian. </p>
                            <p>The tickets will be processed and posted allowing users to view their ticket numbers online, on radio or on televisions. The numbers for each ticket will also be posted on the Service for group users to view. The Company will record the winning numbers for the corresponding Lottery drawings. In the event incorrect information is sent to a user, the user shall not be entitled to any claim based on the receipt of this information. You agree to immediately notify the Company upon receipt of erroneous information and hereby waive any claim against the Company for the receipt of such erroneous information.</p>
                            <p>Lottery tickets can only be sold by authorized lottery agents/retailers and can only be sold be across Rwandan territory (“the Territory”) using our online platforms. Tickets will be sold by Lottery tickets are purchased in person through Carousel online platform or POS, or one of its representatives from an authorized lottery agent/retailer in each respective point of sale that we offer service. The point of sale where the winnings are won may have the identity of the winner(s).</p>
                            <h3>2.6.	Payouts & Statutory Withholdings</h3>
                            <p>Where applicable by law, the Company will subject the payment of any winnings to the statutory withholdings and / or deductions.</p>
                            <h3>2.7.	Jackpots</h3>
                            <p>Quick Lotto & Impamo Jackpot refer to any Lottery winnings that are only redeemable directly from designated Lottery officials of a given point of sale. The Company will notify a user that a jackpot has been won by contacting the user at the email address and with telephone call provided for their Account at the time of registration or as updated according to these Terms and Conditions of Use. Users will have thirty (30) days from the date of the initial notification email/call/SMS was sent to respond to the Company and to provide any additional information requested and / or required by law. The Company will then endeavor to assist you to collect the winnings from the designated lottery officials or at Carousel offices. You hereby acknowledge and accept that taxes as well as statutory withholdings and / or deductions may be withheld by the Company before distribution.</p>
                            <h3>2.8.	Winnings</h3>
                            <p>All other Lottery winnings which are not considered a “Jackpot”, are also settled according to the rules and regulations. The Company will attempt to inform you of any winnings you may be entitled to and, you will be notified of your options to redeem your lottery winnings</p>
                            <p class="color">If your individual winnings or Group Play winnings equal or exceed any applicable specified prize claim threshold, the Company may forward you an official Lottery claim form, which you will be required to fill out, sign, and return to the Company to facilitate the collection of the respective winnings on your or your group’s behalf  .</p>
                            <h3>2.9.	International Prize Collection</h3>
                            <p>If you travel to collect your prize, then you agree that you remain solely responsible and liable for, and shall: (i) obtain all necessary passports, visas, and permits that are necessary to enter the country; (ii) associated travel costs, including flights and accommodations; (iii) secure travel insurance (which must cover you for medical, personal injury and property claims). You acknowledge that your failure to fulfill any of these requirements may preclude you from collecting your prize. You undertake and agree that the travel is entirely at your own risk. You hereby waive any legal or equitable claims, rights or remedies you may have against Carousel Ltd. Alternatively, you may opt to give Carousel Ltd. a Power of Attorney to collect the prize on your </p>
                            <h3>2.10.	Purchase Limit</h3>
                            <p>To protect our customers, the Company may set daily spending limits on purchases, including but not limited to ticket purchases. Once a daily spend has been reached, no more transactions can occur on the user’s account until 24 hours after the last qualifying transaction.</p>
                            <h3>2.11.	Jurisdictional & Lottery Limitations</h3>
                            <p>Upon your Power of Attorney, the Company will attempt to collect your winnings on your behalf using its reasonable best efforts. You acknowledge and agree that the Company will not be held liable for any winnings that cannot be collected because all the conditions are not </p>
                            <p>Lottery tickets can only be sold by the Company and authorized Lottery agents/retailers and canbe sold across the territory with anyone else apart from using our online platforms. It is your responsibility to verify each of your purchases to determine whether you have won any prizes. You further acknowledge and represent that you are aware that the Company will make its best efforts to notify you and, in the event that, for any reason whatsoever, the prize is not claimed within the statutory time limits, you will have no legal recourse against the Company and / or any of its employees or agents, the Company is hereby released of any and all legal recourses and / or remedies as a result of you not verifying your purchases and / or not claiming any winnings within a statutory time frame.</p>
                            <h2>3.	Inzozilotto.rw Account Registration & User Accounts</h2>
                            <h3>3.1.	Registration Information</h3>
                            <p>To register for an Account, visit www.inzozilotto.rw or download the Inzozilotto app or Dail *240# on USSD to complete the online registration process. You will be required to (i) submit your first and last name and other details as requested including, but not limited to, your ID/Passport Number, your phone number, date of birth, your email address, your residential address, and your payment information.</p>
                            <p>You agree to (i) provide true, accurate, current and complete information about yourself (the “Registration Data”) as prompted by the Service’s online registration form and (ii) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. In addition, you agree that the Company may contact you via mail, email or phone to verify your Account information, update group information or provide responses to your requests. Once an account is created, you will not be able to change your User name but you can for the passwordby clicking on Change password link. For further assistance, please contact customer service at support@inzozilotto.rw. Any information collected by the Company will be held subject to its Privacy Policy, available at (link) and “just-in-time” notices, if any, provided at the point of information collection or use. If we cannot contact you within thirty (30) days or if you provide any information that is untrue, inaccurate, not current or incomplete, or the Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, the Company reserves the right to suspend or terminate your Account immediately, withhold your Account balance and refuse any and all current or future use of the Service (or any portion thereof).</p>
                            <h3>3.2.	Secrecy Obligation</h3>
                            <p>You agree to keep your Identifiers secure and confidential. You are solely responsible for all usage or activity on your Account including, but not limited to, use of the Account by any person who uses your Identifiers, with or without authorization, or who has access to any device on which your Account is accessible. In the event that you are concerned that your Account is no longer secure and confidential, you should immediately notify the Company by sending an email to support@inzozilotto.rw. Whereupon new Identifiers may be selected and allocated and any future transactions under the previous Identifiers may be voided, at the sole discretion of the Company. Without limiting the foregoing, any transactions made and accepted within the Service where your Identifiers have been used (and where you have not previously notified the Company as provided herein) will be treated as valid.</p>
                            <h3>3.3.	One Account</h3>
                            <p>Only one (1) Account is allowed per person. In the event the Company determines that you have registered more than one (1) Account, you acknowledge and agree that, in addition to any other rights the Company may have, the Company has the right to suspend or terminate your Account immediately, withhold your Account balance, revoke your right to any Lottery tickets or corresponding prizes, and refuse any and all current or future use of the Service (or any portion thereof).</p>
                            <h3>3.4.	No Transfer</h3>
                            <p>Your Account is not transferable. Under no circumstances shall you allow or permit any other person or third party, including, without limitation, any person under the legal age to use the Service, to use or re-use your Account in such a way that may breach the standards or laws of the territory. You accept full responsibility for any unauthorized use of the Service and for any use of your payment instrument by any other person or third party in connection with your Account. Any person found to have violated this section may be reported to the relevant authorities and will forfeit all funds in their Account. The Company will not be liable for any loss that you may incur as a result of someone else using your Account.</p>
                            <h3>3.5.	Unsubscribe</h3>
                            <p>To unsubscribe from the Service and cancel your Account, you will need to email your request to support@inzozilotto.rw. If you unsubscribe, your Account will be cashed out and terminated.</p>
                            <h3>3.6.	Equipment Obligation</h3>
                            <p>You must provide all equipment and software necessary to connect to the Service, including, but not limited to, a computer or Mobile Device (as defined in Section 7.1) that is suitable to connect with and use the Service. You are responsible for any fees, including, but not limited to, Internet connection or data usage fees, including, without limitation, SMS/MMS text usage fees, that you incur when accessing the Service </p>
                            <h2>4. Inzozilotto.rw Services</h2>
                            <h3>4.1. Lottery Ticket Agreement  </h3>
                            <p>As a registered user of the Service, you acknowledge and agree to each of the following:</p>
                            <p>You are participating as a sole ticket holder independently at your own discretion.
                                You recognize that the payment of Lottery prizes is pursuant to law and regulations. Should the Lottery tickets that are the subject of this agreement result in a prize(s) for which Lottery regulations permit direct payment, then a claim for payment shall be made by the Company on your behalf. Where such regulations permit payment of the prize to only one natural person, then a representative of the Company may claim the prize for the benefit of an individual participant. At all times, any claim for payment will be made in accordance with the laws and regulations of the territory.
                            </p>
                            <h3>4.2. Individual User Commitments</h3>
                            <p>Once a ticket (or tickets) has been committed, you cannot terminate your Account until the drawing for which you are committed has occurred. In addition, once a ticket (or tickets) has been committed, you cannot return, exchange, modify or remove your ticket. If a drawing is missed or rescheduled, the Company will conform to this change by entering the next drawing available.</p>
                            <h3>4.3. Game Results & Information</h3>
                            <p>The “Draw Results” section of the application displays the estimated jackpot and the latest winning numbers at the availed platforms including the radios, televisions, and our socio media platforms. It is your responsibility to verify each of your purchases and to advise the Company in the event of any winnings.</p>
                            <h2>5. Billing Agreement </h2>
                            <h3>5.1. Payment </h3>
                            <p>The user must deposit funds into the Inzozilotto Wallet Account, which is linked to the registered user, to enable you to play games. These funds, once in the wallet, are non-refundable.</p>
                            <p>If your payment is declined, you will be notified in the application and contacted by phone or email. We may restrict your ability to use a certain payment method if that payment method fails multiple times. REMEMBER: If your Automatic Payment fails, you will need to replenish your account with another payment method. In the event any payment is not made, no tickets will be purchased without payment made in advance. </p>
                            <h3>5.2. Changing Payment</h3>
                            <p>If you would like to change your Payment including, for example, making a change from one credit or debit card that was initially selected to another credit or debit card or bank account, you must change the account number in the settings section of the Service.</p>
                            <h3>5.3. Security Verification</h3>
                            <p>I authorize the Company and their sponsoring agency to run an user verification search where applicable. This verification process is a security measure to protect me, the client, from illegal fraud against my credit card. I guarantee and warrant that I am the legal cardholder for this credit card, and that I am legally authorized to enter into this billing agreement with Carousel.rw. I agree to indemnify, defend and hold Carousel.rw. harmless, against any liability pursuant to this authorization. This Agreement is not transferable and must be agreed to by the person authorized on the Carousel.rw user account.</p>
                            <h2>6. Third Party & Interactive Features</h2>
                            <p>If you decide to enable, access or use third-party services, be advised that your access and use of such other services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services.</p>
                            <p>You irrevocably waive any claim against the Company with respect to such other services. The Company is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services.
                            </p>
                            <p>You may be required to register for or log into such other services on their respective websites.
                                By enabling any other services, you are expressly permitting the Company to disclose your data as necessary to facilitate the use or enablement of such other service.
                            </p>
                            <p>In conjunction with the Products, the Company may offer interactive social features (“Interactive Features”) in which you or third parties, including other users of the Service, are able to communicate and post User Content (defined below) within the Service. The Company does not endorse, approve or pre-screen any User Content that you and other users may contribute to the Service, and is not responsible for the accuracy or inaccuracy of the User Content. You bear the entire risk of the completeness, accuracy or usefulness of the User Content found on the Service.</p>
                            <p>You are solely responsible for your use of the Service’s Interactive Features and use them at your own risk.</p>
                            <p>By using any Interactive Features, you agree not to post, transmit, distribute, create or otherwise publish any of the following on or through the Service:</p>
                            <p>Any messages, comments, data, information, text, or other works, content or materials (“User Content”) that are unlawful, libelous, defamatory, obscene, pornographic, harmful to minors, indecent, lewd, vulgar, suggestive, harassing, threatening, tortious, invasive of personal privacy or publicity rights, abusive, inflammatory, hateful, fraudulent or otherwise objectionable (as determined by the Company in its sole discretion);</p>
                            <p>User Content that would encourage or provide instructions for a criminal offense, violate the rights of any party or that would otherwise create liability or violate any local or international law;
                            </p>
                            <p>User Content that you do not have the right to post or otherwise transmit under any law or under contractual or fiduciary relationships (such as insider information, proprietary information and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</p>
                            <p>User Content that may violate or infringe any patent, trademark, trade secret, copyright, right of publicity or other intellectual property, content, or proprietary right of any party. By posting User Content, you represent and warrant that you have the lawful right to transmit, distribute, reproduce, and display such User Content;</p>
                            <p>User Content that falsifies or deletes another’s attributions, legal notices or proprietary designations;</p>
                            <p>User Content that impersonates any person or entity (including, without limitation, a director, officer, employee, shareholder, agent or representative of the Company or a celebrity) or falsely states or otherwise misrepresents your affiliation with the Company, or any other person or entity;</p>
                            <p>Unsolicited or unauthorized advertising, campaign or promotional materials, junk mail, spam, chain letters, pyramid schemes or other forms of commercial solicitation and any materials that promote spyware, malware and downloadable items;</p>
                            <p>Private information of any other party, including, without limitation, phone numbers, postal addresses, email addresses, social security information, credit and debit card information, and other financial institution account information;</p>
                            <p>User Content “stalking” or otherwise harassing another user of the Service or an employee of the Company. This may include, but is not limited to harassing, threatening, embarrassing, or doing anything else that is unwanted, such as repeatedly sending unwanted messages or making personal attacks or statements about race, sexual orientation, religion, heritage, etc.;
                            </p>
                            <p>User Content that disrupts the Service or the servers or networks connected to the Service or collects or stores or attempts to collect or store passwords, user Account information, or other personal data about users of the Service;</p>
                            <p>Software viruses, corrupted data, or other harmful, disruptive or destructive software, computer code, files or programs; and
                                User Content that, as determined by the Company in its sole discretion, is objectionable or which restricts or inhibits any person from using or enjoying the Interactive Features of the Service, adversely affects the availability of its resources to others (e.g., use of all capital letters or continuous posting of repetitive text), or may expose the Company or its users to any harm or liability of any type.
                            </p>
                            <p>You are solely responsible for your User Content and may be held liable for User Content you post. To the maximum extent permitted by law, the Company assumes no responsibility or liability for any User Content posted, stored or uploaded to the Service by you or any third party, for any loss or damage thereto, or for any failure to or delay in removing such User Content, nor is the Company liable for any mistakes, defamation, slander, libel, omissions, falsehood, obscenity, pornography or profanity you may encounter.</p>
                            <p>Although the Company has no responsibility for the contents of any User Content posted through any Interactive Features, you agree to grant the Company the unrestricted, unconditional, unlimited, worldwide, irrevocable, royalty-free, non-exclusive, irrevocable right and license to use, exhibit, broadcast, copy, reproduce, publish, distribute, encode, compress, encrypt, incorporate data into, edit, rebroadcast, transmit, record, publicly display, publicly perform and create derivate works from or otherwise exploit in any manner whatsoever, all or any portion of your User Content to which you have contributed, for any purpose, in any and all media, now known or hereafter devised, throughout the world in perpetuity and without any compensation to you. You agree not to delete or revise any User Content posted by any other party.</p>
                            <p>The Company reserves the right, and has absolute discretion, to remove, screen or edit any User Content posted or stored within the Service for any reason without notice. Any use of the Interactive Features or other portions of the Service in violation of the foregoing violates these Terms and Conditions of Use and may result in, among other things, termination or suspension of your Account or your rights to use the Interactive Features and/or the Service. You acknowledge and agree that the Company may access, use or disclose any information about you or your use of the Service, including, without limitation, any User Content, to comply with the law or any legal process, protect and defend the Company’s rights or property or to protect the safety of its employees, customers or the public.</p>
                            <p>Please be aware that any User Content you post within the Service becomes public information, can be collected and used by others, and may result in your receipt of unsolicited messages from third parties. Accordingly, the Company discourages you from posting any personal information that can be used to identify or locate you, such as your addresses or phone number. IF YOU CHOOSE TO POST ANY PERSONALLY IDENTIFIABLE INFORMATION, YOU DO SO AT YOUR OWN RISK.</p>
                            <h2>7 Limited License to the Inzozilotto online platforms</h2>
                            <h3>7.1. License Grant  </h3>
                            <p>Subject to these Terms and Conditions of Use, the Company grants you a limited, non-exclusive and non-transferable license to (i) download, install and use the Company’s mobile application (“Inzozilotto App”) for your personal, non-commercial use on a mobile device owned or otherwise controlled by you (“Mobile Device”) strictly in accordance with these Terms and Conditions of Use and (ii) access and use the services made available in or otherwise accessible through the Inzozilotto App strictly in accordance with these Terms and Conditions of Use.</p>
                            <h3>7.2. License Restrictions</h3>
                            <p>You acknowledge and agree that you shall not:</p>
                            <p>Copy the Inzozilotto App, except as expressly permitted by this license;</p>
                            <p>Modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Inzozilotto App;</p>
                            <p>Reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Inzozilotto App or any part thereof;</p>
                            <p>Remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Inzozilotto App, including any copy thereof;</p>
                            <p>Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Inzozilotto App or any features or functionality of the Inzozilotto App, to any third party for any reason, including by making the Inzozilotto App available on a network where it is capable of being accessed by more than one Mobile Device at any time;</p>
                            <p>Remove, disable, circumvent or otherwise create or implement any workaround to any copy protection, rights management or security features in or protecting the Inzozilotto App; or
                                Use the Inzozilotto App in a manner prohibited by applicable laws or regulations, including, without limitation, in connection with any form of unlawful gambling.
                            </p>
                            <p>Use the Inzozilotto App in violation of these Terms and Conditions of Use or applicable law.</p>
                            <p>Any such conduct may result in the immediate termination of your Account as well as your limited right and license to the Inzozilotto App, and may subject you to liability for violations of law.</p>
                            <h3>7.3. Reservation of Rights</h3>
                            <p>You further acknowledge and agree that the Inzozilotto App is provided under license, and not sold, to you. You do not acquire any ownership interest in the Inzozilotto App under these Terms and Conditions of Use, or any other rights thereto other than to use the Inzozilotto App in accordance with the license granted, and subject to all terms, conditions and restrictions under these Terms and Conditions of Use. The Company and its licensors and service providers reserve and shall retain their entire right, title and interest in and to the Inzozilotto App, including all copyrights, trademarks and other intellectual property rights therein or relating thereto, except as expressly granted to you in these Terms and Conditions of Use.</p>
                            <h3>7.4. Updates</h3>
                            <p>From time to time, the Company may in its sole discretion develop and provide Inzozilotto App updates, which may include upgrades, bug fixes, patches and other error corrections and/or new features (collectively, including related documentation, “Updates”). Updates may also modify or delete in their entirety certain features and functionality. You agree that the Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is connected to the Internet either (i) the Inzozilotto App will automatically download and install all available Updates or (ii) you may receive notice of or be prompted to download and install available Updates.</p>
                            <p>You acknowledge and agree that the Inzozilotto App or portions thereof may not properly operate should you fail to promptly download and install all Updates. You further agree that all Updates will be deemed part of the Inzozilotto App and be subject to these Terms and Conditions of Use.</p>
                            <h3>7.5. Mobile Device and Service Charges</h3>
                            <p>Certain features of the Inzozilotto App require communication with the Company’s servers, including, without limitation, Inzozilotto App downloads and Updates, which may consume and exceed your service provider’s data or minute allowance limits. You acknowledge and agree that you are solely responsible for any charges incurred with your data/mobile service provider as a result of the Inzozilotto App, including any overage and penalties assessed for exceeding the limits imposed by your service provider.</p>
                            <p>You are responsible for the cost of your Mobile Device and to ensure that your Mobile Device meets the system requirements of the Inzozilotto App, including obtaining periodic updates or upgrades from your Mobile Device service provider to continue using the Inzozilotto App. THE COMPANY DOES NOT WARRANT OR GUARANTEE THAT THE INZOZILOTTO APP WILL BE COMPATIBLE OR FUNCTION WITH ANY PARTICULAR MOBILE DEVICE, NOR DOES THE COMPANY WARRANT OR ACCEPT ANY LIABILITY FOR OPERATION OF THE MOBILE DEVICE USED TO ACCESS THE INZOZILOTTO APP.</p>
                            <h3>7.6. Term and Termination</h3>
                            <p>The term of your limited license to the Inzozilotto App commences when you download the Inzozilotto App and accept these Terms and Conditions of Use and will continue in effect until the earlier of (i) your deletion of the Inzozilotto App from your Mobile Device or (ii) the Company’s termination of your limited license to the Inzozilotto App and/or termination of your Account. Upon termination (i) all rights granted to you under these Terms and Conditions of Use will also terminate and (ii) you must cease all use and delete all copies of the Inzozilotto App from your Mobile Device. Termination of your limited license will not limit any of the Company’s rights or remedies at law or in equity.</p>
                            <h2>8 Other Intellectual Property Rights</h2>
                            <h3>8.1. Copyright Information and Non-Commercial Use Limitation</h3>
                            <p>The Service and its contents, features and functionality, including but not limited to, all information, products, services, text, displays, graphics, video, audio and software as well as the design, selection and arrangement thereof (collectively, “Content”), are owned by the Company, its licensors or other providers of such material and are protected by the Laws of the Republic of Rwanda.</p>
                            <p>These Terms and Conditions of Use permit you to use the Service for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the Content on the Service, except as follows:</p>
                            <p>Your computer may temporarily store copies of such Content in RAM incidental to your accessing and viewing those materials.</p>
                            <p>You may store files that are automatically cached by your Web browser for display enhancement purposes.</p>
                            <p>If we provide social media features with certain Content, you make take such actions as are enabled by such features.</p>
                            <p>You must not:</p>
                            <ul class="marleft">
                                <li>Modify copies of any Content from the Service.</li>
                                <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of Content from the Service.</li>
                            </ul>
                            <p>You must not access or use for any commercial purposes any part of the Service or any Content available through the Service.</p>
                            <h3>8.2. Trademarks</h3>
                            <p>The Company name, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans are the trademarks of their respective owners.</p>
                            <h2>9. General Information</h2>
                            <h3>9.1. Changes to the Terms and Conditions of Use</h3>
                            <p>We reserve the right to change these Terms and Conditions of Use at any time without prior notice to you. If these Terms and Conditions of Use are modified, we will update the “Last Modified Date” and such changes will be effective upon posting. If we make what we determine to be material changes to these Terms and Conditions of Use, we will notify you by prominently posting a notice or by sending a notice to the email addresses on file. It is your obligation to provide us with notice of any change to your email address by sending us notice immediately to support@inzozilotto.rw. Your continued use of the Service following such material changes requires your affirmative consent to the changes. If you do not agree to the changes, your sole remedy is to cease using the Service and provide written notice of same to support@inzozilotto.rw. We will automatically delete your Account and return any funds remaining in your Account, unless you instruct us otherwise. You are expected to check this page each time you access this Service so you are aware of any changes, as they are binding on you.</p>
                            <h3>9.2. Disclaimer of Warranties</h3>
                            <p>YOUR USE OF THE SERVICE, ITS CONTENT AND ANY ITEMS OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS CONTENT AND ANY ITEMS OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICE, ITS CONTENT OR ANY ITEMS OBTAINED THROUGH THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT OR ANY ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, COMPLETE, USEFUL, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT THE SERVICE, ITS CONTENT OR ANY ITEMS OBTAINED THROUGH THE SERVICE WILL OPERATE IN THE CONFIGURATION OR WITH THE HARDWARE OR SOFTWARE YOU USE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. THE INFORMATION IN THE SERVICE MAY BE OUT OF DATE, AND THE COMPANY MAKES NO COMMITMENT TO UPDATE SUCH INFORMATION.</p>
                            <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                            <h3>9.3. Limitation on Liability</h3>
                            <p>YOUR ACCESS TO AND USE OF THE SERVICE, ITS CONTENT AND ANY ITEMS OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, LICENSORS AND SERVICE PROVIDERS, AND ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, SHAREHOLDERS, CONTRACTORS, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS, SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE “RELEASED PARTIES”) BE LIABLE TO YOU OR ANY THIRD PARTY FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS, USE, OR INABILITY TO USE, THE SERVICE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER ITEMS OBTAINED THROUGH THE SERVICE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF WINNINGS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE RELEASED PARTIES ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THE SERVICE OR FOR ANY DAMAGES TO OR INTERFERENCE WITH EQUIPMENT, MOBILE DEVICES, PROGRAMS, FILES, OR OTHER PROPERTY, WHETHER SUCH DAMAGES ARE RELATED TO ACCESS OR USE OF THE SERVICE OR ANY SITES LINKED TO IT.</p>
                            <p>FURTHER, IN NO EVENT SHALL THE COMPANY BE LIABLE FOR FAILURE OR DELAY IN PERFORMING AN OBLIGATION UNDER THESE TERMS AND CONDITIONS OF USE, INCLUDING BUT NOT LIMITED TO PURCHASING LOTTERY TICKETS OR COLLECTING WINNINGS, IF IT IS PREVENTED FROM DOING SO BY CAUSES BEYOND ITS CONTROL SUCH AS A FORCE MAJEURE EVENT OR THE ACTIONS OF THE GOVERNMENT OR GOVERNMENT DIRECTIVE.</p>
                            <p>EXCEPT AS PROVIDED OTHERWISE IN THESE TERMS AND CONDITIONS OF USE, YOUR SOLE REMEDY FOR DISSATISFACTION WITH OR DAMAGE SUSTAINED IN CONNECTION WITH THE SERVICE OR THE CONTENT OFFERED THEREON IS TO STOP USING THE SERVICE. YOUR PERMISSION TO USE THE SERVICE MAY AUTOMATICALLY TERMINATE WITHOUT NOTICE AT THE COMPANY’S SOLE DISCRETION.</p>
                            <h2>10. Indemnification</h2>
                            <p>You agree to defend, indemnify and hold harmless the Released Parties from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms and Conditions of Use or your use of the Service, including, but not limited to, any use of the Service’s Content and products other than as expressly authorized in these Terms and Conditions of Use or your use of any information obtained from the Service. If you are obligated to provide indemnification hereunder, the Company may, in its sole and absolute discretion, control the disposition of any claim at your sole cost and expense. Without limitation of the foregoing, you will not settle, compromise or in any other manner dispose of any claim without the Company’s written consent. If you are obligated to provide indemnification hereunder, the Company may withhold any payment it is otherwise required to make to you to offset your indemnity obligations.</p>
                            <h2>11. Governing Law and Dispute Resolution</h2>
                            <p>Inzozilotto strictly adheres to Rwandan Constitution of 2015 as revised up to date and is compliant with the Law on Prevention and Punishment of Cyber Crimes of 2018, law governing money laundering of 29/01/2020,  the Law governing Information and Communication Technologies of 2016, and the Rwandan Penal Code of 2018. Any offences committed under any law during the use of this Agreement, shall be reported to the relevant Authorities as required by the law.</p>
                            <p>All matters relating to the Company, the Service, these Terms and Conditions of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the Republic of Rwanda by the Competent courts located in the City of Kigali.</p>

                            <h2>12. Tax Obligation</h2>
                            <p>You acknowledge that you are responsible to report your income and pay any taxes due to the appropriate authorities. You further acknowledge that an applicable Lottery organization may retain a portion of your winnings and forward it to the appropriate taxing authority on your behalf. All winnings on inzozilotto are subject to 15% Tax </p>
                            <h2>13. Electronic Signatures</h2>
                            <p>Acceptance of these Terms of Service constitute a valid contract under Article 158 of the Law N°24/2016 of 18/06/2016 governing Information and Communication Technologies in Rwanda. Should you enter into an online user agreement with the Company, you will not be required to submit a paper application/agreement. The entire agreement between you and the Company will be evidenced by an electronic record. You must agree to the use of an electronic record and agree to read and understand these Terms and Conditions of Use </p>
                            <h2>14. Waiver and Severability</h2>
                            <p>No waiver of by the Company of any term or condition set forth in these Terms and Conditions of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms and Conditions of Use shall not constitute a waiver of such right or provision.
                                If any provision of these Terms and Conditions of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms and Conditions of Use will continue in full force and effect.
                            </p>
                            <h2>15. Assignment</h2>
                            <p>The Company may assign its rights and obligations under this Agreement, in whole or in part, to any person or entity at any time without notice to you and without your consent. Upon such assignment, the Company may be relieved of any further obligation hereunder. You may not assign or delegate any rights or obligations under these Terms and Conditions of Use without the Company’s prior written consent, and any unauthorized assignment and delegation by you is void and ineffective.</p>
                            <h2>16. Relationship of Parties</h2>
                            <p>You acknowledge and agree that no joint venture, partnership, or employment relationship exists between you and the Company as a result of these Terms and Conditions of Use or your use of the Service. You agree not to hold yourself out as representative, agent, operator, distributor, or employee of the Company and the Company shall not be liable for any of your representations, acts, or omissions. You also acknowledge and agree that, except as otherwise expressly provided in these Terms and Conditions of Use, there shall be no third-party beneficiaries.</p>
                            <h2>17. Force Majeure</h2>
                            <p>The Company shall not be liable for any delay or failure to perform resulting from unforeseen circumstances or causes outside its reasonable control, including, without limitation, extreme weather and other acts of God, natural catastrophes, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, computer viruses strikes, or shortages of transportation facilities, transportation stoppages or slowdowns, and stoppage or slowdown of the Internet or other networks (each, a “Force Majeure Event”).</p>
                            <h2>18. Privacy & Data Protection</h2>
                            <p><Link to="/privacypolicy" target="_blank">Click</Link> here to see our full privacy policy </p>
                            <h2>19. Cookies</h2>
                            <p><Link to="/cookiepolicy" target="_blank">Click</Link> here to see our full cookies policy </p>
                            <h2>20. Entire Agreement</h2>
                            <p>These Terms and Conditions of Use, our Privacy Policy and any applicable Limited Power of Attorney constitute the sole and entire agreement between you and the Company with respect to the Service and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Service. Nothing in the Terms and Conditions of Use, express or implied, shall be deemed to confer any rights or remedies upon, nor obligate any of the parties hereto, to any person or entity other than such parties, unless so stated to the contrary.
                            </p>
                        </div>
                        :
                        <div class="privacyCont">
                            <h1>Amategeko n’Amabwiriza</h1>
                            <h2>Intangiriro</h2>
                            <p>Murakaza neza kuri <a href="http://carousel.rw" target="_blank">http://carousel.rw</a> ari cyo kigo rukumbi cyemerewe gukoresha no kugenzura imikino ya tombola ku rwego rw’igihugu, kikaba ari ikigo cyahawe uburenganzira na minisiteri y’ubucuruzi n’inganda ifite iimikino mu nshingano zayo. https://www.minicom.gov.rw</p>
                            <p>Amabwiriza akurikira agenga imikoreshereze ya https://inzozilotto.rw/ hakubiyemo ibyanditswe byose, tekinoloji na serivisi zose zigurishwa na Carousel cyangwa se zitangirwa ku rubuga rwa https://inzozilotto.rw/ ndetse n’izindi mbuga zose zakoreshwa mu gusakaza no kwamamaza serivisi za Inzozi Lotto.</p>
                            <p>Icyitonderwa: Urasabwa gusoma witonze aya mabwiriza kuko kutayakurikiza byakuviramo gukurikiranwa mu mategeko. Inzozi Lotto igenzurwa ku bufatanye hagati ya Carousel Ltd na guverinoma y’u Rwanda. Mu gihe utemeranya n’aya mabwiriza, usabwe kudakoresha izi serivisi.</p>
                            <h2>Imbonerahamwe y’ibirimo</h2>
                            <h2>1. Kwemeranya ku mabwiriza y’imikoreshereze</h2>
                            <p>Mu gihe ukinnye cyangwa uguze itike ya tombola unyuze ku rubuga rwacu, ku mbuga nkoranyambaga, ku bafatanyabikorwa bacu, ku ba ajenti cyangwa se ahandi hagurishirizwa ibicuruzwa bya Inzozi Lotto, washyize aplikasiyo ya inzozilotto.rw kuri telephone yawe cyangwa se ukemeranya n’amabwiriza agenga iyi mikino, uraba wemeye ko wasomye neza kandi wasobanukiwe aya mabwiriza. Uraba wemerewe kuba wakoresha serivisi zose dutanga. Niba utifuza kuba wakurikiza aya mabwiriza, usabwe kudakoresha izi serivisi. Mu gihe utemeranya n’aya mabwiriza kandi ntabwo wemererwa gufunguza konti cynagwa se gukoresha serivisi.</p>
                            <h2>2.	Amategeko rusange, amabwiriza n’imikorere</h2>
                            <h3>2.1.	Ibisabwa </h3>
                            <p>Kugirango wemererwe gufunguza konti cyangwa gukoresha serivisi ugomba kuba wujuje imyaka 18 y’amavuko, kuba wemererwa n’amategeko ya repubulika y’u Rwanda kuba wakoresha serivisi kandi buri gihe ukaba ugendera ku mabwiriza agenga serivisi.</p>
                            <p>Niba utuye ahantu usabwa kuba urengeje imyaka 18 y’amavuko kugirango ukoreshe serivisi, urasabwa gukurikiza amategeko y’ako gace uherereyemo. Niba utujuje ibi bisabwa ntabwo wemerewe gukoresha serivisi. Sosiyete ifite uburenganzira bwo gusuzuma imyaka ufite n’ibikuranga kugirango yizere neza ko wemerewe gukoresha serivisi. Mu gihe unyuranije n’amabwiriza ya sosiyete, konti yawe ishobora gufungwa burundu.</p>
                            <p>Abakozi, abayobozi, abagenzuzi, abashoramari ndetse n’undi wese uhagarariye sosiyete cyangwa sosiyete z’abafatanyabikorwa n’abagize imiryango yabo (harimo ababyeyi, abashakanye, ababana n’abana) n’undi wese uba mu ngo zabo ntibemerewe gukoresha serivisi. Aba bose cyakoze bazajya bahabwa uburenganzira bwo gukoresha serivisi hagamijwe kugenzura imikorere n’uko abakiliya bakirwa n’izindi mpamvu zemejwe na sosiyete.</p>
                            <p>Mu gihe ukoresheje serivisi uraba wemeje ko wabitekerejeho, ubifitiye ubushobozi kandi ubyemererwa n’amategeko. Sosiyete ntabwo yishingira uwemeye gukoresha serivisi mu mategeko kandi yitandukanije n’uwakwemeza ko sosiyete yishingira ukoresha serivisi. Ntabwo twifuza ko serivisi yakoreshwa n’umuntu uwo ari we wese uri gukurikiranwa mu nkiko ariyo mpamvu ibi bibujijwe by’umwihariko. Uremeza ko kuba serivisi ihari atari impamvu yatuma uyikoresha mu gihe uherereye mu gace itemerewe gukoreshwa. Sosiyete ifite uburenganzira bwo kwima serivisi umuguzi ku mpamvu zitandukanye.</p>
                            <h3>2.2.	Amategeko agenga imikino</h3>
                            <p>Shakira ahanditse ‘’How to play’’ na ‘’Winning schemes’’ urebe uko wakoresha ibicuruzwa bitandukanye bya Inzozi Lotto.</p>
                            <h3>2.3.	Gukurikiza amabwiriza</h3>
                            <p>Urasabwa gukurikiza amabwiriza yari muri iyi nyandiko ndetse n’amategeko ayashingiyeho mu gihe ukoresheje serivisi. Ugomba kuba wujuje imyaka 18 y’amavuko kugirango ukoreshe serivisi. Aya mabwiriza akoreshwa gusa aho serivisi yemewe gutangwa. Ugomba kuba wizeye neza ko amategeko y’aho uherereye akwemerera gukoresha serivisi. Sosiyete yemerewe gufunga konti y’uyikoresha mu gihe byemejwe na Carousel Ltd ko imyitwarire ye idahwitse. Sosiyete yemerewe gufunga konti y’uyikoresha mu gihe imyirindoto yatanzwe atariyo cyangwa itagendanye n’amabwiriza agenga serivisi. Mu gihe ibihembo byatsindiwe hakoreshejwe konti itakurikije amategeko agenga serivisi, ibihembo bishobora kwamburwa uwabitsindiye.</p>
                            <p>Usabwe kureba buri gihe niba imyirondoro yawe ihuye ndetse nta mpinduka zabayemo.</p>
                            <h3>2.4.	Kumenyesha impinduka</h3>
                            <p>TSosiyete ifite uburenganzira bwo guhindura serivisi nta nteguza. Sosiyete ndetse n’inyandiko zayo, amakuru, amafoto cyangwa amavidewo, ibicuruzwa, ibigize serivisi bishobora gusibwa cyangwa bigahindurwa igihe icyo aricyo cyose nta nteguza kandi sosiyete ishobora kuguhagarika gukoresha serivisi igihe icyo aricyo cyose.</p>
                            <h3>2.5.	Amatike</h3>
                            <p>Amatike ya tombola ashobora kugurwa na buri wese wujuje imyaka 18 y’amavuko cyangwa uyirengeje kandi wemerewe n’amategeko kuba yasinya amasezerano. Niba uri munsi y’imyaka 18 y’amavuko, ushobora gukoresha urubuga rwacu cyangwa ugakoresha ibicuruzwa byacu uherekejwe n’umubyeyi.</p>
                            <p>Amatike iyo atanzwe, umukinnyi ashobora kureba amakuru yose yerekeranye n’itike yaguze ku rubuga, kuri radiyo cyangwa se kuri televiziyo. Amakuru yerekeranye amatike ndetse azashyirwa ku rubuga rwa serivisi kugirango abakiniye mu matsinda babashe kuyageraho. Sosiyete kandi izabika amakuru ku matike yose yatsinze. Mu gihe hatanzwe amakuru atari yok u itike, umukinnyi ntiyemerewe kuba yarega sosiyete. Mu gihe hatanzwe amakuru atari yo ku matike, usabwa guhita ubimenyesha sosiyete kugirango hirindwe icyatuma sosiyete ishinjwa iryo kosa.</p>
                            <p>Amatike ya Tombola agurishirizwa gusa ku ba ajenti babiherewe uburenganzira kandi agurishirizwa ku butaka bw’u Rwanda gusa hakoreshejwe imbuga zitandukanye. Amatike agurishirizwa ku mbuga za Carousel ndetse no ku ba ajenti babifitiye uburenganzira gusa ku byicaro bitandukanye byashyizweho. Ibyicaro byagurishirijweho amatike yatsinze bemerewe kuba bafite imyirindoro y’abatsinze.</p>
                            <h3>2.6.	Kwishyurwa no gukatwa</h3>
                            <p>Aho biteganywa n’itegeko, sosiyete yemerewe gukata amafaranga ku yatsindiwe.</p>
                            <h3>2.7.	Imikino ya Jackpot</h3>
                            <p>Amatike yatsinze mu mikino ya Quick Lotto na Impamo Jackpot ashobora kwishyurirwa gusa ku byicaro byagenwe cyangwa se ku mukozi wa tombola wahiswemo. Umukinnyi azamenyeshwa ko yatsinze hakoreshejwe email cyangwa se nimero ya telephone yatanze mu gihe yiyandikishaga cyangwa se yahinduye akurikije amabwiriza agenga serivisi. Umukinnyi azahabwa iminsi 30 kugirango asubize ubutumwa bumumenyesha ko yatsinze ndetse no gutanga ibindi bisobanuro bisabwa na sosiyete. Sosiyete izafasha uwatsinze kuba yabona ibihembo bye anyuze ku byicaro binyuranye bitanga serivisi cyangwa se ku cyicaro cya Carousel. Uremeza kandi ko imisoro cyangwa se andi mafaranga agenwa n’itegeko yakurwa ku bihembo mbere y’uko ubihabwa.</p>
                            <h3>2.8.	Ibihembo</h3>
                            <p>Ibindi bihembo byatsindiwe bidafatwa nka JACKPOT nabyo bitangwa hakurikijwe amategeko n’amabwiriza. Sosiyete izakumenyesha igihe cyose watsindiye ibihembo ndetse inagusobanurire uburyo wabihabwa.</p>
                            <p class="color">Mu gihe ibihembo watsindiye wenyine cyangwa mu itsinda birenze ibyantagwe ko bishobora gutsindirwa, sosiyete iguhereza igipapuro wuzuzaho ibisabwa ukagisubiza kugirango ubashe guhabwa ibyo bihembo watsindiye cyangwa se itsinda ryawe ryatsindiye.</p>
                            <h3>2.9.	Gusaba ibihembo ku badaherereye mu gihugu</h3>
                            <p>Mu gihe wiyemeje gukora urugendo rwo kuza gufata ibihembo byawe, wiyemeje ko ugomba kwiyishyurira ibisabwa byose kuri urwo rugendo harimo (i) pasiporo, visa, uburenganzira bwose busabwa kugirango wemererwe kwinjira mu gihugu harimo (i) itike y’urugendo, itike y’indenge, icumbi (iii) Kwiyishyurira ubwishingizi bw’ingendo (harimo ubwishingizi bwo kwivuza, ndetse n’ubwishingizi bw’imitungo igendanwa). Uremera ko mu gihe udashoboye kuba wakwiyishyurira ibyavuzwe haruguru byose bishobora gutuma utabasha kuba wabona ibihembo watsindiye. Uremera ko wirengeye icyabera mu rugendo cyose. Uremera ko Carousel Ltd nta nshingano n’imwe igufiteho mu gihe cyose wiyemeje gukora urwo rugendo. Ufite ubushobozi bwo kuba wasabi Carousel Ltd kugufatira ibihembo mu gihe ibi byose utabyujuje.</p>
                            <h3>2.10.	Umubare ntarengwa wo kutarenza ugura</h3>
                            <p>Mu rwego rwo kubungabunga imitungo y’abatugana, birashoboka ko sosiyete yashyiraho umubare w’amafaranga ntarengwa yo gukoresha. Mu gihe uwo mubare wageze, nta bushobozi bwo kugura uba ufite kugeza nyuma y’amasaha 24 igikorwa giheruka cyo kugura cyabereye.</p>
                            <h3>2.11.	Ibice tombola itageramo</h3>
                            <p>Mu gihe ibyemerewe n’uguhagarariye mu mategeko, sosiyete yakwakira ibihembo watsindiye mu cyimbo cyawe biramutse ari ibishoboka. Uremera ko mu gihe sosiyete idashoboye kwakira ibihembo watsindiye mu cyimbo cyawe kubera impamvu zitandulanye nta burenganzira bwo kuyikurikirana ufite.</p>
                            <p>Amtike ya tombola agurishwa gusa na sosiyete ndetse n’abandi yabihereye uburenganzira harimo aba ajenti. Ahandi wabona amatike ni ku mbuga zacu gusa. Ni inshingano zabo kwitegereza itike waguze kugirango urebe niba hari ibihembo watsindiye. Uremera ko sosiyete ifite mu nshingano zayo kuba yakumenyesha igihe cyose watsindiye ibihembo kandi ko mu gihe utaje gufata ibihembo byawe mu gihe ntarengwa cyagenwe, nta burenganzira ufite bwo kuba wakurikirana sosiyete, abakozi cyangwa se aba ajenti bayo kandi ko nta tegeko na rimwe rihana sosiyete, abakozi n’aba ajenti bayo mu gihe utasuzumye ko watsindiye ibihembo ngo ubyakire mu gihe cyagenwe.</p>
                            <h3>3.	3.	Kwiyandikisha no gufunguza konti kuri Inzozilotto.rw</h3>
                            <h3>3.1.	Ibisabwa mu kwiyandikisha</h3>
                            <p>Kugirango wiyandikishe, sura urubuga rwa inzozilotto.rw cyangwa se ushyire aplikasiyo ya InzoziLotto kuri telephone yawe cyangwa uhamagare *240# ukurikize amabwiriza. Urasabwa gutanga (i) Amazina yawe ndetse n’ibindi bikuranga birimo ariko bitarangirira ku ndangamuntu/pasiporo, nimeroya telephone, itariki y’amavuko, email, aho utuye ndetse n’uburyo bwo kwishyura wifuza kuzajya ukoresha.</p>
                            <p>Wemeye gutanga imyirondoro yawe y’ukuri nk’uko bisabwa na serivisi yo kwiyandikisha (ii) Guhindura imyirondoro watanze mu gihe haba harabayemo impinduka. Byongeye, wemeye ko sosiyete yaguhamagara cyangwa se ikakwandikira kuri telephone cyangwa email igusaba guhamya koko ko imyirondoro watanze ari iya nyayo, mu rwego rwo kuvugurura amakuru yatanzwe ku baguzi bose cyangwa se gusubiza bimwe mu bibazo wakwibaza. Mu gihe wamaze gufunguza konti ntabwo ushobora guhindura amazina wafunguje ariko ushobora guhindura umubare w’ibanga ukabda ahanditse CHANGE PASSWORD. Ku bundi busobanuro, wahamagara umurongo watwandikira kuri <a href="mailto:support@inzozilotto.rw">support@inzozilotto.rw.</a> Amakuru yose yakusanijwe azabikwa hakurikijwe amabwiriza yanditse mu mabwiriza agenga amakuru bwite. Mu gihe tuguhamagaye cyangwa se tukakwandikira ntusubize mu gihe kirenze iminsi 30,cyangwa se mu gihe sosiyete ikeka ko amakuru watanze atari ay’ukuri, dufite uburenganzira bwo gufunga konti yawe burundu, kugwatire amafaranga ari kuri konti ndetse no kutaguha serivisi iyo ariyo yose ahazaza.</p>
                            <h3>3.2.	Kubika ibanga</h3>
                            <p>Uremera kugira ibanga ku bikuranga byose bigaragara kuri konti yawe. Uzirengera ibikorwa byose bizakorerwa kuri konti yawe harimo abantu bose bazakoresha konti yawe wabahaye cyangwa utabahaye uburenganzira cynagwa se bazakoresha igikoresho cyose wifashisha ukoresha konti yawe. Mu gihe ukeka ko konti yawe nta mutekano ifite, ufite inshingano zo kubimenyesha sosiyete wohereza ubutumwa kuri <a href="mailto:support@inzozilotto.rw">support@inzozilotto.rw.</a> Mu gihe wandikishije ibikuranga bishya ibyo wakoreshaga byose mbere bigirwa impfabusa. Mu gihe utamenyesheje sosiyete ko ukeka ko konti yawe idafite umutekano, ibikorwa byose bizakorerwaho bizanyuramo.</p>
                            <h3>3.3.	Konti imwe</h3>
                            <p>Buri muntu wese yemerewe konti imwe. Mu gihe sosiyete imenye ko wafunguje konti irenze imwe ifite uburenganzira bwo guhita ifunga iyo konti, ikagumana amafaranga yose ariho, ndetse ikakwambura uburenganzira bwo kuba wagura indi tike n’ibihembo biyigenewe kandi ikaba yanakubuza kuba wakoresha serivisi zayo ahazaza.</p>
                            <h3>3.4.	Konti ntiyimukanwa</h3>
                            <p>Konti yawe ntabwo yimukanwa. Nta mpamvu n’imwe ikwemerera kuba wabwira undi muntu ngo akoreshe konti yawe cyangwa se ngo uyimuhereze burundu mu buryo butemewe n’amategeko. Uzirengera undi muntu wese wakoresha konti yawe cyangwa se agakoresha igikoresha cyawe yishyura serivisi akoresheje konti yawe. Umuntu wese uzafatwa yarenze kuri aya mabwiriza azashyikirizwa inzego zibishinzwe kandi ahare amafaranga yose ari kuri konti ye. Sosisyete ntizishingira ibihombo byose uzagira bitewe n’uko undi muntu yakoresheje konti yawe.</p>
                            <h3>3.5.	Kwiyandukuza</h3>
                            <p>Kugirango wiyandukuze kandi ufunge konti yawe, watwandikira kuri email <a href="mailto:support@inzozilotto.rw">support@inzozilotto.rw.</a> Numara gufungisha konti, amafaranga yawe yose azakurwaho kandi ifungwe burundu.</p>
                            <h3>3.6.	Kugira ibikoresho byabugenewe</h3>
                            <p>Usabwe kuba ufite ibikoresho byabugenewe kugirango ubashe kuba wakoresha serivisi harimo telephone cynagwa se mudasobwa (nk’uko byavuzwe ku murongo wa 7.1) bizakorohera kugirango ubashe gukoresha serivisi. Uzishingira ikiguzi cyose kijyanye no gukoresha murandasi n’ama inite yose uzakoresha wohereza ubutumwa bugufi bwo mu bwoko bwa SMS, MMS ndetse n’andi ma inite yagendera mu gukoresha serivisi.</p>
                            <h3>4.0. Serivisi za Inzozi Lotto</h3>
                            <h3>4.1. Amasezerano ku matike ya tombola</h3>
                            <p>Nk’umwe mu bakoresha serivisi, uzi kandi wemeranya n’ibi bikurikira:<br/>
                                Uri gukina ku bushake bwawe kandi waguze itike ku mpamvu zawe bwite.Uremera ko itangwa ry’ibihembo rigendera ku mategeko n’amabwiriza.Mu gihe itike ya tombola itsindiye ibihembo bisaba kwishyurwa ako kanya, sosiyete izishyuza mu cyimbo cyawe.Niba amabwiriza agaragaza ko ibyo bihabwa umuntu ku giti cye, umukozi wa sosiyete azakwishyuriza.Igihe cyose, kwishyuza bigomba gukorwa hakurikijwe amategeko n’amabwiriza.
                            </p>
                            <h3>4.2. Ibyo ukoresha konti agomba kwiyemeza</h3>
                            <p>Mu gihe itike yakaswe, ntabwo wemerewe gufunga konti yawe kugeza igihe tombola ibereye. Ikindi kandi, mu gihe itike yamaze kugurwa ntabwo wemerewe kuyisubiza, kuyihinduza cyangwa se kuyikura muri sisiteme. Mu gihe tombola itabaye cyangwa se ikimurirwa ikindi gihe, sosiyete izamenyekanisha ikindi gihe tombola iteganijwe.</p>
                            <h3>4.3. Ibyavuye mu mikino n’andi makuru</h3>
                            <p>Agace kanditseho DRAW RESULTS kuri aplikasiyo kagaragaza amafaranga yatsindiwe ndetse n’imibare iheruka gutsinda ku mbuga zitandukanye harimo nka radiyo, televiziyo ndetse n’imbuga nkoranyambaga. Ni inshingano zawe kugenzura itike waguze ukamenyesha sosiyete uramutse watsindiye ibihembo.</p>
                            <h3>5.0. Amasezerano yo kwishyuza </h3>
                            <h3>5.1. Kwishyura </h3>
                            <p>Ukoresha konti asabwa kwishyura anyuze ku ikofi (wallet) ya Inzizi Lotto ihabwa uwiyandikishije kugirango abashe gukina. Amafaranga iyo yamaze gushyirwa kuri iyo kofi ntabwo asubizwa.</p>
                            <p>Niba ubwishyu bwawe butakunze, uzahabwa ubutumwa bubigaragaza kuri aplikasiyo cyangwa se kuri telephone/email. Mu gihe uburyo wahisemo kwishyura ukoresheje bwanze inshuro nyinshi, dushobora kuguhagarika kuba wakongera kubukoresha.</p>
                            <p>TWIBUKIRANYE: Niba uburyo wahisemo kwishyura ukoresheje bwanze, uzuza kuri konti ubundi buryo wifuza gusimbuza wishyura. Mu gihe nta mafaranga yishyuwe, nta tike izagurishwa.</p>
                            <h3>5.2. Guhindura uburyo bwo kwishyura</h3>
                            <p>Niba wifuza guhindura uburyo ukoresha wishyura, nk’urugero guhindura ikarita ya banki usanzwe ukoresha, usabwa guhindura ibiranga iyo karita wari warujuje muri konti mbere ukabisimbuza ibiri kuri iyo karita nshya wifuza gukoresha.</p>
                            <h3>5.3. Kubungabunga umutekano wa konti</h3>
                            <p>Nemereye sosiyete ndetse n’abaterankunga bayo kuba basuzuma konti yanjye igihe cyose bibaye ngombwa. Ibi bikaba bizajya bikorwa mu rwego rwo kubungabunga umutekano wanjye na konti yanjye no kwirinda ibikorwa bya forode byaba ku ikariya yanjye ya banki. Ndahamya ko arinjye nyirikarita kandi ko nemerewe kuba nakora aya masezerano na carousel.rw. Ndemera kuba narenganura carousel.rw haramutse hagize ikibazo kivuka bitewe n’iki cyemezo. Aya masezerano ntiyimukanwa kandi akorwa na nyiri konti ubwe.</p>
                            <h3>6.0. Serivisi na site z’abafatanyabikorwa </h3>
                            <p>Niba wiyemeje gukoresha serivisi cyangwa se site z’abafatanyabikorwa, turakuburira ko bagendera ku mabwiriza n’amategeko yabo bwite. Ntabw tuzirengera serivisi iyo ariyo yose batanga ndetse nuko bakusanya cyangwa basakaza amakuru hagati yawe na bo.</p>
                            <p>Uremera ko sosiyete nta ruhare ifite kuri serivisi uzahabwa. Sosiyete kandi ntabwo izirengera ibihombo ibyo aribyo byose bizava mu kwemera gukoresha izo site cyangwa serivisi zitangirwaho cyangwa se mu gukurikiza amategko, amabwiriza ajyanye n’izo serivisi.</p>
                            <p>Mu rwego rwo koroshya serivisi, sosiyete ishobora gutanga imbuga wowe cyangwa se abanda bakoresha serivisi mushobora kuganiriraho n’izo site z’abafatanyabikorwa. Sosiyete ntabwo izirengera ibyo wowe cyangwa abanda bakoresha izo mbuga bazandikaho ndetse ntabwo izirengera kuba amakuru yatanzwe ari ay’ukuri cyangwa se Atari ay’ukuri. Ufite uruhare rusesuye mu kumenyekanisha amakuru ya nyayo ndetse no kumenya ko amakuru yatanzwe kuri izo mbuga abereye.</p>
                            <p>Ufite uruhare kandi rusesuye mu gukoresha izo mbuga kandi uzirenegera icyaba cyose.</p>
                            <p>Mu gihe wiyemeje gukoresha izo mbuga wiyemeje kutazandika, ngo usakaze cyangwa se wohereze ibi bikurikira:</p>
                            <p>Ubutumwa, amakuru, inyandiko cyangwa se ibindi binyuranije n’amategeko, bisebya, by’urukozasoni, byagira ingaruka ku batujuje imyaka 18, bituka, bisebetse, bihohotera, byibasira ubuzima bw’undi, bibiba urwango, birimo forode cyangwa se ibindi bitemewe (nk’uko amategeko agenga sosiyete abivuga)</p>
                            <p>Ubutumwa ubwo aribwo bwose bwashishikariza abandi kwishora mu cyaha, guhohotera umuntu uwo ariwe wese cyangwa kwica amategeko yo mu gihugu cyangwa yo ku rwego mpuzamahanga.</p>
                            <p>Ubutumwa utemerewe gutambutsa bitewe n’uko biteganwa n’itegeko cyangwa se byanditse muri kontaro wasinye, amabanga y’akazi ndetse n’andi masezerano asaba kugira ibanga.</p>
                            <p>Ubutumwa ubwo aribwo bwose bushobora guhonyora ipatanti iyo ariyo yose, ibanga ry’ubucuruzi, uburenganzira bwo kumenyekanisha. Mu kwandika ubutumwa ubwo aribwo bwose wiyemeje ko ufite uburenganzira busesuye bwo kubutanga, kubusakaza, no kubwohereza.</p>
                            <p>Ubutumwa bubeshya cyangwa busiba inshingano z’undi muntu.</p>
                            <p>Ubutumwa bugamije kwiyitirira undi muntu (harimo diregiteri, umu ofisiye, umukozi, umunyamigabane, umu ajenti, uhagarariye sosiyete cyangwa se icyamamare) cyangwa se bubeshya icyo upfana na sosiyete cyangwa undi muntu.</p>
                            <p>Kwamamaza mu buryo butemewe, ubutumwa bugamije forode ikorerwa kuri murandasi ndetse n’ubundi buriganya bukorerwa mu bucuruzi.</p>
                            <p>Amakuru bwite y’undi muntu harimo nimero za telefoni, agasanduku k’iposita, email, imibare yo ku ikarita ya banki ndetse n’izindi konti zishyurirwaho.</p>
                            <p>Ubutumwa butoteza cyangwa buneka ukoresha serivisi cyangwa se umukozi wa sosiyete. Aha hakubiyemo gutoteza, gutera ubwoba, gukoza isoni cyangwa se ibindi byose bidakenewe nko kohereza ubutumwa undi atifuza, kugaba ibitero cyangwa amacakubiri ashingiye ku bwoko, ku gitsina, ku idini no ku murage.</p>
                            <p>Ubutumwa bubangamira itangwa rya serivisi cyangwa se imiyoboro ikoreshwa mu gutanga serivisi cyangwa se bugamije kwiba amakuru arimo imibare y’ibanga, amakuru ya konti ndetse n’andi makuru yatanzwe n’abakoresha serivisi.</p>
                            <p>Ubutumwa burimo virusi cyangwa bugamije gusenya ibikorwa biri kuri za mudasobwa cyangwa bubangamira abakoresha serivisi bukaba bwababuza gukoresha serivisi zirimo izo kuvugana na ba nyiri serivisi cyangwa bubuza abakoresha serivisi kuyigeraho ( harimo kwandika ubutumwa mu nyuguti nkuru gusa cyangwa gusubiramo ubutumwa inshuro nyinshi cyane) cyangwa bwateza ingaru sosiyete n’abakoresha serivisi mu buryo ubwo aribwo bwose.</p>
                            <p>Wishingiye ubutumwa bwose wandika cyangwa wohereza. Nk’uko itegeko ribiteganya, sosiyete ntizishingira ubutumwa bwose bwoherejwe cyangwa bubitswe n’ukoresha serivisi cyangwa se site z’abafatanyabikorwa, ibohombo byose bwatera cyangwa se kutabasha gukuraho ubwo butumwa mu gihe cyagenwe kandi ntabwo sosiyete izishingira ubutumwa bwose bugamije gusebanya, gutukana, kubeshya, burimo urukozasoni, poronogarafi cyangwa irindi sebanya iryo ariryo ryose.</p>
                            <p>N’ubwo sosiyete itazishingira ubutumwa bwose buzoherezwa, uremera kuyihereza uburenganzira busesuye kandi budasubirwaho bwo kugaragaza, kwandukura, gutangaza, gusakaza, kugaragaza mu ruhame no gushingira kuri ubwo butumwa hatangwa ubundu kandi nta kiguzi. Uremera kandi kutazasiba cyangwa se ngo uhindure ubutumwa bwanditswe n’undi muntu.</p>
                            <p>Sosiyete ifite uburenganzira bwo gukuraho, gufotora cyangwa guhindura ubutumwa bwanditswe n’ukoresha serivisi cyangwa se bubitse nta nteguza. Igihe cyose imbuga zikoreshwa mu kohereza ubutumwa no kuvugana na ba nyiri serivisi zikoreshejwe hadakurikijwe ibyavuzwe haruguru, bishobora kuviramo nyiri konti kuba iyo konti yasubikwa cyangwa se igafungwa burundu. Uremera kuba sosiyete yagera ku makuru watanze, ikaba yayakoresha cyangwa ikayatanga, kubahiriza amategeko n’amabwiriza ya leta, guharanira kubungabunga inyungu za sosiyete cyangwa se imitungo yayo  ndetse no guharanira umutekano w’abakozi, abakoresha serivisi n’abantu bose muri rusange.</p>
                            <p>Turakumenyesha kandi ko ubutumwa bwose wanditse ku mbuga za serivisi bubonwa na buri wese kandi bushoboroa gufatwa no gukoreshwa n’abandi bantu bikaba byatuma ubona ubutumwa buturutse ku zindi sosiyete. Hagendewe kuri ibi, sosiyete iragushishikariza kwirinda kuba wakwandika cyangwa wasakaza amakuru yawe bwite ashobora kuba yatuma umuntu amenya aho utuye cyangwa uherereye nka aderesi cyangwa se nimero za telephone. NIBA WIYEMEJE GUSAKAZA AMAKURU YAWE BWITE, UZIRENGERA IBIZABA BYOSE.</p>
                            <h3>7.0 Uruhushya rwo gukoresha imbuga za Inzozi Lotto</h3>
                            <h3>7.1. Guhabwa uruhushya </h3>
                            <p>Hashingiwe ku mategeko n’amabwiriza yavuzwe haruguru, sosiyete iguhaye uruhushya rudasubirwaho  kandi rutimukanwa rwo (i) gutunga no gukoresha aplikasiyo yo kuri telephone (Inzozi Lotto app) ku mpamvu zawe bwite, zitari izubucuruzi kuri telefone utunze cyangwa se ugenzura nk’uko biteganwa n’amategeko n’amabwiriza agenga serivisi, (ii) Kuba wabona ndetse ukanakoresha serivisi zose zitangirwa kuri aplikasiyo ya Inzozi Lotto nk’uko biteganywa n’amategeko n’amabwiriza.</p>
                            <h3>7.2. Ibyo utagomba gukoresha uru ruhushya</h3>
                            <p>Urahamya kandi uremera ko:</p>
                            <p>Utazigana aplikasiyo ya Inzozi Lotto cyeretse mu gihe ubyemererwa by’umwihariko n’uru ruhushya</p>
                            <p>Ko utazahindura, ugashyira mu rundi rurimi, cyangwa se ugahimba indi aplikasiyo ugendeye kuri iyi kandi ko utazaca ku mpande ngo ube wakwigana ipatanti ya aplikasiyo ya Inzozi Lotto.</p>
                            <p>Ko utazasenya, ngo wice cyangwa ugerageze kuvumbura ibigize aplikasiyo cyangwa se ngo ugerageze kumenya ibigize aplikasiyo mu mizi cyangwa ibice byayo.</p>
                            <p>Ko utazakuraho, ugasiba, ugahindura cyangwa ugakora uburinganya bugamije kurenga ku mategeko agenga ipatanti ndetse n’inyandiko zitanga uburenganzira bwo gukora aplikasiyo ya Inzozi Lotto cyangwa ibice biyigize.</p>
                            <p>Ko utazagurisha, ngo ukodeshe, ngo utize, ngo usakaze,ngo wamamaze cyangwa wimukane aplikasiyo uyishyira andi ma sosiyete harimo ama site aplikasiyo ya Inzozi Lotto yabasha kugerwaho na telefone nyinshi icyarimwe.</p>
                            <p>Ko utazavanaho aplikasiyo, ngo uyikure ku murongo cyangwa ngo ukore uburiganya bugamije kurenga ku mategeko y’ipatanti cyangwa porogaramu zo kurinda aplikasiyo; cyangwa ngo ukoreshe aplikasiyo ya Inzozi Lotto mu buryo bunyuranije n’amategeko, harimo kuyikinisha urusimbi.</p>
                            <p>Kurenga kuri ibi bivuzwe haruguru byakuviramo ko konti yawe ihagarikwa cyangwa igafungwa burundu ndetse ukaba wanakwamburwa uburenganzira bwo kuzongera gukoresha aplikasiyo ya Inzozi Lotto ndetse ukaba wanakurikiranwa mu nkiko.</p>
                            <h3>7.3. Uburenganzira duhabwa</h3>
                            <p>Uremera kandi ukanahamya ko aplikasiyo ya Inzozi Lotto uyihawe ku masezerano utayigurishwijeho. Nta nyungu n’imwe ituruka kuri aplikasiyo ya Inzozi Lotto ikugenewe nk’uko biteganwa mu mategeko n’amabwiriza, cyangwa se ubundi burenganzira ufite butari ugukoresha aplikasiyo ya Inzozi Lotto ugendeye ku mategeko n’amabwiriza. Sosiyete, abagenzuzi n’abatanga serivisi bafite uburenganzira busesuye n’ipatanti kuri aplikasiyo n’ibiyishamikiyeho byose nk’uko amategeko agenga imikoreshereze abigena.</p>
                            <h3>7.4. Impinduka</h3>
                            <p>Rimwe na rimwe, sosiyete ishobora kuvugurura aplikasiyo ya Inzozi Lotto, nko gukosora bimwe mu bituma itagenda neza (bugs, fixes, patches) ndetse n’amakosa arimo. Izi mpinduka zishobora gusiga bimwe mu bigize aplikasiyo bisibika cyangwa imwe mu mikorere ya aplikasiyo igahinduka. Uremera ko sosiyete nta nshingano ifite yo gukora izi mpinduka cyangwa se kuba yavugurura aplikasiyo. Bigendeye ku mikorere ya telefone yawe, mu gihe telefone izaba iri gukoresha murandasi, impinduka zashyizwe muri aplikasiyo zizajya zihita zijyamo. Ushobora kujya ubona ubutumwa bugusaba kwemeza ko izo mpinduka zajyamo.</p>
                            <p>Urahamya ndetse ukanemera ko aplikasiyo ya Inzozi Lotto ishobora gutangira gukora nabi mu gihe utemeje ko izi mpinduka zikorwa kuri aplikasiyo cyangwa mu gihe utemeje impinduka zose zashyizwe muri aplikasiyo. Uremera kandi ko izo mpinduka zizaba zigize aplikasiyo ya Inzozi Lotto kandi zikazaba zikurikiza amategeko n’amabwiriza.</p>
                            <h3>7.5. Telefoni igendanwa n’mafaranga acibwa kuri serivisi</h3>
                            <p>Bimwe mu bigize aplikasiyo ya Inzozi Lotto bikoresha imiyoboro ya murandasi. Bimwe muri ibyo ni impinduka zavuzwe haruguru zishobora gutuma wakoresha ama inite arenze ayo wagenewe kuri serivisi z’itumanaho. Uremera ko uzirengera igiciro cy’ama inite yose azagendera mu gukoresha aplikasiyo ya Inzozi Lotto harimo n’ama inite uzakurwaho mu gihe warengeje pack waguze.</p>
                            <p>Urishingira igiciro cyo kugura telefoni igezweho kandi ihura n’ibisabwa kugirango aplikasiyo ya Inzozi Lotto ikore neza ndetse n’ubutumwa bwa buri gihembwe bukwibutsa kwemeza impinduka zakozwe kuri aplikasiyo ya Inzozi Lotto. SOSIYETE NTABWO YIJEJE KO BURI TELEFONI IGENDANWA IZABA IKORANA NA APLIKASIYO YA INZOZI LOTTO CYANGWA SE NGO IBE IKORANA NA TELEFONI IGENDANWA RUNAKA KANDI NTABWO IZISHINGIRA TELEFONI IGENDANWA ITAZABASHA KUBA YAKORANA NA APLIKASIYO YA INZOZI LOTTO.</p>
                            <h3>7.6. Gusesa amasezerano</h3>
                            <p>Amategeko n’amabwiriza akubiye mu mikoreshereze ya aplikasiyo ya Inzozi Lotto azakomeza kugenderwaho kugeza igihe (i)usibye aplikasiyo muri telefone (ii)sosiyete ihagaritse amasezerano mwagiranye cyangwa se igafunga konti yawe ya Inzozi Lotto. Nyuma yo gusesa amasezerano (i) Uburenganzira bwose uhabwa bwanditse muri aya mabwiriza y’imikoreshereze nayo azahita arangira (ii) Ugomba rero kuzahita usiba burundu ndetse ukarekera no gukoresha aplikasiyo ya Inzozi Lotto kuri telefone yawe. Guseswa kw’amasezerano nta ngaruka n’imwe bizagira kuri sosiyete mu nkiko.</p>
                            <h3>8.0 Ubundi burenganzira butangwa mu ipatanti</h3>
                            <h3>8.1. Amakuru ajyanye n’ipatanti n’ibibujijwe mu mikoreshereze itari iy’ubucuruzi</h3>
                            <p>Serivisi ndetse n’ibikubiyemo byose , amakuru, inyandiko, amafoto, amavidewo, amajwi ndetse na n’imiterere yayo n’uko ipanze n’uko iteguye ni ibya sosiyete, abagenzuzi ndetse n’abandi babyemerewe kandi irinzwe n’amategeko ya Repubulika y’u Rwanda.</p>
                            <p>Aya mabwiriza n’amategeko akwemerera gukoresha serivisi ku giti cyawe ariko atari mu bijyanye n’ubucuruzi. Ntabwo wemerewe kuba wakwigana, ngo usakaze, uhindure cyangwa uhimbe ibindi bikorwa bisa nayo, ngo werekane, utunge cyangwa uhererekanye ibigize serivisi havuyemo ibi bikurikira:</p>
                            <p>Mudasobwa yawe ishobora kujya ibika amakopi y’ibikorwa bya serivisi mu gasanduku ka RAM nyuma y’uko ukoresheje cyangwa se usuye bimwe mu bigize serivisi</p>
                            <p>Ushobora kubika amadosiye ari ku ipaji wakoresheje.</p>
                            <p>Ntabwo ugomba:</p>
                            <ul class="marleft">
                                <li><p>Guhindura amakopi avuga ku bigize serivisi</p></li>
                                <li><p>Gusiba cyangwa guhindura, kwigarurira ipatanti cyangwa uburenganzira bwahawe sosiyete ku mikoreshereze ya serivisi.</p></li>
                            </ul>
                            <p>Nta na rimwe ugomba gukoresha serivisi cyangwa ibiyigize ugamije inyungu z’ubucuruzi.</p>
                            <h3>8.2. Ibirango</h3>
                            <p>Izina rya sosiyete, logo n’andi mazina agendanye na serivisi, logo, ibicuruzwa, imiterere ndetse n’insanganyamatsiko ni ibirango bya sosiyete, abatanyabikorwa ndetse n’abagenzuzi. Ntabwo wemerewe kuba wakoresha ibyo birango nta burenganzira wabiherewe. Andi mazina, logo, ibicuruzwa, amazina ya serivisi, imiterere n’isanganyamatsiko zitandukanye ni iby’aba nyir’ama serivisi atandukanye.</p>
                            <h3>9.0 Amakuru rusange</h3>
                            <h3>9.1. Impinduka mu mategeko n’amabwiriza </h3>
                            <p>Duhabwa uburenganzira bwo kuba twahindura amategeko n’amabwiriza agenga serivisi igihe icyo aricyo cyose nta nteguza uhawe. Mu gihe amategeko n’amabwiriza bihinduwe, tuzashyiraho itariki byahinduriwe hanyuma ayo mategeko n’amabwiriza atangire gukurikizwa. Nituramuka dukoze impinduka twavuga ko zikomeye mu mategeko n’amabwiriza tuzabikumenyesha mbere mu nyandiko cyangwa iyo nyandiko yoherezwe kuri email watanze. Ni inshingano zawe kutumenyesha mu gihe wahinduye email yawe utwandikira kuri  <a href="mailto:support@inzozilotto.rw">support@inzozilotto.rw</a>. Gukomeza gukoresha serivisi nyuma y’uko izo mpinduka zigaragara zakozwe bigaragaza y’uko wemeranije nazo nta kabuza. Niba utemeranya n’impinduka zakozwe, igisubizo kirambye ni uguhagarika gukoresha serivisi ukabitumenyesha utwandikira kuri  <a href="mailto:support@inzozilotto.rw">support@inzozilotto.rw</a>. Tuzahita dusiba burundu konti yawe tugusubize n’amafaranga yari ari kuri konti usinye igihe uzabishaka ukundi. Usabwe kujya ureba kuri ino paji kugirango umenye niba hari impinduka zakozwe mu mikoreshereze ya serivisi kuko zikureba by’umwihariko.</p>
                            <h3>9.2. Guhakana garanti</h3>
                            <p>WISHINGIYE GUKORESHA SERIVISI N’IBIYIGIZE KANDI UKIRENGERA ICYABA CYOSE. SERIVISI, IBIYIGIZE NDETSE N’IKINDI KINTU WABA WARABONYE UKIVANYE MURI SERIVISI BITANGWA UKO BIRI KANDI NTIBISUBIZWA SOSIYETE, NTA GARANTI IYO ARIYO YOSE. YABA SOSIYETE CYANGWA SE ABAYIHAGARARIYE NTIBEMEREWE KUBA BATANGA GARANTI KU BYEREKERANYE NO KUBA SERIVISI YUZUYE, IFITE UMUTEKANO, IKORA NEZA CYANGWA IBONEKA IGIHE CYOSE, IBIYIGIZE CYANGWA SE IBITANGWA BYOSE BIVUYE KURI SERIVISI. NTA KUVUGURUZA IBYANDITSWE HARUGURU, YABA SOSIYETE CYANGWA UYIHAGARARIYE NTAWEMEREWE KUBA YATANGA GARANTI KO SERIVISI YUZUYE, IFITE UMUTEKANO, IKORA NEZA CYANGWA IBONEKA IGIHE CYOSE, IBIYIGIZE CYANGWA SE IBITANGWA BYOSE BIVUYE KURI SERIVISI CYANGWA SE KO SERIVISI IZAKORANA N’IKORANABUHANGA UKORESHA, KO AMAKOSA ARI MURI SERIVISI AZAKOSORWA, KO SERIVISI ITAZIGERA YANGA GUKORA CYANGWA SE KO NTA VIRUSI IBARIZWA KU MIYOBORO ITANGA SERIVISI CYANGWA SE KO SERIVISI MURI RUSANGE IZABA ARI NTAMAKEMWA KANDI IHUYE N’IBYO WIFUZA. AMAKURU ARI MURI SERIVISI ASHOBORA KUBA YARARENGEJE IGIHE ARIKO SOSIYETE NTABWO ITEGETSWE KUBA YAYAVUGURURA.</p>
                            <p>SOSIYETE IHEREYEHO IHAKANA GARANTI IYO ARIYO YOSE, YABA IYANDITSWE CYANGWA IVUZWE, HAKUBIYEMO GARANTI KU BICURUZWA NDETSE N’IMIKORERE YA SERIVISI. IBI NTIBIKURAHO GARANTI ZIDASHOBORA GUKURWAHO CYANGWA SE ZITEGANYWA N’ITEGEKO.</p>
                            <h3>9.3. Ibyo kutarengwaho</h3>
                            <p>WISHINGIYE GUKORESHA SERIVISI N’IBIYIGIZE KANDI UKIRENGERA ICYABA CYOSE. NTA NSHURO N’IMWE SOSIYETE, ABAYIHAGARARIYE, ABAGENZUZI N’ABATANGA SERIVISI NDETSE N’ABAYOBOZI, ABOFISIYE, ABAKOZI, ABANYAMIGABANE, ABAFATANYABIKORWA, ABACURUZA SERIVISI ZABO KURI SOSIYETE BAZIRENGERA IBIHOMBO IBYO ARIBYO BYOSE WABA WARAKUYE MURI SERIVISI, MU KUYIKORESHA, KUTABASHA KUYIKORESHA, SITE ZIYISHINGIYEHO, AMAKURU WAKUYE KURI SERIVISI HARIMO IBIHOMBO WAGIZE KU GITI CYAWE CYANGWA IBISHOBORA GUTUMA WAHANWA N’ITEGEKO HARIMO GUKOMEREKA, KUBABARA, AGAHINDA, KUBA WATAKAZA AMAFARANGA, KUBA WATAKAZA INYUNGU, KUBA WATAKAZA IBIHEMBO, KUBA WATAKAZA BIZINESI CYANGWA AMAFARANGA WATEGANYAGA KWIZIGAMIRA , KUBA WATAKAZA AMAKURU AYO ARIYO YOSE, HAMWE NA KONTARO N’UBWO BYABA BYARAGARAGA MBERE. ABAVUZWE BOSE HARUGURU NTA NSHINGANO BAFITE KU MAKOSA CYANGWA IBIBURA MU MAKURU YATANZWE KURI SERIVISI CYANGWA SE KU BIBAZO YATERA KU MITUNGO HARIMO TELEFONE ZIGENDANWA, POROGARAMU, AMADOSIYE CYANGWA SE INDI MITUNGO, IBYO BIBAZO BYABA BYATEWE NO GUKORESHA SERIVISI CYANGWA SITE ZIYISHAMIKIYEHO.</p>
                            <p>BYONGEYEHO, NTA NSHURO N’IMWE SOSIYETE IZABAZWA KUTABASHA KUBAHIRIZA INSHINGANO ZANDITSWE MURI AYA MATEGEKO N’AMABWIRIZA, HARIMO KUGURA AMATIKE YA TOMBOLA, GUSABA IBIHEMBO BYATSINDIWE, NIBA IBI BYOSE BYABAYE KU MPAMVU ZITAYITURUTSEHO KANDI ZIDASHOBORA GUHINDURWA NA SOSIYETE CYANGWA SE BIVUYE KU MATEGEKO CYANGWA AMABWIRIZA YATANZWE NA LETA.</p>
                            <p>USIBYE AHO BIVUGWA UKUNDI MURI IYI NYANDIKO, IGISUBIZO KIRAMBYE CYO KUBA UTAKWISHIMIRA SERIVISI CYANGWA IBIHOMBO WAVANYE MU GUKORESHA SERIVISI NI UKUREKA KUYIKORESHA BURUNDU. UBURENGANZIRA WAHAWE BWO GUKORESHA SERIVISI SOSIYETE ISHOBORA KUBUKWAMBURA MU GIHE BIGENWE KO BIKWIYE.</p>
                            <h3>10. Kurenganura no kwishyura</h3>
                            <p>Uremera kurenganura, gutanga indishyi y’akababaro no kuvuganira impande zose zavuzwe haruguru ku bijyanye n’ibihombo, amafaranga yatakajwe (harimo n’ayo kwishyura ubahagarariye mu mategeko) biturutse ku kuba utubahirije amabwiriza n’amategeko agenga serivisi harimo uko serivisi ikoreshwa n’ibindi bikubiye mu mabwiriza y’imikoreshereze ya serivisi. Mu gihe usabwa gutanga indishyi y’akababaro, sosiyete ishobora ku ibanga ryayo kugenzura imiterere y’iyo ndishyi y’akababaro ku mufuka wawe. Mu gihe nta burenganzira bwanditse wabiherewe na sosiyete nta kikubuza kuba wareka kwishyura. Mu gihe utegetswe kwishyura indishyi y’akababaro, sosiyete ifite uburenganzira bwo kuba yafatira amafaranga yose igufitiye mu gihe cyose utarishyura iyo ndishyi.</p>
                            <h3>11. Amategeko agendanye no gukemura amakimbirane</h3>
                            <p>Inzozi Lotto yubahiriza byimbitse itegeko nshinga ryavuguruwe muri 2015 kandi igendera ku mategeko agamije kwirinda no guhana ibyaha bikorerwa ku mbuga za murandasi (cyber crimes) yo muri 2018, itegeko rigena kunyereza amafaranga ryo kuwa 29/01/2020, itegeko rigena amakuru na tekinoloji z’itumanaho ryo muri 2016, ndetse n’amategeko ari mu gitabo cy’amategeko ahana ibyaha cyo muri 2018. Ibyaha byose bizakorwa mu gihe aya masezerano yasinywe bizatangirwa ikirego ku nzego zibishinzwe.</p>
                            <p>Ingingo zose zerekeranye na serivisi, amategeko n’amabwiriza cyangwa ikirego cyangwa impaka zizavuka (harimo ibirego n’impaka zitagendeye kuri kontaro) bizakemurwa nk’uko amategeko ya Repubulika y’u Rwanda abigena kandi bishyikirizwe inkiko zibifite mu nshingano mu mujyi wa Kigali.</p>
                            <h3>12. Imisoro yagenwe</h3>
                            <p>Uremera ko ugomba kuvuga amafaranga winjiza ukanishyura umusoro nk’uko itegeko ribigena. Uremera byongeyeho ko sosiyete izakura umusoro wagenwe ku bihembo byose uzatsindira mbere y’uko ubihabwa. Ibihembo byose byatsindiwe muri Inzozi Lotto byishyura umusoro wa 15%</p>
                            <h3>13. Umukino wa elegitoroniki</h3>
                            <p>Kwemeranya n’aya mategeko n’amabwiriza bikurikiza kontaro iri mu ngingo y’i 158 y’itegeko N°24/2016 ryo kuwa 18/06/2016 rigena amakuru na tekinoloji z’itumanaho mu Rwanda. Mu gihe ugiranye amasezerano na sosiyete, ntabwo uzasabwa kuzuza ibisabwa ku mpapuro. Amasezerano yose azakorwa mu buryo buri elegitoroniki. Ugomba kwemera gukoresha ubwo buryo buri elegitoroniki kandi ukemera gusoma no gusobanukirwa amategeko n’amabwiriza agenga imikoreshereze ya serivisi.</p>
                            <h3>14. Amabwiriza y’ikurwaho ry’inshingano n’ingaruka zabyo</h3>
                            <p>Nta kurwaho ry’inshingano bikozwe n’ikigo ku itegeko cyangwa ibwirizwa iryariryo ryose riri muri iyi mbonerahamwe y’amategeko n’amabwiriza agenga imikoreshereze rizafatwa nk’irikomeza kuri iryo tegeko cyangwa ibwiriza cyangwa ikurwaho ry’inshingano ry’irindi tegeko cyangwa ibwiriza, kandi kunanirwa ku ikigo kwemeza uburenganzira cyangwa ingingo ikubiye muri aya mategeko n’amabwiriza agenga imikoreshereze ntibizemeza ikurwaho ry’inshingano kuri ubwo burenganzira cyangwa ingingo.</p>
                            <p>Igihe ingingo iyo ari yo yose yo muri aya mategeko n’amabwiriza agenga imikoreshereze izafatwa n’urukiko cyangwa izindi nkiko zibifitiye ububasha nk’itujuje ibisabwa, itemewe n’amategeko cyangwa idakurikizwa ku mpamvu iyo ari yo yose, iyo ngingo izakurwaho cyangwa igire aho igarukira ku ntera ntoya ku buryo ingingo zisigaye z’amategeko n’amabwiriza agenga imikoreshereze zizakomeza gukurikizwa nk’uko bikwiye.</p>
                            <h3>15. Ihererekanya</h3>
                            <p>Sosiyete ifite uburenganzira bwo guhererekanya inshingano zayo ku wundi muntu cyangwa ikigo igihe icyo aricyo cyose nta nteguza uhawe. Nyuma y’iryo hererekanwa, sosiyete izaba ikuweho inshingano zose zayrebaga. Nta burenganzira uhabwa bwo guhererekanya inshingano zawe cyangwa ibyo usabwa utabiherewe uburenganzira na sosiyete. Mu gihe ubikoze bitemewe bizateshwa agaciro.</p>
                            <h3>16. Umubano hagati y’abasinye amasezerano</h3>
                            <p>Urahamya kandi uremera ko nta bufantanyabikorwa, ubushuti cyangwa isano ry’akazi riri hagati yawe na sosiyete by’umwihariko ku bijyanye n’amategeko n’amabwiriza agenga imikoreshereze ya serivisi. Uremera kuzigera na rimwe wiyitirira ko uhagarariye, ko uri umu ajenti, utanga serivisi, cyangwa umukozi wa sosiyete kandi ko sosiyete itazishingira ibikorwa cyangwa ibinyoma byo muri ubwo bwoko. Uremera kandi ko usibye aho bivugwa muri aya mategeko n’amabwiriza, ntawe mufatanije inyungu.</p>
                            <h3>17. Impamvu zitateganijwe</h3>
                            <p>Sosiyete ntabwo izabazwa amakosa cyangwa imikorere idahwitse ya serivisi mu gihe biturutse ku mpamvu idashobora guhindura cyangwa gukosora zirimo ariko zitagarukira ku ihindagurika ry’ibihe, ibikorwa by’Imana, Ibiza, intambara, ibikorwa by’iterabwoba, imyigaragambyo, ibikorwa bya gisirikare, inkongi, imyuzure, impanuka, ibibazo bituruka ku minara , virusi zo kuri mudasobwa, ibura ry’ubwikorezi, ndetse no kugenda gake kwa murandasi (Zose zikaba ari impamvu zidashobora guteganwa).</p>
                            <h3>18. Ibanga no kurinda amakuru bwite</h3>
                            <p>Kanda <Link to="/privacypolicy"  target="_blank">hano</Link> usome amabwiriza agenga amakuru bwite</p>
                            <h3>19. Amabwiriza y’ikoreshwa ry’amakuru</h3>
                            <p>Kanda <Link to="/cookiepolicy" target="_blank">hano</Link> urebe amabwiriza y’ikoreshwa ry’amakuru</p>
                            <h3>20. Amasezerano yose</h3>
                            <p>Aya mategeko n’amabwiriza, amabwiriza agenga amakur bwite ndetse n’ububasha bw’uwunganira buri ruhande mu mategeko niyo yonyine asinywe hagati yawe na sosiyete ku bigendanye na serivisi kandi yisumbuye ku yandi masezerano, ubwumvikane, garanti byaba byaranditswe cyangwa byaravuzwe mbere ku bigendanye na serivisi. Nta ngingo n’imwe iri muri aya mategeko n’amabwiriza, yaba iyanditswe cyangwa iyavuzwe,ireba, iha uburenganzira , itegeka uruhande urwo arirwo rwose, yaba umuntu cyangwa ikigo atari impande zombi zasinye aya masezerano uretse aho byanditswe ukundi.</p>
                        </div>
                    }
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(TermsAndCondition))