import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { withTranslation } from 'react-i18next';
class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.props.GetUserProfile(this.props.user.mobileNo,this.props.i18n.language);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            userProfile: props.userProfile,
        }
    };

    render() {
        const { t } = this.props;
        return (
            <section id="cTab8" class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblProfile')}</h2>
                </div>
                <div class="col-md-2 col-sm-2 col-xd-12">
                    <div class="browswImg">
                        <i class="fa fa-picture-o" aria-hidden="true"></i>
                        <div class="browswImgbtm">
                            <span><i class="fa fa-edit"></i>{t('lblBrowseImage')}</span>
                        </div>
                    </div>
                </div>
                {this.state.userProfile && <div class="col-md-4 col-sm-12 col-xd-12">
                    <div class="CAshColectionInner">
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblFullName')}:</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.userProfile.UserName}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                            <div class="SelectContCentLft">
                                <span>{t('lblDOB')}:</span>
                            </div>
                            <div class="SelectContCent2">
                                <h3>{this.state.userProfile.DOB}</h3>
                            </div>
                        </div>
                        <div class="CAshDivCollect">
                                <div class="SelectContCentLft">
                                    <span>{t('lblmobno')}:</span>
                                </div>
                                <div class="SelectContCent2">
                                    <h3>{this.state.userProfile.PhoneNo}</h3>
                                </div>
                            </div>
                            <div class="CAshDivCollect">
                                <div class="SelectContCentLft">
                                    <span>{t('lblEmail')}:</span>
                                </div>
                                <div class="SelectContCent2">
                                    <h3>{this.state.userProfile.Email}</h3>
                                </div>
                            </div>
                    </div>
                </div>}
                
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { userProfile } = state.accountReducer;
    return { user, userProfile }
}

const mapActionToProps = {
    GetUserProfile: accountActions.GetUserProfile
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(UserProfile))