import { appConstants } from '../helpers/actionType';
import { quickWinService } from '../services/quickWinService';
import { commonClass } from "..//helpers/commonClass";
import { alertActions } from "../action/alertAction";
import { history } from '../helpers/history';
export const quickWinActions = {
    AddToCart,
    SetCurrentDraw,
    RemoveFromCart,
    ClearCart,
    RemoveClosedDrawbetFromCart,
    Buybet,
    UpdateStakeAll,
    GetCurrentResult,
    UpdateCartAll,
    BuybetPick3,
}

function AddToCart(cItem) {
    return dispatch => {
        dispatch({ type: appConstants.ADD_CARTITEM, cItem });
    }
};


function UpdateCartAll(Items) {
    return dispatch => {
        dispatch({ type: appConstants.UPDATE_CARTITEM, Items });
    }
};

function RemoveFromCart(cid) {
    return dispatch => {
        dispatch({ type: appConstants.REMOVE_CARTITEM, cid });
    }
};

function RemoveClosedDrawbetFromCart(did) {
    return dispatch => {
        dispatch({ type: appConstants.REMOVE_CLOSEDDRAW_CARTITEM, did });
    }
};

function ClearCart() {
    return dispatch => {
        dispatch({ type: appConstants.CLEAR_CARTITEM });
    }
};

function SetCurrentDraw(cDraw) {
    return dispatch => {
        dispatch({ type: appConstants.SET_DRAW, cDraw });
    }
};

function UpdateStakeAll(stake) {
    return dispatch => {
        dispatch({ type: appConstants.UPDATE_STAKE_ALL, stake });
    }
};

function GetCurrentResult(gameID, langID) {
    return dispatch => {
        let data = `'${gameID}','${langID}'`;
        quickWinService.GetCurrentResult(data)
            .then(
                cResult => {
                    dispatch({ type: appConstants.GET_CURRENT_RESULT, cResult });
                },
                error => {
                    let cResult = undefined;
                    dispatch({ type: appConstants.GET_CURRENT_RESULT, cResult });
                }
            );
    }
};

function Buybet(buyStr) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = buyStr;
            quickWinService.QuickWinOnlineSale(data)
                .then(
                    buyInfo => {
                        let myBet = generateBetSlip(buyInfo);
                        dispatch({ type: appConstants.LOADED });
                        if (myBet.status == 1) {
                            dispatch(alertActions.error(myBet.msg));
                        }
                        else if (myBet.status == 3) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                        }
                        else if (myBet.status == 4) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push('/login'); }, 2000);
                        }
                        else {
                            dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        }
                        if (myBet.balance) {
                            let balance = myBet.balance;
                            let winbalance = myBet.winbalance;
                            dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                        }
                    },
                    error => {
                        let myBet = { status: 2, msg: "Something Wrong!" };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function BuybetPick3(buyStr) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = buyStr;
            quickWinService.Pick3OnlineSale(data)
                .then(
                    buyInfo => {
                        let myBet = generateBetSlip(buyInfo);
                        dispatch({ type: appConstants.LOADED });
                        if (myBet.status == 1) {
                            dispatch(alertActions.error(myBet.msg));
                        }
                        else if (myBet.status == 3) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                        }
                        else if (myBet.status == 4) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push('/login'); }, 2000);
                        }
                        else {
                            dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        }
                        if (myBet.balance) {
                            let balance = myBet.balance;
                            let winbalance = myBet.winbalance;
                            dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                        }
                    },
                    error => {
                        let myBet = { status: 2, msg: "Something Wrong!" };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function generateBetSlip(mybet) {
    try {
        let betArr = mybet.split('~');
        if (betArr[0] != 0) {
            return { status: betArr[0], msg: betArr[1] };
        }
        mybet = mybet.substring(2, mybet.length);
        let tempStr = mybet.split('@');
        let tempStr1 = tempStr[1].split('~');
        let tempStr2 = tempStr[0].split('|');
        let tempStr3 = [];
        tempStr2.forEach(ele => {
            let _betValue = ele.split('~');
            if (_betValue.length > 1) {
                tempStr3.push({
                    Id: _betValue[0],
                    BallNo: _betValue[2].split(' ')[1],
                    StakeValue: parseFloat(_betValue[3]),
                    SubTotal: parseFloat(_betValue[4]),
                    OddsValue: _betValue[5],
                    GameTime: _betValue[6] + " " + _betValue[7],
                    GameID: parseInt(_betValue[8]),
                    TransDateTime: _betValue[10],
                    GameName: _betValue[12],
                });
            }
        });
        let _bMsg = "";
        if (tempStr.length > 2) {
            _bMsg = tempStr[2];
        }
        let betObj = {
            betLst: tempStr3,
            status: betArr[0],
            msg: "success",
            balance: tempStr1[0],
            winbalance: tempStr1[1],
            familyID: 5,
            buttomMsg: _bMsg
        }
        return betObj;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};