import { appConstants } from '../helpers/actionType';
import { userService } from '../services/userService';
import { history } from '../helpers/history';
import { alertActions } from "../action/alertAction";
import { commonClass } from "..//helpers/commonClass"

export const loginActions = {
	login,
	loginSkip,
	registration,
	otpAuthentication,
	setPage,
	forgotPassword,
	changePassword,
	logout,
	setPageMenu,
	getBalance,
	reSendOtp,
	saveCustomerComplaint,
	saveAgentRequest
}


function loginSkip(_flog) {
	let _timeout = (1000 * 60 * 2);
	return dispatch => {
		let userdata = { userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: _timeout };
		localStorage.setItem('user', JSON.stringify(userdata));
		dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
		if (_flog != 1) {
			history.push('/');
		}
	}
};


function login(mobileNo, password,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			//dispatch({ type: appConstants.LOGIN_REQUEST, mobileNo });		
			let data = `'0','0','${mobileNo}','${password}','','${langID}'`;
			userService.login(data)
				.then(
					user => {
						dispatch({ type: appConstants.LOADED });
						let strArr = user.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success("Login Success"));
							//let dt=(new Date().getTime() + (1 * 1 * 1 * 60 * 1000)); //(1 * 24 * 60 * 60 * 1000) //day hour  min  sec  msec 												
							let userdata = { userid: strArr[1], username: strArr[2], mobileNo: strArr[1], balance: strArr[3], winbalance: strArr[4], loginKey: strArr[5], emailID: strArr[6], loginExpiry:parseInt(strArr[7]) };
							localStorage.setItem('user', JSON.stringify(userdata));
							dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
							if (strArr.length > 9) {
								let serverTime = strArr[9];
								dispatch({ type: appConstants.GET_SERVERTIME_SUCCESS, serverTime });
							}
							history.push('/');
						}
						else {
							let error = strArr[1];
							dispatch({ type: appConstants.LOGIN_ERROR, error });
							dispatch(alertActions.error(error));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.LOGIN_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function registration(regFirstName, regLastName, regPassword, regEmailID, regMobileNo, regDob, regOtpCode, ipAddress, regRefNo,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `0,'0','${regMobileNo}','${regPassword}','${regFirstName} ${regLastName}','${regEmailID}','${regDob}','${regOtpCode}','${langID}'`;
			userService.registration(data)
				.then(
					reg => {
						dispatch({ type: appConstants.LOADED });
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
							let pageFlog = 0;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog });
							history.push('/');
						}
						else if (strArr[0] == '2') {
							dispatch(alertActions.success(strArr[1]));
							let pageFlog = 3;
							let otpMobileNo = strArr[2];
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
						}
						else {
							let error = strArr[1];
							dispatch({ type: appConstants.REGISTER_ERROR, error });
							dispatch(alertActions.error(error));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function forgotPassword(forgotMobileNo,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${forgotMobileNo}','${langID}'`;
			userService.forgotPassword(data)
				.then(
					reg => {
						dispatch({ type: appConstants.LOADED });
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
							let pageFlog = 0;
							let otpMobileNo = forgotMobileNo;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
							history.push('/');
						}
						else {
							let error = strArr[1];
							dispatch({ type: appConstants.REGISTER_ERROR, error });
							dispatch(alertActions.error(error));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function changePassword(forgotMobileNo, oldPassword, newPassword,langID) {
	try {
		return dispatch => {
			let data = `'${forgotMobileNo}','${oldPassword}','${newPassword}','${langID}'`;
			userService.changePassword(data)
				.then(
					reg => {
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							let pageFlog = 0;
							let otpMobileNo = forgotMobileNo;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
							dispatch(alertActions.success(strArr[1]));
							logout();
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function otpAuthentication(regMobileNo, regOtp,langID) {
	try {
		return dispatch => {
			let data = `'${regMobileNo}','${regOtp}','${langID}'`;
			userService.otpAuthentication(data)
				.then(
					reg => {
						if (reg.Status == '0') {
							dispatch(alertActions.success(reg.Msg));
							let pageFlog = 2;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog });
							history.push('/');
						}
						else {
							let error = reg.Msg;
							dispatch({ type: appConstants.REGISTER_ERROR, error });
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function reSendOtp(regMobileNo, regEmailID,otpFor,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${regMobileNo}','${regEmailID}',${otpFor},'${langID}'`;
			userService.reSendOtp(data)
				.then(
					reg => {
						dispatch({ type: appConstants.LOADED });
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
							logout();
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function getBalance(regMobileNo, loginKey,langID) {
	try {
		return dispatch => {
			let data = `0,0,'${regMobileNo}','${loginKey}','${langID}'`;
			userService.getBalance(data)
				.then(
					reg => {
						let _balArr = reg.data.split('~');
						if (_balArr[0] == '0') {
							let balance = _balArr[1];
							let winbalance = _balArr[2];
							dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
						}
						else if (_balArr[0] == '4') {
							dispatch(alertActions.error(_balArr[1]));
							setTimeout(() => { history.push('/login') }, 2000);
						}
					},
					error => {
						let balance = 0;
						let winbalance = 0;
						dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function setPage(pageFlog, otpMobileNo) {
	return dispatch => {
		dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
	}
};

function setPageMenu(pageMenuID) {
	return dispatch => {
		dispatch({ type: appConstants.SET_PAGE_MENU, pageMenuID });
	}
};

function saveCustomerComplaint(playerName, mobNo, email, complaint,langID) {
	try {
		return dispatch => {
			let data = `'${playerName}','${mobNo}','${email}','${complaint}','${langID}'`;
			userService.saveCustomerComplaint(data)
				.then(
					reg => {
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function saveAgentRequest(playerName, mobNo, email, businessName,businessLocation,langID) {
	try {
		return dispatch => {
			let data = `'${playerName}','${mobNo}','${email}','${businessName}','${businessLocation}','${langID}'`;
			userService.saveAgentRequest(data)
				.then(
					reg => {
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function logout() {
	userService.logout();
	history.push('/login');
	return { type: appConstants.LOGOUT }
};

