import {appConstants} from "../helpers/actionType";

const initialState={serverTime:0};
export const commonReducer=(state=initialState,action)=>{
    switch(action.type)
    {     
          case appConstants.GET_SERVERTIME_SUCCESS:                        
          return {
            ...state,
            serverTime:action.serverTime
          };     
          case appConstants.GET_SERVERTIME_ERROR:
            return {...state};        
            
        default:
          return {...state}
    }

}