import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
class Right extends Component {
    constructor(props) {
        super(props);

    }
    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        };
    }
    render() {
		const { t } = this.props;
        return (
            <div>
                <div className="col-md-4">
                    <div className="betslip">
                        <h2>{t('lblgameslip')}</h2>
                        <div class="betSlip">
                            {this.state.cartItems && this.state.cartItems.length <= 0 && <div className="betError2">
                                <h2 className="warningMsg bounceIn animated infinite">{t('lblplcurbet')}</h2>
                            </div>}
                            <ul className="border-color-left border-color-top border-color-right scrollbar">
                                {this.state.cartItems.map((x, indx) =>
                                    <li key={`bet_${x.id}`}>
                                        <div class="betSlipLft">
                                            <span>{x.drawDateTime}</span>
                                            <div className="betClose" onClick={() => { this.props.removeFromCart(x.id) }}>
                                                <a className="betCloseBtn"><i className="fa fa-times" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                       
                                        <div class="betSlipRHt">
                                        <div class="betSlipCont">
                                                {x.balls && x.balls.map((bx, indx1) => <div key={`lstrslb_${indx1}`} id={`${x.id}_${bx.id}`} className="betSlipBallsRslt">{bx.id}</div>
                                                )}
                                            </div>
                                            <span>{x.total}</span>
                                        </div>
                                    </li>
                                )}

                            </ul>
                        </div>

                        <div class="countArea">
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotbet')} : {this.state.cartItems.length}</span>
                            </div>
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotamt')} : {this.state.cartItems.reduce((a, b) => a = a + b.total, 0)}</span>
                            </div>
                        </div>
                        <div class="lottoBtns">
                            <button class="redBg" onClick={() => { this.props.clearCart() }}>{t('lblclear')}</button>
                            <button class="YellowBg" onClick={() => { this.props.buyLotteryBet() }}>{t('lblBuy')}</button>
                        </div>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="latestRslt">
                    <h2>{t('lbllatresult')}</h2>
                        <div class="betSlip betSlip2">
                        
                            <ul class="scrollbar">
                                {this.props.latestResult ? this.props.latestResult.map((x, indx) =>
                                    <li>
                                        <div class="betSlipLft" style={{width:"100%"}}>
                                            <span>#{x.DrawNo} {x.Drawdatetime}</span>
                                            </div>
                                            <div class="betSlipRHt">
                                            <div class="betSlipCont betSlipContlastRst">
                                                {x.WinningNo.split(",").map((bx, indx1) =>
                                                    <div key={`lstrsld_${indx1}`} id={`${x.DrawNo}_${bx}`} style={(indx1==x.WinningNo.split(",").length-1?{background:"#f2b71d"}:{})} className="betSlipBallsRslt">{bx}</div>
                                                )}
                                            </div>
                                            </div>
                                    </li>
                                ) :
                                    <li>
                                        <div class="betSlipRHt"><div class="betSlipCont betSlipContlastRst" style={{ color: "#000" }}>{t('lblnorecordfound')}</div></div>
                                    </li>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { cartItems } = state.lottoReducer;
    return { user, cartItems };
}

const mapActionToProps = {

}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Right))