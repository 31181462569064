import { appConstants } from '../helpers/actionType';
import { accountService } from '../services/accountService';
import { alertActions } from "../action/alertAction";
import { commonClass } from "../helpers/commonClass";
export const accountActions = {
	GetBetHistory,
	GetAccountHistory,
	AddMoney,
	WithdrawMoney,
	GetAccountSummary,
	GetResultReport,
	GetAccountActivity,
	ReprintTicket,
	GetUserProfile
}

function GetBetHistory(mobileNo, dtFrom, dtTo, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'0','0','${mobileNo}','0','${dtFrom}','${dtTo}','${langID}'`;
			accountService.getbetHistory(data)
				.then(
					bet => {
						let strArr = bet.data.split('|');
						let strArr1 = strArr[0].split('~');
						if (strArr1[0] == '0') {
							strArr[0] = strArr1.slice(1, strArr1.length).join("~");
							let _his = [];
							strArr.forEach(element => {
								if (element == "") { return; }
								let strArr2 = element.split('~');
								_his.push({
									// Id: strArr2[0],
									// Ball: strArr2[1].split(';'),
									// GameName: strArr2[2],
									// GameGroupId: strArr2[3],
									// TransDateTime: strArr2[4] + " " + strArr2[5],
									// StakeAmount: strArr2[7],
									// DrawDateTime: strArr2[8],
									// EstimateWin: strArr2[9],
									// Status: strArr2[10],
									Id: strArr2[0],
									GameName: strArr2[1],
									GameGroupId: strArr2[2],
									TransDateTime: strArr2[3] + " " + strArr2[4],
									TotalBet: strArr2[5],
									TotalStakeAmount: strArr2[6],
									DrawDateTime: strArr2[7],
									Status: strArr2[8],
									WinAmount: strArr2[9],
								});
							});
							let bethistory = { status: "0", data: _his };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.GET_BET_HISTORY, bethistory });
						}
						else {
							let strArr = bet.data.split('~');
							let bethistory = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.GET_BET_HISTORY, bethistory });
						}
					},
					error => {
						let bethistory = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.GET_BET_HISTORY, bethistory });
						dispatch(alertActions.error(bethistory.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAccountHistory(mobileNo, dtFrom, dtTo, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${dtFrom}','${dtTo}','${langID}'`;
			accountService.getAccountHistory(data)
				.then(
					bet => {
						let accountHistory = {};
						if (bet && bet.length > 0)
							accountHistory = { status: "0", data: bet };
						else
							accountHistory = { status: "1", msg: "No Data" };

						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_HISTORY, accountHistory });
					},
					error => {
						let accountHistory = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.GET_ACCOUNT_HISTORY, accountHistory });
						dispatch(alertActions.error(accountHistory.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetResultReport(dtFrom, dtTo, familyID, langID) {
	try {
		return dispatch => {
			if (familyID != -1)
				dispatch({ type: appConstants.LOADING });
			let data = `'${dtFrom}','${dtTo}',0,0,'${familyID}','${langID}'`;
			accountService.getResultReport(data)
				.then(
					resultReport => {
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_RESULT_REPORT, resultReport });
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.GET_RESULT_REPORT, error });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function AddMoney(mobileNo, txnNo, TransID, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${txnNo}','${TransID}','','${langID}'`;
			accountService.addMoney(data)
				.then(
					bet => {
						let strArr = bet.data.split('~');
						if (strArr[0] == '0') {
							let addmoney = { status: strArr[0], msg: strArr[1] };
							let balance = strArr[2];
							let winbalance = strArr[3];
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.ADD_MONEY_SUCCESS, addmoney });
							dispatch(alertActions.success(addmoney.msg));
							dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
						}
						else {
							let strArr = bet.data.split('~');
							let addmoney = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.ADD_MONEY_SUCCESS, addmoney });
							dispatch(alertActions.error(addmoney.msg));

						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						let addmoney = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.ADD_MONEY_SUCCESS, addmoney });
						dispatch(alertActions.error(error.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function WithdrawMoney(mobileNo, amt, TransID, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${amt}','${TransID}','','${langID}'`;
			accountService.withdrawMoney(data)
				.then(
					bet => {
						let strArr = bet.data.split('~');
						if (strArr[0] == '0') {
							let withdraw = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdraw });
							dispatch(alertActions.success(withdraw.msg));
						}
						else {
							let strArr = bet.data.split('~');
							let withdraw = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdraw });
							dispatch(alertActions.error(withdraw.msg));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						let withdraw = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdraw });
						dispatch(alertActions.error(withdraw.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAccountSummary(mobileNo, dtFrom, dtTo, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'0','0','${mobileNo}','${dtFrom}','${dtTo}','${langID}'`;
			accountService.getAccountSummary(data)
				.then(
					accountSummary => {
						if (accountSummary.data != null) {
							let strArr = accountSummary.data.split('~');
							if (strArr[0] == '0') {
								accountSummary = {
									status: strArr[0], openBalance: strArr[3], totSale: strArr[4],
									totClaim: strArr[5], totCashIn: strArr[6], totCashOut: strArr[7], netTotal: strArr[9]
								};
							}
							else {
								accountSummary = { status: strArr[0], msg: strArr[1] };
							}
						}
						else {

							accountSummary = { status: "1", msg: "No Data" };
						}
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_SUMMARY, accountSummary });
					},
					error => {
						let accountSummary = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_SUMMARY, accountSummary });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAccountActivity(mobileNo, dtFrom, dtTo, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${dtFrom}','${dtTo}','${langID}'`;
			accountService.getAccountActivity(data)
				.then(
					bet => {
						let accountActivity = {};
						if (bet && bet.length > 0)
							accountActivity = { status: "0", data: bet };
						else
							accountActivity = { status: "1", msg: "No Data" };

						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_ACTIVITY, accountActivity });
					},
					error => {
						let accountActivity = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.GET_ACCOUNT_ACTIVITY, accountActivity });
						dispatch(alertActions.error(accountActivity.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function ReprintTicket(transID, mobileNo, _gameGroup, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${transID}','${langID}'`;
			accountService.ReprintTicket(data)
				.then(
					buyInfo => {
						if (_gameGroup == "-1") {
							let ss = buyInfo.toLowerCase().includes('jackpot');
							let ss1 = buyInfo.toLowerCase().includes('igitego');
							if (ss)
								_gameGroup = 4;//jackpot
							if (ss1)
								_gameGroup = 1;//igitego-lotto,motto,iphone
							else
								_gameGroup = 5;//quick lotto
						}
						let myBet = commonClass.generateBetSlip(buyInfo, _gameGroup);
						dispatch({ type: appConstants.BUY_SUCCESS, myBet });
						dispatch({ type: appConstants.LOADED });
						//let balance = myBet.balance;
						//let winbalance =myBet.winbalance;     
						//dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });  

					},
					error => {
						let myBet = { status: 2, msg: "Something Wrong!" };
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.BUY_SUCCESS, myBet });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetUserProfile(mobileNo, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${langID}'`;
			accountService.getUserProfile(data)
				.then(
					userProfile => {
						let profile = userProfile;
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_USER_PROFILE, profile });
					},
					error => {
						let profile = { userProfile: null };
						dispatch({ type: appConstants.GET_USER_PROFILE, profile });
						dispatch({ type: appConstants.LOADED });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

