import { appConstants } from "../helpers/actionType";
export const accountReducer = (state = { BetHistory: {},ResultReport:[], AccountSummary: {} ,AccountHistory:{},AccountActivity:{}}, action) => {
  switch (action.type) {
    case appConstants.GET_BET_HISTORY:
      return {
        ...state,
        BetHistory: action.bethistory
      };
    case appConstants.ADD_MONEY_SUCCESS:
      return {
        ...state,
        AddMoney: action.addmoney
      };
    case appConstants.WITHDRAW_MONEY_SUCCESS:
      return {
        ...state,
        WithdrawMoney: action.withdraw
      };
    case appConstants.GET_ACCOUNT_SUMMARY:
      return {
        ...state,
        AccountSummary: action.accountSummary
      };
      case appConstants.GET_RESULT_REPORT:
      return {
        ...state,
        ResultReport: action.resultReport
      };
      case appConstants.GET_ACCOUNT_HISTORY:
      return {
        ...state,
        AccountHistory: action.accountHistory
      };
      case appConstants.GET_ACCOUNT_ACTIVITY:
        return {
          ...state,
          AccountActivity: action.accountActivity
        };
        case appConstants.GET_USER_PROFILE:
          return {
            ...state,
            userProfile: action.profile
          };
        
    default:
      return { ...state }

  }
}