import { commonClass } from "..//helpers/commonClass"
export const rouletteService = {
    RouletteSale,
    GetCurrentResult  
};

const _apiUrl=process.env.REACT_APP_API_URL;


function RouletteSale(data) {
    try{   
    return fetch(`${_apiUrl}/roulette/rouletteSale`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
    }
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function GetCurrentResult(data) {
    try {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({ "data": data })
        // };
        return fetch(`${_apiUrl}/roulette/getCurrentDrawResult`, commonClass.requestOptions(data))
            .then(commonClass.handleResponse)
            .then(results => {
                if (results != null && results.data != null) { results = results.data; }
                //results="0~ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2~3:00:00";
                //results="0~12~142~10/09/2021~10:10 AM~Quick Win~9";
                return results;
            });
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};