import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import { PrivateRoute } from './helpers/PrivateRoute';
import { connect } from 'react-redux';
import { alertActions } from './action/alertAction';
import Login from './component/Pages/Login';
import Home from './component/Pages/Home/Home';
import Lotto from './component/Pages/Lotto/lottoIndex';
import QuickWin from './component/Pages/QuickWin/quickWinIndex';
import Target10 from './component/Pages/Target10/target10Index';


import Common from "./component/Pages/common";

// import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import Account from './component/Pages/Account/AccountIndex';

import ResultReport from './component/Pages/Account/ResultReport';
import Faq from "./component/Pages/Faq";
import PrivacyPolicy from "./component/Pages/PrivacyPolicy";
import TermsAndCondition from "./component/Pages/TermsAndCondition";
import UnderConstruction from "./component/Pages/UnderConstruction";
import Contact from "./component/Pages/Contact";
import AboutUs from "./component/Pages/AboutUs";
import CookiePolicy from "./component/Pages/CookiePolicy";
import HowToPlay from "./component/Pages/HowToPlay";
import HelpAndSupport from "./component/Pages/HelpAndSupport";
import BecomeAnAgent from "./component/Pages/BecomeAnAgent";
import SocialResponsibility from "./component/Pages/SocialResponsibility";
import PressRelease from "./component/Pages/PressRelease";
import { loginActions } from './action/loginAction';
import ErrorBoundary from './component/Error'
import Winners from "./component/Pages/Winners";
import Instant from "./component/Pages/Casino/Instant";
import Raffle from "./component/Pages/Raffle/RaffleIndex";
import Pick3 from './component/Pages/Pick3/pick3Index';
import Roulette from './component/Pages/Roulette/RouletteIndex';
import Rafflephone from './component/Pages/Rafflephone/RafflePhoneIndex';
import RaffleBike from './component/Pages/RaffleBike/RaffleBikeIndex';

// import NotFound from './component/NotFound'


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isTimedOut: false }
    history.listen((location, action) => {
      // clear alert on location change
      //this.props.clearAlerts();         
      this.hideFooter(location.pathname);
      this.hideNavLink(location.pathname);
      console.log("New version is : " + process.env.REACT_APP_VERSION);
    });
    let _myapp = JSON.parse(localStorage.getItem('appvariables'));

    if (_myapp && process.env.REACT_APP_VERSION != _myapp.version) {
      localStorage.setItem('appvariables', JSON.stringify({ countryCode: "", currency: "FRw", version: process.env.REACT_APP_VERSION }));
      //window.location.reload(true);
      window.location.href = window.location.href;
      window.location.replace(window.location.href);
    }

    if (_myapp == null || _myapp == undefined) {
      localStorage.setItem('appvariables', JSON.stringify({ countryCode: "", currency: "FRw", version: process.env.REACT_APP_VERSION }));
    }
    let _user = localStorage.getItem('user');
    if (_user == null || _user == undefined) {
      this.props.loginSkip(1);
    }
  }


  hideFooter = (_pathname) => {
    let dd = document.getElementById("myappFooter");
    if (_pathname == "/login" || _pathname == '/termscondition') {
      if (dd)
        dd.style.display = "none";
    }
    else {
      if (dd)
        dd.style.display = "block";
    }
    // let dd1 = document.getElementById("myappHeader");    
    // if ( _pathname == '/termscondition') {
    //   if (dd1)
    //     dd1.style.display = "none";
    // }
    // else {
    //   if (dd1)
    //     dd.style.display = "block";
    // }
  };


  hideNavLink = (_pathname) => {
    let dd = document.getElementById("navBarSocialLink");
    if (_pathname != "/" && _pathname != '/home') {
      if (dd)
        dd.style.display = "none";
    }
    else {
      if (dd)
        dd.style.display = "block";
    }
  };


  loadsitedata = () => {
    try {
      fetch('siteconfig.json')
        .then(response => response.json())
        .then(data => {
          console.log("New REACT_APP_VERSION is : " + process.env.REACT_APP_VERSION);
          console.log("Old REACT_APP_VERSION is : " + data.version);
          if (data && process.env.REACT_APP_VERSION != data.version) {
            localStorage.setItem('appvariables', JSON.stringify({ countryCode: "", currency: "FRw", version: data.version }));
            //window.location.reload(true);
            window.location.href = window.location.href;
            window.location.replace(window.location.href);
          }
        }
        );

    }
    catch (ex) {
      console.log(ex);
    }
  };


  componentDidMount() {
    //while refresh the page.
    //this.hideFooter(history.location.pathname)
    // localStorage.setItem('appvariables', JSON.stringify({countryCode:"+91",currency:"₹"}));    
    //this.loadsitedata();
    window.documentReadyHome();
  };

  componentDidUpdate() {
    this.hideFooter(history.location.pathname);
    this.hideNavLink(history.location.pathname);
  };

  render() {
    const { alertReducer } = this.props;
    return (
      <div style={{ height: "100%" }}> {/*style={{ height: "100%" }} */}
        <div id="translator" class='mt-5'></div>
        {alertReducer.message &&
          <div>
            <div id="lightBox1" className="lightBox" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
              <div className="userLog warning" style={{ borderColor: alertReducer.alertcolor }}>
                <form method="post" name="enqForm" className="enqForm">
                  <h2 style={{ color: alertReducer.alertcolor }} >{alertReducer.alertTitle}</h2>
                  <span style={{ color: alertReducer.alertcolor }}>{alertReducer.message} </span>
                  <div className="forCenter">
                    <div className="userLogBtnCont">
                      <button style={{ backgroundColor: alertReducer.alertcolor }} onClick={() => { this.props.clearAlerts() }}>Ok</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div style={{ display: "block" }} className="overlayPop"></div>
          </div>
        }
        {alertReducer.loading && <div class="loaderOuter"><div class="loader"></div></div>}
        <ErrorBoundary>
          {/* <Router basename={"/RwandaApp"} history={history}> */}
          <Router basename={process.env.REACT_APP_BASENAME} history={history}>
            <Common />
            {/* <div className="mainContainer">           
            <Header /> */}
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/lotto" component={Lotto} />
              <Route path="/quickwin" component={QuickWin} />
              <Route path="/target" component={Target10} />
              <Route path="/account" component={Account} />
              <Route path="/result" component={ResultReport} />
              <Route path="/contact" component={Contact} />
              <Route path="/privacypolicy" component={PrivacyPolicy} />
              <Route path="/termscondition" component={TermsAndCondition} />
              <Route path="/termsandcondition" component={TermsAndCondition} />
              <Route path="/faq" component={Faq} />
              <Route path="/howtoplay" component={HowToPlay} />
              <Route path="/becomeanagent" component={BecomeAnAgent} />
              <Route path="/underconstruction" component={UnderConstruction} />
              <Route path="/helpsupport" component={HelpAndSupport} />
              <Route path="/aboutus" component={AboutUs} />
              <Route path="/cookiepolicy" component={CookiePolicy} />
              <Route path="/socialresponse" component={SocialResponsibility} />
              <Route path="/pressrelease" component={PressRelease} />
              <Route path="/winners" component={Winners} />
              <Route path="/instant" component={Instant} />
              <Route path="/igitego" component={Raffle} />
              <Route path="/pick3" component={Pick3} />
              <Route path="/Roulette" component={Roulette} />
              <Route path="/iphone" component={Rafflephone} />
              <Route path="/moto" component={RaffleBike} />
              {/*<Route component={NotFound} />*/}
            </Switch>
            {/* </div> */}
            <Footer />
            {/* {history.location.pathname.indexOf("/login")<=-1 && <Footer/> } */}
          </Router>
        </ErrorBoundary>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alertReducer } = state;
  const { user } = state.loginReducer;
  return { alertReducer, user };
}

const mapDispatchToProps = {
  clearAlerts: alertActions.clear,
  loading: alertActions.loading,
  loginSkip: loginActions.loginSkip

};

export default connect(mapStateToProps, mapDispatchToProps)(App)
