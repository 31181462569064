import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { withTranslation  } from 'react-i18next';

class LatestResult extends Component{
    constructor(props)
    {
        super(props);
        this.state={
            ResultReport:{}
        };
    }
    static getDerivedStateFromProps(props, state) {
        return {
            ResultReport:props.ResultReport!=undefined? props.ResultReport.slice(0,5):[],
        }
    };
    componentDidMount() {
        window.documentReadyDatePicker();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.props.GetResultReport(ss, ss,-1,this.props.i18n.language);
    };
    render(){ 
		const { t } = this.props;
        return(
            <div class="ResultSec forpadd">
            <h2>{t('lblLotres')}</h2>
                        <div class="container">
                            <table>
                                <tr>
                                    <th>{t('lblgamename')}</th>
                                    <th>{t('lbldrawdatetime')}</th>
                                    <th>{t('lbldrawno')}</th>
                                    <th>{t('lblResults')}</th>
                                </tr>
                                {this.state.ResultReport && this.state.ResultReport.length > 0 && this.state.ResultReport[0].GameName && this.state.ResultReport.map((x, idx) =>
                                    <tr key={'prpt_'+idx}>
                                        <td>{x.GameName}</td>
                                        <td>{x.GameDateTime}</td>
                                        <td>{x.GameID}</td>
                                        <td>
                                                {x.Result.split(';').map((z, inx) =>
                                                    <span key={'crpt_'+inx}>{z}</span>
                                                )}                                            
                                        </td>
                                    </tr>
                                )}
                                {(this.state.ResultReport ==null ||this.state.ResultReport.length <= 0 ||  (this.state.ResultReport.length > 0 && this.state.ResultReport[0].GameName==undefined) )&&
                                    <tr>
                                        <td  colSpan="6">{t('lblnorecordfound')}</td>
                                    </tr>
                                }
                            </table>
                            </div>                   
                    </div>

        )
    };
}

function mapStateToProps(state) {
    const { ResultReport } = state.accountReducer;
    return { ResultReport };
}

const mapActionToProps = {
    GetResultReport: accountActions.GetResultReport,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(LatestResult))
