import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
//Pick3 Left
class pick3Left extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { t } = this.props;
		return (
			<div class="col-md-3">
					<div class="Nxtdrawsec">
				<ul>
					{this.props.currentAvailableDraws && this.props.currentAvailableDraws.length>0  ? this.props.currentAvailableDraws.map((x, indx) =>
						<li key={`lstav_${indx}`} onClick={() => { this.props.selectDraw(x) }} className={(this.props.currentDraw != undefined && x.DrawID == this.props.currentDraw.DrawID) ? "Active" : ""}>
							<div class="lottoimg">
									<span>
										<img src="./assets/img/icons/Watatu_Y.png" />
									</span>
								</div>
								<div class="lottodraw">
									<label>#{x.DrawNo}</label>
								</div>
								<div class="lottotime">
								<small style={{borderBottom:"1px solid #ccc"}}>{x.DrawTime}</small>
									<small>{x.DrawDate}</small>									
								</div>
						</li>
					) :
						<li><a><div class="lottotime"><small>{t('lblNoMoreDraws')}</small></div></a></li>
					}

				</ul>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state) {
	const { user } = state.loginReducer;
	const { currentDraw } = state.quickWinReducer;
	return { user, currentDraw };
}

const mapActionToProps = {
	//SetCurrentDraw:lottoActions.SetCurrentDraw,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(pick3Left))