import React, { Component } from 'react'
import { connect } from 'react-redux';
import AccountInfo from "./AccountInfo";
import Addmoney from "./Addmoney";
import Withdraw from "./Withdraw";
import BetHistory from "./BetHistory";
import AccountActivity from "./AccountActivity";

import { loginActions } from '../../../action/loginAction';
import AccountHistory from './AccountHistory';
import AccountSummary from './AccountSummary';
import ChangePassword from './ChangePassword';
import UserProfile from './UserProfile';

import Header from "../../Header/Header";
import { history } from '../../../helpers/history';
import { withTranslation  } from 'react-i18next';

class AccountIndex extends Component {
	constructor(props) {
		super(props);
		// this.state = {
		// 	tabID: 0,
		// }
		if(this.props.location.state &&this.props.location.state.tabIDT){
			this.state = {tabID: this.props.location.state.tabIDT}
		}		
		else
		{
			this.state = {tabID: 0}
		}
		if (this.props.user && this.props.user.mobileNo != "0") {
		this.props.getBalance(this.props.user.mobileNo,this.props.user.loginKey,this.props.i18n.language);
		}
	}
	setTab = (id) => {
		if (id == 0 && this.props.user && this.props.user.mobileNo != "0") {
			this.props.getBalance(this.props.user.mobileNo,this.props.user.loginKey,this.props.i18n.language);
		}
		this.setState({
			tabID: id,
		});
		window.closeOption();
	};

	componentDidMount() {
		window.documentReadyjQueryAcc();
		if (this.props.user == undefined || this.props.user.mobileNo == "0") {
			history.push('/login');
		}
	};

	render() {
		const { t } = this.props;
		return (<div>
			<div className="mainContainer">
				<Header />

				<div class="row" id="service">
					<div class="container forpadd">
						<div class="accTabs">
							<nav class="tabview">
								<div id="accTabLi">
									<ul>
										<li onClick={() => { this.setTab(0) }}><a class="tabBg0" data="-1"><span><img src="./assets/img/icons/Add-icon.png" /></span>{t('lblCheckBalance')}</a></li>
										{/* <li class="dropDownLi">
											<a class="tabBg1 dropDownC" data="#cTab1"><span><img src="./assets/img/icons/Withdraw-money.png" /></span>Add Money<i class="fa fa-angle-down" style={{ float: 'right' }} aria-hidden="true"></i></a>
											<div class="AddSubdiv">
												<ul>
													<li><a onClick={() => { this.setTab(1) }}>
														<i class="fa fa-google-wallet" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														Wallet</a></li>
													<li><a href={`${process.env.REACT_APP_PG_URL}/Paymode/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`} target="_self">
														<i class="fa fa-credit-card" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														KPay</a>
													</li>
												</ul>
											</div>
										</li>
										<li class="dropDownLi">
											<a class="tabBg1 dropDownC" data="#cTab1"><span><img src="./assets/img/icons/Withdraw-money.png" /></span>Withdraw<i class="fa fa-angle-down" style={{ float: 'right' }} aria-hidden="true"></i></a>
											<div class="AddSubdiv">
												<ul>
													<li><a onClick={() => { this.setTab(2) }}>
														<i class="fa fa-google-wallet" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														Wallet</a>
													</li>
													<li><a href={`${process.env.REACT_APP_PG_URL}/Paymode/Withdraw?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`} target="_self">
														<i class="fa fa-credit-card" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														KPay</a>
													</li>
												</ul>
											</div>
										</li> */}
										 <li onClick={() => { this.setTab(1) }}><a class="tabBg1" ><span><img src="./assets/img/icons/Withdraw-money.png" /></span>{t('lblAddMoney')}</a></li> 
										 <li onClick={() => { this.setTab(2) }}><a class="tabBg2" ><span><img src="./assets/img/icons/Bet- History.png" /></span>{t('lblWithdrawMoney')}</a></li> 
										<li onClick={() => { this.setTab(3) }}><a class="tabBg3" ><span><img src="./assets/img/icons/Bet- History.png" /></span>{t('lblGameDetails')}</a></li>
										<li onClick={() => { this.setTab(6) }}><a class="tabBg3" ><span><img src="./assets/img/icons/Icon awesome-exchange-alt.png" /></span>{t('lblAccountDetails')}</a></li>
										<li onClick={() => { this.setTab(5) }}><a class="tabBg4" ><span><img src="./assets/img/icons/Icon feather-activity.png" /></span>{t('lblAccountActivity')}</a></li>
										<li onClick={() => { this.setTab(4) }}><a class="tabBg4" ><span><img src="./assets/img/icons/Account Summary icon.png" /></span>{t('lblAccountSummary')}</a></li>
										<li onClick={() => { this.setTab(7) }}><a class="tabBg4" ><span><img src="./assets/img/icons/Icon changepwd.png" /></span>{t('lblChangepwd')}</a></li>
										<li onClick={() => { this.setTab(8) }}><a class="tabBg4" ><span><img src="./assets/img/icons/Icon awesome-user-plus.png" /></span>{t('lblProfile')}</a></li>
										<li onClick={(e) => { e.preventDefault(); this.props.logout() }}><a class="tabBg4" data="#cTab4"><span><img src="./assets/img/icons/Logout.png" /></span>{t('lblLogout')}</a></li>
									</ul>
								</div>
							</nav>
							<div class="toogleacctCont">
								<span class="toogleacct" id="btnOption" >{t('lblOption')}</span>
							</div>
							<div class="accTabsRht">
								{this.state.tabID == 0 && <AccountInfo />}
								{this.state.tabID == 1 && <Addmoney />}
								{this.state.tabID == 2 && <Withdraw />}
								{this.state.tabID == 3 && <BetHistory />}
								{this.state.tabID == 4 && <AccountSummary />}
								{this.state.tabID == 5 && <AccountActivity />}
								{this.state.tabID == 6 && <AccountHistory />}
								{this.state.tabID == 7 && <ChangePassword />}
								{this.state.tabID == 8 && <UserProfile />}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <Footer /> */}
		</div>
		)
	}
};


function mapStateToProps(state) {
	const { user, appvariables } = state.loginReducer;
	return { user, appvariables }
}

const mapActionToProps = {
	getBalance: loginActions.getBalance,
	logout: loginActions.logout
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AccountIndex))