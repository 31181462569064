import React, { Component } from 'react'
import { commonClass } from "../helpers/commonClass"
import { Link } from 'react-router-dom';
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      let _dd=errorInfo?", errorInfo : "+JSON.stringify(errorInfo):"";
      commonClass.writeLog(error+_dd,"componentDidCatch");
         //alert("dd");
        // alert(error);
         //alert(JSON.stringify(errorInfo));
        // alert(errorInfo.componentStack);
        
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error
      })
      this.state.error=error;
      // You can also log error messages to an error reporting service here
    }
    
    render() {        
      if (this.state.error) {
        // Error path
        return (
          <div style={{textAlign:"center"}}>
            <h2  style={{color:"red"}}>Something went wrong.Please try again!.<a href={`${process.env.REACT_APP_BASENAME}/Home`}> Click</a></h2>
            {/* <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error}            
            </details> */}
          </div>
        );
      }
      // Normally, just render children
      return this.props.children;
     }  
  }

export default ErrorBoundary