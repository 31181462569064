
import { appConstants } from '../helpers/actionType';
import { raffleService } from '../services/raffleService';
import { history } from '../helpers/history';
import { commonClass } from "..//helpers/commonClass";
import { alertActions } from "../action/alertAction";
export const raffleActions = {
    GetSearchTicket,
    ClearSearchTicket,
    ClearSelectionTicket,
    Buybet
}

function GetSearchTicket(searchType, searchKey, gameID, mobileNo, loginID, langID) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            //PlayerID,GameID,BetTypeID(1-Full buy,2-Partial buy),TypeID,Number,IpAddress,SecurityKey
            let data = `'${mobileNo}',${gameID},1,${searchType},'${searchKey}','0','${loginID}','${langID}'`;
            raffleService.GetSearchTicket(data)
                .then(
                    games => {
                        let lstTicket = games;
                        dispatch({ type: appConstants.SET_SEARCH_TICKET, lstTicket });
                        dispatch({ type: appConstants.LOADED });
                    },
                    error => {
                        let lstTicket = [];
                        dispatch({ type: appConstants.SET_SEARCH_TICKET, lstTicket });
                        dispatch({ type: appConstants.LOADED });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function ClearSelectionTicket() {
    return dispatch => {
        let lstTicket = [];
        dispatch({ type: appConstants.SET_SEARCH_TICKET, lstTicket });
    }
};

function ClearSearchTicket(mobileNo,loginID) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            //PlayerID,IpAddress,SecurityKey
            let data = `'${mobileNo}',0,'${loginID}'`;
            raffleService.ClearSearchTicket(data)
                .then(
                    games => {
                        let lstTicket = [];
                        dispatch({ type: appConstants.SET_SEARCH_TICKET, lstTicket });
                        dispatch({ type: appConstants.LOADED });
                    },
                    error => {
                        let lstTicket = [];
                        dispatch({ type: appConstants.SET_SEARCH_TICKET, lstTicket });
                        dispatch({ type: appConstants.LOADED });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function Buybet(buyStr) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = buyStr;
            raffleService.OnlineSale(data)
                .then(
                    buyInfo => {
                        let myBet = generateBetSlip(buyInfo);
                        dispatch({ type: appConstants.LOADED });

                        if (myBet.status == 1) {
                            dispatch(alertActions.error(myBet.msg));
                        }
                        else if (myBet.status == 3) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                        }
                        else if (myBet.status == 4) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push('/login'); }, 2000)
                        }
                        else {
                            dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        }
                        if (myBet.balance) {
                            let balance = myBet.balance;
                            let winbalance = myBet.winbalance;
                            dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                        }

                    },
                    error => {
                        let myBet = { status: 2, msg: "Something Wrong!" };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function generateBetSlip(mybet) {
    try {
        let betArr = mybet.split('~');
        if (betArr[0] != 0) {
            return { status: betArr[0], msg: betArr[1] };
        }
        let tempStr = betArr[2].split('^');
        let tempStr1 = [];
        tempStr.forEach(el => {
            let tempStr2 = el.split('@');
            if (tempStr2.length > 1) {
                tempStr1.push({ no: tempStr2[0], amt: tempStr2[1] });
            }
        });
        let _bMsg = "";
        if (betArr.length > 12) {
            _bMsg = betArr[12];
        }
        let betObj = {
            Id: betArr[1],
            TransDate: betArr[7],
            TransTime: betArr[8],
            DrawNo: betArr[6],
            DrawDate: betArr[4],
            DrawTime: betArr[5],
            Ball: tempStr1,
            TotalStakeAmount: betArr[3],
            GameName: betArr[9],
            status: betArr[0],
            msg: "success",
            balance: betArr[10],
            winbalance: betArr[11],
            familyID: 4,
            buttomMsg: _bMsg
        }
        return betObj;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};