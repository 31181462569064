import { appConstants } from "../helpers/actionType";

let user = JSON.parse(localStorage.getItem('user'));
let appVar = JSON.parse(localStorage.getItem('appvariables'));
appVar = appVar != null ? appVar : { countryCode: "", currency: "FRw", version: process.env.REACT_APP_VERSION };
let pagemenu = localStorage.getItem('pagemenuid');
pagemenu = pagemenu == null ? "0" : pagemenu;
const initialState = user ? { loggedIn: true, user, appvariables: (appVar ? appVar : { countryCode: "", currency: "FRw", version: process.env.REACT_APP_VERSION }), pageFlog: 0, pageMenuID: pagemenu } : { pageFlog: 0, pageMenuID: pagemenu, appvariables: appVar };
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: action.user
      };
    case appConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.userdata
      };
    case appConstants.LOGIN_ERROR:
      return {
        pageFlog: state.pageFlog,
        appvariables: state.appvariables
      };
    case appConstants.LOGOUT:
      return {
        pageFlog: state.pageFlog,
        appvariables: state.appvariables
      };
    case appConstants.REGISTER_SUCCESS:
      return {
        ...state,
        pageFlog: action.pageFlog,
        otpMobileNo: action.otpMobileNo
      };
    case appConstants.REGISTER_ERROR:
      return {
        pageFlog: state.pageFlog,
        appvariables: state.appvariables
      };

    case appConstants.GET_BALANCE:
      let userdata = state.user;
      if (userdata != undefined) {
        userdata.balance = action.balance;
        userdata.winbalance = action.winbalance;
        localStorage.setItem('user', JSON.stringify(userdata));
      }
      return {
        ...state,
        user: { ...state.user, balance: action.balance, winbalance: action.winbalance }
      };

    case appConstants.SET_PAGE_MENU:
      localStorage.setItem('pagemenuid', action.pageMenuID);
      return {
        ...state,
        pageMenuID: action.pageMenuID
      };
    default:
      return { ...state }
  }
}