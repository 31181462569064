import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';

class Faq extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		window.applyFAQaccordion();
		window.scrollTo(0,0);
	};

	render() {
		const { i18n } = this.props;
		return (
			<div className="mainContainer">
				<Header />
				<div class="container">
					{i18n.language == "en" ?
						<div class="inrContent">

							<h2>FAQS</h2>
							<ul class="faqAccordian">
								<li><a>What is inzozilotto?</a>
									<ul>
										<li>
											<p>Carousel Ltd is the sole company authorized to operate the national lottery, licensed and regulated by the Ministry of Industry and Trade in charge of the gaming activities https://www.minicom.gov.rw/Carousel built Inzozilotto to become the ultimate lottery ticket management service. Inzozilotto.rw works to solve all of that for you. </p>
											<p>We also have a dedicated Customer Care team to help address any of your questions or concerns. </p>
										</li>
									</ul>
								</li>
								<li><a>How does Inzozilotto work?</a>
									<ul>
										<li>
											<p>Inzozi Lotto is the name of our product which is accessible via our Website, social media pages, mobile apps, online partner marketplaces accounts, Point of Sales, sales events, physical stores or any other form of sales and marketing channels, offline and online to allow purchasing, checking lottery tickets and play the games.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Who is eligible to purchase ticket?</a>
									<ul>
										<li>
											<p>We are happy to serve customers of 18 years of age or older and If you are under 18, you may use the Website or the Marketplace only with involvement of a parent or guardian.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">How do I buy a ticket? Do I have to register to purchase a ticket?</a>
									<ul>
										<li>
											<p>Yes if you are using our online platforms. You are only allowed to register oneAccount, on www.inzozilotto.rw or download the Inzozilotto app to complete the online registration process. You will be required to (i) submit your first and last name and other details as requested including, but not limited to, your ID/Passport Number, your phone number, date of birth, your email address, your residential address, and your payment information.Once you have created your account according to the terms and conditions , you can use Inzozilotto web or app to purchase authentic lottery tickets. </p>
											<p>Otherwise you can use our representative, agent, or distributors accessible all over the country.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What is my username? Can I change the username and the password?</a>
									<ul>
										<li>
											<p>Your valid mobile number is your username. Once registered with valid mobile number, username cannot be changed. To change your password you click on Change password link. </p>

										</li>
									</ul>
								</li>
								<li><a href="#">How can I Check my Account?</a>
									<ul>
										<li>
											<p>Go to your account and click on account.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What if I forget my User ID? Or Password?</a>
									<ul>
										<li>
											<p>Click on forget User ID link and fill up the email ID, you will receive an email with your username. Click on Forget password link and reset your password.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Do I need to pay any registration Fee?</a>
									<ul>
										<li>
											<p>No, there is no registration fee required.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">How much does it cost to purchase a ticket? Can I buy more than one ticket?</a>
									<ul>
										<li>
											<p>Inzozilotto tickets range from 100 to 500 Rwandan Francs (FRW). Yes, you can buy more than one ticket.To protect our customers, we may set daily spending limits on purchases, including but not limited to ticket purchases. Once a daily spend has been reached, no more transactions can occur on the user’s account until 24 hours after the last qualifying transaction.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Can my tickets be emailed to me?</a>
									<ul>
										<li>
											<p>Yes! let our customer care team or our point of sales know over the phone. Just make sure your email address is correct. To ensure your e-tickets reach your inbox, please use support@inzozilotto.rw and mark us as a safe sender, or if your e-tickets are in your spam or junk folder, you can mark the email as 'not spam/junk.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Do I have to be a Rwandan Citizen? </a>
									<ul>
										<li>
											<p>No, You can buy tickets and win prizes regardless of your visa status as long as you have successfully created an account on our online platforms or managed to purchase tickets from our retailers and agents. However be aware that if you do win a prize, we have to deliver prizes within Rwanda, so please be prepared to make suitable arrangements if you do win a prize but don't live here</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What methods of payment can I use?</a>
									<ul>
										<li>
											<p>You can fund your account using most bankcards, cash or Momo. Our international users are able to pay with Visa and Mastercard.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">How Can I create my wallet?</a>
									<ul>
										<li>
											<p>By registering yourself on Inzozi Website, your Inzozilotto wallet gets created automatically and you can fund it with mobile money / credit card from the minimum amount of RWF 100 to the maximum allowed by our policies. You need to go to your account link and click on Add Money and select the desire payment mode and amount. </p>
										</li>
									</ul>
								</li>
								<li><a href="#">How can I play the Games?</a>
									<ul>
										<li>
											<p>You Need to go to the game menu and select the games you desire to play, further you can check on the link of each game “How to Play”.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Does Lottery.com send me a physical ticket when I play through the app or the web?</a>
									<ul>
										<li>
											<p>No, but we will send you an email receipt and notify you if you’ve won, and track your winnings. </p>
										</li>
									</ul>
								</li>
								<li><a href="#">What is the minimum amount I can play?</a>
									<ul>
										<li>
											<p>For Quick Win you can play a minimum amount is FWR 100 and For Lotto RWF 500 a ticket.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">How Can I Check my Games?</a>
									<ul>
										<li>
											<p>Go to your account and click on ticket details.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What is the maximum amount I can play?</a>
									<ul>
										<li>
											<p>For Quick Win you can play maximum of RWF 10,000 each ticket and Lotto as you wish.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">How do I know I have won and how do I do?</a>
									<ul>
										<li>
											<p>We will attempt to inform you of any winnings you may be entitled to and, you will be notified of your options to redeem your lottery winnings. Otherwise you can also visit our agents/retailers/distributors present your physical ticket and get the claim.  Alternatively you go to Search bar and enter the transaction ID, you will get the ticket status, Games played online get auto claim if it’s a winning ticket.</p>
										</li>
									</ul>
								</li>

								<li><a href="#">What's the maximum amount I can win?</a>
									<ul>
										<li>
											<p>The maximum is FRW 1,000,000. </p>
										</li>
									</ul>
								</li>
								<li><a href="#">How can I withdraw?</a>
									<ul>
										<li>
											<p>You need to register the bank account and on you registered mobile money account.
												You Can only Withdraw your winning balance / Winning amount.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What is the minimum amount I can withdraw?</a>
									<ul>
										<li>
											<p>You can withdraw FRW 1,000 as minimum amount. For Online player its an auto claim and it automatically credited to the player wallet.

											</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What is the maximum amount I can withdraw?</a>
									<ul>
										<li>
											<p>You can withdraw maximum FRW 1,000,000 and if the amount exceeded you need to go to the Head office Claim department.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What's the tax deduction?</a>
									<ul>
										<li>
											<p>All Inzozilotto Prizes are subject to 15% gaming tax deduction.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Can my ticket expire?</a>
									<ul>
										<li>
											<p>If you played your games with POS/Agent/Retailers, your physical receipt/ticket will expire in 14 days. If you played the games online get auto claim if it’s a winning ticket.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">What if I lose my ticket?</a>
									<ul>
										<li>
											<p>Inzozilotto will not be responsible for lost or stolen tickets. We advised you keep your tickets properly and away from moisture, heat and water. To avoid losing your ticket, you can play online. As your ticket selection is securely stored in your wallet, there is no worry over losing your ticket.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Is your service secure? How do I know my personal information is safe?</a>
									<ul>
										<li>
											<p>Absolutely. The security of your tickets is our specialty. Not only does Inzozilotto keep your tickets secure, we keep you secure too. We protect your personal data as well. Your payment/banking information is fully encrypted, and we utilize third-party service providers to ensure the safety of your information.</p>
										</li>
									</ul>
								</li>
							</ul>
						</div>
						:
						<div class="inrContent">

							<h2>Ibibazo Bikunze kubazwa</h2>
							<ul class="faqAccordian">
								<li><a href="#">Inzozi Lotto ni iki?</a>
									<ul>
										<li>
											<p>Carousel Ltd ni cyo kigo cyonyine mu Rwanda cyemerewe gutegura Tombola ku rwego rw’igihugu, kikaba cyarahawe uburenganzira kandi kigenzurwa na minisiteri y’ubucuruzi n’inganda akaba ari nayo igenga  imikino yose y’amahirwe https://www.minicom.gov.rw/ Carousel yatangije Inzozi Lotto mu rwego rwo koroshya serivisi zose zo kugura amatike ku mikino itandukanye ya Tombola.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Inzozi Lotto ikora ite?</a>
									<ul>
										<li>
											<p>Inzozi Lotto ni izina ry’ibicuruzwa byacu byose bikaba biboneka ku rubuga rwa inzozilotto.rw, kuri aplikasiyo ya telephone, ku mbuga nkoranyambaga, ku mbuga zitandukanye z’abafatanyabikorwa bacu ndetse no kuri site zitandukanye twashyizeho mu rwego rwo koroshya icuruzwa ry’amatike ya lotto no gukina imikino ya Tombola.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ninde wemereye kuba yagura itike?</a>
									<ul>
										<li>
											<p>Serivisi zacu zihabwa buri muntu wese wujuje imyaka 18 y’amavuko. Niba uri munsi y’imyaka 18 y’amavuko, urasabwa gukoresha serivisi zacu uherekejwe n’umubyeyi.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute nagura itike? Ese Ngomba kwiyandikisha kugirango mbashe kugura itike?</a>
									<ul>
										<li>
											<p>Yego ni ngombwa kwiyandikisha mbere yo kugura itike. Wemerewe gufunguza konti imwe gusa kuri inzozilotto.rw cyangwa se ugashyira aplikasiyo ya Inzozi Lotto muri telephone yawe kugirango ubashe kwiyandikisha. Usabwa gutanga amazina yawe yombi ndetse n’indi myirodoro ikubiyemo nimero yawe y’indangamuntu, nimero yawe ya telefoni, itariki y’amavuko, email, aho utuye ndetse n’uburyo wahisemo bwo kwishyura. Nyuma yo kwiyandikisha ushobora gukoresha urubuga rwa Inzozi Lotto ndetse ukaba wanabasha kugura amatike ya tombola.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni irihe zina nkoresha kuri konti yanjye? Ese nshobora guhindura iryo zina n’umubare w’ibanga?</a>
									<ul>
										<li>
											<p>Nimero yawe ya telefoni niyo wandika aho usabwa gushyiramo izina rya konti. Iyo umaze kwiyandikisha na nimero ya telefoni yawe ntabwo ushobora kuyihindura. Iyo ukeneye guhindura umubare w’ibanga ukanda kuri link yanditseho guhindura umubae w'ibanga.</p>

										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute nareba kuri konti yanjye?</a>
									<ul>
										<li>
											<p>Ukanda ku kaziga kanditseho konti ukaba wakwinjira muri konti yawe</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ndamutse se nibagiwe izina riri kuri konti cyangwa se umubare w’ibanga ?</a>
									<ul>
										<li>
											<p>Ukanda ahanditse FORGET USER ID hanyuma ukuzuza email yawe mu gasanduku kabugenewe. Uhita ubona ubutumwa kuri email bugusaba guhindura umubare w’ibanga hanyuma ugankandaho ukabasha kuba wahindura umubare w’ibanga.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese nsabwa kwishyura amafaranga kugirango niyandikishe?</a>
									<ul>
										<li>
											<p>Oya kwiyandikisha muri Inzozi Lotto ni ubuntu</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Itike igura amafaranga angahe? Ese nshobora kugura itike irenze imwe?</a>
									<ul>
										<li>
											<p>Amatike ya Inzozi Lotto agura hagati y’amafaranga 100 na 500 y’amanyarwanda. Yego, ushobora kugura itike irenze imwe. Mu rwego rwo guharanira inyungu z’abatugana, hari amafaranga ntarengwa udashobora kurenza ku munsi ugura amatike. Iyo ayo mafaranga ntarengwa wayagejejeho, nta yindi tike wongera kwemererwa kugura kugera amasaha 24 nyuma y’igihe waguriye itike ya nyuma.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese itike yanjye yakoherezwa kuri email?</a>
									<ul>
										<li>
											<p>Yego! Bimenyeshe umukozi wa Inzozi Lotto kuri telephone. Banza urebe neza ko email watanze nta kosa ririmo. Kugirango uhamye neza ko itike waguze iri bukugereho kuri email, twandikire kuri support@inzozilotto.rw hanyuma ukande aho usabwa kwemeza ko Inzozi Lotto ari urubuga rwizewe.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese nsabwa kuba ndi umunyarwanda? </a>
									<ul>
										<li>
											<p>Oya. Ushobora kugura itike ndetse ukanatsindira ibihembo hatitawe ku bwenegihugu bwawe mu gihe wiyandikishije ku rubuga rwacu ukanagura itike ukoresheje murandasi cyangwa se kuri umwe mu ba ajenti bacu. Gusa ni ngombwa kumenya ko ibihembo byose byatsindiwe byoherezwa hose mu Rwanda ariko tutarenga imipaka. Niba watsindiye ibihembo uri hanze y’igihugu ukora k’uburyo byoherezwa kuri aderesi iri mu Rwanda.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni ubuhe buryo bwo kwishyura nakoresha?</a>
									<ul>
										<li>
											<p>Ushobora kugura itike ukoresheje konti za banki zitandukanye cyangwa se Momo. Abatugana baherereye hanze bashobora kwishyura bakoresheje ikarita ya Mastercard cyangwa Visa.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute natangiza ikofi yanjye ya Inzozi Lotto?</a>
									<ul>
										<li>
											<p>Iyo ufunguje konti ya Inzozi Lotto, ikofi yawe nayo ihita ifunguka ukaba watangira gushyiraho amafaranga uhereye ku 100 ry’amanyarwanda nkuko amabwiriza yacu abigena. Usabwa kujya kuri konti yawe ugakanda ahanditse ADD MONEY hanyuma ugahita uburyo wifuza bwo kwishyura n’amafaranga wifuza gushyiraho. </p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute nakina imikino ya Inzozi Lotto?</a>
									<ul>
										<li>
											<p>Ujya kuri paji ibanza hanyuma ugahitamo umukino wifuza gukina, kuri buri mukino hariho amabwiriza akwereka uko ukinwa.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese nshobora koherezwa itike iri ku rupapuro nakiniye ku rubuga rwa Inzozi Lotto?</a>
									<ul>
										<li>
											<p>Oya. Icyakora cyo ushobora kubona ubutumwa kuri email watanze bukumenyesha niba watsinze ndetse bukwereka n’inshuro zose umaze gutsinda.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni ayahe mafaranga make ashoboka nshobora gukinisha?</a>
									<ul>
										<li>
											<p>Ku mikino ya Quick Win ayo utajya munsi ni 100 naho ku mikino ya Lotto ayo utajya munsi ni 500</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute nakurikirana imikino ndi gukina?</a>
									<ul>
										<li>
											<p>Ujya kuri konti yawe hanyuma ugakanda ahanditse TICKET DETAILS</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni ayahe mafaranga menshi ashoboka nakinisha?</a>
									<ul>
										<li>
											<p>Ku mikino ya Quick Win amafaranga ntarengwa ni 10,000 naho kuri Lotto ushobora gukinisha kugeza ku mubare wifuza.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute namenya niba natsinze ? Nakora iki ?</a>
									<ul>
										<li>
											<p>Ni inshingano zacu kukumenyesha buri gihe watsinze ndetse tukanakumenyesha uko wabona ibihembo watsindiye. Ushobora no kujya ku mu ajenti wa Inzozi Lotto uri hafi yawe, ukerekana itike yawe hanyuma ugasaba ibihembo byawe. Ku rubuga rwa Inzozi Lotto, ujya mu gasanduku ko guashakisha ugashyiramo nimero iranga itike waguze hanyuma ukabona ubutumwa bukwereka niba iyo tike yatsinze. Iyo wakiniye ku rubuga ukoresheje murandasi ushobora kwishyuza ibihembo byawe niba itike yawe yatsinze.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni ayahe mafaranga menshi nshobora gutsindira ?</a>
									<ul>
										<li>
											<p>Amafaranga menshi watsindira ni Miliyoni 1 y’amanyarwanda (1, 000,000)</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni gute nabikuza ?</a>
									<ul>
										<li>
											<p>Usabwa gusa kuba ufite konti ya banki cyangwa se konti ya Momo. Ushobora kubikuza amafaranga watsindiye gusa.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni ayahe mafaranga make nabikuza?</a>
									<ul>
										<li>
											<p>Amafaranga utajya munsi ni 1000. Ku wakiniye ku rubuga akoresheje murandasi, amafaranga yongerwa ku ikofi ye ya Inzozi Lotto.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ni ayahe mafaranga menshi ashoboka nabikuza ?</a>
									<ul>
										<li>
											<p>Ushobora kubikuza kugeza kuri Miliyoni 1 (1, 000,000) Iyo arenze Miliyoni imwe, usabwa kugera ku cyicaro cya Inzozi Lotto ugasaba ubufasha.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese hari umusoro wishyurwa ku bihembo?</a>
									<ul>
										<li>
											<p>Ku mafaranga yose yatsindiwe muri Inzozi Lotto, hakurwaho umusoro wa 15% nkuko amategeko agenga iyi imikino abigena.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese itike naguze ishobora kurangira?</a>
									<ul>
										<li>
											<p>Niba waraguze itike unyuze ku mu ajenti wa Inzozi Lotto, igihe ntarengwa cyo kuba wayikoresheje ni iminsi 14. </p>
										</li>
									</ul>
								</li>
								<li><a href="#">Bigenda bite iyo ntaye itike yanjye?</a>
									<ul>
										<li>
											<p>Inzozi Lotto ntabwo yishingira itike yibwe cyangwa yatakaye. Mu rwego rwo kiwirinda ko itike yakwibwa cyangwa igatakara, dushishikariza buri mukinnyi gukinira ku rubuga bitewe nuko itike yawe ibi ibitse kuri telefone cyangwa mudasobwa ntabwo yakwibwa cyangwa ngo itakare.</p>
										</li>
									</ul>
								</li>
								<li><a href="#">Ese serivisi zanyu ziranoze? Ni gute nakwizera ko imyirondoro natanze itazajya hanze?</a>
									<ul>
										<li>
											<p>Kubika itike yawe ni ingenzi kuri twe. Usibye n’itike, imyirondoro yawe ibitse mu buryo bwizewe ndetse dukoresha abafatanyabikorwa mu rwego rwo kubika amakuru mu buryo bwizewe.</p>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					}
				</div>
			</div>
		)
	};
}

function mapStateToProps(state) {
	return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Faq))