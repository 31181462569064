import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginActions } from '../../action/loginAction'
import { gameActions } from '../../action/gamesAction';
import { accountActions } from '../../action/accountAction';
import { history } from '../../helpers/history';
import IdelSessionTimer from "../Pages/IdelSessionTimer";

import LangSelector from '../LangSelector';

import { withTranslation } from 'react-i18next';

class Header extends Component {
	constructor(props) {
		super(props);
		this.submitLogout = this.submitLogout.bind(this);
		this.state = {
			gamesTab: "0",
			homeTab: "1",
			accountTab: "2",
			logoutTab: "3",
			resultTab: "4",
			contactTab: "5",
			howtoplay: "6",
			becomeanagent: "7",
			helpsupport: "8",
			searchTransID: ""
		}
		if (history && history.location) {
			switch (history.location.pathname) {
				case "/":
					this.setPageMenu(0);
					break;
				case "/home":
					this.setPageMenu(1);
					break;
				case "/howtoplay":
					this.setPageMenu(6);
					break;
				case "/result":
					this.setPageMenu(4);
					break;
				case "/becomeanagent":
					this.setPageMenu(7);
					break;
				case "/helpsupport":
					this.setPageMenu(8);
					break;
				case "/pressrelease":
					this.setPageMenu(9);
					break;
				case "/winners":
					this.setPageMenu(10);
					break;
				case "/underconstruction":
					this.setPageMenu(10);
					break;
				case "/instant":
					this.setPageMenu(11);
					break;
			}
		}
		//props.setPageMenu(6);
	}
	submitLogout(e) {
		e.preventDefault();
		this.props.logout();
	}

	setPageMenu = (id) => {
		this.props.setPageMenu(id);
	};
	componentDidMount() {
		window.documentReadyjQuery();
	};

	static getDerivedStateFromProps(props, state) {
		return {
			games: props.games,
			pageMenuID: props.pageMenuID
		};
	};

	searchPrintTicker = () => {
		if (this.state.searchTransID && this.state.searchTransID.trim() != "") {
			this.props.ReprintTicket(this.state.searchTransID.trim(), this.props.user.mobileNo, "-1", this.props.i18n.language);
		}
		else {
			alert(this.props.t('lblEnter') + " Barcode/SlipId");
		}
	};

	render() {
		const { t } = this.props;
		return (
			<div id="myappHeader">
				{this.props.user && this.props.user.mobileNo != "0" && <IdelSessionTimer />}
				<div class="headerBg">
					<div class="container">
						<header className="header">
							<div className="row">
								<div class="col-md-2">
									<div class="logo">
										<Link to="/home"><img src="./assets/img/logo.webp" alt="inzozilotto Logo" /></Link>
									</div>
								</div>

								<div class="signsectIon">
									<LangSelector />
									{this.props.user && this.props.user.mobileNo == "0" &&
										<div class="signSec">
											<div class="signSecinnr">
												<Link to="/login"><i class="fa fa-sign-in" style={{ marginRight: "5px", color: "#fff" }}></i>{t('lblsignin')}</Link>
												<Link to={{ pathname: "/login", state: { isRegister: 1 } }}><i class="fa fa-user-plus" aria-hidden="true" style={{ marginRight: "5px", color: "#fff" }}></i>{t('lblRegister')}</Link>
											</div>
										</div>
									}
									{this.props.user && this.props.user.mobileNo != "0" &&
										<div class="signSec">
											<div class="loginSecinnr">
												<div class="loginSec formbl" style={{ cursor: 'pointer' }} onClick={() => history.push('/account')}>
													<span><a href="#" class="login text-center"><i class="fa fa-user" aria-hidden="true"></i></a></span>
													<div class="userRgt">
														<label style={{ cursor: 'pointer' }}>{t('lblHello')}, {this.props.user.username}</label>
														{this.props.user.mobileNo != "0" ? <small>ID: {this.props.user.mobileNo}</small> : <small></small>}
													</div>
												</div>
												<div class="headCurBlnce">
													<div id="mycurrentBal">
														<span >{t('lblCreditsBal')}</span>
														<span>{this.props.appvariables.currency} {this.props.user.balance}</span>
													</div>
													<div id="mycurrentBal">
														<span>{t('lblWinBal')}</span>
														<span>{this.props.appvariables.currency} {this.props.user.winbalance}</span>
													</div>
												</div>
											</div>
											<div class="logoutSec">
												<a onClick={this.submitLogout}><i class="fa fa-sign-out" style={{ color: "#f1b61c", marginRight: "5px", fontSize: "25px" }} aria-hidden="true"></i></a>
											</div>
										</div>
									}
								</div>

							</div>
						</header>
					</div>
				</div>

				<div class="container">
					<div class="menuSec forBtomMargin bg">
						<div class="hmMenu">
							<ul class="mobilemenu" id="mobilemenu">
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '1' ? 'activeMenu' : ''}`} to="/home" ><span>{t('lblhome')}</span></Link></li>}
								{/* {this.props.user && <li className={`${this.props.pageMenuID == '1' ? 'activeMenu' : ''}`}><Link to="/">Home</Link></li>} */}
								{this.props.user && <li className={`dropdown ${this.props.pageMenuID == '0' ? 'activeMenu' : ''}`}>
									<button class="dropbtn">{t('lblGames')} <i class="fa fa-angle-down" aria-hidden="true"></i></button>
									<div class="dropdown-content">
										{this.props.games && this.props.games.map((x, indx) =>
											<a key={`headergame_${indx}`} onClick={() => this.props.SelectGameFamily(x)} >{x.name}</a>
										)}	
									</div>
								</li>}
							   {/* {this.props.user && <li><Link className={`${this.props.pageMenuID == '11' ? 'activeMenu' : ''}`} to="/instant"><span>{t('lblInstant')}</span></Link></li>}  */}
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '6' ? 'activeMenu' : ''}`} to="/howtoplay"><span>{t('lblHtp')}</span></Link></li>}
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '4' ? 'activeMenu' : ''}`} to="/result" ><span>{t('lblResults')}</span></Link></li>}
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '7' ? 'activeMenu' : ''}`} to="/becomeanagent" ><span>{t('lblBecanagt')}</span></Link></li>}
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '8' ? 'activeMenu' : ''}`} to="/helpsupport" ><span>{t('lblHelpSupt')}</span></Link></li>}
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '9' ? 'activeMenu' : ''}`} to="/pressrelease" ><span>{t('lblPressRelease')}</span></Link></li>}
								{this.props.user && <li><Link className={`${this.props.pageMenuID == '10' ? 'activeMenu' : ''}`} to="/winners" ><span>{t('lblOurWinners')}</span></Link></li>}
								{/* {this.props.user && this.props.user.mobileNo != "0" && <li className={`${this.props.pageMenuID == '3' ? 'activeMenu' : ''}`}> <a onClick={this.submitLogout}>Logout</a></li>} */}
							</ul>
						</div>
						<div class="mobMenu2">
							<a href="#" class="toggleBtn2"></a>
							<div class="mobMenuCont2">
								<div class="quicksearchSec">
									<input maxLength="25" value={this.state.searchTransID} onChange={(e) => this.setState({ searchTransID: e.target.value })} placeholder={`${t('lblEnter')} Barcode/SlipID`} />
									<span class="quicksearchBtn" onClick={() => this.searchPrintTicker()}></span>
								</div>
							</div>
						</div>
						<div class="mobMenu">
							<a class="toggleBtn"></a>
							<div class="mobMenuCont">
								<div class="heading">
									<h4>{t('lblQuickLinks')}</h4>
									<div class="heading-line"></div>
								</div>
								<ul class="mobileMenu1">
									<li><Link to="/home" ><span>{t('lblHome')}</span></Link></li>
									{/* <li><a href="index.html" class="activeMenu"><span>Games</span></a></li> */}
									{this.props.games && this.props.games.map((x, indx) =>
										<li key={`lnkgame_${indx}`}><a onClick={() => this.props.SelectGameFamily(x)} >{x.name}</a></li>
									)}
									{/* <li><Link to="/instant" ><span>{t('lblInstant')}</span></Link></li>  */}
									<li><Link to="/howtoplay" ><span>{t('lblHtp')}</span></Link></li>
									<li><Link to="/result" ><span>{t('lblResults')}</span></Link></li>
									<li><Link to="/becomeanagent" ><span>{t('lblBecanagt')}</span></Link></li>
									<li><Link to="/helpsupport" ><span>{t('lblHelpSupt')}</span></Link></li>
								</ul>
								<ul class="mobileMenu2">
									<li><Link to="/aboutus" >{t('lblAboutus')}</Link></li>
									<li><Link to="/contact" >{t('lblContus')}</Link></li>
									<li><Link to="/termsandcondition" target='_blank'>{t('lblTercon')}</Link></li>
									<li><Link to="/privacypolicy" >{t('lblPripol')}</Link></li>
									<li><Link to="/pressrelease" >{t('lblPressRelease')}</Link></li>
									<li><Link to="/winners" >{t('lblOurWinners')}</Link></li>
									<li><Link to="/faq" >{t('lblFAQ')}</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	};
}

function mapStateToProps(state) {
	const { loggingIn, user, pageMenuID, appvariables } = state.loginReducer;
	const { games } = state.gameReducer;
	return { loggingIn, user, pageMenuID, games, appvariables };
}
const mapActionToProps = {
	logout: loginActions.logout,
	SelectGameFamily: gameActions.SetGameFamily,
	setPageMenu: loginActions.setPageMenu,
	ReprintTicket: accountActions.ReprintTicket,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Header))