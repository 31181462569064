import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginActions } from '../../action/loginAction';
import { alertActions } from "../../action/alertAction";
import { commonClass } from "../../helpers/commonClass";
import { withTranslation } from 'react-i18next';
class Login extends Component {

	constructor(props) {
		super(props);
		this.props.logout();

		if (this.props.location.state && this.props.location.state.isRegister) {
			this.props.setPage(this.props.location.state.isRegister)
		}
		this.state = {
			mobileNo: '',
			password: '',
			submitted: false,

			regFirstName: '',
			regLastName: '',
			regPassword: '',
			regConfirmPassword: '',
			regEmailID: '',
			regMobileNo: '',
			regOtp: '',
			regTC: false,
			regDob: '',
			regSubmitted: false,

			forgotMobileNo: '',
			forgotSubmitted: false,

			otpCode: '',
			otpSubmitted: false,

			regOtpCode: '',
			regOtpSubmitted: false,
			isOtpSendNeed: false,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.submitLogin = this.submitLogin.bind(this);
		this.submitRegister = this.submitRegister.bind(this);
		this.submitForgotPassword = this.submitForgotPassword.bind(this);
		this.submitOtpValidate = this.submitOtpValidate.bind(this);
		this.submitSendOtp = this.submitSendOtp.bind(this);

	}
	static getDerivedStateFromProps(props, state) {
		return {
			pageFlog: props.pageFlog,
			otpMobileNo: props.otpMobileNo
		};
	};
	handleInputChange(e) {
		try {
			let name = e.target.name;
			let value = e.target.value;
			if ((name.toLowerCase().includes("mobile") || name.toLowerCase().includes("otp")) && value && (/^[0-9\b]+$/.test(value)) == false) {
				return;
			}
			if (name == "regDob") {
				value = document.getElementById("datepicker1").value;
			}
			else if (this.state.pageFlog == 1) {
				let dt = document.getElementById("datepicker1").value;
				if (this.state.regDob != dt) {
					this.setState({ regDob: dt });
				}
			}
			if (name == "regTC") {
				//this.setState({ regTC: !regTC }) 
				value = !this.state.regTC;
			}
			this.setState({
				[name]: value
			});
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleInputChange");
		}
	};


	submitLogin(e) {
		try {
			e.preventDefault();
			this.setState({ submitted: true });
			let { mobileNo, password } = this.state;
			if (mobileNo && password) {
				mobileNo = "250" + mobileNo.substring(1, mobileNo.length);
				password = password.replace(/'/g, "''");
				this.props.login(this.props.appvariables.countryCode + mobileNo, password, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitLogin");
		}
	};

	submitRegister(e) {
		try {
			e.preventDefault();
			this.setState({ regSubmitted: true });
			let { regFirstName, regLastName, regPassword, regConfirmPassword, regEmailID, regDob, regMobileNo, regTC, regOtpCode } = this.state;
			let regDobTemp = document.getElementById("datepicker1").value;
			if (regDobTemp != regDob) {
				regDob = regDobTemp;
				this.setState({ regDob: regDobTemp });
			}
			if (regFirstName && regLastName && regPassword && regConfirmPassword && regMobileNo && regDob && regTC) {
				if (this.handleFormValidation(1, regMobileNo) && this.handleFormValidation(2, regEmailID) && this.handleFormValidation(3, regDob)) { }
				else {
					this.showMsg("Must fill all field value with correct format. or else refresh this page and try again.");
					return;
				}
				let ss = this.isDate18orMoreYearsOld(regDob);
				if (ss == false) {
					this.showMsg("lblAgeshouldbe");
					return;
				}
				if (regOtpCode) {
					regMobileNo = "250" + regMobileNo.substring(1, regMobileNo.length);
					regFirstName = regFirstName.replace(/'/g, "''");
					regLastName = regLastName.replace(/'/g, "''");
					regPassword = regPassword.replace(/'/g, "''");
					regEmailID = regEmailID.replace(/'/g, "''");
					regDob = regDob.replace(/'/g, "''");
					regOtpCode = regOtpCode.replace(/'/g, "''");
					this.props.registration(regFirstName, regLastName, regPassword, regEmailID, this.props.appvariables.countryCode + regMobileNo, regDob, regOtpCode, '', '0', this.props.i18n.language);
				}
				else {
					this.showMsg("lblmobileverifymust");
				}
			}
			else {
				if (this.state.regSubmitted)
					this.props.alertError("Must fill all field value with correct format. or else refresh this page and try again.");
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitRegister");
		}
	};

	submitForgotPassword(e) {
		try {
			e.preventDefault();
			this.setState({ forgotSubmitted: true });
			let { forgotMobileNo } = this.state;
			if (forgotMobileNo && this.handleFormValidation(1, forgotMobileNo)) {
				forgotMobileNo = "250" + forgotMobileNo.substring(1, forgotMobileNo.length);
				this.props.forgotPassword(this.props.appvariables.countryCode + forgotMobileNo, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitForgotPassword");
		}
	};

	submitOtpValidate(e) {
		try {
			e.preventDefault();
			this.setState({ otpSubmitted: true });
			let { otpMobileNo, otpCode } = this.state;
			if (otpMobileNo && otpCode && this.handleFormValidation(1, otpMobileNo)) {
				otpMobileNo = "250" + otpMobileNo.substring(1, otpMobileNo.length);
				otpCode = otpCode.replace(/'/g, "''");
				this.props.otpAuthentication(this.props.appvariables.countryCode + otpMobileNo, otpCode, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitOtpValidate");
		}
	};

	submitSendOtp(e) {
		try {
			e.preventDefault();
			this.setState({ regOtpSubmitted: true });
			let { regMobileNo, regEmailID } = this.state;
			if (regMobileNo && this.handleFormValidation(1, regMobileNo)) {
				this.setState({ isOtpSendNeed: true })
				regMobileNo = "250" + regMobileNo.substring(1, regMobileNo.length);
				regEmailID = regEmailID.replace(/'/g, "''");
				this.props.reSendOtp(this.props.appvariables.countryCode + regMobileNo, regEmailID, 0, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitSendOtp");
		}
	};
	showMsg = (msg) => {
		this.props.alertError(this.props.t(msg));
	}
	handleFormValidation(flog, data) {
		try {
			//Phone number          
			//var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
			//var mobPattern = /^\d{10}$/;
			var mobPattern = /^[0]\d{9}$/;
			if (flog == 1 && !mobPattern.test(data)) {
				this.showMsg('lblInvalidMobileNo');
				return false;
			}
			//Email    
			if (flog == 2 && data && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data))) {
				this.showMsg("lblInvalidEmailID");

				return false;
			}
			//DOB           
			var pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/;
			if (flog == 3 && !pattern.test(data)) {
				this.showMsg("lblDateFormatMust");
				return false;
			}
			return true;
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleFormValidation");
			return false;
		}
	};
	componentDidUpdate() {
		window.documentReadyDatePicker();
	};
	componentDidMount() {
		//window.docMapVisiblePwd();
	};

	isDate18orMoreYearsOld = (birthday) => {
		var lst = birthday.split('/');
		// it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
		///var optimizedBirthday = birthday.replace(/-/g, "/");	
		var optimizedBirthday = lst[2] + "/" + lst[1] + "/" + lst[0];
		//set date based on birthday at 01:00:00 hours GMT+0100 (CET)
		var myBirthday = new Date(optimizedBirthday + ' 01:00:00');
		// set current day on 01:00:00 hours GMT+0100 (CET)
		var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
		// calculate age comparing current date and borthday
		var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
		if (myAge < 18) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		const { t } = this.props;
		//const { loggingIn } = this.props;
		const { mobileNo, password, submitted } = this.state;
		const { regFirstName, regLastName, regPassword, regConfirmPassword, regEmailID, regMobileNo, regOtpCode, regDob, regTC, regSubmitted } = this.state;
		const { forgotMobileNo, forgotSubmitted } = this.state;
		const { regOtpSubmitted } = this.state;
		const { otpCode, otpSubmitted } = this.state;
		return (
			<div class="LoginContainer" style={{ minHeight: "400px", height: (this.state.pageFlog == 1 ? "100%" : "100%") }}>
				<div class="loginRgt">
					<div class="loginRgtInner">
						<img src="./assets/img/Login-pagelogo.png" />
					</div>
				</div>
				<div class="loginLft">
					{/* Login Form */}
					{this.state.pageFlog == 0 &&
						<div className="loginContentFrm bounceIn delay-500 fast go" id="userLoginForm">
							<h1>{t('lblLogin')}</h1>
							<div className="userLogRgt">
								<form method="post" name="enqForm" className="enqForm scrollbar">
									<div className="userLogCont">
										<div>
											<input maxLength="10" className="newUserTxt" placeholder={`${t('lblmobno')} *`} type="text" value={this.state.mobileNo} onChange={this.handleInputChange} name="mobileNo" />
										</div>
										{submitted && !mobileNo && <small>{t('lblRequired')}</small>}
										{submitted && mobileNo && (!(/[0]\d{9}$/.test(mobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
									</div>
									<div className="userLogCont">
										<div>
											<input maxLength="10" className="newUserTxt" placeholder={`${t('lblPassword')} *`} type="password" value={this.state.password} onChange={this.handleInputChange} name="password" />
										</div>
										<span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span>
										{submitted && !password && <small>{t('lblRequired')}</small>}
									</div>
								</form>
							</div>
							<div className="userLogContdrbbtn">
								<button className="button buttonBlue" id="loginBtn" onClick={this.submitLogin}>{t('lblLogin')}</button>
							</div>
							<div class="userlogined">
								<a onClick={() => { this.props.setPage(2) }}><span>{t('lblForgetpwd')}?</span></a>
								<a onClick={() => { this.props.setPage(1) }}><span>{t('lblNewMember')}?</span></a>
							</div>
							<div class="homeBtm">
								<a onClick={() => { this.props.loginSkip() }}><i style={{ color: "#f1b61c", fontSize: "20px", marginRight: "5px" }} class="fa fa-home" aria-hidden="true"></i>{t('lblHome')}</a>
							</div>
						</div>
					}
					{/* Register Form */}
					{this.state.pageFlog == 1 &&
						<div class="loginContentFrm bounceIn delay-500 fast go" id="userRegForm">
							<h1>{t('lblSignUp')}</h1>
							<div class="userLogRgt scrollbar">

								<form method="post" name="enqForm" class="enqForm scrollbar">
									<div class="userLogCont">
										<div>
											<input maxLength="20" class="newUserTxt" type="text" placeholder={`${t('lblFirstName')} *`} value={this.state.regFirstName} onChange={this.handleInputChange} name="regFirstName" />
										</div>
										{regSubmitted && !regFirstName && <small>{t('lblRequired')}</small>}
									</div>
									<div class="userLogCont">
										<div>
											<input maxLength="20" class="newUserTxt" type="text" placeholder={`${t('lblLastName')} *`} value={this.state.regLastName} onChange={this.handleInputChange} name="regLastName" />
										</div>
										{regSubmitted && !regLastName && <small>{t('lblRequired')}</small>}
									</div>
									<div class="userLogCont">
										<div>
											<input maxLength="10" class="newUserTxt" type="password" placeholder={`${t('lblPassword')} *`} value={this.state.regPassword} onChange={this.handleInputChange} name="regPassword" />
										</div>
										<span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span>
										{regSubmitted && !regPassword && <small>{t('lblRequired')}</small>}
									</div>
									<div class="userLogCont">
										<div>
											<input maxLength="10" class="newUserTxt" type="password" placeholder={`${t('lblConfirmpwd')} *`} value={this.state.regConfirmPassword} onChange={this.handleInputChange} name="regConfirmPassword" />
										</div>
										<span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span>
										{regSubmitted && !regConfirmPassword && <small>{t('lblRequired')}</small>}
										{regSubmitted && regPassword != regConfirmPassword && <small>{t('lblPasswordMismatch')}</small>}
									</div>
									<div class="userLogCont">
										<div>
											<input maxLength="50" class="newUserTxt" type="text" placeholder={t('lblEmail')} value={this.state.regEmailID} onChange={this.handleInputChange} name="regEmailID" />
										</div>
										{/* {regSubmitted && !regEmailID && <small>Required</small>} */}
										{regSubmitted && regEmailID && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(regEmailID))) && <small>{t('lblInvalidEmailID')}</small>}
									</div>
									<div class="userLogCont">
										<div>
											<input id="datepicker1" maxLength="10" class="newUserTxt " type="text" placeholder={`${t('lblDOB')}(dd/MM/yyyy)*`} value={this.state.regDob} onChange={this.handleInputChange} name="regDob" />
										</div>
										{regSubmitted && !regDob && <small>{t('lblRequired')}</small>}
										{regSubmitted && regDob && (!(/^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/.test(regDob))) && <small>{t('lblInvalidDate')}</small>}
									</div>
									<div class="userLogCont">
										<div>
											<input maxLength="10" class="newUserTxt forCntryCode" type="text" placeholder={`${t('lblmobno')}(${t('lblStartwith')} 0) *`} value={this.state.regMobileNo} onChange={this.handleInputChange} name="regMobileNo" />
											<input class="newUserTxt OtPBtn" type="Button" value={t('lblSendOTP')} onClick={this.submitSendOtp} />
											{/* <input class="newUserTxt OtPBtn" type="Button" value="Send OTP" onClick={() => { this.setState({ isOtpSendNeed: 1 }); }} /> */}
										</div>
										{(regSubmitted || regOtpSubmitted) && !regMobileNo && <small>{t('lblRequired')}</small>}
										{regSubmitted && regMobileNo && (!(/[0]\d{9}$/.test(regMobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
									</div>
									{this.state.isOtpSendNeed && <div class="userLogCont">
										<div>
											<input maxLength="6" class="newUserTxt" type="text" placeholder={`${t('lblOTP')}`} value={this.state.regOtpCode} onChange={this.handleInputChange} name="regOtpCode" />
										</div>
										{regSubmitted && !regOtpCode && <small>{t('lblRequired')}</small>}
									</div>}
									<div class="termsAndCond">
										<input type="checkbox" class="checkbox" value="Terms and Conditions" defaultChecked={this.state.regTC} onChange={this.handleInputChange} name="regTC" />
										<span class="agree">{t('lblTermsAndCondition')}<Link target="_blank" to="/termscondition" style={{ float: "none" }}>{t('lblTermsAndCondition1')}.</Link></span>
									</div>
									{regSubmitted && !regTC && <small style={{ color: "red", marginLeft: "20px" }}>{t('lblRequired')}</small>}
								</form>
							</div>
							<div class="userLogContdrbbtn">
								<button class="button buttonBlue" onClick={this.submitRegister}>{t('lblRegister')}</button>
							</div>
							<div class="userlogined">
								<a style={{ color: "#1d0dee" }} onClick={() => { this.props.setPage(0) }}>{t('lblsignin')}</a>
							</div>

						</div>
					}
					{/* Forgot Password Form */}
					{this.state.pageFlog == 2 &&
						<div class="loginContentFrm bounceIn delay-500 fast go" id="userForgotPwdForm">
							<h1>{t('lblForgetpwd')}</h1>
							<div className="userLogRgt">
								<form method="post" name="enqForm" class="enqForm scrollbar">
									<div class="userLogCont">
										<div>
											<input maxLength="10" class="newUserTxt" type="text" placeholder={`${t('lblmobno')} *`} value={this.state.forgotMobileNo} onChange={this.handleInputChange} name="forgotMobileNo" />
										</div>
										{forgotSubmitted && !forgotMobileNo && <small>{t('lblRequired')}</small>}
										{forgotSubmitted && forgotMobileNo && (!(/[0]\d{9}$/.test(forgotMobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
									</div>
									<div class="userLogContdrbbtn">
										<button class="button buttonBlue" onClick={this.submitForgotPassword} >{t('lblSubmit')}</button>
									</div>

									<div class="userlogined">
										<a className="frgtPsw"><span onClick={() => { this.props.setPage(0) }}>{t('lblsignin')}</span></a>
									</div>
								</form>
							</div>
						</div>
					}
					{this.state.pageFlog == 3 &&
						<div class="loginContentFrm bounceIn delay-500 fast go" id="userOtpForm">
							<h1>{t('lblOTPVerification')}</h1>
							<div className="userLogRgt">
								<form method="post" name="enqForm" class="enqForm scrollbar bounceIn delay-500 fast go">
									<div class="userLogCont">
										<div>
											<label>{t('lblOTP')}</label>
											<input maxLength="6" class="newUserTxt" type="text" placeholder={`${t('lblOTP')} *`} value={this.state.otpCode} onChange={this.handleInputChange} name="otpCode" />
										</div>
										{otpSubmitted && !otpCode && <small>{t('lblRequired')}</small>}
									</div>
									<div class="lottoBtns">
										<button class="redBg signInBtn" onClick={this.submitOtpValidate}>{t('lblSubmit')}</button>
									</div>
									<span style={{ color: "#000" }}>{t('lblClickto')} <a style={{ color: "#1d0dee" }} onClick={() => { this.props.setPage(0) }}>{t('lblsignin')}.</a></span>
								</form>
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { loggingIn, appvariables, pageFlog, otpMobileNo } = state.loginReducer;
	return { loggingIn, appvariables, pageFlog, otpMobileNo };
}

const mapActionToProps = {
	login: loginActions.login,
	loginSkip: loginActions.loginSkip,
	logout: loginActions.logout,
	setPage: loginActions.setPage,
	registration: loginActions.registration,
	forgotPassword: loginActions.forgotPassword,
	otpAuthentication: loginActions.otpAuthentication,
	reSendOtp: loginActions.reSendOtp,
	alertError: alertActions.error,

}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Login))