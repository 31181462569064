import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';

class AboutUs extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    };

    render() {
        const { i18n } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    {i18n.language == "en" ?
                        <div class="privacyCont">
                            <h1>About Us</h1>
                            <p>Rwanda’s 7 year Government program set six specific objectives, where creation of decent jobs for economic development and poverty reduction is one of them. Sport sector has been identified as one of the opportunity to create jobs but also a possible major source of revenues as it is a reality in most developed countries.</p>
                            <p>Carousel LTD is a limited share company, registered in 2018,that has been awarded a ten year license to operate the national lottery in Rwanda.</p>
                            <p>In partnership with the Government of Rwanda through the Ministry of Sports, Carousel is working towards generatingfinancial resources needed to support Sports development programs and sport talents in Rwanda.</p>
                            <p>We created a Commercial brand “ Inzozi Lotto” to drive the business in the market.  </p>
                            <p>You can find us at Nyarugenge, Kigali in CHIC Mall, 2nd Floor, Room F019A, KN 2 Ave Kigali</p>
                            <p>You can reach us at <a href="mailto:info@carousel.rw">info@carousel.rw</a>or call our number 250 788 30 18 24 or +250 788 30 06 74 </p>
                            <p>Our Vision is becoming the biggest sponsors for Rwandan sport talents as they chase their winning dreams and positively impact their economic well-being.</p>
                            <p>Our mission is to, by means of the establishment and operation of a National Lottery,fund and promote sports activities in Rwanda, </p>
                            <p>Our potential market is Rwandans residents of 18 years and above.</p>
                            <p>Our Objectivesare to establish a proven and tested business concept and funding for development but also helped to control and prevent abuse of gambling. This journey is made possible by a strong <b>strategic partnership with <a href="http://www.gitechgames.com/" target="_blank">GI TECH I GAME INDIA PRIVATE LIMITED</a></b> , an experienced and internationally recognized Indian based lottery system operator, present in more than 20 countries in Africa since 2001.</p>
                        </div>
                        :
                        <div class="privacyCont">
                            <h1>Abo turi bo</h1>
                            <p>Gahunda ya guverinoma y’u Rwanda y’imyaka irindwi yashyizeho intego esheshatu nyamukuru harimo guhanga umurimo hagamijwe iterambere ndetse no kugabanya ubukene. Imikino na siporo ni hamwe mu hashobora kuva akazi kandi hakaba hanakurwamo inyungu nkuko bigaragara mu bihugu biteye imbere.</p>
                            <p>Carousel LTD ni ikigo cyashinzwe mu 2018 kikaba cyarahawe uburenganzira bw’imyaka 10 bwo gutegura no kugenzura gahunda ya tombola ku rwego rw’igihugu. Ku bufatanye na guverinoma y’u Rwanda binyuze muri minisiteri ya siporo, Carousel iri gukora igamije guteza imbere gahunda za siporo mu Rwanda ndetse no gushyigikira abafite impano muri siporo zitandukanye. Ni muri urwo rwego twashyizeho ibicuruzwa bya Inzozi Lotto.</p>
                            <p>Wadusanga I Nyarugenge, Kigali mu nyubako ya CHIC kuri etaje ya 2, Icyumba nimero F019A, duherereye kuri KN2 Ave. Wanatwandikira kuri <a href="mailto:info@carousel.rw">info@carousel.rw</a> cyangwa ukaduhamagara kuri 250 788 30 18 24 cyangwa +250 788 30 06 74.</p>
                            <p>Intumbero yacu ni ukuba sosiyete ya mbere mu guteza imbere abanyempano muri siporo mu Rwanda no kubashyigikira kugera ku nzozi zabo no kwiteza imbere.</p>
                            <p>Icyerekezo cyacu ni ugushyiraho no kugenzura gahunda ya tombola ku rwego rw’igihugu, gutera inkunga ibikorwa bya siporo mu Rwanda, tukaba duteganya ko abaguzi ba serivisi zacu ari buri muntu wese wujuje imyaka 18 y’amavuko. </p>
                            <p>Intego zacu ni ugushyiraho bizinesi ikozwe mu buryo bunoze hagamijwe iterambere ari nako dufatanya kugabanya zimwe mu ngaruka zikunze kugaragara mu mikino y’amahirwe. <b>Ibi byose twizeye kubigeraho ku bufatanye na <a href="http://www.gitechgames.com/" target="_blank">GI TECH I GAME INDIA PRIVATE LIMITED</a></b>, sosiyete izobereye mu kugenzura imikino y’amahirwe iherereye mu Buhinde, ikaba ikorera mu bihugu birenga 20 muri Afurika kuva muri 2001.</p>
                        </div>
                    }
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutUs))