import { commonClass } from "../helpers/commonClass"
export const userService = {
	login,
	registration,
	logout,
	forgotPassword,
	changePassword,
	otpAuthentication,
	getBalance,
	reSendOtp,
	saveCustomerComplaint,
	saveAgentRequest
};
//const _apiUrl="http://localhost:2000/api";
//const _apiUrl="http://www.nodetest.com/api";
//const _apiUrl="http://220.225.104.132:8080/api";
const _apiUrl=process.env.REACT_APP_API_URL;

function login(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},
	// 	body: JSON.stringify({"data":data})
	// }; 
	return fetch(`${_apiUrl}/user/login`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"login");
	}
};

function registration(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/register`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"registration");
	}
};

function forgotPassword(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/forgotPassword`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"forgotPassword");
	}
};

function changePassword(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/changePassword`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"changePassword");
	}
};

function otpAuthentication(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/otpAuthentication`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"otpAuthentication");
	}
};

function reSendOtp(data) {
try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/resendOtp`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"reSendOtp");
	}
};


function getBalance(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/getBalance`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"getBalance");
	}
};

function saveCustomerComplaint(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/saveCustomerComplaint`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"saveCustomerComplaint");
	}
};

function saveAgentRequest(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/user/saveAgentDetails`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"saveAgentRequest");
	}
};

function handleResponse_old(response) {
	try{
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				// location.reload(true);
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
catch (ex) {		
	commonClass.writeLog(ex);
}
};

// remove user from local storage to log user out
function logout(data) {
	localStorage.removeItem('user');	
	if(data)
	{
	try{		
		return fetch(`${_apiUrl}/user/logout`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(user => {		
				return user;
			});
		}
		catch (ex) {		
			commonClass.writeLog(ex,"logout");
		}
	}
};