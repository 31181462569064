import { appConstants } from "../helpers/actionType";
import { history } from '../helpers/history';
let allFamily = JSON.parse(localStorage.getItem('allFamily'));
allFamily = allFamily ? allFamily : [];
let cDraw = {};
//if new games ,add here and gamesAction file
function initFamily() {
  let location = history.location.pathname;
  let cFamily = {};
  switch (location) {
    case "/lotto":
      cFamily = allFamily.filter(a => a.id == 4)[0];
      break;
    case "/quickwin":
      cFamily = allFamily.filter(a => a.id == 5)[0];
      break;
    case "/target":
      cFamily = allFamily.filter(a => a.id == 3)[0];
      break;
    case "/igitego":
      cFamily = allFamily.filter(a => a.id == 1)[0];
      break;
    case "/Pick3":
      cFamily = allFamily.filter(a => a.id == 7)[0];
      break;
    case "/Roulette":
      cFamily = allFamily.filter(a => a.id == 8)[0];
      break;
    case "/moto":
      cFamily = allFamily.filter(a => a.id == 9)[0];
      break;
    case "/iphone":
      cFamily = allFamily.filter(a => a.id == 10)[0];
      break;
  }
  return cFamily;
}
//by thamil for fix the session share diff browser tabs with diff games on 24/02/2023
//let cFamily = JSON.parse(localStorage.getItem('cFamily'));
//cFamily = cFamily ? cFamily : {};
//let cDraw = JSON.parse(localStorage.getItem('cDraw'));
//cDraw = cDraw ? cDraw : {};

const initialState = { games: allFamily, currentGamesFamily: initFamily(), currentAvailableDraws: [], currentDraw: cDraw, serverTime: 0, latestResult: [], casinoGames: [] };
export const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.GET_GAMEFAMILY_SUCCESS:
      return {
        ...state,
        games: action.games
      };
    case appConstants.GET_GAMEFAMILY_ERROR:
      return { ...state };
    case appConstants.SET_GAMEFAMILY:
      return {
        ...state,
        currentGamesFamily: action.gFamily
      };

    case appConstants.GET_AVAILDRAWS_SUCCESS:
      return {
        ...state,
        currentAvailableDraws: action.currentAvailableDraws
      };
    case appConstants.GET_AVAILDRAWS_ERROR:
      return {
        ...state,
        currentAvailableDraws: []
      };

    case appConstants.BUY_SUCCESS:
      return {
        ...state,
        myBet: action.myBet
      };
    case appConstants.BUY_ERROR:
      return {
        ...state,
        myBet: null
      };

    case appConstants.GET_LATEST_RESULT_SUCCESS:
      return {
        ...state,
        latestResult: action.lResult
      };
    case appConstants.GET_LATEST_RESULT_ERROR:
      return {
        ...state,
        latestResult: null
      };
    case appConstants.GET_CASINO_GAMES:
      return {
        ...state,
        casinoGames: action.casinoGame
      };
    //create new reducer for servertime
    // case appConstants.GET_SERVERTIME_SUCCESS:                        
    // return {
    //   ...state,
    //   serverTime:action.serverTime
    // };     
    // case appConstants.GET_SERVERTIME_ERROR:
    //   return {...state};        

    default:
      return { ...state }
  }

}