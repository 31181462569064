import React, { Component } from 'react'
import { connect } from 'react-redux';
import { quickWinActions } from '../../../action/quickWinAction';
import { withTranslation  } from 'react-i18next';
class QuickWinRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stake: 0
        }
    }

    setUpdateStake = (stake) => {
        this.setState({
            stake: stake
        })
        this.props.UpdateStakeAll(stake);
    };

    updatesStakeValue = (bid, flog) => {
        if (this.state.cartItems != null) {
            let lst = this.state.cartItems.filter(a => a.id == bid);
            if (lst != null && lst != undefined && lst.length > 0) {
                lst = lst[0];
                if (flog == "+") {
                    if (lst.stakeValue + lst.multiStake > lst.maxStake){
                    alert(this.props.t('lblMaximumstake')+" : "+ lst.maxStake);
                        return;
                    }
                    else
                        lst.stakeValue = lst.stakeValue + lst.multiStake;
                }
                else if (flog == "-") {
                    if (lst.stakeValue - lst.multiStake <  lst.minStake){                        
                    alert(this.props.t('lblMinimumstake')+" : "+lst.minStake);
                        return
                    }
                    else
                        lst.stakeValue = lst.stakeValue - lst.multiStake;
                }
                lst.total = lst.stakeValue * lst.oddsValue;
                this.props.UpdateCartAll(lst);
            }
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        };
    }
    render() {
		const { t } = this.props;
        return (
            <div>
                <div className="col-md-4">
                    <div class="quickbetslip">
                        <h2>{t('lblgameslip')}</h2>                       
                        {this.state.cartItems && this.state.cartItems.length<=0 && <div className="betError2">
                    <h2 className="warningMsg bounceIn animated infinite">{t('lblplcurbet')}</h2>
                </div>}
                        <div className="quickbetSlip2 scrollbar">
                      
                            <ul className="betSlipCont" style={{ width: "100%" }}>
                                {this.state.cartItems && this.state.cartItems.map((x, indx) =>
                                    <li className="toggle" key={`bet_${indx}`}>
                                        <div class="betSlipLi">
                                            <div class="betValue2"><span class="circle">{x.ballNo}</span></div>
                                            <div class="drawNumber"><span>#{x.drawNo}</span></div>
                                            <div class="betValueEntr">
                                                <div class="betValueEntrCont">
                                                    <span class="quickminusSign" onClick={() => { this.updatesStakeValue(x.id, "-") }}>-</span>
                                                    <input readOnly={true} class="betValueInput" type="textbox" value={x.stakeValue} />
                                                    <span class="quickplusSign" onClick={() => { this.updatesStakeValue(x.id, "+") }}>+</span>
                                                </div>
                                            </div>
                                            <div class="betValueEntr">
                                                <div class="betValueEntrCont">
                                                    <span class="betValueEntr2Lft">{x.total}</span>
                                                    <span class="quickbetValueEntr2Rht">{x.oddsValue}<small>X</small></span>
                                                </div>
                                            </div>
                                            <div class="betClose2">
                                                <a class="betCloseBtn2" onClick={() => { this.props.removeFromCart(x.id) }}><i class="fa fa-times" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </li>
                                )}

                            </ul>
                        </div>

                        <div class="countArea">
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotbet')} : {this.state.cartItems.length}</span>
                            </div>
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotstak')} : {this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0)}</span>
                            </div>
                        </div>
                        <div class="quickBtns">
                            <button class="redBg" onClick={() => { this.props.clearCart() }}>{t('lblclear')}</button>
                            <button class="orangeBg" onClick={() => { this.props.buyLotteryBet() }}>{t('lblBuy')}</button>
                        </div>
                        {/* <div class="enterBetValBtom border-color background-color">
                            <span class="border-color-right">ENTER STAKE VALUE</span>
                            <input type="text" placeholder="0" value={this.state.stake} onChange={(e) => this.setUpdateStake(e.target.value)}/>
                        </div> */}
                    </div>

                </div>

                <div class="col-md-4">
                    <div class="latestRslt">
                        <h2>{t('lbllatresult')}</h2>
                        <ul class="targetrecentRsltCont scrollbar">
                            {this.props.latestResult ? this.props.latestResult.map((x, indx) =>
                                <li key={`lstrsl_${indx}`}>
                                    <div class="drawNoTime">
                                        <div class="drawNo"><span>#{x.DrawNo}</span></div>
                                        <div class="drawTime"><span>{x.Drawdatetime}</span></div>
                                        <div class="drawCount"><span class="circle">{x.WinningNo}</span></div>
                                    </div>
                                </li>) :
                                  <li>
                                  <div class="betSlipRHt"><div class="betSlipCont betSlipContlastRst" style={{ color: "#000" }}>{t('lblnorecordfound')}</div></div>
                              </li>
                                 }
                        </ul>
                        {/* <div class="viewAllBets border-color background-color">
                        <Link to="/result">View All Results</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { cartItems } = state.quickWinReducer;
    return { user, cartItems };
}

const mapActionToProps = {
    UpdateStakeAll: quickWinActions.UpdateStakeAll,
    UpdateCartAll: quickWinActions.UpdateCartAll,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(QuickWinRight))