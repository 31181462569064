import {appConstants} from "../helpers/actionType";
export const lottoReducer=(state={cartItems:[],currentDraw:{}},action)=>{
    switch(action.type)
    {
        case appConstants.ADD_CARTITEM:                        
          return {
            ...state,
            cartItems:[...state.cartItems, action.cItem]
          };                
          case appConstants.REMOVE_CARTITEM:                        
          return {
            ...state,
            cartItems:[...state.cartItems.filter(a=>a.id !=action.cid)]
          };     
          case appConstants.REMOVE_CLOSEDDRAW_CARTITEM:                        
          return {
            ...state,
            cartItems:[...state.cartItems.filter(a=>a.drawID !=action.did)]
          };                
          case appConstants.SET_DRAW:                        
          return {
            ...state,
            currentDraw:action.cDraw
          };   
          case appConstants.CLEAR_CARTITEM:                        
          return {
            ...state,
            cartItems:[]
          };      
        default:
          return {...state}
    }

}