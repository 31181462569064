import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../../Header/Header";
import { gameActions } from '../../../action/gamesAction';
import { loginActions } from '../../../action/loginAction';
import { withTranslation } from 'react-i18next';
var CryptoJS = require("crypto-js");
var popUpObj=null;
class Instant extends Component {
    constructor(props) {
        super(props);
      
        this.props.GetCasinoGames(this.props.user.mobileNo,1);
      }
      
    componentDidMount() {
        window.scrollTo(0, 0);
    };
    static getDerivedStateFromProps(props, state) {
        return {
            casinoGames: props.casinoGames,
        }
    };
    ecry = (data) => {
        var password = 'U2FsdGVkX1/dTA00hg0swnksKTyUdhPWxhPpY1CEqEs=';
        var encrypted = CryptoJS.AES.encrypt(data, password);
        return encrypted.toString() + "~" + password;
    };
    showModalPopUp = (url, no, key, _categoryID) => {
        if (this.props.user==undefined || this.props.user.mobileNo == "0") {
            alert(this.props.t('lblLoginValidation'));
            return;
        }

        var type = 1; // indicate demo or money game.
        var loginKey = this.props.user.loginKey; // this key get at the time of login.
        var userID = this.props.user.mobileNo;

        if (url.indexOf("?") == -1 && url.indexOf("html#") == -1) {
            var datastr = type + "~" + userID + "~" + key + "~" + loginKey + "~" + this.props.appvariables.currency + "~rwanda~" + this.props.appvariables.currency + "~" + 1 + "~" + 1 + "~" + _categoryID;
            var data = this.ecry(datastr);
            url = url + "?data=" + data;
        }
        else if (url.indexOf("html#") != -1) // for flash games.
        {
            var isMobile = window.orientation > -1;
            var ss = isMobile ? 0 : 1;
            var datastr = this.ecry(userID + "~" + loginKey) + "#" + ss;
            url = url + "data=" + datastr;
        }
        //Comment by thamil for open the slat game with same tab.
        //popUpObj = window.open(url,key);
        if (popUpObj != null) { popUpObj.close(); }
        //For restruct multiple tabs
        //popUpObj = window.open(url, "SulivebetingSlotGames");
        popUpObj = window.open(url, userID);
        popUpObj.focus();
        var _mobNo=this.props.user.mobileNo
       var _loginkey=this.props.user.loginKey;
       var _lang=this.props.i18n.language;       
       var pmethod=this.props;
        var timer = setInterval(function () {
            if (popUpObj.closed) {
                clearInterval(timer);
                pmethod.getBalance(_mobNo, _loginkey, _lang);
               // document.location.reload();
            }
        }, 500);
    };


    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    <div class="inrContainer">
                        <ul class="promotion scrollbar">
                            {this.state.casinoGames && this.state.casinoGames.map((x, inx) =>
                                <li key={'cas_' + inx} onClick={() => { this.showModalPopUp(x.GameUrl, 1, x.GameTypeID, x.CategoryID) }}><span>{x.GameTypeName}</span>
                                    <a><img src={`./assets/img/casino/${x.GameIcon}`} /></a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { appvariables, user } = state.loginReducer;
    const { casinoGames } = state.gameReducer;
    return { appvariables, user,casinoGames }
}

const mapActionToProps = {
    GetCasinoGames:gameActions.GetCasinoGames,
    getBalance: loginActions.getBalance,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Instant))