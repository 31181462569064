import { commonClass } from "..//helpers/commonClass"
export const gameService = {
	GetGameFamily,
	GetAvailableGames,
	GetServerTime,
	GetLatestResult,
	LotteryOnlineSale,
	GetCasinoGames
};
//const _apiUrl="http://localhost:2000/api";
//const _apiUrl="http://www.nodetest.com/api";
//const _apiUrl="http://220.225.104.132:8080/api";
const _apiUrl = process.env.REACT_APP_API_URL;

function GetGameFamily(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/games/GameFamily`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				if (games != null && games.data != null) { games = games.data; }
				if (games != null && games.startsWith("0~")) {
					gamefamily = [];
					games = games.replace("0~", "");
					let strArr = games.split('|');

					strArr.forEach(elementobj => {
						if (elementobj !== "") {
							let element = elementobj.split('~');
							let minSt=500;
							let maxSt=10000;
							let prizeSt="";
							if(element.length>6)
							{
								minSt=parseInt(element[5]);
								maxSt=parseInt(element[6]);
								if(element.length>7)
							{
								prizeSt=element[7];
							}
							}			

							gamefamily.push(
								{
									id: element[0],
									name: element[1],
									ball: parseInt(element[2]),
									minSelect: parseInt(element[3]),
									maxSelect: parseInt(element[4]),
									minStakePerTrans: minSt,
									maxStakePerTrans: maxSt,
									maxStakePerTrans: maxSt,
									prizeInfo:prizeSt,
									extraBall: 0
								}
							);
						}
					});

				}

				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAvailableGames(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/games/GetAvailableGames`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				if (games != null && games.data != null) { games = games.data; }
				gamefamily = games;
				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetLatestResult(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/quickwin/latestResult`, commonClass.requestOptions(data))
			 .then(commonClass.handleResponse)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};


function GetServerTime(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// };
		return fetch(`${_apiUrl}/games/GetServerTime`, commonClass.requestOptions())
			 .then(commonClass.handleResponse)
			.then(serverTime => {
				return serverTime.data;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function LotteryOnlineSale(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/games/onlineSale`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				if (games != null && games.data != null) { games = games.data; }
				gamefamily = games;
				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};
function GetCasinoGames(data) {
	try {	
		return fetch(`${_apiUrl}/games/GetCasinoGames`, commonClass.requestOptions(data))
			 .then(commonClass.handleResponse)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};



function handleResponse_old(response) {
	try {
		return response.text().then(text => {
			const data = text && JSON.parse(text);
			if (!response.ok) {
				if (response.status === 401) {
					// auto logout if 401 response returned from api
					//logout();
					// location.reload(true);
				}
				const error = (data && data.message) || response.statusText;
				return Promise.reject(error);
			}
			return data;
		});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}