import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class AccountHistory extends Component {

    constructor(props) {
        super(props);
    }

    getRecord = (flg) => {
        if (flg != 1) {
            this.state.startDate = document.getElementById("datepicker2").value;
            this.state.endDate = document.getElementById("datepicker3").value;
        }
        let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate)
        if(_flog)
        this.props.GetAccountHistory(this.props.user.mobileNo, this.state.startDate, this.state.endDate,this.props.i18n.language);
    };
    reSetFilter() {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            AccountHistory: props.AccountHistory,
        }
    };

    componentDidMount() {
        window.documentReadyDatePicker();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };


    render() {
        //window.applyRptAccordion();
        const { t } = this.props;
        return (
            <section id="cTab3" class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblAccountDetails')}</h2>
                    <div class="selRslt">
                        <ul>
                            <li class="dateCont">
                                <div class="selRsltCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate white" type="text" value={this.state.startDate} id="datepicker2" name="startDate" placeholder="2017-06-10" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="selRsltCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="endDate white" type="text" value={this.state.endDate} id="datepicker3" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="resetFtrBtn" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                                <a href="#" class="relaodBtn" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>
                    {window.innerWidth > 800 ?
                        (<div class="booking">
                            <div class="bookLiHead">
                                <table>
                                    <tr>
                                        <th className='Col13'>{t('lblTransID')}</th>
                                        <th className='Col10'>{t('lblAmount')}</th>
                                        <th className='Col12'>{t('lblTransDate')}</th>
                                        <th className='Col14'>{t('lblType')}</th>
                                        <th className='Col12'>{t('lblStatus')}</th>
                                    </tr>
                                    {this.state.AccountHistory && this.state.AccountHistory.status == 0 && this.state.AccountHistory.data.map((x, idx) =>
                                        <tr key={'arpt_' + idx}>
                                            <td>{x.TransId}</td>
                                            <td>{x.AddmoneyAmt}</td>
                                            <td>{x.Createddatetime}</td>
                                            <td>{x.TransType}</td>
                                            <td>{x.TransDesc}</td>
                                        </tr>
                                    )}
                                    {this.state.AccountHistory && this.state.AccountHistory.status != 0 &&
                                        <tr>
                                            <td colspan="5">{this.state.AccountHistory.msg}</td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>)
                        :
                        (
                            <div class="lftsportsRprt borColor3">
                                <div class="teamListfoRMob">
                                    <div class="teams">
                                        <ul class="teamListRprt background-color3 scrollbar3 borColor3">
                                            {this.state.AccountHistory && this.state.AccountHistory.status == 0 && this.state.AccountHistory.data.map((x, idx) =>
                                                <li onClick={() => { this.state.isActiveID != idx ? this.setState({ isActiveID: idx }) : this.setState({ isActiveID: -1 }) }} key={'amrpt_' + idx}><a ><div class="countryName">ID : {x.TransId} | {x.TransType}</div></a>
                                                    <ul class="teamListRprtLi" style={{ display: this.state.isActiveID == idx ? "block" : "none" }}>
                                                        <li><a >{t('lblAmount')} : {x.AddmoneyAmt}</a></li>
                                                        <li><a >{t('lblTransDate')} : {x.Createddatetime} </a></li>
                                                        <li><a >{t('lblStatus')} : {x.TransDesc}</a></li>
                                                    </ul>
                                                </li>)
                                            }
                                            {this.state.AccountHistory && this.state.AccountHistory.status != 0 &&
                                                <li> <a><div class="countryName">{this.state.AccountHistory.msg}</div></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    const { AccountHistory } = state.accountReducer;
    const { user } = state.loginReducer;
    return { AccountHistory, user };
}

const mapActionToProps = {
    GetAccountHistory: accountActions.GetAccountHistory,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AccountHistory))