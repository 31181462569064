import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { alertActions } from '../../../action/alertAction';
import { withTranslation  } from 'react-i18next';
//import { commonClass } from "../../../helpers/commonClass"
class Withdraw extends Component {

	constructor(props) {
		super(props);
		this.state = {
			amount: 0
		};
	}
	submitWithdraw = () => {
		if (this.state.amount > 0) {
			var sTime = new Date(this.props.serverTime);
			let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;			
			this.props.WithdrawMoney(this.props.user.mobileNo, this.state.amount, TransID,this.props.i18n.language);
			this.clearWithdraw();
		}
		else {
			this.props.error(this.props.t('lblInvaildWithdrawAmt'))
		}
	};

	clearWithdraw = () => {
		this.setState({
			amount: 0
		});
		this.state.amount = 0;
	};
	render() {
        const { t } = this.props;
		let _addUrl=`${process.env.REACT_APP_PG_URL}/Paymode/Withdraw?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`;
		//let _edata=commonClass.Encryption(`player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`)
        //let _addUrl = `${process.env.REACT_APP_PG_URL}/Paymode/Withdraw?data=${_edata}`;
        return (
			<section class="tabCont">
				<div class="Ourservinerpg">
				<h2>{t('lblWithdrawal')}<small>{t('lblInzoziVoucher')}</small></h2>
					<div class="account">
						<div class="Voucher">
							<span class="amntBg ActiveamntBg"><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.winbalance}</span>
						</div>
						<div class="accountinr">
							<input type="text" maxLength="8" placeholder={`${t('lblAmount')} *`} value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })} />
						</div>
						<div class="accountBtn">
							<a class="blueBg" onClick={() => { this.submitWithdraw() }}>{t('lblWithdrawal')}</a>
						</div>
					</div>
					<h2><small>{t('lblOtherMethods')}</small></h2>
					<div class="Payment">
					<a href={_addUrl+"|paytype_id~1"}  target="_self"><img src="./assets/img/bank.png" />  </a>           
                    </div>
				</div>
			</section>
		)
	}
}

function mapStateToProps(state) {
	const { WithdrawMoney } = state.accountReducer;
	const { user , appvariables} = state.loginReducer;
	//const { serverTime } = state.gameReducer;
	const {serverTime } = state.commonReducer;
	return { WithdrawMoney, user, serverTime, appvariables };
}

const mapActionToProps = {
	WithdrawMoney: accountActions.WithdrawMoney,
	error: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Withdraw))