import { commonClass } from "..//helpers/commonClass"
export const accountService = {
	getbetHistory,
	getAccountHistory,
    addMoney,
    withdrawMoney,
	getAccountSummary,
	getResultReport,
	getAccountActivity,
	ReprintTicket,
	getUserProfile
};
//const _apiUrl="http://localhost:2000/api";
//const _apiUrl="http://www.nodetest.com/api";
//const _apiUrl="http://220.225.104.132:8080/api";
const _apiUrl=process.env.REACT_APP_API_URL;

function getbetHistory(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getBetBistory`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getAccountHistory(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getAccountHistoryReport`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function addMoney(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/addMoney`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function withdrawMoney(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/withdrawMoney`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getAccountSummary(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getAccountSummary`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getResultReport(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getResultReport`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getAccountActivity(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getAccountActivityReport`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function ReprintTicket(data) {
	try{
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ "data": data })
    // };
    return fetch(`${_apiUrl}/account/getReprintTicket`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getUserProfile(data) {
	try{
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ "data": data })
    // };
    return fetch(`${_apiUrl}/account/getUserProfile`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function handleResponse_old(response) {
	try{
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				//auto logout if 401 response returned from api
				//logout();
				//location.reload(true);
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
catch (ex) {		
	commonClass.writeLog(ex);
}
};
