import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';
//import { loginActions } from '../../action/loginAction';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    };

    render() {
        const { i18n } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    {i18n.language == "en" ?
                        <div class="privacyCont">
                            <h1>Privacy Policy</h1>
                            <h2>Privacy Notice</h2>
                            <p>We know that you care about how your information is used and shared, and we, Carousel Ltd. (http://carousel.rw/, “the Company,” “we,” “our,” “us”) care about your privacy. Carousel Ltd through its product inzozilotto.rw. (the “product”) is the sole company authorized to operate the national lottery, licensed and regulated by the Ministry in charge of the gaming activitieshttps://www.minicom.gov.rw/.</p>
                            <p>This Privacy Notice explains how inzozilotto.rw collects, uses, and stores some of your personal data (excluding special categories of personal data and data relating to criminal convictions and offenses) through the use of our website, app, and services (“Product(s)”). This notice also outlines how we protect your personal data when you visit our Products (regardless of where you visit it from) and informs you of your privacy rights and how the law protects you. Please see the “Definitions” portion of this notice to understand many of the terms used throughout.</p>
                            <p></p>
                            <h2>1. PURPOSE OF THIS NOTICE AND WHO WE ARE</h2>
                            <h3>The Purpose of This Notice</h3>
                            <p>We provide this Privacy Notice explaining our online information practices and the choices you can make about the way your Personal Information is collected and used in connection with our Products and certain services that we offer through or in connection with our Products. “Personal Information” is defined below in the Definitions Section. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
                            <p>By using our Products, you consent to the terms of this Privacy Notice and to the processing of Personal Information for the purposes set forth herein. If you do not agree to this Privacy Notice, please do not use our Products.</p>
                            <h2>Our Contact Details</h2>
                            <p>If you have any concern about our privacy practices, our use and disclosure practices, your consent choices, or if would like to exercise your rights, please contact us by email at support@inzozilotto.rw, or write to us at:
                                Carousel Ltd. (http://carousel.rw/)
                                CHIC Mall, 2nd floor, Romm F019A
                                Po.Box. 6035, Kigali, Rwanda

                            </p>
                            <h2>Third Parties</h2>
                            <p>This Product may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our Product, we encourage you to read the privacy notice of every website you visit.</p>
                            <h2>2. THE INFORMATION WE COLLECT WHEN YOU USE OUR PRODUCTS</h2>
                            <h3>Information that You Provide</h3>

                            <p>• Registration and Profile Information includes identity data, such as first name, last name, phone number, ID or Passport Number, email, and date of birth.</p>
                            <p>• Financial Information includes payment cards and bank information or other service provider information necessary to transfer and receive funds.</p>
                            <p>• Marketing and Communications that you initiate with us, such as contacting our Customer Care team, may result in us receiving additional information such as your the contents of a message or attachments that you may send us, and other information you may choose to provide. If you choose to opt-out or unsubscribe from any marketing emails, we will also have your marketing and communications preferences.</p>
                            <p>• Career Information. In the event that you apply for a career with us, you may submit your contact information and your resume online. We will collect the information you choose to provide on your resume, such as your education and employment experience.</p>

                            <h2>Information We Collect When You Use Our Products</h2>
                            <p>•	Location Information. When you use our Products, we receive your general location information (for example, your internet protocol (“IP”) address may indicate your more general geographic region).</p>
                            <p>•	Device Information. Access of your device may or may not require, which user need to authenticate at his/her end.We receive information about the device and software you use to access our Products, including IP address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens.</p>
                            <p>•	Usage Information includes information about how you use our Products.</p>
                            <p>•	Information We Infer. We may derive information or draw inferences about you based on information we collect about you. For example, based on your browsing or purchasing activities, we may infer your purchasing preferences.</p>
                            <p>•	Information from Cookies and Similar Technologies. We and third-party partners collect information using cookies, pixel tags, or similar technologies. Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files that are stored on your device. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Products. Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies, some of the features may become inaccessible or not function properly.</p>

                            <h2>3. HOW WE COLLECT YOUR DATA</h2>
                            <p>We collect data about you using various methods including but not limited to:</p>
                            <p>•	Automated interactions and/or technologies – As you interact with our Products, some technical data is automatically collected about your browsing, browsing patterns, and equipment. This data is collected through such technologies as log files and cookies.</p>
                            <p>•	Direct interactions – You may provide us with some personal information when you communicate with us by email, phone, or otherwise. This includes personal data you provide when you: create an account in our Products; verify your account; receive payments from us (for lottery winnings) or make purchases with us; make a donation; send us an email or “contact us” request; request marketing to be sent to you; enter a sweepstakes, promotion, or survey; or provide us with feedback.</p>
                            <p>•	Third Parties - We may receive personal data about you from various third parties and public sources, like social media. You may engage with our content, such as video, lottery games, applications, and other offerings. When you engage with our content on or through social networking sites, plug-ins or applications, you may allow us to have access to certain information from your public social media profile or as part of the operation of the application.</p>
                            <h2>4. HOW WE USE THE DATA WE COLLECT</h2>
                            <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
                            <p>When we need to comply with a legal or regulatory obligation.</p>
                            <p>When we need to perform the contract we are about to enter into or have entered into with you.</p>
                            <p>When it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</p>
                            <p>Generally, we do not rely on consent as a legal basis for processing your personal data other than in relation to sending direct marketing communications to you via push notifications, email, text message, or phone. You have the right to withdraw consent to marketing at any time by contacting us at <a href="support@inzozilotto.rw"> support@inzozilotto.rw.</a></p>
                            <p>Your data is used, however, usage is not limited to the following ways:</p>
                            <ul class="marleft">
                                <li>•	To set up your account and service our Products</li>
                                <li>•	To verify your identity and authenticate your access to our Products</li>
                                <li>•	To process and deliver your purchases, including the management of payment to and from you</li>
                                <li>•	To prevent money laundering, fraud, and other illegal activity</li>
                                <li>•	To provide you with support and troubleshooting assistance</li>
                                <li>•	To communicate with you and keep you apprised of our updates</li>
                                <li>•	To market our Products</li>
                                <li>•	To perform analytics and research to improve our Products and the Products of our partners</li>
                                <li>•	To ensure enforcement of our policies, to investigate any violations, and to comply with laws, government authorities, or similar legal entities, requirements, or processes.</li>

                            </ul>
                            <h2>5. DISCLOSURE OF YOUR PERSONAL DATA TO THIRD PARTIES</h2>
                            <p>We do not sell, rent, or share your Personal Information with third parties except as described in this Privacy Notice:</p>
                            <ul class="marleft">
                                <li>•	Affiliates and subsidiaries - We may disclose your information with our affiliates and subsidiaries for any of the purposes described in this Privacy Notice.</li>
                                <li>•	Vendors and Service Providers - We may share any information we receive with vendors and service providers retained in connection with the provision of our Products.</li>
                                <li>•	Third-Party App Integrations - If you connect a third-party application to our Products, we may share information with that third party.</li>
                                <li>•	Advertising Partners. We may work with third-party advertising partners to show you ads we think may interest you. These advertising partners may set and access their own cookies, pixel tags and similar technologies on our Products and they may otherwise collect or have access to information about you which they may collect over time and across different websites and online services. You can also access any settings offered by your mobile operating system to limit ad tracking, or you can install the AppChoices mobile app to learn more about how you may opt-out of personalized ads in mobile apps.</li>
                                <li>•	As Required By Law and Similar Disclosures. We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (i) comply with law enforcement requests and legal process, such as a court order or other lawful requests by public authorities, including to meet national security or law enforcement requirements; (ii) respond to your requests; or (iii) protect your, our, or others’ rights, property, or safety. For the avoidance of doubt, the disclosure of your information may occur if you post any objectionable content on or through our Products.</li>
                                <li>•	Merger, Sale, or Other Asset Transfers. We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets. The use of your information following any of these events will be governed by the provisions of this Privacy Notice in effect at the time the applicable information was collected.</li>
                                <li>•	Consent. We may also disclose your information with your permission or at your direction.</li>

                            </ul>
                            <h2>6. YOUR LEGAL RIGHTS</h2>
                            <p>You have a right to request access to and receive information about the Personal Information we maintain about you, update and correct inaccuracies in your Personal Information, restrict or object to the processing of your Personal Information, have the information anonymized or deleted, as appropriate, or exercise your right to data portability to easily transfer your Personal Information to another company. In addition, you may also have the right to lodge a complaint with a supervisory authority. If you wish to access or amend any Personal Information we hold about you. In addition, you may have the right to withdraw any consent you previously provided to us regarding the processing of your personal information, at any time and free of charge. We will apply your preferences going forward and this will not affect the lawfulness of the processing before your consent withdrawal.</p>
                            <p>You have the right to withdraw any consent you previously provided to us regarding the processing of your Personal Information, at any time and free of charge. We will apply your preferences going forward and this will not affect the lawfulness of the processing before your consent withdrawal. If you would like to exercise any of these rights, you may contact us ”inserter the link”.</p>
                            <h2>7. DATA SECURITY</h2>
                            <p>The security of your personal information is important to us. We follow generally accepted industry standards, including the use of appropriate administrative, physical, and technical safeguards, to prevent your personal data from being accidentally lost, used, altered, disclosed, or accessed in an unauthorized way. In addition, we limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                            <p>To the extent that we collect sensitive personal data from you (for example, your bank account number or other financial personal information), we use SSL encryption to protect that information. However, no method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. If you have any questions about the security of our Products, you may contact us ”inserter the link”.</p>

                            <p>We have put procedures in place to deal with any suspected personal data breach and will always notify you and any applicable regulator of a breach where we are legally required to do so.</p>
                            <h2>8. DATA RETENTION</h2>
                            <p>We store the information we collect about you for as long as is necessary for the purposes for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law. When determining the retention period, we take into account various criteria, such as the type of products and services requested by or provided to you, the nature and length of our relationship with you, the impact on the services we provide to you if we delete some information from or about you, mandatory retention periods provided by law, and the statute of limitations.</p>
                            <p>We will take steps to maintain an adequate level of protection for this information and guaranty you your data will not be hosted outside Rwanda.</p>
                            <p>We may rectify, replenish or remove incomplete or inaccurate information at any time and at our own discretion.</p>
                            <p>In some circumstances, we may anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use such information indefinitely without further notice to you.</p>

                            <h2>9. NOTICE OF CHANGES</h2>
                            <p>We will continue to evaluate this Privacy Policy against new technologies, business practices and our users’ needs, and may make changes to the Privacy Policy accordingly. Please check this page periodically for updates. You acknowledge that your continued use of our Products after the posting of any changes to this Privacy Policy means that you agree to be bound by such change.</p>
                            <h2>10. DEFINITIONS</h2>
                            <p>Personal Information means any information relating to an identified or identifiable data subject that may be used, either alone or in combination with other information, to personally identify an individual.</p>
                            <p>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best and most secure services, product, and experience. We always consider your rights and weigh any potential impact on you (both positive and negative) before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you, unless we have your consent or are otherwise required or permitted to by law.</p>

                            <p>Performance of Contract means processing your information when necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
                            <p>Comply with a legal or regulatory obligation means processing your personal data when we are obligated to comply with a legal or regulatory obligation.</p>

                        </div>
                        :
                        <div class="privacyCont">
                            <div class="privacyCont">
                                <h1>Amabwiriza agenga amakuru bwite</h1>
                                <h2>Inyandiko yerekeranye n’amakuru bwite</h2>
                                <p>Tuzi neza ko witaye ku buryo amakuru waduhaye atangwa cyangwa se asakazwa ari yo mpamvu twebwe nka Carousel Ltd. <a href=" http://carousel.rw/ ">http://carousel.rw</a> twitaye ku kuyarinda uko bishoboka. Carousel ibinyujije mu bicuruzwa byayo bya Inzozi Lotto niyo sosiyete yonyine yemerewe gukoresha no kugenzura gahunda ya tombola k rwego rw’igihugu kandi ikaba yarabiherewe uburenganzira na minisiteri y’ubucuruzi n’inganda ifite imikino y’amahirwe mu nshingano zayo <a href="https://www.minicom.gov.rw/">https://www.minicom.gov.rw</a></p>
                                <p>Iyi nyandiko irasobanura uburyo inzozilotto.rw ikusanya, ikoresha ndetse inabika imyirondoro ndetse n’andi makuru yatanzwe (hakuwemo amabanga yerekeranye n’inkiko ndetse n’ibyaha umuntu yaba akurikiranwaho) aya makuru akaba akusanywa hakoreshejwe urubuga rwa Inzozi Lotto, aplikasiyo n’izindi serivisi zitandukanye. Iyi nyandiko kandi yerekana uburyo ducunga amakuru yatanzwe ndetse ikanakumenyesha n’amategeko akurengera. Urasabwa gusoma ahanditse ‘’definitions’’ kugira usobanukirwe amagambo menshi akubiye muri iyi nyandiko.</p>
                                <h2>1. 1.	ICYO IYI NYANDIKO IGAMIJE N’ABO TURIBO</h2>
                                <h3>Icyo inyandiko igamije</h3>
                                <p>Iyi nyandiko tuyitanga mu rwego rwo kugufasha gusobanukirwa uko amakuru watanze akusanywa ndetse anakoreshwa kuri serivisi zacu zitandukanye. Igisobanuro cy’amakuru bwite (Personal Information) uragisanga mu gace kanditseho DEFINITIONS. Ni ngombwa ko amakuru yose tugufiteho cyangwa twakusanije aba ari ay’ukuri igihe cyose. Turagusaba kubitumenyesha haramutse hagize igihinduka mu myirondoro cyangwa andi makuru yatanzwe.</p>
                                <p>Mu gihe ukoresheje ibicuruzwa byacu, bivuze ko wemeye amategeko n’amabwiriza yose akubiye muri iyi nyandiko ndetse n’ikoreshwa ry’amakuru watanze ku mpamvu zavuzwe haruguru. Niba utemeranya n’ibikubiye muri iyi nyandiko, usabwe kudakoresha serivisi zacu.</p>
                                <h2>Uko watugeraho</h2>
                                <p>Uramutse ugize ikibazo ku byerekeranye n’imikorere yacu, ikusanywa ry’amakuru, uburenganzira bwawe n’uko warenganurwa, watwandikira kuri<a href="mailto:support@inzozilotto.rw">support@inzozilotto.rw</a> cyangwa mu gasanduku k’ibitekerezo kabugenewe kuri Carousel Ltd.</p>
                                <ul class="marleft">
                                    <li>Carousel Ltd. (http://carousel.rw/)</li>
                                    <li>CHIC Mall, 2nd floor, Romm F019A</li>
                                    <li>Tel: (+250) 788301824</li>
                                    <li>Po.Box. 6035, Kigali, Rwanda</li>
                                </ul>  <h2>Site z’abafatanyabikorwa</h2>
                                <p>Mu rwego rwo kubagezaho serivisi zinoze, ibicuruzwa byacu bishobora kunyura kuri site zitandukanye z’abafatanyabikorwa n’izindi aplikasiyo. Gukanda kuri izo site cyangwa se izo aplikasiyo biha uburenganzira abo bafatanyabikorwa bacu kuba bagera ku myirondoro ndetse n’amakuru watanze. Nta bushobozi bwo kugenzura izo site dufite cyangwa se kumenya uko amakuru akoreshwa. Mu gihe uvuye kuri site yacu usabwa gukoresha indi, turagushishikariza kubanza gusoma amategeko n’amabwiriza agenga izo site zindi mu rwego rwo gucunga amakuru watanze.</p>
                                <h2>2. AMAKURU DUKUSANYA IYO UKORESHEJE IBICURUZWA BYACU</h2>
                                <h3>Amakuru utanga</h3>
                                <p>• Imyirondoro usabwa wiyandikisha harimo ibikuranga nk’amazina yombi, nimero ya telephone, indangamuntu cyangwa se pasiporo, email ndetse n’itariki y’amavuko.</p>
                                <p>• Amakuru ajyanye n’uburyo bwo kwishyura wahisemo harimo imyorondoro yo kuri banki n’ibindi bisabwa mu kwakira cyangwa se kohereza amafaranga.</p>
                                <p>• Itumanaho iryo ariryo ryose hagati yawe natwe nko guhamagara umurongo wacu w’ubufasha bishobora kugusaba gutanga amakuru yisumbuyeho. Niba wifuza kutoherezwa ubutumwa buduturutseho kuri email, ayo mahitamo nayo turayaguha.</p>
                                <p>• Amakuru atangwa mu gusaba akazi. Iyo uhisemo gukorana natwe, usabwa imyirondoro yawe ndetse na CV yawe. Amwe mu makuru uzasabwa gutanga kuri CV harimo amashuri wize ndetse n’ubunararibonye wakuye mu tuzi wakoze.</p>
                                <h2>Amakuru dukusanya iyo ukoresheje ibicuruzwa byacu</h2>
                                <p>• Aho utuye. Iyo ukoresheje ibicuruzwa byacu, dukusanya amakuru yerekeranye n’aho utuye, ndetse na aderesi iva kuri murandasi ukoresha itwereka aho uherereye.</p>
                                <p>• Amakuru kuri telephone ukoresha. Dukusanya amakuru ku gikoresho wakoresheje mu kugera ku bicuruzwa byacu harimo aderesi y’aho uherereye mu gihe usaba serivisi, ubwoko bwa telephone n’uruganda rwayikose, aplikasiyo ukoresha ndetse na byinshi mu biranga telephone yawe.</p>
                                <p>• Amakuru dukusanya kandi harimo n’uburyo ukoresha ibicuruzwa byacu.</p>
                                <p>• Tugendeye ku makuru twakusanije dushobora kugereranya tukamenya ubwoko bw’ibicuruzwa ukunze kugura.</p>
                                <p>• Twebwe n’abafatanyabikorwa bacu dukoresha tekinoloji zitandukanye harimo COOKIES, PIXEL TAGS n’izindi. Abafatanyabikorwa bacu harimo abakora ibarurishamibare n’abamamaza bashobora gukoresha izi tekinoloji bakaba bakusanya amakuru yerekeranye n’ibikorwa ukorera kuri murandasi kuri mbuga cyangwa se serivisi zitandukanye. Icyitwa COOKIES ni inyandiko nto ziba zibitse ku gikoresho wifashisha kuri murandasi. Dukoresha ubwoko bubiri bwa COOKIES arizo session cookies na persistent cookies. Amakuru aturuka kuri session cookies avamo iyo ukuyemo urubuga uri kureberaho naho amakuru ava kuri persistent cookies aguma ku gikoresho wifashishije ukoresha murandasi akaba yakoreshwa inshuro zikurikiye usuye urwo rubuga.</p>
                                <h2>3. UKO DUKUSANYA AMAKURU AKUREBA</h2>
                                <p>Dukusanya amakuru dukoresheje uburyo butandukanye harimo:</p>
                                <p>• Uburyo buri otomatike _ Iyi ukoresheje ibicuruzwa byacu, dukusanya amwe mu makuru akureba harimo imbuga uba wasuye n’uburyo uba wakoresheje kuzigeraho. Ayo makuru akusanywa hakoreshejwe tekinoloji ya LOG FILES cyangwa se COOKIES.</p>
                                <p>•	Kuvugana natwe. Dukusanya kandi amakuru iyo utwandikiye cyangwa se utuvugishije kuri email, telephone cyangwa se ahandi. Muri ayo makuru dukusanya harimo ayo tubona iyo ufunguje konti, iyo uhawe ibihembo ,iyo uguze ibicuruzwa byacu, iyo utanze inkunga , utwohereje email cyangwa se ukaduhamagara udusaba ko twakohereza ubutumwa. Amakuru kandi akusanywa iyo ukoresheje poromosiyo zacu cyangwa se iyo uduhaye amakuru ku buryo serivisi zacu zitangwa.</p>
                                <p>• Abafatanyabikorwa – Dushobora gukusanya andi makuru akureba tuyahawe n’abafatanyabikorwa bacu cyangwa se ku mbuga zitandukanye za rusange harimo imbuga nkoranyambaga. Cyangwa se urebye ibikorwa byacu dusakaza mu buryo bwa videwo, imikino, aplikasiyo n’ibindi. Iyo utwandikiye cyangwa se ugakurikirana ibikorwa byacu ku mbuga nkoranyambaga cyangwa se aplikasiyo nabyo bishobora gutuma tubona amakuru akureba.</p>
                                <h2>4. UKO DUKORESHA AMAKURU TWAKUSANIJE</h2>
                                <p>Amakuru yakusanijwe akoreshwa gusa aho amategeko abitwemerera. By’umwihariko amakuru twakusanije akoreshwa mu buryo bukurikira:</p>
                                <p>Mu rwego rwo kubahiriza amabwiriza agenwa n’itegeko cyangwa amabwiriza agenga serivisi.</p>
                                <p>Iyo twifuza gusinyana amasezerano cyangwa se twamaze kuyasinyana nawe.</p>
                                <p>Iyo biri mu nyungu zacu ndetse n’izawe cyangwa se iz’abafatanyabikorwa kandi uburenganzira bwawe nk’umuguzi bukaba bubyemera.</p>
                                <p>Muri rusange, ntabwo bisaba uburenganzira watanze kugirango dukoreshe amakuru akureba wohereje mu buryo butandukanye harimo email, ubutumwa bugufi cyangwa watwohereje ukoresheje telephone. Ufite uburenganzira bwo kwanga ko twakoresha amakuru akureba igihe cyose utwandikira kuri <a href="mailto:support@inzozilotto.rw">support @inzozilotto.rw.</a></p>
                                <p>YAmakuru watanze akoreshwa mu buryo bukurikira:</p>
                                <ul class="marleft">
                                    <li>• Gufunguza konti yawe no gukoresha ibicuruzwa byacu</li>
                                    <li>• Kubarura no kureba ko imyirondoro watanze ihura mbere yo gukoresha ibicuruzwa byacu.</li>
                                    <li>• Kwishyura serivisi cyangwa ibicuruzwa byacu </li>
                                    <li>• Kwirinda ibikorwa byose bya forode no gukoresha amafaranga mu buryo bunyuranije n’amategeko n’ibindi bikorwa bibusanije n’amategeko.</li>
                                    <li>• Kuguha ubufasha butandukanye harimo igihe wagize ikibazo mu gukoresha serivisi </li>
                                    <li>• Kuvugana nawe tukumenyesha ibishya kuri serivisi </li>
                                    <li>• Kumenyekanisha no kwamamaza ibicuruzwa byacu</li>
                                    <li>• Mu gusuzuma uko serivisi zacu n’ibicuruzwa byacu cyangwa se iby’abafatanyabikorwa bacu bikora</li>
                                    <li>• Mu rwego rwo gukurikiza amabwiriza atugenga, kugenzura aho amabwiriza atakurikijwe no kubahiriza amategeko yashyizweho na leta, inzego za leta cyangwa se ibindi bigo bibifitiye ububasha.</li>
                                </ul>
                                <h2>5. GUTANGA AMAKURU AKUREBA KU BAFATANYABIKORWA</h2>
                                <p>Ntabwo tugurisha cyangwa se ngo dutange amakuru waduhaye ku bafatanyabikorwa keretse mu buryo bwavuzwe muri iyi nyandiko:</p>
                                <ul class="marleft">
                                    <li>• Ibigo bidushamikiyeho _ Dushobora gutanga amakuru akureba ku bigo bidushamikiyeho nk’uko iyi nyandiko ibigena</li>
                                    <li>•	Abacuruza n’abatanga serivisi – Dushobora gutanga amakuru akureba ku bagurisha cyangwa batanga serivisi mu gihe bisabwa kugirango iyo serivisi itangwe</li>
                                    <li>•	Site z’abafatanyabikorwa – Iyo ukoresheje site z’abafatanyabikorwa, bashobora kuba babona amakuru akureba.</li>
                                    <li>•	Abafatanyabikorwa badufasha kwamamaza _Dushobora gukorana na bamwe mu bafatanyabuikorwa bacu tukugezaho serivisi dukeka ko wakwifuza gukoresha. Aba bafatanyabikorwa nabo bashobora gukoresha tekinoloji nk’izavuzwe haruguru bakaba bagera ku makuru watanze, imbuga wasuye n’izindi serivisi zose wasabye ukoresheje murandasi. Ushobora gukuramo cyangwa kugabanya serivisi zo kwamamaza ushyira aplikasiyo ya AppChoices kuri telephone yawe.</li>
                                    <li>•	Nk’uko itegeko ribigena, dushobora kureba, kubika cyangwa se gusakaza amakuru watanze (i)biramutse bisabwe n’inzego za leta nk’inkiko ndetse n’izindi nzego zibifitiye ububasha, ndetse n’igihe byaba byerekeranye no kubungabunga umutekano w’igihugu cyangwa bisabwa n’inzego z’umutekano. (ii)Mu gihe was abwe guhabwa ubufasha (iii) Mu rwego rwo kubahiriza uburenganzira bwawe. Mu gihe usakaje amakuru asebya ibicuruzwa byacu, dufitw uburenganzira bwo gusakaza amakuru akureba mu rwego rwo kurinda abatugana urujijo.</li>
                                    <li>•	Amakuru akwerekeye kandi ashobora gutangwa ku bacuruza serivisi zacu, abajyanama, n’abandi bafatanyabikorwa ndetse no ku yindi sosiyete mu gihe iyi iramutse iguzwe cyangwa igurishije imitungo yayo. Uko aya makuru yose azakoreshwa bizajya bigendera ku mabwiriza akubiye muri iyi nyandiko ndetse n’igihe ayo makuru yatangiwe.</li>
                                    <li>•	Amakuru waduhaye kandi twanayakoresha igihe cyose ubiduhereye uburenganzira.</li>
                                </ul>
                                <h2>6. AMATEGEKO AKURENGERA </h2>
                                <p>Ufite uburenganzira bwo gusaba cyangwa guhabwa amakuru yose tugufiteho ndetse no gukosoza ahaba harimo amakosa, kwanga ko amakuru yawe yatambuka, kuba wakwifuza ko amakuru asibwa cyangwa atangwa hadatangajwe amazina yawe cyangwa se gukoresha uburenganzira bwawe bukwemerera kuba wahereza amakuru waduhaye ku yindi sosiyete. Byongeye, ufite uburenganzira bwo kuba wageza ikibazo cyawe ku nzego zidukuriye mu gihe ukeneye kwisubiza uburenganzira wari waraduhaye bwo kuba twakoresha amakuru akwerekeye. Ibi bikaba byakorwa igihe icyo ari cyo cyose kandi nta kiguzi. Mu gihe wisubije ubu burenganzira dukora ku buryo twazajya tugendera bishoboka ku gushaka kwawe mbere y’uko bigera ku kwisubiza uburenganzira.</p>
                                <p>Mu gihe waba wifuza gukoresha bumwe muri ubu burenganzira bwavuzwe, ushobora kuba watwandikira cyangwa se ukaduhamagara.</p>
                                <h2>7. UMUTEKANO W’AMAKURU TUBITSE</h2>
                                <p>Kubungabunga umutekano w’amakuru tubitse ni ngombwa kuri twe. Muri rusange dukurikiza amategeko nk’agenga ibindi bigo harimo gukurikiza amabwiriza yose tugamije kwirinda ko amakuru yatakara, yakoreshwa , yasakazwa, yahindurwa mu buryo butemewe. Byongeye, amakuru watanze agerwaho gusa n’abakozi ba sosiyete, aba ajenti, n’abandi bafatanyabikorwa bacu. Nabo kandi bakayakoesha mu ibanga rikomeye kandi bakurikije amabwiriza twabahaye.</p>
                                <p>Ku makuru akomeye harimo nimero ya konti ya banki cyangwa se izindi konti zitandukanye, dukoresha tekinoloji ya encryption idufasha kuyacunga byimbitse. Cyakoze, nta buryo bwo kubika amakuru ukoresheje murandasi bwizewe ku kigero cy’i 100%. Niyo mpamvu tutakwizeza burundu ko ayo makuru atazasakazwa. Uramutse ugize ikibazo ku mutekano w’amakuru watanze, watwandikira.</p>
                                <p>Twashyizeho uburyo bwo kumenya haramutse hageragejwe kwiba amakuru ariyo mpamvu dufite mu nshingano kubikumenyesha nkuko itegeko ribigena.</p>
                                <h2>8. KUBIKA AMAKURU WATANZE</h2>
                                <p>Dukomeza kubika amakuru watanze mu gihe cyose tugikeneye kuyakoresha ku mpamvu twayasabiye. Dushobora no gukomeza kubika amakuru ku mpamvu zerekeranye na bizinesi cyangwa se igihe bigenwa n’itegeko. Mbere yo kuba twagumana amakuru hitabwa ku bicuruzwa cyangwa serivisi zacu wakoresheje, igihe tumaze dukorana, n’ingaruka zaramuka zibaye kuri serivisi zacu mu gihe amakuru yawe yasibwa burundu ndetse n’igihe ntarengwa kigenwa n’itegeko.</p>
                                <p>Tukwijeje kubungabunga amakuru watanze mu buryo bwose bushoboka kandi ko inshingano zo kuyacunga zitazakorerwa hanze y’u Rwanda.</p>
                                <p>Dushobora guhindura, kuzuza cyangwa gukuraho amakuru atuzuye igihe icyo aricyo cyose ariko bigakorwa mu ibanga.</p>
                                <p>Hari igihe dushobora kuba twakoresha amakuru watanze tudatangaje amazina yawe, aya makuru akaba yakoreshwa no mu gihe kizaza tutarinze kongera kugusaba uburenganzira.</p>
                                <h2>9. INYANDIKO IMENYESHA IMPINDUKA</h2>
                                <p>Tuzakomeza guhindura ibikubiye muri iyi nyandiko mu gihe habaye impinduka muri tekinoloji, muri bizinesi no mu byo abaguzi bakenera. Urasabwa kujya usoma iyi paji kugirango umenye niba hari impinduka zabayemo. Gukomeza gukoresha serivisi zacu mu gihe wamaze gusoma impinduka zabayeho, bizaba bisobanura ko wasobanukiwe kandi wemeranya n’izo mpinduka.</p>
                                <h2>10. IBISOBANURO</h2>
                                <p>Amakuru bwite (personal information) ni amakuru yose yakoreshwa yonyine cyangwa hifashishijwe n’andi mu kuba hamenywa umwirondoro w’umuntu.</p>
                                <p>Inyungu zihariye (legitimate interest) ni inyungu dufite mu kugenzura bizinesi tugamije gutanga serivisi zinoze n’ibicuruzwa by’umwimerere. Twita ku burenganzira bwawe igihe cyose bibaye ngombwa kuba twakoresha amakuru watanze ku nyungu zacu zihariye. Nta na rimwe dukoresha amakuru watanze mu gihe utabiduhereye uburenganzira  cyangwa mu gihe itegeko ritabyemera.</p>
                                <p>Imikoreshereze ya kontaro (performance of contract) igena igihe cyose byemewe gukoresha amakuru watanze mu rwego rwo kubahiriza kontaro wasinye cyangwa uteganya gusinya.</p>
                                <p>Kubahiriza itegeko cyangwa amabwiriza agenga serivisi (comply with legal or regulatory obligations) bigena kuba twakoresha amakuru watanze mu gihe tubisabwe n’amategeko cyangwa mu gihe amabwiriza agenga serivisi abigennye.</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PrivacyPolicy))