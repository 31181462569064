import { appConstants } from '../helpers/actionType';
import { rouletteService } from '../services/rouletteService';
import { commonClass } from "..//helpers/commonClass";
import { alertActions } from "../action/alertAction";
import { history } from '../helpers/history';
export const rouletteActions = {
    AddToCart,
    SetCurrentDraw,
    RemoveFromCart,
    ClearCart,
    RemoveClosedDrawbetFromCart,
    Buybet,
    UpdateStakeAll,
    GetCurrentResult,
    UpdateCartAll,
}

function AddToCart(cItem) {
    return dispatch => {
        dispatch({ type: appConstants.ADD_CARTITEM, cItem });
    }
};


function UpdateCartAll(Items) {
    return dispatch => {
        dispatch({ type: appConstants.UPDATE_CARTITEM, Items });
    }
};

function RemoveFromCart(cid) {
    return dispatch => {
        dispatch({ type: appConstants.REMOVE_CARTITEM, cid });
    }
};

function RemoveClosedDrawbetFromCart(did) {
    return dispatch => {
        dispatch({ type: appConstants.REMOVE_CLOSEDDRAW_CARTITEM, did });
    }
};

function ClearCart() {
    return dispatch => {
        dispatch({ type: appConstants.CLEAR_CARTITEM });
    }
};

function SetCurrentDraw(cDraw) {
    return dispatch => {
        dispatch({ type: appConstants.SET_DRAW, cDraw });
    }
};

function UpdateStakeAll(stake) {
    return dispatch => {
        dispatch({ type: appConstants.UPDATE_STAKE_ALL, stake });
    }
};

function GetCurrentResult(gameID,langID) {
    return dispatch => {
        let data = `'${gameID}','${langID}'`;
        rouletteService.GetCurrentResult(data)
            .then(
                cResult => {
                    dispatch({ type: appConstants.GET_CURRENT_RESULT, cResult });
                },
                error => {
                    let cResult = undefined;
                    dispatch({ type: appConstants.GET_CURRENT_RESULT, cResult });
                }
            );
    }
};

function Buybet(buyStr) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = buyStr;
            rouletteService.RouletteSale(data)
                .then(
                    buyInfo => {
                        let myBet = generateBetSlip(buyInfo);
                        dispatch({ type: appConstants.LOADED });
                        if (myBet.status == 1) {
                            dispatch(alertActions.error(myBet.msg));
                        }
                        else if (myBet.status == 3) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                        }
                        else if (myBet.status == 4) {
                            dispatch(alertActions.error(myBet.msg));
                            setTimeout(() => { history.push('/login'); }, 2000);
                        }
                        else {
                            dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                        }
                        if (myBet.balance) {
                            let balance = myBet.balance;
                            let winbalance = myBet.winbalance;
                            dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                        }
                    },
                    error => {
                        let myBet = { status: 2, msg: "Something Wrong!" };
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function generateBetSlip(mybet) {
    try {       
        let betArr = mybet.RS.split('~');
        if (betArr[0]!= 0) {
            return { status: betArr[0], msg: mybet.RSDESC };
        }
        //mybet = mybet.substring(2, mybet.RSDESC.length);
        let tempStr = mybet.RSDESC.split('|');
       /*  let tempStr1 = tempStr[1].split('~');
        let tempStr2 = tempStr[0].split('|'); */
        let tempStr3 = []; 
        tempStr.forEach(ele => {
            let _betValue = ele.split('~');
            if (_betValue.length > 1) {
                tempStr3.push({
                    Id: _betValue[0],
                    BallNo: _betValue[4],
                    GameName: _betValue[3],
                    StakeValue: parseFloat(_betValue[5]),
                    SubTotal: parseFloat(_betValue[7]),
                    OddsValue: _betValue[6],
                    GameTime: _betValue[1],
                    GameID: parseInt(_betValue[2]),
                    TransDateTime: _betValue[1]                    
                });
            }
        });
        /* let _bMsg="";
        if( tempStr.length>2)
        {
            _bMsg=tempStr[2];
        } */
        let betObj = {
            betLst: tempStr3,
            status: betArr[0],
            msg: "success",
            balance: betArr[1],
            winbalance: betArr[2],
            familyID: 5,            
            //buttomMsg:_bMsg
        }
        return betObj;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};