import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class BetHistory extends Component {

    constructor(props) {
        super(props);
    }

    getRecord = (flg) => {       
        if (flg != 1) {
            this.state.startDate = document.getElementById("datepicker2").value;
            this.state.endDate = document.getElementById("datepicker3").value;
        }
      let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate);
      if(_flog)
        this.props.GetBetHistory(this.props.user.mobileNo, this.state.startDate, this.state.endDate,this.props.i18n.language);
    };
    reSetFilter() {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            BetHistory: props.BetHistory,
        }
    };

    componentDidMount() {
        window.documentReadyDatePicker();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };
    rePrintTicker(TransId, gameGroupId) {
        this.props.ReprintTicket(TransId, this.props.user.mobileNo, gameGroupId,this.props.i18n.language);
    }
    render() {
        const { t } = this.props;
        return (
            <section id="cTab3" class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblGameDetails')}</h2>
                    <div class="selRslt">
                        <ul>
                            <li class="dateCont">
                                <div class="selRsltCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate white" type="text" value={this.state.startDate} id="datepicker2" name="startDate" placeholder="2017-06-10" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="selRsltCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="endDate white" type="text" value={this.state.endDate} id="datepicker3" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="resetFtrBtn" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                                <a href="#" class="relaodBtn" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>
                    {window.innerWidth > 800 ?
                        (
                            <div class="booking">
                                <div class="bookLiHead">
                                    <table>
                                        <tr>
                                            <th>{t('lblgamename')}</th>
                                            <th>{t('lblTransID')}</th>
                                            <th>{t('lblDateTime')}</th>
                                            <th>{t('lbldrawdatetime')}</th>
                                            <th>{t('lblTotalGame')}</th>
                                            <th>{t('lblPlayedAmt')}</th>
                                            <th>{t('lblStatus')}</th> 
                                            <th>{t('lblWinningAmt')}</th>
                                            <th></th>
                                        </tr>
                                        {this.state.BetHistory && this.state.BetHistory.status == 0 && this.state.BetHistory.data.map((x, idx) =>
                                            <tr id={'bethis_' + idx}>
                                                <td >{x.GameName}</td>
                                                <td style={{cursor:"pointer"}} onClick={() => this.rePrintTicker(x.Id, x.GameGroupId)}><u>{x.Id}</u></td>
                                                <td>{x.TransDateTime}</td>
                                                <td>{x.DrawDateTime}</td>
                                                <td>{x.TotalBet}</td>
                                                <td>{x.TotalStakeAmount}</td>
                                                <td>{x.Status}</td> 
                                                <td>{x.WinAmount}</td> 
                                            </tr>
                                        )}
                                        {this.props.BetHistory && this.props.BetHistory.status != 0 &&
                                            <tr>
                                                <td colspan="8">{this.props.BetHistory.msg}</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>)
                        :
                        (
                            <div class="lftsportsRprt borColor3">
                                <div class="teamListfoRMob">
                                    <div class="teams">
                                        <ul class="teamListRprt background-color3 scrollbar3 borColor3">
                                            {this.state.BetHistory && this.state.BetHistory.status == 0 && this.state.BetHistory.data.map((x, idx) =>
                                                <li onClick={() => { this.state.isActiveID != idx ? this.setState({ isActiveID: idx }) : this.setState({ isActiveID: -1 }) }} key={'amrpt_' + idx}><a ><div class="countryName">ID : {x.Id} | {x.GameName} | {x.TransDateTime}<span onClick={() => this.rePrintTicker(x.Id, x.GameGroupId)} style={{cursor:"pointer", color:"red",position:'absolute',right:"45px",marginTop:"0px"}}>PRINT</span></div></a>
                                                    <ul class="teamListRprtLi" style={{ display: this.state.isActiveID == idx ? "block" : "none" }}>
                                                        <li><a >{t('lbldrawdatetime')} : {x.DrawDateTime}</a></li>
                                                        <li><a >{t('lblTotalGame')} : {x.TotalBet} </a></li>
                                                        <li><a >{t('lblPlayedAmt')} : {x.TotalStakeAmount} </a></li>
                                                        <li><a >{t('lblStatus')} : {x.Status} </a></li>
                                                        <li><a >{t('lblWinningAmt')} : {x.WinAmount} </a></li>
                                                    </ul>
                                                </li>)
                                            }
                                            {this.props.BetHistory && this.props.BetHistory.status != 0 &&
                                                <li> <a><div class="countryName">{this.props.BetHistory.msg}</div></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { BetHistory } = state.accountReducer;
    const { user } = state.loginReducer;
    return { BetHistory, user };
}

const mapActionToProps = {
    GetBetHistory: accountActions.GetBetHistory,
    ReprintTicket: accountActions.ReprintTicket,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(BetHistory))