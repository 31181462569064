import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class AccountActivity extends Component {
    constructor(props) {
        super(props);       
    }

    getRecord = (flg) => {
        try{
        if(flg!=1)
        {
        this.state.startDate=document.getElementById("datepicker2").value;        
        this.state.endDate=document.getElementById("datepicker3").value;
        }
        let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate)
        if(_flog)
        this.props.GetAccountActivity(this.props.user.mobileNo, this.state.startDate, this.state.endDate,this.props.i18n.language);
    }
    catch (ex) {
        commonClass.writeLog(ex,"AccActivity-getRecord");
        return false;
    }
    };
    reSetFilter() {
        try{
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    }
    catch (ex) {
        commonClass.writeLog(ex,"AccActivity-reSetFilter");
        return false;
    }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            AccountActivity: props.AccountActivity,
        }
    };

    componentDidMount() {
        try{
        window.documentReadyDatePicker();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    }
    catch (ex) {
        commonClass.writeLog(ex,"AccActivity-componentDidMount");
        return false;
    }
    };

    render() {
        const { t } = this.props;
        return (
            <section id="cTab3" class="tabCont">
              <div class="Ourservinerpg">
                    <h2>{t('lblAccountActivity')}</h2>
                <div class="selRslt">
                    <ul>
                        <li class="dateCont">
                            <div class="selRsltCont">
                                <p>{t('lblfrom')} :</p>
                                <input class="startDate white" type="text" value={this.state.startDate}  id="datepicker2" name="startDate" placeholder="2017-06-10" onChange={(date) =>this.setState({ startDate: date.target.value })} />
                            </div>
                            <div class="selRsltCont">
                                <p>{t('lblto')} :</p>
                                <input class="endDate white" type="text" value={this.state.endDate} id="datepicker3" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                            </div>
                        </li>
                        <li class="BtnCont">
                            <a href="#" class="resetFtrBtn" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                            <a href="#" class="relaodBtn" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                        </li>
                    </ul>
                </div>
                {window.innerWidth > 800 ?
                    (<div class="booking">
                        <div class="bookLiHead">
                            <table>
                                <tr>
                                    <th>{t('lblDescription')}</th>                                   
                                    <th>{t('lblTransDate')}</th>
                                    <th>{t('lblOpenbal')}</th>
                                    <th>{t('lblDebits')}</th>
                                    <th>{t('lblCredits')}</th>
                                    <th>{t('lblClosebal')}</th>
                                </tr>
                                {this.state.AccountActivity && this.state.AccountActivity.status == 0 && this.state.AccountActivity.data.map((x, idx) =>
                                    <tr>
                                        <td>{x.Description}</td>
                                        <td>{x.Transdate}</td>
                                        <td>{x.OpenBalance}</td>
                                        <td>{x.Debit}</td>
                                        <td>{x.Credit}</td>
                                        <td>{x.CloseBalance}</td>
                                    </tr>
                                )}
                                {this.state.AccountActivity && this.state.AccountActivity.status != 0 &&
                                    <tr>
                                        <td colspan="6">{this.state.AccountActivity.msg}</td>
                                    </tr>
                                }
                            </table>
                        </div>
                    </div>
                    ):
                    (
                        <div class="lftsportsRprt borColor3">
                            <div class="teamListfoRMob">
                                <div class="teams">
                                    <ul class="teamListRprt background-color3 scrollbar3 borColor3">
                                        {this.state.AccountActivity && this.state.AccountActivity.status == 0 && this.state.AccountActivity.data.map((x, idx) =>
                                            <li onClick={() => { this.state.isActiveID != idx ? this.setState({ isActiveID: idx }) : this.setState({ isActiveID: -1 }) }} key={'amrpt_' + idx}><a ><div class="countryName">Date : {x.Transdate} | Open Bal :{x.OpenBalance}  |  Close Bal :{x.CloseBalance}</div></a>
                                                <ul class="teamListRprtLi" style={{ display: this.state.isActiveID == idx ? "block" : "none" }}>
                                                    <li><a >{t('lblDescription')} : {x.Description}</a></li>
                                                    <li><a >{t('lblDebits')} : {x.Debit} </a></li>
                                                    <li><a >{x.Credit} : {x.Credit}</a></li>
                                                </ul>
                                            </li>)
                                        }
                                        {this.state.AccountActivity && this.state.AccountActivity.status != 0 &&
                                            <li> <a><div class="countryName">{this.state.AccountActivity.msg}</div></a></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                                    }
                                
           </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    const { AccountActivity } = state.accountReducer;
    const { user } = state.loginReducer;
    return { AccountActivity, user };
}

const mapActionToProps = {
    GetAccountActivity: accountActions.GetAccountActivity,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AccountActivity))