import { appConstants } from '../helpers/actionType';

export const alertActions = {
	success,
	error,
	clear,
	loading
};

function success(message) {
	return { type: appConstants.SUCCESS, message };
}

function error(message) {
	return { type: appConstants.ERROR, message };
}

function clear() {
	return { type: appConstants.CLEAR };
}

function loading(message) {
	return { type: appConstants.LOADING, message };
}