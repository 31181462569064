import React, { Component } from 'react'
import { connect } from 'react-redux';
import { gameActions } from '../../../action/gamesAction';
import { lottoActions } from '../../../action/lottoAction';
import { raffleActions } from '../../../action/raffleAction';
import Countdown, { zeroPad } from 'react-countdown';
import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';

class RaffleBike extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cartItems: [],
            currentRunningDraw: {},
            currentFinishingDraw: {},
            coundownStart: 0,
            coundTime: 18900000,
            currentDraw: {},
            currentAvailableDraws: [],
            ballStartNumber: 1,
            ballEndNumber: 0,
            QPBallCount: 0,
            random: [],
            showSingleMsg: false,
            _serverTimeDiff: 0,
            allGameClosed: 0,
            isViewAnimation: false,

            searchOptionID: 2,   //1-Whole Number,2-Last 2 digit,3-First 2 digit,4-2 digit anywere,5-Quick Pick
            searchkey: '',
            lstAvailableTickets: [],
            txtSearchMaxLen: 2,
            txtSearchEnable: false,
            SearchOption: { WholeNumber: 1, Last2: 2, First2: 3, AnyWhere2: 4, QuickPick: 5 }
        };
        this.my_componentWillUnmount();
        if (props.currentGamesFamily.id !== undefined) {
            props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id, this.props.i18n.language);
        }
    }

    selectDraw = (_draw) => {
        this.props.SetCurrentDraw(_draw);
    };

    selectRunningDraw = () => {
        if (this.state.coundownStart != undefined && this.state.coundownStart == 0) {

            //if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
            if (this.state.currentFinishingDraw != undefined) {
                //if (this.state.currentFinishingDraw.DrawID == undefined || this.state.currentRunningDraw.DrawID == undefined || this.state.currentFinishingDraw.DrawID !=this.state.currentRunningDraw.DrawID) {
                let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
                if (lst.length > 0 || this.state.currentAvailableDraws.length <= 0) {
                    let _draw = this.state.currentAvailableDraws[0];
                    //let _draw = lst[0];
                    this.setState({
                        currentRunningDraw: _draw,
                    })
                    this.state.currentRunningDraw = _draw;
                    this.selectDraw(_draw);
                }
                else {
                    return;
                }
            }
            let _draw = this.state.currentAvailableDraws[0];
            if (_draw == undefined) { this.state.coundownStart = 1; return; }
            try {
                let sTime = undefined; //new Date(Date.now() - this.state.coundTime);
                if (_draw != undefined) {
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    let ss = new Date(_draw.DrawDateTime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                    sTime = new Date(ss.setSeconds(ss.getSeconds() - 60));
                }
                let dStr = ("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + sTime.getHours() + ":" + sTime.getMinutes() + ":" + sTime.getSeconds();
                window.timer = null;
                this.state.coundownStart = window.startDraw(dStr, this.RefreshAfterGameClose);
            }
            catch (ex) {
                alert(ex);
            }
        }
    };

    componentDidUpdate(previousProps, previousState) {
        if (this.state.currentDraw != null && Object.keys(this.state.currentDraw).length != 0) {
            this.state.allGameClosed = 0;
        }
        if (this.state.currentAvailableDraws.length > 0 && ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length <= 0) || this.state.currentAvailableDraws[0].DrawNo != this.state.currentRunningDraw.DrawNo))
        //if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0)) 
        {
            if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
                this.state.allGameClosed = 1
            }
            let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
            this.selectDraw(_draw);
            this.state.currentRunningDraw = _draw;
            this.setState({ currentRunningDraw: _draw });
        }
        window.documentReadyjQueryFlex();
    };

    my_componentWillUnmount = () => {
        this.props.ClearCart();
        this.setState({ currentRunningDraw: null });
        this.props.ClearAvailableGames();
        this.props.ClearSelectionTicket();
    };

    RefreshAfterGameClose = () => {
        this.state.coundownStart = 0;
        this.state._serverTimeDiff = 0;
        this.state.currentFinishingDraw = this.state.currentRunningDraw;
        this.state.currentRunningDraw = {};
        this.props.GameAvailableGames(this.props.user.mobileNo, this.props.currentGamesFamily.id, this.props.i18n.language);
    };

    clearCart = () => {
        this.props.ClearCart();
    };

    clearSearchTicket = () => {
        this.setState({ searchkey: "" });
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            return;
        }
        this.props.ClearSearchTicket(this.props.user.mobileNo, this.props.user.loginKey);
    };

    removeFromCart = (cid) => {
        this.props.RemoveFromCart(cid);
    };

    countdownCompleted = () => {
        //this.RefreshAfterGameClose();
    };

    getGameTime = () => {
        let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
        if (_date != undefined && _date != "") {
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
            console.log("getGameTime : " + sTime);
            console.log("DateNow : " + Date.now());
            return sTime;
        }
        else {
            return 0;
        }
    };

    // Renderer callback with condition
    renderer = ({ total, days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state			
            return <span>{this.props.t('lblDrawClosed')}!</span>;
        } else {
            // Render a countdown
            return <span>{zeroPad(total)}:{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    };

    getServerTime = () => {
        if (this.state._serverTimeDiff != 0 && this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0) {
            console.log("getServerTime : " + this.state._serverTimeDiff);
            return this.state._serverTimeDiff = (this.state._serverTimeDiff + 1000);
        }
        else {
            let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
            if (_date != undefined && _date != "") {
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                var sTime = (new Date(ss.setSeconds(ss.getSeconds() - 10)).getTime());
                this.state._serverTimeDiff = sTime;
                console.log("getServerTime : " + sTime);
                console.log("DateNow : " + Date.now());
                return sTime;

            }
            else {
                return 0;
            }
        }
    };

    reCount = (rundraw) => {
        if (this.state.currentDraw == null || rundraw == null || Object.keys(rundraw).length == 0) {
            return <small>{this.props.t('lblDrawClosed')}!</small>;
        }
        else {
            this.state.coundownStart = 1;
            return <small key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime()} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{zeroPad((days * 24) + hours)}hh:{zeroPad(minutes)}mm:{zeroPad(seconds)}ss</div>) }} /> </small>;
        }
    };

    BuyLotteryBet = () => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            alert(this.props.t('lblLoginValidation'));
            return;
        }
        if (this.state.cartItems == undefined || this.state.cartItems.length <= 0) {
            alert(this.props.t('lblplcurbet'));
            return;
        }
        let totstake = this.state.cartItems.reduce((a, b) => a = a + b.amount, 0);
        let minTotStake = 200;
        let maxTotStake = 10000;
        if (this.state.currentGamesFamily != undefined) {
            minTotStake = this.state.currentGamesFamily.minStakePerTrans;
            maxTotStake = this.state.currentGamesFamily.maxStakePerTrans;
        }
        if (totstake < minTotStake) //300
        {
            alert(this.props.t('lblMinimumplayAmt') + " : " + minTotStake);
            return;
        }
        else if (totstake > maxTotStake)//10000
        {
            alert(this.props.t('lblMaximumplayAmt') + " : " + maxTotStake);
            return;
        }
        var sTime = new Date(this.props.serverTime);
        let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;
        let betInfo = "";
        let _betType = "1"; //0-partial,1-full        
        this.state.cartItems.forEach(element => {
            betInfo += element.TicketID + "~" + _betType + "~" + element.TicketNo + "|";
        });
        let butStr = `'${this.props.user.mobileNo}',${this.state.cartItems[0].gameID},'${betInfo}','${TransID}',0,'${this.props.user.loginKey}','${this.props.i18n.language}'`;
        this.props.Buybet(butStr);
        this.clearSearchTicket();
        this.clearCart();
    };

    static getDerivedStateFromProps(props, state) {
        return {
            currentAvailableDraws: props.currentAvailableDraws,
            cartItems: props.cartItems,
            currentDraw: props.currentDraw,
            currentGamesFamily: props.currentGamesFamily,
            latestResult: props.latestResult,
            lstAvailableTickets: props.AvailableTickets
        };
    };

    selectTicket = (_ticket) => {
        if (this.state.currentDraw == null || Object.keys(this.state.currentDraw).length == 0) {
            this.state.showSingleMsg = true;
            alert(this.props.t('lblSelectDraw'));
            return;
        }
        if (_ticket.Status != 0 && _ticket.Status != 5) {
            alert(this.props.t('lblUnableBuyTicket'));
            return;
        }
        let obj = this.state.cartItems.filter(x => x.id == _ticket.TicketNo);
        if (obj.length > 0) {
            _ticket.Active = false;
            this.removeFromCart(_ticket.TicketNo);
        }
        else {
            _ticket.Active = true;
            var objLst = {};
            objLst.gameName = this.state.currentDraw.LotName;
            objLst.drawID = this.state.currentDraw.DrawID;
            objLst.gameID = this.state.currentDraw.GameId;
            objLst.drawDateTime = this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime;
            objLst.amount = this.state.currentDraw.RateInPs;
            objLst.TicketNo = _ticket.TicketNo;
            objLst.id = _ticket.TicketNo;
            objLst.TicketID = _ticket.TransactionID;
            this.props.AddToCart(objLst);
        }
        let dd = this.state.lstAvailableTickets;
        this.setState({ lstAvailableTickets: dd });
    };

    setSearchOption = (op) => {
        let _len = 0;
        let _txtenable = false;
        //1-Whole Number,2-Last 2 digit,3-First 2 digit,4-2 digit anywere,5-Quick Pick
        if (op == this.state.SearchOption.WholeNumber) {
            _len = 6;
        }
        else if (op == this.state.SearchOption.QuickPick) {
            _txtenable = true;
        }
        else {
            _len = 2;
        }
        this.state.txtSearchEnable = _txtenable;
        this.setState({ txtSearchEnable: _txtenable });
        this.state.txtSearchMaxLen = op;
        this.setState({ txtSearchMaxLen: _len });
        this.state.searchOptionID = op;
        this.setState({ searchOptionID: op });
        this.setState({ searchkey: '' });
        this.props.ClearSelectionTicket();
    };

    searchTickets = () => {
        if (this.state.currentDraw == null || Object.keys(this.state.currentDraw).length == 0) {
            alert(this.props.t('lblSelectDraw'));
            return;
        }
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            alert(this.props.t('lblLoginValidation'));
            return;
        }
        if (this.state.searchkey == '' && this.state.searchOptionID != this.state.SearchOption.QuickPick) {
            alert(this.props.t('lblEnterTicketNumber'));
            return;
        }
        if (this.state.searchkey.length != 2 && (this.state.searchOptionID != this.state.SearchOption.WholeNumber && this.state.searchOptionID != this.state.SearchOption.QuickPick)) {
            alert(this.props.t('lblEnterTicketNumber'));
            return;
        }
        if (this.state.searchkey.length != 6 && this.state.searchOptionID == this.state.SearchOption.WholeNumber) {
            alert(this.props.t('lblEnterTicketNumber'));
            return;
        }
        this.props.GetSearchTicket(this.state.searchOptionID, this.state.searchkey, this.state.currentDraw.GameId, this.props.user.mobileNo, this.props.user.loginKey, this.props.i18n.language);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                {/* <div style={{ margin: "-20px auto", display: "table", width: "auto" }}>
                    <div style={{ width: "100%", float: "left", fontSize: "20px", textAlign: "center", fontFamily: "Montserrat-ExtraBold", padding: "10px 30px", border: "3px solid #003188" }}>
                        <small style={{ color: "#003188", fontSize: "16px", fontFamily: "Montserrat-ExtraBold", font: "bold" }}>IGITEGO-MOTO</small>
                    </div>
                </div>  */}
                <div class="container">
                    <div class="banner bounceIn delay-500 fast go">
                        <div className="flexslider hmBan">
                            <ul class="slides" style={{ paddingBottom: "10px" }}>
                                <li><img src="./assets/img/Motobike.webp" /></li>
                            </ul>
                        </div>
                    </div>
                    <div class="ContestContent">
                        <div class="Contest">
                            <div class="Contestinner">
                                <ul>
                                    <li><a onClick={() => this.setSearchOption(this.state.SearchOption.WholeNumber)} className={this.state.searchOptionID == this.state.SearchOption.WholeNumber ? 'Active' : ''}>Whole Number</a></li>
                                    <li><a onClick={() => this.setSearchOption(this.state.SearchOption.First2)} className={this.state.searchOptionID == this.state.SearchOption.First2 ? 'Active' : ''}>First 2 Digit</a></li>
                                    <li><a onClick={() => this.setSearchOption(this.state.SearchOption.Last2)} className={this.state.searchOptionID == this.state.SearchOption.Last2 ? 'Active' : ''}>Last 2 Digit</a></li>
                                    <li><a onClick={() => this.setSearchOption(this.state.SearchOption.AnyWhere2)} className={this.state.searchOptionID == this.state.SearchOption.AnyWhere2 ? 'Active' : ''}>2 Digit Anywere</a></li>
                                    <li><a onClick={() => this.setSearchOption(this.state.SearchOption.QuickPick)} className={this.state.searchOptionID == this.state.SearchOption.QuickPick ? 'Active' : ''}>Quick Pick</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="quickTimesecRwdCont">
                            <div class="quickTimesecRwd">
                                {this.reCount(this.state.currentRunningDraw)}
                            </div>
                            <div class="headRgtInr" id='btnCart'>
                                <span><img src="./assets/img/icons/shoping-bag.png" /><small>{this.state.cartItems.length}</small></span>
                                <p>{this.state.cartItems.reduce((a, b) => a = a + b.amount, 0)}</p>
                            </div>
                        </div>
                        <div class="ContestBtm">
                            <div class="col-md-9 col-sm-9 col-xs-12">
                                <div class="ContestBtmLft">
                                    <input type="text" placeholder={`${t('lblEnterTicketNumber')}`} value={this.state.searchkey} readOnly={this.state.txtSearchEnable} maxLength={this.state.txtSearchMaxLen} onChange={(e) => { if (/^[0-9\b]+$/.test(e.target.value) == true || e.target.value == "") this.setState({ searchkey: e.target.value }) }} />
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="ContestBtmRgt">
                                    <a onClick={() => this.searchTickets()} id="Tickets"><i class="fas fa-ticket-alt" style={{ color: "#fca334", transform: "rotate(125deg)", marginRight: "5px" }}></i>{this.props.t('lblSearchTickets')}</a>
                                </div>
                            </div>
                        </div>
                        <div class="BigWinform">
                            <div class="col-md-8 BigWinForm">
                                <div class="col-md-12 forminnr">
                                    <h3>{this.props.t('lblSearchResult')}</h3>
                                    <a onClick={() => this.clearSearchTicket()}><i class="fa fa-refresh" aria-hidden="true"></i>{this.props.t('lblClearTicket')}</a>
                                    <a onClick={() => this.clearCart()}><i class="fa fa-refresh" aria-hidden="true"></i>{this.props.t('lblClearSelection')}</a>
                                </div>
                                <div class="formInnerCont scrollbar">
                                    {this.state.lstAvailableTickets.length > 0 && this.state.lstAvailableTickets[0].Status == 5 && <p>{this.props.t('lblSimilarTicket')} </p>}
                                    {this.state.lstAvailableTickets.map((x, inx) =>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group">
                                                <a onClick={() => this.selectTicket(x)} class={this.state.cartItems.filter(xy => xy.id == x.TicketNo).length > 0 ? "TicketACtive" : ""}>{x.TicketNo} {this.state.cartItems.filter(xy => xy.id == x.TicketNo).length > 0 && <i class="fa fa-check tickIcon" aria-hidden="true"></i>}</a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="cartArea">
                                    <h3>{this.props.t('lblgameslip')}</h3>
                                    <p>You've got 30% of chance to win.shop more tickets to get more chance</p>
                                    <div class="dropdown-content2">

                                        {(this.state.cartItems && this.state.cartItems.length > 0) ? <ul class="scrollbar">
                                            <li>
                                                <span>{this.props.t('lblTicketNo')}</span>
                                                <span>{this.props.t('lblAmount')}</span>
                                            </li>
                                            {this.state.cartItems.map((x, indx) =>
                                                <li key={`bet_${indx}`}>
                                                    <span>{x.TicketNo}</span>
                                                    <span>{x.amount}</span>
                                                    <small><a onClick={() => this.removeFromCart(x.id)}><i class="fa fa-close"></i></a></small>
                                                </li>
                                            )}
                                        </ul> :
                                            <h3 style={{ color: "#f1b61c" }}>{this.props.t('lblPlaceYourTicket')}</h3>}
                                    </div>
                                    <div class="cartTotal">
                                        <ul>
                                            <li><label>{this.props.t('lblTotalTicket')}</label><span>{this.state.cartItems.length}</span></li>
                                            <li><label>{this.props.t('lbltotamt')}</label><span>{this.state.cartItems.reduce((a, b) => a = a + b.amount, 0)}</span></li>
                                        </ul>
                                    </div>
                                    <div class="lottoBtns">
                                        <button class="redBg" onClick={() => this.clearCart()}>{this.props.t('lblclear')}</button>
                                        <button class="YellowBg" onClick={() => this.BuyLotteryBet()}>{this.props.t('lblBuy')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { currentGamesFamily, currentAvailableDraws, myBet, latestResult } = state.gameReducer;
    const { serverTime } = state.commonReducer;
    const { user } = state.loginReducer;
    const { cartItems, currentDraw } = state.lottoReducer;
    const { AvailableTickets } = state.raffleReducer;
    return { currentGamesFamily, user, currentAvailableDraws, cartItems, currentDraw, serverTime, myBet, latestResult, AvailableTickets };
}

const mapActionToProps = {
    GameFamily: gameActions.GameFamily,
    GameAvailableGames: gameActions.GameAvailableGames,
    AddToCart: lottoActions.AddToCart,
    RemoveFromCart: lottoActions.RemoveFromCart,
    RemoveClosedDrawbetFromCart: lottoActions.RemoveClosedDrawbetFromCart,
    SetCurrentDraw: lottoActions.SetCurrentDraw,
    ClearCart: lottoActions.ClearCart,
    Buybet: raffleActions.Buybet,
    GetLatestResult: gameActions.GetLatestResult,
    ClearAvailableGames: gameActions.ClearAvailableGames,

    GetSearchTicket: raffleActions.GetSearchTicket,
    ClearSelectionTicket: raffleActions.ClearSelectionTicket,
    ClearSearchTicket: raffleActions.ClearSearchTicket
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(RaffleBike))