import {appConstants} from "../helpers/actionType";
export const raffleReducer=(state={AvailableTickets:[]},action)=>{
    switch(action.type)
    {
        case appConstants.SET_SEARCH_TICKET:                        
          return {
            ...state,
            AvailableTickets:action.lstTicket
          };      
        default:
          return {...state}
    }

}