import React, { Component } from 'react'
import { connect } from 'react-redux';
import Pick3Left from "./pick3Left";
import Pick3Right from "./pick3Right";
import { gameActions } from '../../../action/gamesAction';
import { quickWinActions } from '../../../action/quickWinAction';
import Countdown, { zeroPad } from 'react-countdown';
import WebGLApp from "./webGL";
import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
//Pick3 Index
class pick3 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cartItems: [],
			ballItem: [],
			currentRunningDraw: {},
			currentFinishingDraw: {},
			coundownStart: 0,
			coundTime: 18900000,
			currentDraw: {},
			currentAvailableDraws: [],
			currentCartItem: { id: 0, balls: [], stakeValue: 300, oddsValue: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, drawNo: 0, gameID: 0, gameGroupID: 0, ballCount: 0, combination: 0, multiStake: 0, maxStake: 10000, minStake: 300 },

			ballStartNumber: 0,
			ballEndNumber: 0,
			QPBallCount: 0,
			random: [],
			showSingleMsg: false,
			_serverTimeDiff: 0,
			allGameClosed: 0,
			isViewAnimation: false,
			webglInit: false,
			timerGetResult: null,
			timerGetLatestResult: null,
			timerGetResultCount: 0,
		};
		this.my_componentWillUnmount();
		if (props.currentGamesFamily.id !== undefined) {
			props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id, this.props.i18n.language);
			props.GetLatestResult(props.currentGamesFamily.id, this.props.i18n.language);
			// let ss = Date.now;
			// let ssss = ss();
			// console.log("Date.now : " + ss);
		}
	}

	calculateLottoPermCombinations = (classType, ballscnt) => {
		try {
			var i;
			var Numerator = 1;
			var Denominator = 1;
			var NetResult, Perm;
			Perm = classType;
			for (i = 0; i < Perm; i++) {
				Numerator = Numerator * ballscnt;
				ballscnt = ballscnt - 1;
			}
			do {
				Denominator = Denominator * Perm;
				Perm = Perm - 1;
			} while (Perm != 0);
			NetResult = Numerator / Denominator;
			return NetResult;
		} catch (ex) {
		}
		return 0;
	};

	checkBallCountAndCalculateCombination = (selectedCount) => {
		let default_Min_val = this.state.currentGamesFamily.minSelect;
		let default_max_val = this.state.currentGamesFamily.maxSelect;
		let default_val = default_Min_val;
		let resultStr = "";
		if (default_Min_val <= selectedCount && default_max_val >= selectedCount) {
			resultStr = "1~" + this.calculateLottoPermCombinations(default_val, selectedCount);
		}
		else {
			if (default_max_val < selectedCount) {
				resultStr = "0~" + this.props.t('lblMaximumselection') + " : " + default_max_val;
			}
			else {
				resultStr = "1~0";
			}
		}
		return resultStr;
	};

	static getDerivedStateFromProps(props, state) {
		return {
			currentAvailableDraws: props.currentAvailableDraws,
			cartItems: props.cartItems,
			currentDraw: props.currentDraw,
			currentGamesFamily: props.currentGamesFamily,
			latestResult: props.latestResult,
			currentDraw: props.currentDraw,
			currentResult: props.currentResult
		};
	};

	createBall = () => {
		let ballLst = [];
		let pick3Lst = [];
		if (this.state.currentGamesFamily != undefined && this.state.currentDraw != null && Object.keys(this.state.currentDraw).length > 0) {
			for (let j = 1; j <= 3; j++) {
				let row_i = j;
				for (let i = this.state.ballStartNumber; i <= this.state.currentGamesFamily.ball + this.state.ballStartNumber; i++) {
					let b_i = i;
					ballLst.push(<div id={`ball_${b_i}`} onClick={() => { this.selectBall(b_i, row_i) }} className={`lottoBalls2 ${this.state.currentCartItem.balls.filter(x => x.id == row_i + "_" + b_i).length > 0 ? "lottoBallsActive" : ""}`}>{b_i}</div>);
				}
				pick3Lst.push(<li id={`row_${row_i}`}>{ballLst}</li>);
				ballLst = [];
			}

			this.state.ballEndNumber = this.state.currentGamesFamily.ball + (-1 + this.state.ballStartNumber);
		}
		else {
			return <div style={{ color: "#000" }}>Draw Closed</div>;
		}
		return pick3Lst;
	};

	showSingleMsg = false;
	selectBall = (b_id, row_id, isQP) => {
		//alert("isQP");
		if (this.state.currentDraw == null || Object.keys(this.state.currentDraw).length == 0) {
			this.state.showSingleMsg = true; alert(this.props.t('lblSelectDraw')); return;
		}
		let obj1 = row_id + "_" + b_id;
		let obj = this.state.currentCartItem.balls.filter(x => x.id == obj1);
		if ($("#row_" + row_id + " #ball_" + b_id).hasClass("lottoBallsActive") && isQP != 2) {
			$("#row_" + row_id + " #ball_" + b_id).removeClass("lottoBallsActive");
		}
		else {
			var objBall = $("#row_" + row_id + " div.lottoBalls2.lottoBallsActive").length;
			if (objBall > 0 && isQP != 2) {
				alert('Invalid Combination');
				return;
			}
		}
		let cnt = 0;
		if (obj.length <= 0) {
			cnt = this.state.currentCartItem.ballCount + 1;
		}
		else {
			cnt = this.state.currentCartItem.ballCount - 1;
		}
		var checkResult = this.checkBallCountAndCalculateCombination(cnt);
		let arrResult = checkResult.split('~');
		if (arrResult[0] == '0') { this.state.showSingleMsg = true; alert(arrResult[1]); return; }

		let objLst = this.state.currentCartItem;
		if (obj.length > 0) {
			objLst.ballCount--;
			objLst.balls = this.state.currentCartItem.balls.filter(x => x.id !== obj1);
		}
		else {
			objLst.gameName = this.state.currentDraw.LotName;
			objLst.drawID = this.state.currentDraw.DrawID;
			objLst.gameID = this.state.currentDraw.GameId;
			objLst.drawNo = this.state.currentDraw.DrawNo;
			objLst.drawDateTime = this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime
			objLst.ballCount++;
			objLst.oddsValue = this.state.currentDraw.RateInPs;
			objLst.multiStake = 50;

			///by thamil for fix the re order issue
			let newid = 1;
			if (this.state.cartItems.length > 0) {
				newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.bMax), this.state.cartItems[0].bMax);
			}
			objLst.bMax = newid + 1;
			objLst.balls.push({ id: obj1 });
		}
		objLst.combination = parseInt(arrResult[1]);
		objLst.total = objLst.stakeValue * objLst.oddsValue;

		this.setState({
			currentCartItem: objLst
		});

	}

	selectDraw = (_draw) => {
		this.props.SetCurrentDraw(_draw);
	};

	selectRunningDraw = () => {
		if (this.state.coundownStart != undefined && this.state.coundownStart == 0) {

			//if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			if (this.state.currentFinishingDraw != undefined) {
				//if (this.state.currentFinishingDraw.DrawID == undefined || this.state.currentRunningDraw.DrawID == undefined || this.state.currentFinishingDraw.DrawID !=this.state.currentRunningDraw.DrawID) {
				let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
				if (lst.length > 0 || this.state.currentAvailableDraws.length <= 0) {
					let _draw = this.state.currentAvailableDraws[0];
					//let _draw = lst[0];
					this.setState({
						currentRunningDraw: _draw,
					})
					this.state.currentRunningDraw = _draw;
					this.selectDraw(_draw);
				}
				else {
					return;
				}
			}

			// if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			// 	let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
			// 	if (lst.length > 0) {
			// 		return;
			// 	}
			// }

			let _draw = this.state.currentAvailableDraws[0];
			if (_draw == undefined) { this.state.coundownStart = 1; return; }
			try {
				let sTime = undefined; //new Date(Date.now() - this.state.coundTime);
				if (_draw != undefined) {
					var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
					let ss = new Date(_draw.DrawDateTime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
					sTime = new Date(ss.setSeconds(ss.getSeconds() - 60));
				}
				let dStr = ("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + sTime.getHours() + ":" + sTime.getMinutes() + ":" + sTime.getSeconds();
				window.timer = null;
				this.state.coundownStart = window.startDraw(dStr, this.RefreshAfterGameClose);
			}
			catch (ex) {
				alert(ex);
			}
		}
	};

	componentDidUpdate(previousProps, previousState) {
		if ((previousState.currentResult != this.state.currentResult) || (this.state.currentResult && this.state.currentResult.indexOf("1~") == 0)) {
			console.log("start startAnimationWebGL in componentDidUpdate with : " + this.state.currentResult);
			this.startAnimationWebGL();
		}
		if (this.state.currentAvailableDraws.length > 0 && ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length <= 0) || this.state.currentAvailableDraws[0].DrawNo != this.state.currentRunningDraw.DrawNo))
		//if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0)) 
		{
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				this.state.allGameClosed = 1
			}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
			if (this.state.webglInit == false && this.state.currentRunningDraw) {
				this.setWelcomScreenWebGL();
				this.state.webglInit = true;
			}
		}
	};

	componentDidMount() {
		//window.documentReadyjQuery();
		//setTimeout(	this.RefreshAfterGameClose,10000);
		//setInterval(() => {		this.RefreshAfterGameClose()	 }, 60*1000); 
		//window.documentReadyjQuery();
		window.addEventListener('resize', window.documentReadyResize);
		//window.loadWebGL();
		//window.WelcomeAnimationScreen();
		//this.setWelcomScreenWebGL();
	};
	my_componentWillUnmount = () => {
		this.props.ClearCart();
		this.setState({
			currentRunningDraw: null,
		})
		this.props.ClearAvailableGames();
	}

	RefreshAfterGameClose = () => {
		this.state.coundownStart = 0;
		this.state._serverTimeDiff = 0;
		this.state.currentFinishingDraw = this.state.currentRunningDraw;

		this.props.RemoveClosedDrawbetFromCart(this.state.currentFinishingDraw.DrawID);
		setTimeout(() => { this.props.GameAvailableGames(this.props.user.mobileNo, this.props.currentGamesFamily.id, this.props.i18n.language) }, 2000)
		this.state.currentRunningDraw = {};
		console.log("RefreshAfterGameClose drawID : " + this.state.currentFinishingDraw.GameId)
		this.state.timerGetResultCount = 0;
		this.state.timerGetResult = setTimeout(() => { this.props.GetCurrentResult(this.state.currentFinishingDraw.GameId) }, 5000);

	};


	setWelcomScreenWebGL = () => {
		if (this.state.currentRunningDraw && this.state.currentRunningDraw.DrawDateTime) {
			var s = this.state.currentRunningDraw.DrawDateTime.split(' ');
			var strkey = "ThOtArPuElLaThA~" + this.state.currentRunningDraw.LotName + "~" + this.state.currentRunningDraw.DrawNo + "~" + s[0] + "~" + s[1] + "";
			//let strkey = "ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2";
			console.log("start setWelcomScreenWebGL");
			this.fnWelcome(strkey);
		}
	}
	startAnimationWebGL = () => {
		//alert("call animate");
		//this.state.currentResult="0~1~142~05/09/2021~10:00 AM~Quick Win~978";
		if (this.state.currentResult) {
			let arr = this.state.currentResult.split("~");
			if (arr[0] == "0") {
				//var s = this.state.currentRunningDraw.Drawdatetime.split(' ');
				//var strkey = "ThOtArPuElLaThA~" + this.state.currentRunningDraw.GameName + "~" + this.state.currentRunningDraw.GameID + "~" + s[0] + "~" + s[1] + "";
				let strkey = "ThOtArPuElLaThA~" + this.state.currentResult.substring(2, this.state.currentResult.length);
				var s = this.state.currentDraw ? this.state.currentDraw.DrawTime : "No More";
				if (s != null) {
					strkey = strkey + "~" + s;
				}
				else {
					strkey = strkey + "~00:00";
				}
				console.log("start startAnimationWebGL with : " + strkey);
				this.viewAnimationResult(1);
				this.fnStartAnimation(strkey);
				clearTimeout(this.state.timerGetResult);
				this.state.timerGetResult = null;
				this.state.timerGetResultCount = 0;
				this.state.timerGetLatestResult = setTimeout(() => { this.props.GetLatestResult(this.props.currentGamesFamily.id, this.props.i18n.language) }, 45000);
			}
			else {
				if (this.state.timerGetResult)
					clearTimeout(this.state.timerGetResult);
				if (this.state.timerGetResultCount < 5) {
					console.log("GetCurrentResult drawID : " + this.state.currentFinishingDraw.GameId)
					this.state.timerGetResult = setTimeout(() => { this.props.GetCurrentResult(this.state.currentFinishingDraw.GameId, this.props.i18n.language) }, 2000);
				}
				this.state.timerGetResultCount++;
				//	setTimeout(this.startAnimationWebGL(), 3000);
			}
		}
		else {
			//	this.props.GetCurrentResult(this.state.currentDraw.DrawNo)
			//	setTimeout(this.startAnimationWebGL(), 3000);
		}
	}

	fnWelcome = () => {
	}
	fnStartAnimation = () => {
	}
	viewAnimationResult = (flog) => {
		if (flog == 1 && this.state.isViewAnimation == true) {
			return;
		}
		this.setState({ isViewAnimation: !this.state.isViewAnimation });
	}

	addToCart = () => {
		if (this.state.currentCartItem.combination <= 0) { alert(this.props.t('lblCompleteGame')); return; }
		let newid = 1;
		if (this.state.cartItems.length > 0) {
			newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.id), this.state.cartItems[0].id);
		}
		this.state.currentCartItem.id = newid + 1;
		this.props.AddToCart(this.state.currentCartItem);
		this.clearAfterAddToCart();
	};

	clearAfterAddToCart = () => {
		this.setState({
			currentCartItem: { id: 0, balls: [], stakeValue: 300, oddsValue: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, drawNo: 0, gameID: 0, gameGroupID: 0, ballCount: 0, combination: 0, multiStake: 0, maxStake: 10000, minStake: 300 },

			random: [],
			QPBallCount: 0
		});
	};

	clearCart = () => {
		this.props.ClearCart();
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};
	quickPick = () => {
		if (this.state.currentCartItem.balls.length == this.state.currentGamesFamily.maxSelect) {
			this.state.currentCartItem = { id: 0, balls: [], stakeValue: 300, oddsValue: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, drawNo: 0, gameID: 0, gameGroupID: 0, ballCount: 0, combination: 0, multiStake: 0, maxStake: 10000, minStake: 300 };
			this.clearAfterAddToCart();
		}
		this.state.random = this.state.currentCartItem.balls.map(a => a.id);
		this.state.QPBallCount = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		if (this.state.QPBallCount <= 0) {
			this.state.QPBallCount = this.state.currentGamesFamily.maxSelect;
		}
		let row_id = 0;
		var index = -1;
		for (var k = this.state.ballStartNumber; k < this.state.QPBallCount; k++) {
			index = this.generateRandomInteger();
			let _bal = index
			row_id++;
			let d=this.state.currentCartItem.balls.filter(a=>a.id.indexOf(row_id)==0);
			if(d.length<=0)
			{
			this.selectBall(_bal,row_id,2);			
			}
			else{
				k--;
			}
			if (this.state.showSingleMsg == true) {
				this.state.showSingleMsg = false;
				return;
			}
		}
	};
	generateRandomInteger = () => {
		var min = this.state.ballStartNumber;
		var max = this.state.ballEndNumber;
		if (this.state.random.length >= this.state.QPBallCount) {
			this.state.random = [];
		}
		for (var i = min; i <= max; i++) {
			var temp = Math.floor(max - Math.random() * (max - min));
			//temp = ("0" + temp).slice(-2);
			//temp=i;
			if (this.state.random.indexOf(temp) == -1) {
				this.state.random.push(temp);
				return temp;
			}
		}
	};

	removeFromCart = (cid) => {
		this.props.RemoveFromCart(cid);
	};

	countdownCompleted = () => {
		this.RefreshAfterGameClose();
	};

	getGameTime = () => {
		let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
		if (_date != undefined && _date != "") {
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
			console.log("getGameTime : " + sTime);
			console.log("DateNow : " + Date.now());
			return sTime;
		}
		else {
			return 0;
		}
	};

	// Renderer callback with condition
	renderer = ({ total, days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state			
			return <span>Draw Closed!</span>;
		} else {
			// Render a countdown
			return <span>{zeroPad(total)}:{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
		}
	};

	getServerTime = () => {
		if (this.state._serverTimeDiff != 0 && this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0) {
			//return this.state._serverTimeDiff+Date.now()+1000;
			console.log("getServerTime : " + this.state._serverTimeDiff);
			return this.state._serverTimeDiff = (this.state._serverTimeDiff + 1000);
		}
		else {
			let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
			if (_date != undefined && _date != "") {
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
				var sTime = (new Date(ss.setSeconds(ss.getSeconds() - 10)).getTime());
				//this.state._serverTimeDiff=Date.now()-sTime;
				this.state._serverTimeDiff = sTime;
				console.log("getServerTime : " + sTime);
				console.log("DateNow : " + Date.now());
				return sTime;

			}
			else {
				return 0;
			}
		}
	};

	reCount = (rundraw) => {
		//return <span class="DropCnt" data-pid={this.state.currentDraw ? this.state.currentDraw.DrawID : ""} data-countdown={this.state.currentDraw ? this.state.currentDraw.DrawDateTime : ""}></span>;
		//return <span class="DropCnt" data-pid={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawID : ""} data-countdown={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : ""}></span>;		
		//return <span><Countdown now={Date.now} date={Date.now() + 100000} onTick={this.rendererTick()} /> </span>;

		if (this.state.currentDraw == null || rundraw == null || Object.keys(rundraw).length == 0) {

			return <span></span>;
		}
		else {
			this.state.coundownStart = 1;
			return <span key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime()} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{this.props.t('lblnextdraw')} {zeroPad((days * 24) + hours)}hh:{zeroPad(minutes)}mm:{zeroPad(seconds)}ss</div>) }} /> </span>;
		}
	};

	BuyLotteryBet = () => {
		if (this.props.user == undefined || this.props.user.mobileNo == "0") {
			alert(this.props.t('lblLoginValidation'));
			return;
		}
		if (this.state.cartItems == undefined || this.state.cartItems.length <= 0) {
			alert(this.props.t('lblplcurbet'));
			return;
		}
		let totStake = this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0);
		let minTotStake = 500;
		let maxTotStake = 20000;
		if (this.state.currentGamesFamily != undefined) {
			minTotStake = this.state.currentGamesFamily.minStakePerTrans;
			maxTotStake = this.state.currentGamesFamily.maxStakePerTrans;
		}

		if (totStake < minTotStake)//300
		{
			alert(this.props.t('lblMinimumplayAmt') + " : " + minTotStake);
			return;
		}
		else if (totStake > maxTotStake)//10000
		{
			alert(this.props.t('lblMaximumplayAmt') + " : " + maxTotStake);
			return;
		}

		var sTime = new Date(this.props.serverTime);
		//let cartList = this.state.cartItems.sort((a, b) => (a.drawID > b.drawID) ? 1 : -1);		
		let cartList = this.state.cartItems.sort((a, b) => a.drawID - b.drawID);
		let transactionString = "";
		let gamestring = "";
		let subgameId = 0;
		cartList.forEach(item => {
			if (subgameId != 0 && subgameId != item.drawID) {
				gamestring += "*";
			}
			if (subgameId != item.drawID) {
				gamestring += item.drawID + "-" + item.gameID;
			}
			else {
				gamestring += item.gameID;
			}
			gamestring += "^0";
			let ballData = item.balls.reduce((result, item) => { return `${result}${item.id.split('_')[1]}` }, "");
			gamestring += "^" + ballData;
			gamestring += "^" + parseInt(item.stakeValue);
			gamestring += "^" + parseInt(item.total);
			gamestring += "^" + item.oddsValue;
			gamestring += "^0^|";
			subgameId = item.drawID;
		})
		gamestring += "*";
		let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);

		let betoption = "2";

		transactionString += `'${this.props.user.mobileNo}',`;
		transactionString += `'${TransID}',`;
		transactionString += `'${gamestring}',`;
		transactionString += `'-',`;
		transactionString += `${betoption},`;
		transactionString += `'${this.props.user.loginKey}','0','0','${this.props.i18n.language}'`;

		this.props.BuybetPick3(transactionString);
		this.clearCart();
	};

	render() {
		const { t } = this.props;
		return (
			<div className="mainContainer">
				<Header />
				<div className="Gamesection">
					<div class="container">

						<Pick3Left selectDraw={this.selectDraw} currentAvailableDraws={this.state.currentAvailableDraws} />
						<div className="col-md-9 col-sm-12 smMbl">

							<div className="drawTabs background-color border-color">
								<a className="" id="drawTabs1">{t('lblAllDraws')}</a>
								<a className="" id="drawTabs2">{t('lbllatresult')}</a>

								<a class="" id="virtualbetSlipBtn">{t('lblgameslip')} <span class="betCount">{this.state.cartItems.length}</span></a>
							</div>
							<div class="drawTabs1024">
								<a class="" id="drawTabs3">{t('lblAllDraws')}</a>
							</div>
							{/* <div class="BetSLiparea">
								<div class="BetSLipBtn1024">
									<a id="virtualbetSlipBtn" href="#">Game Slip</a><span class="betCount"> {this.state.cartItems.length}</span>
								</div>
							</div> */}

							<div class="lottoTimesec">
								<span><img src="./assets/img/icons/Watatu_B.png" /></span>

								<div class="TimeSec">
									<small style={{ borderRight: "1px solid #fff" }}>{this.reCount(this.state.currentRunningDraw)}</small>
									<small>{this.state.currentDraw ? this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime : '-'}</small>
								</div>
							</div>


							<div class="lottoGame">
								<div class="col-md-12">
									<span id="eyeIcon" class={`eyeIcon eyeOpen ${this.state.isViewAnimation ? 'eyeClose' : ''}`} onClick={() => { this.viewAnimationResult() }} >{this.state.isViewAnimation ? t('lblHide') : t('lblShow')}</span>
									<div class="animateSec" style={{ display: this.state.isViewAnimation ? "block" : "none" }}>
										<WebGLApp fnWelcome={(dd) => { this.fnWelcome = dd; }} fnStartAnimation={(dd) => { this.fnStartAnimation = dd; }} />
									</div>
								</div>
								<div class="col-md-8">
									<div class="lottoInnr">
										<div class="lottoInnrTime">
											<span>{t('lbloddsval')} : <small>{this.state.currentDraw ? this.state.currentDraw.RateInPs : 0}</small></span>
										</div>
										<ul className="lottoBalCont3">
											{this.createBall()}
										</ul>
										<div className="lottoBtns">
											<button className="redBg" onClick={() => { this.clearAfterAddToCart() }}>{t('lblclear')}</button>
											<button className="YellowBg" onClick={() => { this.quickPick() }}>QP</button>
											<button className="redBg" onClick={() => { this.addToCart() }}>{t('lbladd')}</button>
										</div>
									</div>
								</div>
								<Pick3Right buyLotteryBet={this.BuyLotteryBet} clearCart={this.clearCart} removeFromCart={this.removeFromCart} latestResult={this.state.latestResult} />
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	};
}

function mapStateToProps(state) {
	const { currentGamesFamily, currentAvailableDraws, myBet, latestResult } = state.gameReducer;
	const { serverTime } = state.commonReducer;
	const { user } = state.loginReducer;
	const { cartItems, currentDraw, stakeAll, currentResult } = state.quickWinReducer;
	return { currentGamesFamily, user, currentAvailableDraws, cartItems, currentDraw, serverTime, myBet, stakeAll, latestResult, currentResult };
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	AddToCart: quickWinActions.AddToCart,
	RemoveFromCart: quickWinActions.RemoveFromCart,
	RemoveClosedDrawbetFromCart: quickWinActions.RemoveClosedDrawbetFromCart,
	SetCurrentDraw: quickWinActions.SetCurrentDraw,
	ClearCart: quickWinActions.ClearCart,
	BuybetPick3: quickWinActions.BuybetPick3,
	GetLatestResult: gameActions.GetLatestResult,
	GetCurrentResult: quickWinActions.GetCurrentResult,
	ClearAvailableGames: gameActions.ClearAvailableGames
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(pick3));




//dsfas