
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
//import { loginActions } from '../../action/loginAction';
import { withTranslation  } from 'react-i18next';
class UnderConsruction extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    };

    render() {
		const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    <span class="underCons">{t('lblUnderCons')}</span>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(UnderConsruction))