import React, { Component } from 'react'
import { connect } from 'react-redux';
import { quickWinActions } from '../../../action/quickWinAction';
import { withTranslation } from 'react-i18next';
//Pick3 Right
class pick3Right extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stake: 0
        }
    }

    setUpdateStake = (stake) => {
        this.setState({
            stake: stake
        })
        this.props.UpdateStakeAll(stake);
    };

    updatesStakeValue = (bid, flog) => {
        if (this.state.cartItems != null) {
            let lst = this.state.cartItems.filter(a => a.id == bid);
            if (lst != null && lst != undefined && lst.length > 0) {
                lst = lst[0];
                if (flog == "+") {
                    if (lst.stakeValue + lst.multiStake > lst.maxStake) {
                        alert(this.props.t('lblMaximumstake') + " : " + lst.maxStake);
                        return;
                    }
                    else
                        lst.stakeValue = lst.stakeValue + lst.multiStake;
                }
                else if (flog == "-") {
                    if (lst.stakeValue - lst.multiStake < lst.minStake) {
                        alert(this.props.t('lblMinimumstake') + " : " + lst.minStake);
                        return
                    }
                    else
                        lst.stakeValue = lst.stakeValue - lst.multiStake;
                }
                lst.total = lst.stakeValue * lst.oddsValue;
                this.props.UpdateCartAll(lst);
            }
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        };
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="col-md-4">
                    <div class="betslip">
                        <h2>{t('lblgameslip')}</h2>
                        {this.state.cartItems && this.state.cartItems.length <= 0 && <div className="betError2">
                            <h2 className="warningMsg bounceIn animated infinite">{t('lblplcurbet')}</h2>
                        </div>}
                        <div className="betSlip2 scrollbar">

                            <ul className="betSlipCont" style={{ width: "100%" }}>
                                {this.state.cartItems && this.state.cartItems.map((x, indx) =>
                                    <li className="toggle" key={`bet_${indx}`}>
                                        <div class="betSlipLi">
                                            <div class="betValue3">
                                                {x.balls && x.balls.map((bx, indx1) => <span key={`lstrslb_${indx1}`} id={`${x.id}_${bx.id}`} className="circle3">{(bx.id).split('_')[1]}
                                                </span>
                                                )}</div>
                                            <div class="drawNumber1"><span>#{x.drawNo}</span></div>
                                            <div class="betValueEntrSec">
                                                <div class="betValueEntrCont">
                                                    <span class="minusSign" onClick={() => { this.updatesStakeValue(x.id, "-") }}>-</span>
                                                    <input readOnly={true} class="betValueInput" type="textbox" value={x.stakeValue} />
                                                    <span class="plusSign" onClick={() => { this.updatesStakeValue(x.id, "+") }}>+</span>
                                                </div>
                                            </div>
                                            <div class="betValueEntr">
                                                <div class="betValueEntrCont">
                                                    <span class="betValueEntr2Lft">{x.total}</span>
                                                    <span class="betValueEntr2Rht">{x.oddsValue}<small>X</small></span>
                                                </div>
                                            </div>
                                            <div class="betClose3">
                                                <a class="betCloseBtn2" onClick={() => { this.props.removeFromCart(x.id) }}><i class="fa fa-times" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </li>
                                )}

                            </ul>
                        </div>

                        <div class="countArea">
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotbet')} : {this.state.cartItems.length}</span>
                            </div>
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotstak')} : {this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0)}</span>
                            </div>
                        </div>
                        <div class="lottoBtns">
                            <button class="redBg" onClick={() => { this.props.clearCart() }}>{t('lblclear')}</button>
                            <button class="YellowBg" onClick={() => { this.props.buyLotteryBet() }}>{t('lblBuy')}</button>
                        </div>
                        {/* <div class="enterBetValBtom border-color background-color">
                            <span class="border-color-right">ENTER STAKE VALUE</span>
                            <input type="text" placeholder="0" value={this.state.stake} onChange={(e) => this.setUpdateStake(e.target.value)}/>
                        </div> */}
                    </div>
                    <div class="latestRslt formrgn">
                        <h2>{t('lbllatresult')}</h2>
                        <ul class="recentRsltCont scrollbar">
                            {this.props.latestResult ? this.props.latestResult.map((x, indx) =>
                                <li>
                                    <div class="drawNoTime">
                                        <div class="drawCount2">
                                            {x.WinningNo.split("").map((bx, indx1) =>
                                                <span class="circle2" key={`lstrsld_${indx1}`} id={`${x.DrawNo}_${bx}`}>{bx}</span>
                                            )}</div>
                                        <div class="drawNo"><span>#{x.DrawNo}</span></div>
                                        <div class="drawTime2"><span>{x.Drawdatetime}</span></div>
                                    </div>
                                </li>) : <div></div>}
                        </ul>
                        {/* <div class="viewAllBets border-color background-color">
                        <Link to="/result">View All Results</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { cartItems } = state.quickWinReducer;
    return { user, cartItems };
}

const mapActionToProps = {
    UpdateStakeAll: quickWinActions.UpdateStakeAll,
    UpdateCartAll: quickWinActions.UpdateCartAll,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(pick3Right))