import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';
import { gameActions } from '../../action/gamesAction';
class HowToPlay extends Component {
    constructor(props) {
        super(props);
        this.state = { cTab: 4 }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    };

    SelectGameFamily = (inx) => {
        var item = this.props.games.filter(x => x.id == inx);
        // item.ball = parseInt(item.ball);
        // item.minSelect = parseInt(item.minSelect);
        // item.maxSelect = parseInt(item.maxSelect);
        if (item && item.length > 0) {
            this.props.SelectGameFamily(item[0]);
            //this.props.GameAvailableGames(this.props.user.mobileNo, item.id);
        }
    };
    static getDerivedStateFromProps(props, state) {
        return {
            gameFamily: props.games.filter(a => a.id == 4),
        };
    };

    render() {
        const { t, i18n } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="Aboutgame">
                    <h2 >{t('lblAboutGames')}</h2>
                </div>
                <div class="container">
                    <div class="betTabs">
                        <ul id="betaccTabLi">
                            <li onClick={() => this.setState({ cTab: 4 })} class={this.state.cTab == 4 ? "Active" : ""}><a class="tabBg6 text-center">Igitego Million</a></li>
                            <li onClick={() => this.setState({ cTab: 3 })} class={this.state.cTab == 3 ? "Active" : ""}><a class="tabBg6 text-center">Quick 10</a></li>
                            <li onClick={() => this.setState({ cTab: 1 })} class={this.state.cTab == 1 ? "Active" : ""}><a class="tabBg5 text-center" >Impamo Jackpot</a></li>
                            <li onClick={() => this.setState({ cTab: 2 })} class={this.state.cTab == 2 ? "Active" : ""}><a class="tabBg6 text-center">Quick lotto</a></li>
                            <li onClick={() => this.setState({ cTab: 5 })} class={this.state.cTab == 5 ? "Active" : ""}><a class="tabBg6 text-center">Watatu</a></li>
                            <li onClick={() => this.setState({ cTab: 6 })} class={this.state.cTab == 6 ? "Active" : ""}><a class="tabBg6 text-center">Igitego-Moto</a></li>
                            <li onClick={() => this.setState({ cTab: 7 })} class={this.state.cTab == 7 ? "Active" : ""}><a class="tabBg6 text-center">Igitego-Iphone</a></li>
                            {/* <li  class={this.state.cTab == 6 ? "Active" : ""}><a class="tabBg6 text-center">Roulette</a></li> */}
                        </ul>
                        <div class="BetaccTabsRht">
                            {this.state.cTab == 1 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Impamo Jackpot</h3>
                                    <p>{t('lblHowToplayjackpot')}</p>
                                    {/* <Link to="/lotto">{t('lblGetstarted')}</Link>     */}
                                    <a onClick={(e) => { this.SelectGameFamily(4) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/1omC_017uRc?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        {/* <img style={{width:"100%",marginTop:"5px"}} src="./assets/img/Jackpot prize.jpg?ver2.0"/> */}
                                        <div class="JackpotImg">
                                            {/* <img src="./assets/img/JPrizeTemp.jpg" /> */}
                                            <img src={process.env.REACT_APP_BANNER_URL + 'JackpotPrize.webp?v1'} />
                                            <div class="JackpotImgPoshwplay">
                                                {this.state.gameFamily && this.state.gameFamily.length > 0 && this.state.gameFamily[0].prizeInfo != "" && this.state.gameFamily[0].prizeInfo.split('^').map((x, indx) =>
                                                    <span>{x}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b> Open an internet browser and go to www.inzozilotto.rw </p>
                                            <p><b>Step 2:</b> On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b> Input your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b> On the home page, go to “Impamo Jackpot” and click on “Play Now”</p>
                                            <p><b>Step 5:</b>                          <br />
                                                •  Select 5 numbers of yourchoice between 01 and 35 and they will be added automatically to the game slip. <br />
                                                •  For computer generated numbers, click on “QP” Quick Pick
                                            </p>
                                            <p><b>Step 6:</b> Click on “ADD” to buy the ticket</p>
                                            <p><b>Step 7:</b> Click on “Game Slip”</p>
                                            <p><b>Step 8:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 9:</b><br />
                                                •    After clicking on buy, you can view your confirmed ticket.<br />
                                                •   To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 10:</b> To view the games played, click on account name</p>
                                            <p><b>Step 11:</b> Click on “option”</p>
                                            <p><b>Step 12:</b> Click on “game details”</p>
                                            <p><b>Step 13:</b> A report will show all the games you played and your tickets’ status.</p>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p> <b>Intambweya 1:</b> Funguraurubugarwawww.inzozilotto.rw</p>
                                            <p><b>Intambweya 2:</b> Kanda “Sign in” kuri pajiibanza</p>
                                            <p><b>Intambweya 3:</b> Shyiramonimeroyaweyatelefonen’ijambo banga</p>
                                            <p><b>Intambweya 4:</b> Kanda kuri “games” maze ujye kuri “jackpot lotto”</p>
                                            <p><b>Intambweya 5:</b><br />
                                                •	Hitamoimibare 5 wifuzahagatiya 01 na 35<br />
                                                •	Ku mibareitangwa na mudasobwakandaahanditse“QP”</p>
                                            <p><b>Intambweya 6:</b> Kanda kuri “add”</p>
                                            <p><b>Intambweya 7:</b> Kanda kuri “Game slip”</p>
                                            <p><b>Intambweya 8:</b> Kuguraitike, kanda “buy”</p>
                                            <p><b>Intambweya 9:</b><br />
                                                •	Nyumayogukanda kuri buy, ushoborakurebaitikeyaweyemejwe.<br />
                                                •	Gusubirainyumakandakukamenyetso ka X kagaragaraiburyohejuru.</p>
                                            <p><b>Intambweya 10:</b> Kurebaimikinoumazegukina, kandaahanditseizina rya kontiyawe.</p>
                                            <p><b>Intambweya 11:</b> Kanda kuri “option”</p>
                                            <p><b>Intabweya 12:</b> Kanda kuri “game details”</p>
                                            <p><b>Intabweya 13:</b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatike yose yaguzwe</p>
                                        </div>}
                                </div>
                            </section>
                            }
                            {/*  Quick Lotto */}
                            {this.state.cTab == 2 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Quick lotto</h3>
                                    <p>{t('lblHowToplayQucik')}</p>
                                    {/* <Link to="/quickwin">{t('lblGetstarted')}</Link>       */}
                                    <a onClick={(e) => { this.SelectGameFamily(5) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/EwTGNZ6H7Zw?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b> Open an internet browser and go to www.inzozilotto.rw</p>
                                            <p><b>Step 2:</b> On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b> Input your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b> On the home page, go to “Quick Lotto” and click on “Play Now”</p>
                                            <p><b>Step 5:</b> <br />
                                                •	Select any number of your choice between 00 and 34 and it will be added automatically to the game slip.<br />
                                                •	You can select as many as you wish<br />
                                                •	For computer generated numbers, click on “QP” Quick Pick</p>
                                            <p><b>Step 6:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 7:</b> <br />
                                                •	After clicking on buy, you can view your confirmed ticket.<br />
                                                •	To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 8:</b> To view the games played, click on account name</p>
                                            <p><b>Step 9:</b> Click on “option”</p>
                                            <p><b>Step 10:</b> Click on “game details”</p>
                                            <p><b>Step 11:</b> A report will show all the games you played and your tickets’ status.</p>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p><b>Intambweya 1:</b> Funguraurubugarwawww.inzozilotto.rw</p>
                                            <p><b>Intambweya 2:</b> Kanda “Sign in” kuri pajiibanza</p>
                                            <p><b>Intambweya 3:</b> Shyiramonimeroyaweyatelefonen’ijambo banga</p>
                                            <p><b>Intambweya 4:</b> Kanda kuri “games” maze ujye kuri “Quick lotto”</p>
                                            <p><b>Intambweya 5:</b><br />
                                                •	Hitamoumubarewifuzahagatiya 00 na 34 urahitawongerwamoako kanya kugapapurokumukinokawe<br />
                                                •	Ushoboraguhitamoimibaremyinshiukoubyifuza<br />
                                                •	Ku mibareitangwa na mudasobwakandaahanditse “QP”</p>
                                            <p><b>Intambweya 6:</b> Kuguraitike, kanda “buy”</p>
                                            <p><b>Intambweya 7:</b><br />
                                                •	Nyumayogukanda kuri buy, ushoborakurebaitikeyaweyemejwe.<br />
                                                •	Gusubirainyumakandakukamenyetso ka X kagaragaraiburyohejuru.</p>
                                            <p><b>Intambweya 8:</b> Kurebaimikinoumazegukina, kandaahanditseizina rya kontiyawe.</p>
                                            <p><b>Intambweya 9:</b> Kanda kuri “option”</p>
                                            <p><b>Intambweya 10:</b> Kanda kuri “game details”</p>
                                            <p><b>Intambweya 11:</b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatike yose yaguzwe</p>
                                        </div>
                                    }
                                </div>
                            </section>
                            }
                            {/*  Quick 10 */}
                            {this.state.cTab == 3 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Quick 10</h3>
                                    <p>{t('lblHowToplayQuick10')}</p>
                                    <a onClick={(e) => { this.SelectGameFamily(3) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/EwTGNZ6H7Zw?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b> Open an internet browser and go to www.inzozilotto.rw</p>
                                            <p><b>Step 2:</b> On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b> Input your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b> On the home page, go to “Quick 10” and click on “Play Now”</p>
                                            <p><b>Step 5:</b> <br />
                                                •	Select any number of your choice between 00 and 09 and it will be added automatically to the game slip.<br />
                                                •	You can select as many as you wish<br />
                                                •	For computer generated numbers, click on “QP” Quick Pick</p>
                                            <p><b>Step 6:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 7:</b> <br />
                                                •	After clicking on buy, you can view your confirmed ticket.<br />
                                                •	To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 8:</b> To view the games played, click on account name</p>
                                            <p><b>Step 9:</b> Click on “option”</p>
                                            <p><b>Step 10:</b> Click on “game details”</p>
                                            <p><b>Step 11:</b> A report will show all the games you played and your tickets’ status.</p>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p><b>Intambweya 1:</b> Funguraurubugarwawww.inzozilotto.rw</p>
                                            <p><b>Intambweya 2:</b> Kanda “Sign in” kuri pajiibanza</p>
                                            <p><b>Intambweya 3:</b> Shyiramonimeroyaweyatelefonen’ijambo banga</p>
                                            <p><b>Intambweya 4:</b> Kanda kuri “games” maze ujye kuri “Quick 10”</p>
                                            <p><b>Intambweya 5:</b><br />
                                                •	Hitamoumubarewifuzahagatiya 00 na 09 urahitawongerwamoako kanya kugapapurokumukinokawe<br />
                                                •	Ushoboraguhitamoimibaremyinshiukoubyifuza<br />
                                                •	Ku mibareitangwa na mudasobwakandaahanditse “QP”</p>
                                            <p><b>Intambweya 6:</b> Kuguraitike, kanda “buy”</p>
                                            <p><b>Intambweya 7:</b><br />
                                                •	Nyumayogukanda kuri buy, ushoborakurebaitikeyaweyemejwe.<br />
                                                •	Gusubirainyumakandakukamenyetso ka X kagaragaraiburyohejuru.</p>
                                            <p><b>Intambweya 8:</b> Kurebaimikinoumazegukina, kandaahanditseizina rya kontiyawe.</p>
                                            <p><b>Intambweya 9:</b> Kanda kuri “option”</p>
                                            <p><b>Intambweya 10:</b> Kanda kuri “game details”</p>
                                            <p><b>Intambweya 11:</b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatike yose yaguzwe</p>
                                        </div>
                                    }
                                </div>
                            </section>
                            }
                            {/*  Raffle */}
                            {this.state.cTab == 4 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Igitego Million</h3>
                                    <p>{t('lblHowToplayRaffle')}</p>
                                    <a onClick={(e) => { this.SelectGameFamily(1) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/EwTGNZ6H7Zw?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b> Open an internet browser and go to www.inzozilotto.rw</p>
                                            <p><b>Step 2:</b> On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b> Input your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b> On the home page, go to “IGITEGO MILLION” and click on “Play Now”</p>
                                            <p><b>Step 5:</b> <br />
                                                <b>	Choose how you want your numbers;</b><br />
                                                1	The first two numbers<br />
                                                2	The last 2 numbers<br />
                                                3	Or whole numbers<br />
                                                4	You click on search for tickets<br />
                                                5	You are given tickets that have not been sold and you choose which ticket you want to buy.
                                            </p>
                                            <p><b>Step 6:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 7:</b> <br />
                                                •	After clicking on buy, you can view your confirmed ticket.<br />
                                                •	To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 8:</b> To view the games played, click on account name</p>
                                            <p><b>Step 9:</b> Click on “option”</p>
                                            <p><b>Step 10:</b> Click on “game details”</p>
                                            <p><b>Step 11:</b> A report will show all the games you played and your tickets’ status.</p>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p><b>Intambweya 1:</b> Funguraurubugarwawww.inzozilotto.rw</p>
                                            <p><b>Intambweya 2:</b> Kanda “Injira” kuripajiibanza</p>
                                            <p><b>Intambweya 3:</b> Shyiramonimeroyaweya telephone n’ijambo banga</p>
                                            <p><b>Intambweya 4:</b> Kanda ku “imikino” maze ujyekuri “IGITEGO MILLION”</p>
                                            <p><b>Intambweya 5:</b><br />
                                                <b>	Hitamouburyoushakaimibareyawe</b><br />
                                                1.	Imibareibiriibanza<br />
                                                2.	Imibare 2 iheruka<br />
                                                3.	CyangwaImibareyose<br />
                                                4.	Urakandakurishakishaamatike<br />
                                                5.	Urahabwaitikezitaragurwauhitemoiyowifuzakugura.
                                            </p>
                                            <p><b>Intambweya 6:</b><br />
                                                •	Nyumayogukandakurigura, ushoborakurebaitikeyaweyemejwe.<br />
                                                •	Gusubirainyumakandakukamenyetsoka X kagaragaraiburyohejuru.</p>
                                            <p><b>Intambweya 7:</b> Kurebaimikinoumazegukina, kandaahanditseizinaryakontiyawe.</p>
                                            <p><b>Intambweya 8:</b> Kanda kuri “option”</p>
                                            <p><b>Intambweya 9:</b> Kanda kuri “game details”</p>
                                            <p><b>Intambweya 10:</b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatikeyoseyaguzwe</p>
                                        </div>
                                    }
                                </div>
                            </section>
                            }
                            {/* Pick3 */}
                            {this.state.cTab == 5 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Watatu</h3>
                                    <p>{t('lblHowToplayPick3')}</p>
                                    <a onClick={(e) => { this.SelectGameFamily(7) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/EwTGNZ6H7Zw?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b>Open an internet browser and go to www.inzozilotto.rw</p>
                                            <p><b>Step 2:</b>On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b>your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b>On the home page, go to “WATATU” and click on “Play Now”</p>
                                            <p><b>Step 5:</b> <br />
                                                • Select 1 number of your choice between 0 and 9 and I will select each three rows with same.
                                                they will be added automatically to the game slip.
                                                • For computer generated numbers, click on “QP” Quick Pick

                                            </p>
                                            <p><b>Step 6:</b> Click on “ADD” to buy the ticket</p>
                                            <p><b>Step 7:</b> Click on “Game Slip”</p>
                                            <p><b>Step 8:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 9:</b>• After clicking on buy, you can view your confirmed ticket.
                                                • To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 10:</b> To view the games played, click on account name</p>
                                            <p><b>Step 11:</b> Click on “option”</p>
                                            <p><b>Step 12:</b> Click on “game details”</p>
                                            <p><b>Step 13:</b> A report will show all the games you played and your tickets’ status.</p>
                                            <h2>WINNING POSSIBILITIES</h2>
                                            <table>
                                                <tr>
                                                    <th>TYPE</th>
                                                    <th>MATCHING</th>
                                                    <th>ODDS</th>
                                                </tr>
                                                <tr>
                                                    <td>SEQ</td>
                                                    <td>MATCH ALL 3 IN THE SAME ORDER</td>
                                                    <td>X150</td>
                                                </tr>
                                                <tr>
                                                    <td>BOX</td>
                                                    <td>MATCH ALL 3 IN THE ANY ORDER</td>
                                                    <td>X20</td>
                                                </tr>
                                                <tr>
                                                    <td>FRONT PAIR</td>
                                                    <td>FIRST TWO IN THE SAME ORDER</td>
                                                    <td>X10</td>
                                                </tr>
                                                <tr>
                                                    <td>BACK PAIR</td>
                                                    <td>LAST TWO IN THE SAME ORDER</td>
                                                    <td>X10</td>
                                                </tr>
                                                <tr>
                                                    <td>LUCKY FIRST</td>
                                                    <td>MATCH THE FIRST NUMBER</td>
                                                    <td>X1.5</td>
                                                </tr>
                                            </table>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p><b>Intambweya 1 : </b> Funguraurubugarwa www.inzozilotto.rw</p>
                                            <p><b>Intambweya 2 : </b> Kanda "Injira" kuripajiibanza</p>
                                            <p><b>Intambweya 3 : </b> Shyiramonimeroyaweya telephone n'ijambobanga</p>
                                            <p><b>Intambweya 4 : </b> Kanda ku "imikino" maze ujyekuri "WATATU"</p>
                                            <p><b>Intambweya 5 : </b> <br />
                                                • Hitamoumubareumweushakahagatiya0 na 9 maze uhabweindimibare mu wundimurongo.<br />
                                                • Kanda "QP" uhabweimibare.</p>
                                            <p><b>Intambweya 6 : </b> kandakuri ADD ugureitikeyawe</p>
                                            <p><b>Intambweya 7 : </b><br />
                                                • Nyumayogukandakurigura, ushoborakurebaitikeyaweyemejwe.<br />
                                                • Gusubirainyumakandakukamenyetso ka X kagaragaraiburyohejuru </p>
                                            <p><b>Intambweya 8 : </b> Kurebaimikinoumazegukina, kandaahanditseizina rya kontiyawe.Kanda kuri "option",  Kanda kuri "game details"</p>
                                            <p><b>Intambweya 9 : </b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatike yose yaguzwe</p>
                                            <table>
                                                <tr>
                                                    <th>TYPE</th>
                                                    <th>MATCHING</th>
                                                    <th>ODDS</th>
                                                </tr>
                                                <tr>
                                                    <td>SEQ</td>
                                                    <td>MATCH ALL 3 IN THE SAME ORDER</td>
                                                    <td>X150</td>
                                                </tr>
                                                <tr>
                                                    <td>BOX</td>
                                                    <td>MATCH ALL 3 IN THE ANY ORDER</td>
                                                    <td>X20</td>
                                                </tr>
                                                <tr>
                                                    <td>FRONT PAIR</td>
                                                    <td>FIRST TWO IN THE SAME ORDER</td>
                                                    <td>X10</td>
                                                </tr>
                                                <tr>
                                                    <td>BACK PAIR</td>
                                                    <td>LAST TWO IN THE SAME ORDER</td>
                                                    <td>X10</td>
                                                </tr>
                                                <tr>
                                                    <td>LUCKY FIRST</td>
                                                    <td>MATCH THE FIRST NUMBER</td>
                                                    <td>X1.5</td>
                                                </tr>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </section>
                            }
                            {/* Igitego-moto */}
                            {this.state.cTab == 6 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Igitego-Moto</h3>
                                    <p>{t('lblHowToplayMotto')}</p>
                                    <a onClick={(e) => { this.SelectGameFamily(9) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/EwTGNZ6H7Zw?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b> Open an internet browser and go to www.inzozilotto.rw</p>
                                            <p><b>Step 2:</b> On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b> Input your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b> On the home page, go to “IGITEGO MOTO” and click on “Play Now”</p>
                                            <p><b>Step 5:</b> <br />
                                                <b>	Choose how you want your numbers;</b><br />
                                                1	The first two numbers<br />
                                                2	The last 2 numbers<br />
                                                3	Or whole numbers<br />
                                                4	You click on search for tickets<br />
                                                5	You are given tickets that have not been sold and you choose which ticket you want to buy.
                                            </p>
                                            <p><b>Step 6:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 7:</b> <br />
                                                •	After clicking on buy, you can view your confirmed ticket.<br />
                                                •	To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 8:</b> To view the games played, click on account name</p>
                                            <p><b>Step 9:</b> Click on “option”</p>
                                            <p><b>Step 10:</b> Click on “game details”</p>
                                            <p><b>Step 11:</b> A report will show all the games you played and your tickets’ status.</p>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p><b>Intambweya 1:</b> Funguraurubugarwawww.inzozilotto.rw</p>
                                            <p><b>Intambweya 2:</b> Kanda “Injira” kuripajiibanza</p>
                                            <p><b>Intambweya 3:</b> Shyiramonimeroyaweya telephone n’ijambo banga</p>
                                            <p><b>Intambweya 4:</b> Kanda ku “imikino” maze ujyekuri “IGITEGO MOTO”</p>
                                            <p><b>Intambweya 5:</b><br />
                                                <b>	Hitamouburyoushakaimibareyawe</b><br />
                                                1.	Imibareibiriibanza<br />
                                                2.	Imibare 2 iheruka<br />
                                                3.	CyangwaImibareyose<br />
                                                4.	Urakandakurishakishaamatike<br />
                                                5.	Urahabwaitikezitaragurwauhitemoiyowifuzakugura.
                                            </p>
                                            <p><b>Intambweya 6:</b><br />
                                                •	Nyumayogukandakurigura, ushoborakurebaitikeyaweyemejwe.<br />
                                                •	Gusubirainyumakandakukamenyetsoka X kagaragaraiburyohejuru.</p>
                                            <p><b>Intambweya 7:</b> Kurebaimikinoumazegukina, kandaahanditseizinaryakontiyawe.</p>
                                            <p><b>Intambweya 8:</b> Kanda kuri “option”</p>
                                            <p><b>Intambweya 9:</b> Kanda kuri “game details”</p>
                                            <p><b>Intambweya 10:</b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatikeyoseyaguzwe</p>
                                        </div>
                                    }
                                </div>
                            </section>
                            }
                            {/* Igitego-IPhone */}
                            {this.state.cTab == 7 && <section class="tabContbet" style={{ display: "table" }}>
                                <div class="Game1">
                                    <h3>Igitego-Iphone</h3>
                                    <p>{t('lblHowToplayPhone')}</p>
                                    <a onClick={(e) => { this.SelectGameFamily(10) }}>{t('lblGetstarted')}</a>
                                </div>
                                <div class="hwplSec">
                                    <div class="col-md-6 vediosec2">
                                        <iframe src="https://www.youtube.com/embed/EwTGNZ6H7Zw?list=PLtgeTuJl2-jWlPDRT0d3HUGZdfgkvrrSl&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {i18n.language == "en" ?
                                        <div class="col-md-6 vediDes">
                                            <h2>How to play</h2>
                                            <p><b>Step 1:</b> Open an internet browser and go to www.inzozilotto.rw</p>
                                            <p><b>Step 2:</b> On the home page, click on “Sign in”</p>
                                            <p><b>Step 3:</b> Input your registered mobile number and password and click “Login”</p>
                                            <p><b>Step 4:</b> On the home page, go to “IGITEGO  IPHONE” and click on “Play Now”</p>
                                            <p><b>Step 5:</b> <br />
                                                <b>	Choose how you want your numbers;</b><br />
                                                1	The first two numbers<br />
                                                2	The last 2 numbers<br />
                                                3	Or whole numbers<br />
                                                4	You click on search for tickets<br />
                                                5	You are given tickets that have not been sold and you choose which ticket you want to buy.
                                            </p>
                                            <p><b>Step 6:</b> Click on “BUY” to buy the ticket</p>
                                            <p><b>Step 7:</b> <br />
                                                •	After clicking on buy, you can view your confirmed ticket.<br />
                                                •	To go back click on the cross (X) on the top right.</p>
                                            <p><b>Step 8:</b> To view the games played, click on account name</p>
                                            <p><b>Step 9:</b> Click on “option”</p>
                                            <p><b>Step 10:</b> Click on “game details”</p>
                                            <p><b>Step 11:</b> A report will show all the games you played and your tickets’ status.</p>
                                        </div>
                                        :
                                        <div class="col-md-6 vediDes">
                                            <h2>Ukobakina</h2>
                                            <p><b>Intambweya 1:</b> Funguraurubugarwawww.inzozilotto.rw</p>
                                            <p><b>Intambweya 2:</b> Kanda “Injira” kuripajiibanza</p>
                                            <p><b>Intambweya 3:</b> Shyiramonimeroyaweya telephone n’ijambo banga</p>
                                            <p><b>Intambweya 4:</b> Kanda ku “imikino” maze ujyekuri “IGITEGO IPHONE</p>
                                            <p><b>Intambweya 5:</b><br />
                                                <b>	Hitamouburyoushakaimibareyawe</b><br />
                                                1.	Imibareibiriibanza<br />
                                                2.	Imibare 2 iheruka<br />
                                                3.	CyangwaImibareyose<br />
                                                4.	Urakandakurishakishaamatike<br />
                                                5.	Urahabwaitikezitaragurwauhitemoiyowifuzakugura.
                                            </p>
                                            <p><b>Intambweya 6:</b><br />
                                                •	Nyumayogukandakurigura, ushoborakurebaitikeyaweyemejwe.<br />
                                                •	Gusubirainyumakandakukamenyetsoka X kagaragaraiburyohejuru.</p>
                                            <p><b>Intambweya 7:</b> Kurebaimikinoumazegukina, kandaahanditseizinaryakontiyawe.</p>
                                            <p><b>Intambweya 8:</b> Kanda kuri “option”</p>
                                            <p><b>Intambweya 9:</b> Kanda kuri “game details”</p>
                                            <p><b>Intambweya 10:</b> Urahitaubonaraporoigaragazaimikinoumazegukinandetsen'amatikeyoseyaguzwe</p>
                                        </div>
                                    }
                                </div>
                            </section>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    };
}

function mapStateToProps(state) {
    const { games } = state.gameReducer;
    return { games };
}
const mapActionToProps = {
    SelectGameFamily: gameActions.SetGameFamily,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(HowToPlay))