import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";

import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';
class ResultReport extends Component {

    constructor(props) {
        super(props);
        this.setState({
            reportID: 4,
        });
    }

    getRecord = (flg) => {
        if (flg != 1) {
            this.state.startDate = document.getElementById("datepicker2").value;
            this.state.endDate = document.getElementById("datepicker3").value;
        }
        let _flog = commonClass.checkRptDateFilter(this.state.startDate, this.state.endDate)
        if (_flog)
            this.props.GetResultReport(this.state.startDate, this.state.endDate, this.state.reportID, this.props.i18n.language);
    };

    handleChange = (e) => {
        let _id = 0;
        switch (e) {
            case "r4":
                _id = 4;
                break;
            case "r5":
                _id = 5;
                break;
            case "r3":
                _id = 3;
                break;
            case "r1":
                _id = 1;
                break;
            case "r7":
                _id = 7;
                break;
            case "r8":
                _id = 8;
                break;
            case "r9":
                _id = 9;
                break;
            case "r10":
                _id = 10;
                break;
        }
        this.setState({
            reportID: _id,
        });
        this.state.reportID = _id;
        if (this.state.reportID == _id) {
            this.getRecord();
        }
    };

    reSetFilter() {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss,
            reportID: 4
        });
        this.state.reportID = 4;
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            ResultReport: props.ResultReport != undefined ? props.ResultReport : [],
        }
    };

    componentDidMount() {
        window.documentReadyDatePicker();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss,
            reportID: 4
        });
        this.state.reportID = 4;
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="Ourservinerpg">
                    <h2>{t('lblResults')}</h2>
                    <div class="accHeadCont">
                        <input id="r4" checked={this.state.reportID == 4} onChange={(e) => { this.handleChange("r4") }} type="radio" name="r4" class="radio1" /><label onChange={(e) => { this.handleChange("r4") }} for="r4">Impamo Jackpot</label>
                        <input id="r5" checked={this.state.reportID == 5} onChange={(e) => { this.handleChange("r5") }} type="radio" name="r5" class="radio2" /><label onChange={(e) => { this.handleChange("r5") }} for="r5">Quick Lotto</label>
                        <input id="r3" checked={this.state.reportID == 3} onChange={(e) => { this.handleChange("r3") }} type="radio" name="r3" class="radio2" /><label onChange={(e) => { this.handleChange("r3") }} for="r3">Quick 10</label>
                        <input id="r1" checked={this.state.reportID == 1} onChange={(e) => { this.handleChange("r1") }} type="radio" name="r1" class="radio2" /><label onChange={(e) => { this.handleChange("r1") }} for="r1">Igitego Million</label>
                        <input id="r7" checked={this.state.reportID == 7} onChange={(e) => { this.handleChange("r7") }} type="radio" name="r7" class="radio2" /><label onChange={(e) => { this.handleChange("r7") }} for="r7">Watatu</label>
                        <input id="r9" checked={this.state.reportID == 9} onChange={(e) => { this.handleChange("r9") }} type="radio" name="r9" class="radio2" /><label onChange={(e) => { this.handleChange("r9") }} for="r9">Igitego-moto</label>
                        <input id="r10" checked={this.state.reportID == 10} onChange={(e) => { this.handleChange("r10") }} type="radio" name="r10" class="radio2" /><label onChange={(e) => { this.handleChange("r10") }} for="r10">Igitego-Iphone</label>
                        {/*  <input id="r6" checked={this.state.reportID == 8} onChange={(e) => { this.handleChange("r2") }} type="radio" name="r8" class="radio2" /><label class="" for="r2">Roulette</label> */}
                    </div>
                    <div class="selRslt">
                        <ul>
                            <li class="dateCont">
                                <div class="selRsltCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate white" type="text" value={this.state.startDate} id="datepicker2" name="startDate" placeholder="2017-06-10" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="selRsltCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="endDate white" type="text" value={this.state.endDate} id="datepicker3" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="resetFtrBtn" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                                <a href="#" class="relaodBtn" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="booking">
                        <div class="container">
                            <table>
                                <tr>
                                    <th className="Col20">{t('lblgamename')}</th>
                                    <th className="Col20">{t('lbldrawdatetime')}</th>
                                    <th className="Col20">{t('lbldrawno')}</th>
                                    <th className="Col20">{t('lblResults')}</th>
                                </tr>
                                {this.state.ResultReport && this.state.ResultReport.length > 0 && this.state.ResultReport[0].GameName && this.state.ResultReport.map((x, idx) =>
                                    <tr>
                                        <td className="Col20">{x.GameName}</td>
                                        <td className="Col20">{x.GameDateTime}</td>
                                        <td className="Col20">{x.GameID}</td>
                                        <td className="Col20">
                                            {x.Result.split(',').map((z, inx) =>
                                                <span>{z}</span>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {(this.state.ResultReport == null || this.state.ResultReport.length <= 0 || (this.state.ResultReport.length > 0 && this.state.ResultReport[0].GameName == undefined)) &&
                                    <tr>
                                        <td colspan="6">{t('lblnorecordfound')}</td>
                                    </tr>
                                }
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { ResultReport } = state.accountReducer;
    // const { user } = state.loginReducer;
    return { ResultReport };
}

const mapActionToProps = {
    GetResultReport: accountActions.GetResultReport
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ResultReport))
