import React, { Component } from 'react'
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';

class HelpAndSupport extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    };
    render() {
        const { t, i18n } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="SupportgameBtm">
                    <h2 >{t('lblhowhelp')}</h2>
                    <div class="container">
                        <div class="col-md-6 col -sm-6 col-xs-12 support">
                            <span><img src="./assets/img/Inzozi-icon-01.png" /></span>
                            <div class="supportRgt">
                                <label>{t('lblCustomercareNo')}</label>
                                <label>2424</label>
                            </div>
                        </div>
                        <div class="col-md-6 col -sm-6 col-xs-12 support">
                            <span><img src="./assets/img/Inzozi-icon-02.png" /></span>
                            <div class="supportRgt">
                                <label>{t('lblWritetoUs')}</label>
                                <label><a href="mailto:customercare@inzozilotto.rw">customercare@inzozilotto.rw</a></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="helplist">
                        <div class="helpTop">
                            <h2>{t('lblhelptopics')}</h2>
                        </div>
                        <div class="col-md-12 bg3">
                            {i18n.language == "en" ?
                                <div class="col-md-12 col-sm-12  helplistLft">
                                    <ul>
                                        <li>
                                            <div class="helplistInnr">
                                                <h3><span><img src="./assets/img/icons/HT-icon-01.png" /></span>Getting started</h3>
                                                <p>To play Inzozi lotto games, you must have a registered account and you must, at the time of registration be at least eighteen (18) years of age or above. To register in the Inzozi lotto, you must follow the following simple </p>
                                                <p>
                                                    <b>Step 1:</b><br />
                                                    Open an internet browser and go to <a href="https://www.inzozilotto.rw/">www.inzozilotto.rw</a>
                                                </p>
                                                <p>
                                                    <b>Step 2:</b><br />
                                                    On the home page, click on “register”
                                                </p>
                                                <p>
                                                    <b>Step 3:</b><br />
                                                    On the register page, you will be required to provide your basic information<br />
                                                    Input your first name<br />
                                                    Input your last name<br />
                                                    Input your desired password<br />
                                                    Retype the password in “Confirm Password”<br />
                                                    Input your email ID (If Any)<br />
                                                    Input your date of Birth (must be 18 years old)<br />
                                                    Input a valid Mobile Number and click on send OTP to get an OTP for mobile number verification<br />
                                                    Input the OTP sent to your number via SMS<br />
                                                    Click on “I understand and agree to the Terms and Conditions”<br />
                                                    Click on the “Register” Button<br />
                                                    On Successful registration, you will receive a “Successfully Registered” message via SMS and e-mail
                                                </p>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="helplistInnr2">
                                                <h3><span><img src="./assets/img/icons/HT-icon-03.png" /></span>Billing and Payment</h3>
                                                <p>
                                                    You can add/withdraw money on your account using mobile money wallet (MTN mobile money & Airtel money), bank account and credit or debit card.
                                                    The user must deposit funds into the Inzozi lotto Wallet Account, which is linked to the registered user, to enable you to play games. These funds, once in the wallet, are non-refundable. If your payment is declined, you will be notified in the application and contacted by phone or email. We may restrict your ability to use a certain payment method if that payment method fails multiple times.
                                                    REMEMBER: If your Automatic Payment fails, you will need to replenish your account with another payment method. In the event any payment is not made, no tickets will be purchased without payment made in advance.
                                                </p>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="helplistInnr2">
                                                <h3><span><img src="./assets/img/icons/HT-icon-04.png" /></span>My account</h3>
                                                <p>
                                                    You are only allowed to register one account, on <a href="https://www.inzozilotto.rw/">www.inzozilotto.rw</a> or download the Inzozi lotto app to complete the online registration process. You will be required to submit your first and last name and other details as requested including, but not limited to, your ID/Passport Number, your phone number, date of birth, your email address, your residential address, and your payment information.
                                                    Once you have created your account according to the terms and conditions, you can use Inzozi lotto web or app to purchase authentic lottery tickets. Otherwise, you can use our representative, agent, or distributors accessible all over the country.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="helplistInnr">
                                                <h3><span><img src="./assets/img/icons/HT-icon-02.png" /></span>Copyright & Legal</h3>
                                                <p>
                                                    The Service and its contents, features and functionality, including but not limited to, all information, products, services, text, displays, graphics, video, audio and software as well as the design, selection and arrangement thereof (collectively, “Content”), are owned by the Company, its licensors or other providers of such material and are protected by the Laws of the Republic of Rwanda.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="helplistInnr2" style={{cursor:"pointer"}} onClick={() => history.push('/faq')}>
                                                <h3>FAQ</h3>
                                                <p>
                                                    What is inzozilotto?<br />
                                                    How does Inzozilotto work?<br />
                                                    Who is eligible to purchase ticket?<br />
                                                    How can I Check my Account?<br />
                                                    Do I need to pay any registration Fee?
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                :
                                <div class="col-md-12 col-sm-12  helplistLft">
                                    <ul>
                                        <li>
                                            <div class="helplistInnr">
                                                <h3><span><img src="./assets/img/icons/HT-icon-01.png" /></span>Gutangira</h3>
                                                <p>
                                                    Kugira ngo ukine imikino ya Inzozi lotto, ugomba kuba warafunguje konti kandi wujuje imyaka 18 y’amavuko. Kugira ngo wiyandikishe mu Inzozi Lotto ugomba gukurikiza intambwe zikurikira:
                                                </p>
                                                <p>
                                                    <b>Intambwe ya 1:</b><br />
                                                    Fungura urubuga rwa <a href="https://www.inzozilotto.rw/">www.inzozilotto.rw</a>
                                                </p>
                                                <p>
                                                    <b>Intambwe ya 2:</b><br />
                                                    Kanda “Register” kuri paji ibanza
                                                </p>
                                                <p>
                                                    <b>Intambwe ya 3:</b><br />
                                                    Uzuzamo imyirondoro yawe yose kuri paji yo kwiyandikisha<br />
                                                    Shyiramo izina ryawe rya mbere<br />
                                                    Shyiramo izina ryawe rya kabiri<br />
                                                    Shyiramo ijambo banga wifuza<br />
                                                    Ongera wandikemo ijambo banga ryawe<br />
                                                    Shyiramo email (niba uyifite)<br />
                                                    Shyiramo itariki y’amavuko (ugomba kuba ufite imyaka 18)<br />
                                                    Shyiramo nimero yawe ya telefone hanyuma wemeze ahanditse OTP<br />
                                                    Shyiramo imibare ya OTP woherejwe muri SMS<br />
                                                    Kanda kuri “I understand and agree to the Terms and Conditions”<br />
                                                    Emeza kwiyandikisha ukanda kuri “Register”<br />
                                                    Urakira ubutumwa bwemeza ko kwiyandikisha byagenze neza kuri SMS no kuri email
                                                </p>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="helplistInnr2">
                                                <h3><span><img src="./assets/img/icons/HT-icon-03.png" /></span>Ibijyanye no kwishyura</h3>
                                                <p>
                                                    Ushobora kongera/kubikuza amafaranga kuri konti yawe ukoresheje MTN mobile money, Airtel money, konti ya banki ndetse n’amakarita yo kwishyura.
                                                    Ugomba gushyira amafaranga kuri konti yawe y’inzozi ihujwe n’imyirondoro yawe kugira ngo ubashe gukina imikino y’Inzozi lotto. Aya mafaranga iyo ageze kuri konti ntasubizwa. Iyo ubwishyu bwawe bwanzwe, urabimenyeshwa biciye kuri telephone yawe cyangwa imeyili. Dushobora guhagarika bumwe mu buryo bwo kwishyura iyo ubwo buryo bwanze inshuro nyinshi.
                                                    ICYITONDERWA: Iyo kwishyura by’ako kanya byanze, ugomba guhindura uburyo bwo kwishyura kuri konti yawe. Mu gihe nta bwishyu bwabaye, ntabwo itike igurwa utabanje kwishyura mbere.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="helplistInnr2">
                                                <h3><span><img src="./assets/img/icons/HT-icon-04.png" /></span>Konti yanjye</h3>
                                                <p>
                                                    Wemerewe kwandikisha konti imwe kuri <a href="https://www.inzozilotto.rw/">www.inzozilotto.rw</a> cyangwa uka downloadinga apulikasiyo y’inzozi lotto kugira ngo ubashe kwiyandikisha uciye kuri murandasi. Urasabwa gutanga imyirondoro yawe yuzuye harimo amazina yombi ndetse n’indi myirondoro y’ingenzi ikubiyemo nomero y’indangamuntu/pasiporo, nimero yawe ya telephone, itariki y’amavuko, adiresi ya imeyili, aho utuye ndetse n’uburyo bwo kwishyura.
                                                    Iyo umaze gufunguza konti ukurikije amategeko n’amabwiriza, ushobora gukoresha urubuga rw’inzozi lotto cyangwa apulikasiyo kugira ngo ubashe kugura itike ya tombola yemewe. Ushobora no gukoresha abaduhagarariye, aba ajenti bacu ndetse n’abadandaza bacu ushobora gusanga mu bice bitandukanye by’igihugu.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="helplistInnr">
                                                <h3><span><img src="./assets/img/icons/HT-icon-02.png" /></span>Uburenganzira n’amategeko</h3>
                                                <p>
                                                    Serivisi ndetse n’ibiyirimo byose, ibiyiranga ndetse n’imikorere ikubiyemo amakuru yose, ibicuruzwa, serivisi, inyandiko, amashusho, ibishushanyo, videwo, amajwi, software ndetse n’ibindi bishushanyo, uko byatoranijwe ndetse n’uko bipanze (mu ncamake, “Ibirimo byose”), bitunzwe na company, abatanga impushya ndetse n’abatanga ibyo bikoresho byose ndetse bikaba bigengwa n’amategeko ya Repubulika y’u Rwanda.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="helplistInnr2" style={{cursor:"pointer"}}  onClick={() => history.push('/faq')}>
                                                <h3>Ibibazo Bikunze kubazwa</h3>
                                                <p>
                                                    Inzozi Lotto ni iki?<br />
                                                    Inzozi Lotto ikora ite?<br />
                                                    Ninde wemereye kuba yagura itike?<br />
                                                    Ni gute nareba kuri konti yanjye?     <br />
                                                    Ese nsabwa kwishyura amafaranga kugirango niyandikishe?
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {}
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(HelpAndSupport))