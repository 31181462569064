import React, { Component } from 'react'
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { loginActions } from '../../action/loginAction';
import { alertActions } from '../../action/alertAction';
import { withTranslation } from 'react-i18next';

class IdelSessionTimer extends Component {
    constructor(props) {
        super(props);
        this.state = { counter: 1 }
    }
    _onAction = (e) => {
        console.log('user did something', e)
        this.setState({ isTimedOut: false })
    };
    _onActive = (e) => {
        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    };
    _onIdle = (e) => {
        console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            this.props.logout();
        } else {
            this.setState({ showPopUp: true })
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }
    };
    handleClose = () => {
        this.setState({ showPopUp: false })

        // $('.overlay').fadeOut();
        //$('#lightBox9').fadeOut();
    }
    handleLogout = () => {
        this.setState({ showPopUp: false })

        //$('.overlay').fadeOut();
        //$('#lightBox9').fadeOut();
        this.props.logout();
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <IdleTimer ref={(ref) => { this.idleTimer = ref }}
                    element={document} onActive={() => { this._onActive() }} onIdle={() => { this._onIdle() }} onAction={() => { this._onAction() }}
                    debounce={250} timeout={this.props.user.loginExpiry} />

                {this.state.showPopUp && <div>
                    <div id="lightBox1" className="lightBox" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
                        <div className="userLog warning" style={{ borderColor: "#007bff" }}>
                            <form method="" name="enqForm" className="enqForm">
                                <h2 style={{ color: "#007bff" }} >{t('lblAlert')}</h2>
                                <span style={{ color: "#007bff" }}>{t('lblSessionExpired')}? </span>
                                <div className="forCenter">
                                    <div className="userLogBtnCont">
                                        <button style={{ width:"auto", backgroundColor: "#545b62" }} onClick={() => { this.setState({ showPopUp: false });this.props.logout(); }}>{t('lblLogout')}</button>
                                        <button style={{ width:"auto",backgroundColor: "#007bff" }} onClick={() => {this.setState({ showPopUp: false }) }}>{t('lblStay')}</button>
                                  </div>
                                </div>
                            </form>
                        </div>
                    </div><div style={{ display: "block" }} className="overlayPop"></div>
                </div>
                }
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    clearAlerts: alertActions.clear,
    logout: loginActions.logout,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(IdelSessionTimer))