import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';

class SocialResponsibility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pressList: []
        };
    }

    componentDidMount() {
        this.setjsondata();
        window.scrollTo(0, 0);
    };
    setjsondata = (myJson) => {
        //fetch('assets/img/banner/PressBanner.json')
        fetch(process.env.REACT_APP_BANNER_URL+'PressBanner.json')
            .then(response => response.json())
            .then(data =>
                this.setState({ pressList: data.data })
            );
    };
    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    <div class="PressMeetContainter">
                        <h1>{t('lblPressRelease')}</h1>
                        <div class="PressMeetCont">                      
                            {this.state.pressList && this.state.pressList.map((x, indx) =>
                                <div class="PressMeet" key={`wimg_${indx}`}>
                                    <h4>{x.pressname}</h4>
                                    <h2>{x.title}</h2>
                                    <div class="PressMeetImg">
                                        <img src={x.imagPath} />
                                    </div>
                                    <a target="_blank" href={x.linkUrl}>{x.linkUrl}</a>
                                </div>
                            )}
                        {/* <div class="PressMeet">
                                <h4>The New Times</h4>
                                <h2>FEATURED: Winner bags Rwf2m in Inzozi Lotto’s new jackpot</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/lottowinner3.jpeg" />
                                </div>
                                <a target="_blank" href="https://www.newtimes.co.rw/featured/winner-bags-rwf2m-inzozi-lottos-new-jackpot">https://www.newtimes.co.rw/featured/winner-bags-rwf2m-inzozi-lottos-new-jackpot</a>
                            </div>
                            <div class="PressMeet">
                                <h4>Kigali today</h4>
                                <h2>Yatsindiye Miliyoni ebyiri muri Tombola ‘Inzozi Lotto’</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/lottowinner2.jpeg" />
                                </div>
                                <a target="_blank" href="https://www.kigalitoday.com/kwamamaza/article/yatsindiye-miliyoni-ebyiri-muri-tombola-inzozi-lotto">https://www.kigalitoday.com/kwamamaza/article/yatsindiye-miliyoni-ebyiri-muri-tombola-inzozi-lotto</a>
                            </div>
                        <div class="PressMeet">
                                <h4>Igihe</h4>
                                <h2>Nsabimana yashyikirijwe miliyoni 1 Frw yatsindiye binyuze muri Inzozi Lotto</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/lottowinner1.jpg" />
                                </div>
                                <a target="_blank" href="https://igihe.com/amakuru/article/nsabimana-yashyikirijwe-miliyoni-1-frw-yatsindiye-binyuze-muri-inzozi-lotto">https://igihe.com/amakuru/article/nsabimana-yashyikirijwe-miliyoni-1-frw-yatsindiye-binyuze-muri-inzozi-lotto</a>
                            </div>
                            <div class="PressMeet">
                                <h4>Kigali today</h4>
                                <h2>Video : Nsabimana abaye uwa mbere utsindiye miliyoni muri tombola ‘Inzozi Lotto’</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/winnerlotto.png" />
                                </div>
                                <a target="_blank" href="https://www.kigalitoday.com/imikino-11/indi-mikino/Video-Nsabimana-abaye-uwa-mbere-utsindiye-miliyoni-muri-tombola-Inzozi-Lotto">https://www.kigalitoday.com/imikino-11/indi-mikino/Video-Nsabimana-abaye-uwa-mbere-utsindiye-miliyoni-muri-tombola-Inzozi-Lotto</a>
                            </div>
                            <div class="PressMeet">
                                <h4>The New Times</h4>
                                <h2>FEATURED : New lottery lanched to boost national sports development</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/newsTime.jpg" />
                                </div>
                                <a target="_blank" href="https://www.newtimes.co.rw/featured/new-lottery-launched-boost-national-sport-development">https://www.newtimes.co.rw/featured/new-lottery-launched-boost-national-sport-development</a>
                            </div>
                            <div class="PressMeet">
                                <h4>Kigali today</h4>
                                <h2>Sobanukirwa byinshi kuri tombola ‘Inzozi Lotto’ yatangijwe ku mugaragaro</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/kigali.jpg" />
                                </div>
                                <a target="_blank" href="https://www.kigalitoday.com/imikino-11/indi-mikino/article/sobanukirwa-byinshi-kuri-tombola-inzozi-lotto-yatangijwe-ku-mugaragaro">https://www.kigalitoday.com/imikino-11/indi-mikino/article/sobanukirwa-byinshi-kuri-tombola-inzozi-lotto-yatangijwe-ku-mugaragaro</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(SocialResponsibility))