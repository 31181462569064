import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loginActions } from '../../../action/loginAction';
import { alertActions } from '../../../action/alertAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation } from 'react-i18next';
class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            newConfirmPassword: '',
            changeSubmitted: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitChangePassword = this.submitChangePassword.bind(this);
    }

    submitChangePassword(e) {
        try {
            e.preventDefault();
            this.setState({ changeSubmitted: true });
            let { oldPassword, newPassword, newConfirmPassword } = this.state;
            if (oldPassword && newPassword && newConfirmPassword && newPassword == newConfirmPassword) {
                oldPassword = oldPassword.replace(/'/g, "''");
                newPassword = newPassword.replace(/'/g, "''");
                this.props.changePassword(this.props.user.mobileNo, oldPassword, newPassword,this.props.i18n.language);
            }
        }
        catch (ex) {
            commonClass.writeLog(ex, "ChangePassword-submitChangePassword");
            return false;
        }
    };


    clearChangePwd = () => {
        try {
            this.setState({
                oldPassword: '',
                newPassword: '',
                newConfirmPassword: '',
                changeSubmitted: false,
            });
            this.state.oldPassword = "";
            this.state.newPassword = "";
            this.state.newConfirmPassword = "";
            this.state.changeSubmitted = false;
        }
        catch (ex) {
            commonClass.writeLog(ex, "ChangePassword-clearChangePwd");
            return false;
        }
    };

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    };

    componentDidMount() {
		//window.docMapVisiblePwd();
    };

    render() {
        const { t } = this.props;
        const { changeSubmitted, oldPassword, newPassword, newConfirmPassword } = this.state;
        return (
            <section class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblChangepwd')}</h2>
                    <div class="loginContentFrm">
                        <div class="userLogRgt">
                            <form method="post" name="enqForm" class="enqForm scrollbar">
                                <div class="userLogCont">
                                    <div>
                                        <input maxLength="10" class="newUserTxt txtgray" type="password" placeholder={`${t('lblPassword')} *`} value={this.state.oldPassword} onChange={this.handleInputChange} name="oldPassword" />
                                    </div>
										<span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span>
                                    {changeSubmitted && !oldPassword && <small>{t('lblRequired')}</small>}
                                </div>
                                <div class="userLogCont">
                                    <div>
                                        <input maxLength="10" class="newUserTxt txtgray" type="password" placeholder={`${t('lblnewPassword')} *`} value={this.state.newPassword} onChange={this.handleInputChange} name="newPassword" />
                                    </div>
										<span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span>
                                    {changeSubmitted && !newPassword && <small>{t('lblRequired')}</small>}
                                </div>
                                <div class="userLogCont">
                                    <div>
                                        <input maxLength="10" class="newUserTxt txtgray" type="password" placeholder={`${t('lblConfirmpwd')} *`} value={this.state.newConfirmPassword} onChange={this.handleInputChange} name="newConfirmPassword" />
                                    </div>
										<span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span>
                                    {changeSubmitted && !newConfirmPassword && <small>{t('lblRequired')}</small>}
                                    {changeSubmitted && newPassword && newConfirmPassword && newPassword != newConfirmPassword && <small>{t('lblPasswordMismatch')}</small>}
                                </div>
                                <div class="accountBtnPsw">
                                    <br />
                                    <a class="blueBg" onClick={this.submitChangePassword} >{t('lblSubmit')}</a>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}

const mapActionToProps = {
    changePassword: loginActions.changePassword,
    error: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ChangePassword))