import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import { loginActions } from '../../action/loginAction';
import { withTranslation } from 'react-i18next';

class BecomeAnAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            mobileNo: '',
            email: '',
            businessName: '',
            businessLocation: '',
			regTC: false,
            submitted: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitAgent = this.submitAgent.bind(this);
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name.toLowerCase().includes("mobile") && value && (/^[0-9\b]+$/.test(value)) == false) {
            return;
        }
        if (name == "regTC") {
			value = !this.state.regTC;
		}
        this.setState({
            [name]: value
        });
    };

    submitAgent(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        let { firstName,  mobileNo, email,businessName,businessLocation,regTC } = this.state;
        if (mobileNo && firstName && email && businessName && businessLocation,regTC) {
            mobileNo = "250" + mobileNo.substring(1, mobileNo.length);
            email = email.replace(/'/g, "''");
            businessName = businessName.replace(/'/g, "''");
            firstName = firstName.replace(/'/g, "''");
            businessLocation = businessLocation.replace(/'/g, "''");
            this.props.saveAgentRequest(firstName, mobileNo, email, businessName,businessLocation,this.props.i18n.language);
            this.setState({
                firstName: '',
                mobileNo: '',
                email: '',
                businessName: '',
                businessLocation: '',
                regTC: false,
                submitted: false,
            })
        }
    };

    componentDidMount() {
        window.scrollTo(0,0);
    };

    render() {
        const { t } = this.props;
        const { firstName, mobileNo, email, businessName,businessLocation,regTC, submitted } = this.state;
        return (
            <div className="mainContainer">
                <Header />
                <div class="becomeAgentCont">
                    <div class="container">
                        <div class="col-md-6 aGentRgt">
                            <h2>{t('lblBENEFITS')} <br/>{t('lblBecanagt')}</h2>
                            <ul>
                                <li><span><i class="fa fa-check-circle"></i></span> {t('lblBeyourownboss')}</li>
                                <li><span><i class="fa fa-check-circle"></i></span> {t('lblEarnRevenues')}</li>
                                <li><span><i class="fa fa-check-circle"></i></span> {t('lblBecamepartLottery')}</li>
                                <li><span><i class="fa fa-check-circle"></i></span> {t('lblSupportSports')}</li>
                            </ul>
                            <div class="mailcont">
                                <div class="mailcontCent">
                                    <span><img src="./assets/img/Inzozi-icon-02.png" /></span>
                                    <a href="mailto:Sales@inzozilotto.rw">Sales@inzozilotto.rw</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="BecomeAgentFrm bg1">
                                <div class="userLogRgt">
                                    <h2>{t('lblRegister')} <br/>{t('lblBecanagt')}</h2>
                                    <form method="post" name="enqForm" class="enqForm scrollbar">
                                        <div class="userLogCont">
                                            <div>                                                
                                                <input maxLength="20" type="text" placeholder={`${t('lblFirstName')} *`} name="firstName" class="newUserTxt" value={this.state.firstName} onChange={this.handleInputChange} />                                        
                                            </div>
                                            {submitted && !firstName && <small>{t('lblRequired')}</small>}
                                        </div>
                                        <div class="userLogCont">
                                            <div>                                                
                                                <input maxLength="10" type="text"  placeholder={`${t('lblmobno')} *`} name="mobileNo" class="newUserTxt" value={this.state.mobileNo} onChange={this.handleInputChange} />
                                            </div>
                                            {submitted && !mobileNo && <small>{t('lblRequired')}</small>}
                                        {submitted && mobileNo && (!(/\d{10}$/.test(mobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
                                        </div>
                                        <div class="userLogCont">
                                            <div>
                                                <input maxLength="50" type="email" placeholder={`${t('lblEmail')} *`} name="email" class="newUserTxt" value={this.state.email} onChange={this.handleInputChange} />
                                            </div>
                                            {submitted && email && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) && <small>{t('lblInvalidEmailID')}</small>}
                                            {submitted && !businessName && <small>{t('lblRequired')}</small>}
                                        </div>
                                        <div class="userLogCont">
                                            <div>
                                                <input maxLength="50" type="text" placeholder={`${t('lblBusiName')} *`} name="businessName" class="newUserTxt" value={this.state.businessName} onChange={this.handleInputChange} />                                        
                                            </div>
                                            {submitted && !businessName && <small>{t('lblRequired')}</small>}
                                        </div>
                                        <div class="userLogCont">
                                            <div>
                                            <input maxLength="100" type="text" placeholder={`${t('lblBusiLoc')}`} name="businessLocation" class="newUserTxt" value={this.state.businessLocation} onChange={this.handleInputChange} />                                                                                       
                                            </div>
                                            {submitted && !businessLocation && <small>{t('lblRequired')}</small>}
                                        </div>
                                    </form>
                                </div>
                                <div class="termsAndCond">
                                    <input type="checkbox" defaultChecked={this.state.regTC} name="regTC" class="checkbox" value="Terms and Conditions" onChange={this.handleInputChange}/>
                                    <span class ="agree">{t('lblAccept')}<a target="_blank">{t('lblTercon')}.</a></span>                                    
                                </div>
                                {submitted && !regTC && <small style={{ color: "red", marginLeft: "20px" }}>{t('lblRequired')}</small>}
                                <div class="userLogContdrbbtn"><button class="button buttonBlue" onClick={this.submitAgent}>{t('lblGetstarted')}</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { appvariables } = state.loginReducer;
    return { appvariables }
}
const mapActionToProps = {

    saveAgentRequest: loginActions.saveAgentRequest,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(BecomeAnAgent))