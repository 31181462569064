import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { alertActions } from '../../../action/alertAction';
import { withTranslation  } from 'react-i18next';
//import { commonClass } from "../../../helpers/commonClass"

class Addmoney extends Component {

    constructor(props) {
        super(props);
        this.state = {
            txnNo: ""
        };
    }
    submitAddMoney = () => {
        if (this.state.txnNo.trim() != "") {
            var sTime = new Date(this.props.serverTime);
            let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;
            this.state.txnNo = this.state.txnNo.replace(/'/g, "''");
            this.props.Addmoney(this.props.user.mobileNo, this.state.txnNo, TransID,this.props.i18n.language);
            this.clearAddMoney();
        }
        else {
            this.props.error(this.props.t('lblInvaildTransactionCode'));
        }
    };

    clearAddMoney = () => {
        this.setState({
            txnNo: ""
        });
        this.state.txnNo = "";
    };
    render() {
        const { t } = this.props;        
        let _addUrl = `${process.env.REACT_APP_PG_URL}/Paymode/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`;
        let _addMTNUrl = `${process.env.REACT_APP_PG_URL}/MTN/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}|login_key~${this.props.user.loginKey}`;
        let _addAirtelUrl = `${process.env.REACT_APP_PG_URL}/Airtel/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}|login_key~${this.props.user.loginKey}`;
        //let _edata=commonClass.Encryption(`player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`)
        //let _addUrl = `${process.env.REACT_APP_PG_URL}/Paymode/Addmoney?data=${_edata}`;
        return (
            <section class="tabCont">
                <div class="Ourservinerpg">
                    <h2>{t('lblAddMoney')}<small>{t('lblInzoziVoucher')}</small></h2>
                    <div class="account">
                        <div class="Voucher">
                            <span class="amntBg ActiveamntBg"><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.balance}</span>
                        </div>
                        <div class="accountinr">
                            <input type="text" maxLength="30" placeholder="TXN No *" value={this.state.txnNo} onChange={(e) => this.setState({ txnNo: e.target.value })} />
                        </div>
                        <div class="accountBtn">
                            <a class="blueBg" onClick={() => { this.submitAddMoney() }}>{t('lblAddMoney')}</a>
                        </div>
                    </div>
                    <h2><small>{t('lblOtherMethods')}</small></h2>
                    <div class="Payment">
					  {/*<a href={_addMTNUrl + "|paytype_id~7"} target="_self"><img src="./assets/img/mtn.png" /></a>				      
                         <a href={_addAirtelUrl + "|paytype_id~8"} target="_self"><img src="./assets/img/airtel.png" /></a>*/}
						 
                        <a href={_addUrl + "|paytype_id~3"} target="_self"><img src="./assets/img/airtelmtn.png" /></a>
                        <a href={_addUrl + "|paytype_id~2"} target="_self"><img src="./assets/img/mastervisa.png" /></a>
                        <a href={_addUrl + "|paytype_id~5"} target="_self"><img src="./assets/img/smartcash.png" /></a>
                        <a href={_addUrl + "|paytype_id~6"} target="_self"><img src="./assets/img/spenn.png" /></a>

                       
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { AddMoney } = state.accountReducer;
    const { user, appvariables } = state.loginReducer;
    //const { serverTime } = state.gameReducer;
    const {serverTime } = state.commonReducer;
    return { AddMoney, user, serverTime, appvariables };
}

const mapActionToProps = {
    Addmoney: accountActions.AddMoney,
    error: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Addmoney))