import { commonClass } from "..//helpers/commonClass"
export const target10Service = {
    Target10OnlineSale,
    GetCurrentResult,
};
//const _apiUrl = "http://localhost:2000/api";
//const _apiUrl="http://www.nodetest.com/api";
//const _apiUrl="http://220.225.104.132:8080/api";
const _apiUrl=process.env.REACT_APP_API_URL;


function Target10OnlineSale(data) {
    try{
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ "data": data })
    // };
    return fetch(`${_apiUrl}/target/targetSale`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
    }
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function GetCurrentResult(data) {
    try{
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ "data": data })
    // };
    return fetch(`${_apiUrl}/target/getCurrentDrawResult`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(results => {
            if (results != null && results.data != null) 
            { results = results.data; }
            //results="0~ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2~3:00:00";
            //results="0~12~142~10/09/2021~10:10 AM~Quick Win~9";
            return results;
        });
    }
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};


function handleResponse_old(response) {
    try{
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                // location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
catch (ex) {		
    commonClass.writeLog(ex);
}
}