import React, { Component } from 'react'
import { connect } from 'react-redux';
import Left from "./lottoLeft";
import Right from "./lottoRight";
import { gameActions } from '../../../action/gamesAction';
import { lottoActions } from '../../../action/lottoAction';
import Countdown, { zeroPad } from 'react-countdown';
import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';

class Lotto extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cartItems: [],
			currentRunningDraw: {},
			currentFinishingDraw: {},
			coundownStart: 0,
			coundTime: 18900000,
			currentDraw: {},
			currentAvailableDraws: [],
			currentCartItem: { id: 0, balls: [], amount: 0, ballCount: 0, combination: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, gameID: 0 },

			ballStartNumber: 1,
			ballEndNumber: 0,
			QPBallCount: 0,
			random: [],
			showSingleMsg: false,
			_serverTimeDiff: 0,
			allGameClosed: 0,
			isViewAnimation: false,
		};
		this.my_componentWillUnmount();
		if (props.currentGamesFamily.id !== undefined) {
			props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id, this.props.i18n.language);
			this.props.GetLatestResult(this.props.currentGamesFamily.id, this.props.i18n.language);

		}
	}

	calculateLottoPermCombinations = (classType, ballscnt) => {
		try {
			var i;
			var Numerator = 1;
			var Denominator = 1;
			var NetResult, Perm;
			Perm = classType;
			for (i = 0; i < Perm; i++) {
				Numerator = Numerator * ballscnt;
				ballscnt = ballscnt - 1;
			}
			do {
				Denominator = Denominator * Perm;
				Perm = Perm - 1;
			} while (Perm != 0);
			NetResult = Numerator / Denominator;
			return NetResult;
		} catch (ex) {
		}
		return 0;
	};

	checkBallCountAndCalculateCombination = (selectedCount) => {
		let default_Min_val = this.state.currentGamesFamily.minSelect;
		let default_max_val = this.state.currentGamesFamily.maxSelect;
		let default_val = default_Min_val;
		let resultStr = "";
		if (default_Min_val <= selectedCount && default_max_val >= selectedCount) {
			resultStr = "1~" + this.calculateLottoPermCombinations(default_val, selectedCount);
		}
		else {
			if (default_max_val < selectedCount) {
				resultStr = "0~" + this.props.t('lblMaximumselection') + " : " + default_max_val;
			}
			else {
				resultStr = "1~0";
			}
		}
		return resultStr;
	};

	static getDerivedStateFromProps(props, state) {
		return {
			currentAvailableDraws: props.currentAvailableDraws,
			cartItems: props.cartItems,
			currentDraw: props.currentDraw,
			currentGamesFamily: props.currentGamesFamily,
			latestResult: props.latestResult
		};
	};

	createBall = () => {
		let ballLst = [];
		if (this.state.currentGamesFamily != undefined) {
			for (let i = this.state.ballStartNumber; i < this.state.currentGamesFamily.ball + this.state.ballStartNumber; i++) {
				let b_i = ("0" + i).slice(-2);
				ballLst.push(<div id={`ball_${b_i}`} onClick={() => { this.selectBall(b_i) }} className={`lotteryBalls ${this.state.currentCartItem.balls.filter(x => x.id == b_i).length > 0 ? "lottoBallsActive" : ""}`}>{b_i}</div>);
			}
			this.state.ballEndNumber = this.state.currentGamesFamily.ball + (-1 + this.state.ballStartNumber);
		}
		else {
			return <div style={{ color: "#000" }}>{this.props.t('lblDrawClosed')}</div>;
		}
		return ballLst;
	};

	showSingleMsg = false;
	selectBall = (b_id) => {
		if (this.state.currentDraw == null || Object.keys(this.state.currentDraw).length == 0) {
			this.state.showSingleMsg = true; alert(this.props.t('lblSelectDraw')); return;
		}
		let obj = this.state.currentCartItem.balls.filter(x => x.id == b_id);
		let cnt = 0;
		if (obj.length <= 0) {
			cnt = this.state.currentCartItem.ballCount + 1;
		}
		else {
			cnt = this.state.currentCartItem.ballCount - 1;
		}
		var checkResult = this.checkBallCountAndCalculateCombination(cnt);
		let arrResult = checkResult.split('~');
		if (arrResult[0] == '0') { this.state.showSingleMsg = true; alert(arrResult[1]); return; }

		let objLst = this.state.currentCartItem;
		if (obj.length > 0) {
			objLst.ballCount--;
			objLst.balls = this.state.currentCartItem.balls.filter(x => x.id !== b_id);
		}
		else {
			objLst.gameName = this.state.currentDraw.LotName;
			objLst.drawID = this.state.currentDraw.DrawID;
			objLst.gameID = this.state.currentDraw.GameId;
			objLst.drawDateTime = this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime
			objLst.ballCount++;
			objLst.amount = this.state.currentDraw.RateInPs;
			objLst.balls.push({ id: b_id });
		}
		objLst.combination = parseInt(arrResult[1]);
		objLst.total = this.state.currentDraw.RateInPs * objLst.combination;

		this.setState({
			currentCartItem: objLst
		});
	};

	selectDraw = (_draw) => {
		this.props.SetCurrentDraw(_draw);
	};

	selectRunningDraw = () => {
		if (this.state.coundownStart != undefined && this.state.coundownStart == 0) {

			//if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			if (this.state.currentFinishingDraw != undefined) {
				//if (this.state.currentFinishingDraw.DrawID == undefined || this.state.currentRunningDraw.DrawID == undefined || this.state.currentFinishingDraw.DrawID !=this.state.currentRunningDraw.DrawID) {
				let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
				if (lst.length > 0 || this.state.currentAvailableDraws.length <= 0) {
					let _draw = this.state.currentAvailableDraws[0];
					//let _draw = lst[0];
					this.setState({
						currentRunningDraw: _draw,
					})
					this.state.currentRunningDraw = _draw;
					this.selectDraw(_draw);
				}
				else {
					return;
				}
			}

			// if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			// 	let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
			// 	if (lst.length > 0) {
			// 		return;
			// 	}
			// }

			let _draw = this.state.currentAvailableDraws[0];
			if (_draw == undefined) { this.state.coundownStart = 1; return; }
			try {
				let sTime = undefined; //new Date(Date.now() - this.state.coundTime);
				if (_draw != undefined) {
					var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
					let ss = new Date(_draw.DrawDateTime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
					sTime = new Date(ss.setSeconds(ss.getSeconds() - 60));
				}
				let dStr = ("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + sTime.getHours() + ":" + sTime.getMinutes() + ":" + sTime.getSeconds();
				window.timer = null;
				this.state.coundownStart = window.startDraw(dStr, this.RefreshAfterGameClose);
			}
			catch (ex) {
				alert(ex);
			}
		}
	};

	componentDidUpdate(previousProps, previousState) {
		//if ((this.state.currentRunningDraw ==null || Object.keys(this.state.currentRunningDraw).length == 0) && this.state.currentAvailableDraws.length>0) {
		//if ((this.state.currentDraw ==null || Object.keys(this.state.currentDraw).length == 0) && (previousState.currentDraw ==null && (this.state.currentDraw==null || this.state.currentAvailableDraws.length>0))) {
		if (this.state.currentDraw != null && Object.keys(this.state.currentDraw).length != 0) {
			this.state.allGameClosed = 0;
		}
		if (this.state.currentAvailableDraws.length > 0 && ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length <= 0) || this.state.currentAvailableDraws[0].DrawNo != this.state.currentRunningDraw.DrawNo))
		//if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0)) 
		{
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				this.state.allGameClosed = 1
			}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
		}
	};

	componentDidMount() {
		//window.documentReadyjQuery();
		window.addEventListener('resize', window.documentReadyResize);
	};
	my_componentWillUnmount = () => {
		this.props.ClearCart();
		this.setState({
			currentRunningDraw: null
		})
		this.props.ClearAvailableGames();
	}

	RefreshAfterGameClose = () => {
		//alert("coundown finish");
		this.state.coundownStart = 0;
		this.state._serverTimeDiff = 0;
		this.state.currentFinishingDraw = this.state.currentRunningDraw;

		this.props.RemoveClosedDrawbetFromCart(this.state.currentFinishingDraw.DrawID);
		this.state.currentRunningDraw = {};
		this.props.GameAvailableGames(this.props.user.mobileNo, this.props.currentGamesFamily.id, this.props.i18n.language);
		this.props.GetLatestResult(this.props.currentGamesFamily.id, this.props.i18n.language);
	};


	addToCart = () => {
		if (this.state.currentCartItem.combination <= 0) { alert(this.props.t('lblCompleteGame')); return; }
		let newid = 1;
		if (this.state.cartItems.length > 0) {
			newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.id), this.state.cartItems[0].id);
		}
		this.state.currentCartItem.id = newid + 1;
		this.props.AddToCart(this.state.currentCartItem);
		this.clearAfterAddToCart();
	};
	viewAnimationResult = (flog) => {
		if (flog == 1 && this.state.isViewAnimation == true) {
			return;
		}
		this.setState({ isViewAnimation: !this.state.isViewAnimation });
	}
	clearAfterAddToCart = () => {
		this.setState({
			currentCartItem: { id: 0, balls: [], amount: 0, ballCount: 0, combination: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0 },
			random: [],
			QPBallCount: 0
		});
	};

	clearCart = () => {
		this.props.ClearCart();
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};

	quickPick = () => {
		if (this.state.currentCartItem.balls.length == this.state.currentGamesFamily.maxSelect) {
			this.state.currentCartItem = { id: 0, balls: [], amount: 0, ballCount: 0, combination: 0, total: 0, gameName: "", drawDateTime: "", drawID: 0, gameID: 0 };
			this.clearAfterAddToCart();
		}
		this.state.random = this.state.currentCartItem.balls.map(a => a.id);
		this.state.QPBallCount = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		if (this.state.QPBallCount <= 0) {
			this.state.QPBallCount = this.state.currentGamesFamily.maxSelect;
		}
		var index = -1;
		for (var k = this.state.ballStartNumber; k <= this.state.QPBallCount; k++) {
			index = this.generateRandomInteger();
			let _bal = index
			this.selectBall(_bal);
			if (this.state.showSingleMsg == true) {
				this.state.showSingleMsg = false;
				return;
			}
		}
	};

	generateRandomInteger = () => {
		var min = this.state.ballStartNumber;
		var max = this.state.ballEndNumber;
		if (this.state.random.length >= this.state.QPBallCount) {
			this.state.random = [];
		}
		for (var i = min; i <= max; i++) {
			var temp = Math.floor(max - Math.random() * (max - min));
			temp = ("0" + temp).slice(-2);
			if (this.state.random.indexOf(temp) == -1) {
				this.state.random.push(temp);
				return temp;
			}
		}
	};

	removeFromCart = (cid) => {
		this.props.RemoveFromCart(cid);
	};

	countdownCompleted = () => {
		this.RefreshAfterGameClose();
	};

	getGameTime = () => {
		let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
		if (_date != undefined && _date != "") {
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
			console.log("getGameTime : " + sTime);
			console.log("DateNow : " + Date.now());
			return sTime;
		}
		else {
			return 0;
		}
	};

	// Renderer callback with condition
	renderer = ({ total, days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state			
			return <span>Draw Closed!</span>;
		} else {
			// Render a countdown
			return <span>{zeroPad(total)}:{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
		}
	};

	getServerTime = () => {
		if (this.state._serverTimeDiff != 0 && this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0) {
			//return this.state._serverTimeDiff+Date.now()+1000;
			console.log("getServerTime : " + this.state._serverTimeDiff);
			return this.state._serverTimeDiff = (this.state._serverTimeDiff + 1000);
		}
		else {
			let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
			if (_date != undefined && _date != "") {
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
				var sTime = (new Date(ss.setSeconds(ss.getSeconds() - 10)).getTime());
				//this.state._serverTimeDiff=Date.now()-sTime;
				this.state._serverTimeDiff = sTime;
				console.log("getServerTime : " + sTime);
				console.log("DateNow : " + Date.now());
				return sTime;

			}
			else {
				return 0;
			}
		}
	};

	reCount = (rundraw) => {
		//return <span class="DropCnt" data-pid={this.state.currentDraw ? this.state.currentDraw.DrawID : ""} data-countdown={this.state.currentDraw ? this.state.currentDraw.DrawDateTime : ""}></span>;
		//return <span class="DropCnt" data-pid={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawID : ""} data-countdown={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : ""}></span>;		
		//return <span><Countdown now={Date.now} date={Date.now() + 100000} onTick={this.rendererTick()} /> </span>;

		if (this.state.currentDraw == null || rundraw == null || Object.keys(rundraw).length == 0) {

			return <span></span>;
		}
		else {
			this.state.coundownStart = 1;
			return <span key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime()} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{this.props.t('lblnextdraw')} {zeroPad((days * 24) + hours)}hh:{zeroPad(minutes)}mm:{zeroPad(seconds)}ss</div>) }} /> </span>;
		}
	};

	BuyLotteryBet = () => {
		if (this.props.user == undefined || this.props.user.mobileNo == "0") {
			alert(this.props.t('lblLoginValidation'));
			return;
		}
		if (this.state.cartItems == undefined || this.state.cartItems.length <= 0) {
			alert(this.props.t('lblplcurbet'));
			return;
		}
		let totstake = this.state.cartItems.reduce((a, b) => a = a + b.total, 0);
		let minTotStake = 500;
		let maxTotStake = 10000;
		if (this.state.currentGamesFamily != undefined) {
			minTotStake = this.state.currentGamesFamily.minStakePerTrans;
			maxTotStake = this.state.currentGamesFamily.maxStakePerTrans;
		}
		if (totstake < minTotStake) //300
		{
			alert(this.props.t('lblMinimumplayAmt') + " : " + minTotStake);
			return;
		}
		else if (totstake > maxTotStake)//10000
		{
			alert(this.props.t('lblMaximumplayAmt') + " : " + maxTotStake);
			return;
		}
		var sTime = new Date(this.props.serverTime);
		let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;
		let betInfo = "";
		//let cartList = this.state.cartItems.sort((a, b) => (a.drawID > b.drawID) ? 1 : -1);
		let cartList = this.state.cartItems.sort((a, b) => a.drawID - b.drawID);
		let _dratID = '0';
		let _betType = "0";
		let _extraBallCount = 0;
		let extraBallData = "";
		let totStakeAmt = 0;
		cartList.forEach(element => {
			let betCountPerDraw = cartList.filter(a => a.drawID == element.drawID).length;
			let ballData = element.balls.reduce((result, item) => { return `${result}${item.id};` }, "");
			ballData = ballData.substring(0, ballData.length - 1);
			if (_dratID != '0' && _dratID != element.drawID) {
				betInfo += "*";
			}
			if (_dratID == element.drawID) {
				_betType = _betType + 1;
			}
			else {
				_betType = "A".charCodeAt(0);
				//betInfo += element.drawID + "," + element.drawDateTime+","+betCountPerDraw + "@";				
			}
			//betInfo += String.fromCharCode(_betType)+","+ ballData + ","+betCountPerDraw+",0,0," + element.ballCount+ "," + element.amount  + "$";
			betInfo += String.fromCharCode(_betType) + "," + ballData + "," + betCountPerDraw + "," + element.ballCount + ",0," + element.amount + "," + element.total + ",$";
			_dratID = element.drawID;
			totStakeAmt = totStakeAmt + element.total;
		});
		//betInfo += "*";
		let dateArr = cartList[0].drawDateTime.split(" ");
		let butStr = `0,0,'${this.props.user.mobileNo}',${cartList[0].gameID},'${dateArr[0]}','${betInfo}','${TransID}',0,${cartList.length},${totStakeAmt},0,'',1,'${this.props.user.loginKey}','${this.props.i18n.language}'`;
		this.props.Buybet(butStr);
		this.clearCart();
	};

	render() {
		const { t } = this.props;
		return (
			<div className="mainContainer">
				<Header />
				<div class="Gamesection">
					<div class="container">

						<Left selectDraw={this.selectDraw} currentAvailableDraws={this.state.currentAvailableDraws} />
						<div class="col-md-9 col-sm-12 smMbl">

							<div class="drawTabs">
								<a class="" id="drawTabs1">{t('lblAllDraws')}</a>
								<a class="" id="drawTabs2">{t('lbllatresult')}</a>
								<a class="" id="virtualbetSlipBtn">{t('lblgameslip')} <span class="betCount">{this.state.cartItems.length}</span></a>
							</div>

							<div class="drawTabs1024">
								<a class="" id="drawTabs3">{t('lblAllDraws')}</a>
							</div>
							{/* <div class="BetSLiparea">
								<div class="BetSLipBtn1024">
									<a id="virtualbetSlipBtn" href="#">Game Slip</a><span class="betCount"> {this.state.cartItems.length}</span>
								</div>
							</div> */}
							<div class="lottoTimesec">
								<span><img src="./assets/img/icons/IMPAMO_JACKPOT.png?v1" /></span>
								<div class="TimeSec">
									<small style={{ borderRight: "1px solid #21409a" }}>{this.reCount(this.state.currentRunningDraw)}</small>
									<small >{this.state.currentDraw ? this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime : "-"}</small>
								</div>
							</div>

							<div className="lottoGame">

								<div class="col-md-12">
									<span id="eyeIcon" class={`eyeIcon eyeOpen ${this.state.isViewAnimation ? 'eyeClose' : ''}`} onClick={() => { this.viewAnimationResult() }} >{this.state.isViewAnimation ? t('lblHide') : t('lblShow')}</span>
									<div class="animateSec" style={{ display: this.state.isViewAnimation ? "block" : "none" }}>
										{/* <iframe class="mywebgl" width="560" height="315" src="https://www.youtube.com/embed/mxlhvWYsH3A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
										<iframe class="mywebgl" width="560" height="315" src="https://www.youtube.com/embed/1GOthf0cii8?list=PLtgeTuJl2-jX1KnMsZnKlUv2-D-pP1TQE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</div>
								</div>

								<div className="col-md-4">
									<div class="lottoInnr">
										<div class="lottoInnrTime">
											<span>{t('lblstakeval')} : <small>{this.state.currentDraw ? this.state.currentDraw.RateInPs : 0}</small></span>
										</div>
										<div className="lottoBalCont">
											{this.createBall()}
										</div>

										<div className="lottoBtns">
											<button className="redBg" onClick={() => { this.clearAfterAddToCart() }}>{t('lblclear')}</button>
											<button className="YellowBg" onClick={() => { this.quickPick() }}>QP</button>
											<button className="greenBg" onClick={() => { this.addToCart() }} >{t('lbladd')}</button>
										</div>
									</div>
								</div>
								<Right buyLotteryBet={this.BuyLotteryBet} clearCart={this.clearCart} removeFromCart={this.removeFromCart} latestResult={this.state.latestResult} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	};
}

function mapStateToProps(state) {
	const { currentGamesFamily, currentAvailableDraws, myBet, latestResult } = state.gameReducer;
	const { serverTime } = state.commonReducer;
	const { user } = state.loginReducer;
	const { cartItems, currentDraw } = state.lottoReducer;
	return { currentGamesFamily, user, currentAvailableDraws, cartItems, currentDraw, serverTime, myBet, latestResult };
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	AddToCart: lottoActions.AddToCart,
	RemoveFromCart: lottoActions.RemoveFromCart,
	RemoveClosedDrawbetFromCart: lottoActions.RemoveClosedDrawbetFromCart,
	SetCurrentDraw: lottoActions.SetCurrentDraw,
	ClearCart: lottoActions.ClearCart,
	Buybet: lottoActions.Buybet,
	GetLatestResult: gameActions.GetLatestResult,
	ClearAvailableGames: gameActions.ClearAvailableGames
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Lotto))