import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gameActions } from '../../action/gamesAction';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = { _serverTimeDiff: 0 };
		props.GetServerTime();
	}
	componentDidMount() {
		setInterval(this.getServerTimeF, 1000);
	}
	// hangout time difference 
	/* getServerTimeF = () => {
		if (this.props.serverTime != undefined && this.props.serverTime != null) {
			//console.log("ServerTime : " + this.props.serverTime);			
			this.props.SetServerTime((this.props.serverTime+1000));
		}
		else {
			return 0;
		}
	}; */

	getServerTimeF = () => {
		try {
			if (this.props.serverTime != undefined && this.props.serverTime != null) {
				this.props.SetServerTime((this.props.serverTime + 1000));
				let d = new Date();
				let dd = (d.getSeconds() % 10);
				if (dd == 0) {
					console.log(d.toString());
					var ss = this;
					setTimeout(() => { ss.getlatestTime() }, 100);
				}
			}
			else {
				return 0;
			}
		}
		catch (ex) {
			console.log(ex);
			return 0;

		}
	};

	getlatestTime = () => {
		try {
			let date1 = new Date();
			let dateMDY = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
			let dateTime = ("0" + date1.getHours()).slice(-2) + ":" + ("0" + date1.getMinutes()).slice(-2) + ":" + date1.getSeconds();
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let ss = new Date((dateMDY + " " + dateTime).replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
			let LocalTime = (ss.getTime());
			console.log("LocalTime from db : " + LocalTime);
			console.log("serverTime from db : " + this.props.serverTime);
			let dd = LocalTime - this.props.serverTime;
			console.log("diff Time from db : " + dd);
			let difTime = Math.abs(dd) / 1000;
			console.log("diff Time from db/S : " + difTime);
			if (this.state._serverTimeDiff == 0 || Math.abs(this.state._serverTimeDiff - difTime) > 9) {
				this.state._serverTimeDiff = difTime;
				console.log("this.state._serverTimeDiff : " + this.state._serverTimeDiff);
				console.log("props.GetServerTime() ");
				this.props.GetServerTime();
			}
		}
		catch (ex) {
			console.log(ex);
		}

	}

	render() {
		const { serverTime, t } = this.props;
		var sTime = new Date(serverTime);
		return (
			<footer id="myappFooter">
				<div class="container">
					<div class="col-md-3 footlogo">
						<img src="./assets/img/Footer logo.webp" />
					</div>
					<div class="col-md-3 footlink">
						<ul>
							<li><Link to="/aboutus">{t('lblwhoweare')}</Link></li>
							<li><Link to="/howtoplay">{t('lblhowitwork')}</Link></li>
							<li><Link to="/contact">{t('lblContus')}</Link></li>
							<li><Link to="/becomeanagent">{t('lblBecanagt')}</Link></li>
						</ul>
					</div>
					<div class="col-md-3 footlink">
						<ul>
							<li><Link to="/termsandcondition" target='_blank'>{t('lblTercon')}</Link></li>
							<li><Link to="/privacypolicy">{t('lblPripol')}</Link></li>
							<li><Link to="/faq">{t('lblFAQ')}</Link></li>
							<li><Link to="/socialresponse">{t('lblsocialres')}</Link></li>
						</ul>
					</div>
					<div class="col-md-3 footlink">
						<ul>
							<li><Link to="/cookiepolicy" target='_blank'>{t('lblCookiePoli')}</Link></li>
							<li><Link to="/underconstruction">{t('lblCurrency')} : {this.props.appvariables.currency}</Link></li>
							<li><a>{t('lblServerTime')} : {serverTime ? (("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + ("0" + sTime.getHours()).slice(-2) + ":" + ("0" + sTime.getMinutes()).slice(-2) + ":" + ("0" + sTime.getSeconds()).slice(-2)) : "-"}</a></li>
						</ul>
						<div class="socilaMedia">
							<span><a href="https://www.facebook.com/inzozilotto" target="_blank"><i class="fa fa-facebook-f"></i></a></span>
							<span><a href="https://twitter.com/inzozilotto" target="_blank"><i class="fa fa-twitter"></i></a></span>
							<span><a href="https://www.instagram.com/inzozilotto/" target="_blank"><i class="fa fa-instagram"></i></a></span>
							<span><a href="https://www.youtube.com/channel/UCLAJNa1LVuFLEzJC2PoOwZQ/videos" target="_blank"><i class="fa fa-youtube"></i></a></span>
						</div>
					</div>
				</div>
				<div class="text-center">
					<p> {t('lblCopyright')}@2020, Carousel LTD</p>
					<p class="theme-social-icons" id="navBarSocialLink" style={{ display: "none" }}>
						<a href="https://twitter.com/inzozilotto" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
						<a href="https://www.facebook.com/inzozilotto" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
						<a href="https://www.instagram.com/inzozilotto/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
						<a href='https://wa.me/0788301824' target='_blank'><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
						<a href="https://www.youtube.com/channel/UCLAJNa1LVuFLEzJC2PoOwZQ/videos" target="_blank"><i class="fa fa-youtube"></i></a>
					</p>
				</div>
			</footer>
		)
	};
}


function mapStateToProps(state) {
	//const { serverTime } = state.gameReducer;
	const { serverTime } = state.commonReducer;
	const { appvariables } = state.loginReducer;
	return { serverTime, appvariables };
}
const mapActionToProps = {
	SetServerTime: gameActions.SetServerTime,
	GetServerTime: gameActions.GetServerTime
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Footer))