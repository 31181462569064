import { commonClass } from "..//helpers/commonClass"
export const raffleService = {
    GetSearchTicket,
    ClearSearchTicket,
    OnlineSale
};
const _apiUrl=process.env.REACT_APP_API_URL;

function GetSearchTicket(data) {
    try{
    return fetch(`${_apiUrl}/raffle/getSearchTicket`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(results => {
            if (results != null && results.data != null) 
            { results = results.data; }
            return results;
        });
    }
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function OnlineSale(data) {
    try{    
    return fetch(`${_apiUrl}/raffle/onlineSale`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
    }
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function ClearSearchTicket(data) {
    try{
    return fetch(`${_apiUrl}/raffle/clearSearchTicket`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(results => {
            if (results != null && results.data != null) 
            { results = results.data; }
            return results;
        });
    }
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};
